import { DELIVERY_ZONES_SETS } from '~/constants/adminOptions'
import httpClient from '~/modules/core/httpClient'
import { capitalize } from '~/utils/utility'
import { get as getItem } from '~/utils/storage/memoryStorage'

const url = {
  WAREHOUSE_READ: '/warehouses/read/',
  WAREHOUSE_UPDATE: '/warehouses/update/',
  WAREHOUSE_CREATE: '/warehouses/create/',
  WAREHOUSE_DELETE: '/warehouses/delete/',
  WAREHOUSE_CHECK: '/warehouses/check/',
  WAREHOUSE_LINK_STORE: '/organizations/stores/warehouses',
  WAREHOUSE_ZONES: '/warehouse_zones',
}

const parseErrorsToString = (e) => {
  let s = ''

  if (!e) return s

  if (typeof e === 'object') {
    s = Object.entries(e)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return [capitalize(key), value.join(' ')].join(': ')
        }

        return [capitalize(key), value].join(': ')
      })
      .join('; ')
  } else if (typeof e === 'string') {
    s = e
  }

  return s
}

const get = ({ warehouses_list, organization_id }) =>
  httpClient.post(url.WAREHOUSE_READ, (params) => ({
    organization_id: organization_id || params.organization_id,
    warehouses_list,
  }))

const check = (warehouse_id) =>
  httpClient.post(url.WAREHOUSE_CHECK, (params) => ({
    organization_id: params.organization_id,
    warehouse_id,
  }))

const remove = (warehouse_id) =>
  httpClient.post(url.WAREHOUSE_DELETE, (params) => ({
    organization_id: params.organization_id,
    warehouse_id,
  }))

const update = async ({
  warehouse_id,
  name,
  phone_number,
  address_1,
  address_2,
  country,
  state,
  city,
  postal_code,
  max_range,
  local_delivery,
  local_pickup,
  organization_id,
  regular_shipping,
  waybill_address,
  return_address,
  waybill_address_option,
  return_address_option,
  is_cova_warehouse,
  instore_pickup,
  delivery,
  area_type,
  of_delivery,
  zones,
  threshold_by_categories,
  curbside_pickup_hours,
  curbside_pickup,
  curbside_pickup_instruction,
  canfleet_delivery,
  limit_all_slot,
  limit_type,
  enable_instore_hours,
  canfleet_enable_delivery_hours,
  enable_curbside_hours,
}) => {
  if (zones?.length && area_type === DELIVERY_ZONES_SETS.DISTANCE_RADIUS) {
    const distanceRadius = zones.find(
      (x) => x.area_type === DELIVERY_ZONES_SETS.DISTANCE_RADIUS,
    )
    if (distanceRadius) {
      await httpClient.json.post('/warehouse_zones', {
        warehouse_id,
        action: distanceRadius._id ? 'update' : 'create',
        parameters: distanceRadius,
      })
    }
  }
  return (httpClient.json as any)
    .create()
    .ejectRes('defaultResponseErrorHandler')
    .post(url.WAREHOUSE_UPDATE, (params) => ({
      organization_id: organization_id || params.organization_id,
      warehouse_id,
      warehouse_data: {
        name,
        phone_number,
        address_1,
        address_2,
        country,
        state,
        city,
        postal_code,
        max_range: local_delivery ? max_range : 0,
        local_delivery,
        local_pickup,
        regular_shipping: regular_shipping ?? true,
        of_delivery,
        ...(is_cova_warehouse
          ? {}
          : {
              waybill_address_option,
              waybill_address,
              return_address_option,
              return_address,
            }),
        instore_pickup,
        curbside_pickup,
        curbside_pickup_instruction,
        curbside_pickup_hours,
        delivery,
        area_type,
        threshold_by_categories,
        canfleet_delivery,
        limit_all_slot,
        limit_type,
        enable_instore_hours,
        canfleet_enable_delivery_hours,
        enable_curbside_hours,
      },
    }))
    .catch((e) => {
      throw parseErrorsToString(e?.response?.data?.message)
    })
}

const create = async ({
  name,
  phone_number,
  address_1,
  address_2,
  country,
  state,
  city,
  postal_code,
  max_range,
  local_delivery,
  local_pickup,
  organization_id,
  regular_shipping,
  waybill_address,
  return_address,
  waybill_address_option,
  return_address_option,
  is_cova_warehouse,
  of_delivery,
  instore_pickup,
  delivery,
  zones,
  area_type,
  threshold_by_categories,
  curbside_pickup_hours,
  curbside_pickup,
  curbside_pickup_instruction,
  canfleet_delivery,
  longitude,
  latitude,
  enable_instore_hours,
  canfleet_enable_delivery_hours,
  enable_curbside_hours,
}) => {
  try {
    const result = await (httpClient.json as any)
      .create()
      .ejectRes('defaultResponseErrorHandler')
      .post(url.WAREHOUSE_CREATE, (params) => ({
        organization_id: organization_id || params.organization_id,
        warehouse_data: {
          name,
          phone_number,
          address_1,
          address_2,
          country,
          state,
          city,
          postal_code,
          max_range: local_delivery ? max_range : 0,
          local_delivery,
          local_pickup,
          regular_shipping: regular_shipping ?? true,
          of_delivery,
          instore_pickup,
          curbside_pickup,
          curbside_pickup_instruction,
          curbside_pickup_hours,
          delivery,
          area_type,
          threshold_by_categories,
          canfleet_delivery,
          ...(longitude ? { longitude } : {}),
          ...(latitude ? { latitude } : {}),

          ...(is_cova_warehouse
            ? {}
            : {
                waybill_address_option,
                waybill_address,
                return_address_option,
                return_address,
              }),
          enable_instore_hours,
          canfleet_enable_delivery_hours,
          enable_curbside_hours,
        },
      }))
    const savedZones = (zones || []).filter(
      (z) => !!area_type && z.area_type === area_type,
    )
    const radiusZone = (zones || []).find(
      (z) =>
        area_type === DELIVERY_ZONES_SETS.DISTANCE_RADIUS &&
        z.area_type === DELIVERY_ZONES_SETS.DISTANCE_RADIUS,
    )

    if (savedZones.length) {
      await httpClient.json.post(url.WAREHOUSE_ZONES, {
        action: 'create_many',
        warehouse_id: result.warehouse_id,
        parameters: {
          data: savedZones,
        },
      })
    }
    return {
      ...result,
      ...([
        DELIVERY_ZONES_SETS.DRAWN_ZONES,
        DELIVERY_ZONES_SETS.POSTAL_CODE,
      ].includes(area_type)
        ? { delivery_area_counting: savedZones.length }
        : {}),
      ...(area_type === DELIVERY_ZONES_SETS.DISTANCE_RADIUS
        ? {
            max_delivery_distance: radiusZone?.max_delivery_distance || 0,
            max_delivery_distance_type:
              radiusZone?.max_delivery_distance_type || 'kilometer',
          }
        : {}),
    }
  } catch (e) {
    throw parseErrorsToString(e?.response?.data?.message)
  }
}

const mapLocationIds = (locationIds = []) => {
  if (!locationIds.length) {
    return ''
  }
  const allLocation = locationIds.map((id) => `location_ids=${id}`)
  return `?${allLocation.join('&')}`
}

const getWhLinkedStore = (store_id, location_ids) =>
  httpClient.get(`${url.WAREHOUSE_LINK_STORE}${mapLocationIds(location_ids)}`, {
    params: {
      ...(getItem('ORGANIZATION_ID')
        ? { organization_id: getItem('ORGANIZATION_ID') }
        : {}),
      store_id,
    },
  })

const warehouseApi = {
  get,
  check,
  remove,
  update,
  create,
  getWhLinkedStore,
}

export default warehouseApi
