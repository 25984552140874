export const ADMIN_ROUTES = {
  STORE: '/s/:storeId',
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
  WOO_SETTINGS: '/settings/woo',
  ACCOUNT: '/account',
  ADMIN_OPTIONS: '/admin-options',
  USER_PROFILE: '/profile',
  LOGOUT: '/logout',
  DASHBOARD_SERVICE: '/service-dashboard',
  DASHBOARD_MARKETING: '/marketing-dashboard',
  HOME: '/home',
  WORKSPACE_SWITCHER: '/workspace-switcher/:id',
}

export const PRODUCT_ROUTES = {
  PRODUCTS: '/products',
  CREATE_PRODUCT: '/products/create',
  EDIT_PRODUCT: '/products/:productId/edit/',
  TRASH_PRODUCTS: '/products/trash',
}

export const CUSTOMER_ROUTES = {
  LIST: '/customers',
  SERVICE: '/service-customers',
  MARKETING: '/marketing-customers',
  DETAIL: '/customers/:id/view',
  CREATE: '/customers/create',
  SEND_EMAIL: '/customers/send-email',
}

export const COUPON_ROUTERS = {
  LIST: '/loyalty/coupons',
  CREATE: '/loyalty/coupons/create',
  UPDATE: '/loyalty/coupons/:couponId?/edit',
}

export const POINT_AND_REWARDS_ROUTERS = {
  POINT: '/loyalty/point-reward',
}

export const TAG_ROUTES = {
  LIST: '/products/tags',
  EDIT: '/products/tags/:tagId/edit',
  CREATE: '/products/tags/create',
}

export const CATEGORY_ROUTES = {
  LIST: '/products/categories',
  EDIT: '/products/categories/:categoryId/edit',
  CREATE: '/products/categories/create',
}

export const MEDIA_ROUTES = {
  MEDIA_LIBRARY: '/media',
}

export const ORDER_ROUTES = {
  ORDERS: '/orders',
  CREATE_ORDER: '/orders/create',
  EDIT_ORDER: '/orders/:orderId/edit/',
  TRASH_ORDERS: '/orders/trash',
}
export const ATTRIBUTE_ROUTES = {
  ATTRIBUTES: '/products/attributes',
  CREATE: '/products/attributes/create',
  EDIT: '/products/attributes/:attributeId/edit/',
}
export const FULFILLMENT_ROUTES = {
  FULFILLMENT: '/fulfillment',
  PICKUP_DELIVERY: '/fulfillment/pickup-delivery',
  MAIL_ORDERS: '/fulfillment/mail-orders',
  ONFLEET_DELIVERIES: '/fulfillment/onfleet-deliveries',
  COMPLETED_ORDERS: '/fulfillment/completed-orders',
}
export const COMMENT_ROUTES = {
  COMMENTS: '/products/comments',
  COMMENT_TRASH: '/products/comments/trash',
  COMMENT_SPAM: '/products/comments/spam',
  EDIT: '/products/:productId/comments/:commentId/edit/',
}

export const INVENTORY_ROUTES = {
  WAREHOUSES: '/inventory/warehouses',
  CREATE_WAREHOUSES: '/inventory/warehouses/create',
  TRANSFER: '/inventory/transfer',
  ADJUSTMENT: '/inventory/adjustment',
}

export const ANALYTIC_ROUTES = {
  PARENT: '/insights',
  REPORT_DASHBOARD: '/insights/custom-report',
  REPORT_CREATING: '/insights/custom-report/create',
  REPORT_DEFAULT: '/insights/report/:reportName?',
  REPORT_VIEW: '/insights/custom-report/view/:id?',
  REPORT_EDIT: '/insights/custom-report/edit/:id?',
  EXPORT_PDF: '/analytic/view/:id?',
}

export const COVA_INSIGHTS_ROUTES = {
  RAW: '/insights',
  REPORT_DEFAULT: '/insights/:reportName?',
  EXPORT_PDF: '/analytic/view/:id?',
}

export const GREENLINE_INSIGHTS_ROUTES = {
  // RAW: '/insights',
  REPORT_DEFAULT: '/insights/greenline/:reportName?',
  // EXPORT_PDF: '/analytic/view/:id?',
}

export const PROMOTION_ROUTES = {
  PROMOTION: '/loyalty/promotion/:tab?/:action?/:promotionId?',
  SERVICE_SALE: '/loyalty/service-sale/:tab?/:action?/:promotionId?',
  MARKETING_SALE: '/loyalty/marketing-sale/:tab?/:action?/:promotionId?',
  PROMOTION_RAW: '/loyalty/promotion',
}

export const PERFORMANCE_ROUTES = {
  PERFORMANCE_PRODUCTS: '/products/performance',
}

export const SETTING_ROUTES = {
  ECOMMERCE: `${ADMIN_ROUTES.SETTINGS}/ecommerce`,
  USER: `${ADMIN_ROUTES.SETTINGS}/users`,
  LOCATIONS: `${ADMIN_ROUTES.SETTINGS}/locations`,
  POS: `${ADMIN_ROUTES.SETTINGS}/pos`,
  MANAGE_CATEGORIES: 'manage-categories',
  CHAT_WIDGET: `${ADMIN_ROUTES.SETTINGS}/chat-widget`,
  SOCIAL_CHAT: `${ADMIN_ROUTES.SETTINGS}/social-chats`,
  SERVICE_DEPT: `${ADMIN_ROUTES.SETTINGS}/service-departments`,
  STORES: `${ADMIN_ROUTES.ADMIN_OPTIONS}/stores`,
  WIDGET_P2: `${ADMIN_ROUTES.ADMIN_OPTIONS}?tab=widget`,
  CHANNEL_P2: `${ADMIN_ROUTES.ADMIN_OPTIONS}?tab=channel`,
  DEPARTMENT_P2: `${ADMIN_ROUTES.ADMIN_OPTIONS}?tab=department`,
  WIDGET: `${ADMIN_ROUTES.SETTINGS}/chat-widget`,
  CHANNEL: `${ADMIN_ROUTES.SETTINGS}/social-chats`,
  DEPARTMENT: `${ADMIN_ROUTES.SETTINGS}/service-departments`,
}

export const DEFAULT_URL = '/#'
