/* eslint-disable no-param-reassign */
import produce from 'immer'
import { ANALYTIC_ACTION } from '../actions/analytic'

const initialState = {
  timeZones: {
    data: [],
    loading: false,
    error: '',
  },
  report: {
    data: {},
    loading: false,
    error: '',
  },
  customReports: {
    data: [],
    loading: false,
    error: '',
  },
}

const convertData = (data) =>
  (data || []).reduce((charts, chartItem) => {
    const chartKey = chartItem.type
    const chartData = chartItem.data || {}
    if (
      !!chartData.headers &&
      !!chartData.rows &&
      chartData.headers.length > 0
    ) {
      if (chartData.total) {
        charts[chartKey] = {
          data: chartData.rows.map((item) =>
            chartData.headers.reduce((acc, key, idx) => {
              acc[key] = item[idx]
              return acc
            }, {}),
          ),
          total: chartData.total,
        }
      } else {
        charts[chartKey] = chartData.rows.map((item) =>
          chartData.headers.reduce((acc, key, idx) => {
            acc[key] = item[idx]
            return acc
          }, {}),
        )
      }
    } else {
      charts[chartKey] = chartData
    }
    if (chartItem.sub_components && chartItem.sub_components.length > 0) {
      charts[chartKey] = convertData(chartItem.sub_components)
    }

    return charts
  }, {})

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case ANALYTIC_ACTION.GET_TIME_ZONES:
        draft.timeZones.data = []
        break
      case ANALYTIC_ACTION.FETCH_CUSTOM_ALL:
        draft.customReports.data = []
        draft.customReports.loading = true
        draft.customReports.error = ''
        break
      case ANALYTIC_ACTION.FETCH_CUSTOM_ALL_SUCCESS:
        draft.customReports.data = payload
        draft.customReports.loading = false
        draft.customReports.error = ''
        break
      case ANALYTIC_ACTION.FETCH_CUSTOM_ALL_ERROR:
        draft.customReports.loading = false
        draft.customReports.error = payload
        break
      case ANALYTIC_ACTION.FETCH_DEFAULT_REPORT:
        draft.report.data = {}
        draft.report.loading = true
        draft.report.error = ''
        break
      case ANALYTIC_ACTION.FETCH_DEFAULT_REPORT_SUCCESS:
        draft.report.data = convertData(payload)
        draft.report.loading = false
        draft.report.error = ''
        break
      case ANALYTIC_ACTION.FETCH_DEFAULT_REPORT_ERROR:
        draft.report.loading = false
        draft.report.error = payload
        break
      case ANALYTIC_ACTION.CLEAR_DEFAULT_REPORT:
        draft.report.loading = false
        draft.report.data = {}
        draft.report.error = ''
        break
      case ANALYTIC_ACTION.CREATE_CUSTOM_REPORT:
        draft.customReports.loading = true
        break
      case ANALYTIC_ACTION.CREATE_CUSTOM_REPORT_SUCCESS:
        draft.customReports.loading = false
        break
      case ANALYTIC_ACTION.CREATE_CUSTOM_REPORT_ERROR:
        draft.customReports.loading = false
        break
      case ANALYTIC_ACTION.EDIT_CUSTOM_REPORT:
        draft.customReports.loading = true
        break
      case ANALYTIC_ACTION.EDIT_CUSTOM_REPORT_SUCCESS:
        draft.customReports.loading = false
        break
      case ANALYTIC_ACTION.EDIT_CUSTOM_REPORT_ERROR:
        draft.customReports.loading = false
        break
      case ANALYTIC_ACTION.DELETE_CUSTOM_REPORT:
        draft.customReports.loading = true
        break
      case ANALYTIC_ACTION.DELETE_CUSTOM_REPORT_SUCCESS:
        draft.customReports.loading = false
        break
      case ANALYTIC_ACTION.DELETE_CUSTOM_REPORT_ERROR:
        draft.customReports.loading = false
        break
      default:
        break
    }
  })

export default reducer
