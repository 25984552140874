import produce from 'immer'
import { rewardAction } from '../actions/reward'
import { DEFAULT_PAGE_SIZE } from '~/constants/settings'

const initialState = {
  cachingList: {},
  list: [],
  listPointLog: [],
  currentPage: 0,
  limit: 10 || DEFAULT_PAGE_SIZE,
  filters: {},
  totalPages: 1,
  totalItems: 10,
  loading: false,
  error: '',
  listPointLogCustomer: {
    loading: false,
    data: {
      items: [],
      total_items: 0,
      total_pages: 0,
      current_page: 1,
      limit: 10 || DEFAULT_PAGE_SIZE,
    },
    error: '',
  },
  detail: {
    loading: false,
    data: {},
    error: '',
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action

    if (type === rewardAction.GET_LIST_REWARD) {
      draft.loading = true
    }

    if (type === rewardAction.GET_LIST_REWARD_OK) {
      draft.loading = false
      draft.list = payload.items
      draft.currentPage = payload.current_page
      draft.totalPages = payload.total_pages
      draft.totalItems = payload.total_items
      draft.limit = payload.limit
    }

    if (type === rewardAction.GET_LIST_REWARD_ERR) {
      draft.loading = false
      draft.error = payload.error
      draft.list = []
    }
    if (type === rewardAction.UPDATE_CUSTOMER_POINT) {
      draft.loading = true
    }
    if (type === rewardAction.UPDATE_CUSTOMER_POINT_OK) {
      draft.loading = false
    }
    if (type === rewardAction.UPDATE_CUSTOMER_POINT_ERR) {
      draft.loading = false
      draft.error = 'error'
    }

    if (type === rewardAction.GET_LIST_POINT_LOG) {
      draft.loading = true
    }
    if (type === rewardAction.GET_LIST_POINT_LOG_OK) {
      draft.loading = false
      draft.listPointLog = payload.items
      draft.currentPage = payload.current_page
      draft.totalPages = payload.total_pages
      draft.totalItems = payload.total_items
      draft.limit = payload.limit
    }
    if (type === rewardAction.GET_LIST_POINT_LOG_ERR) {
      draft.loading = false
      draft.error = payload.error
    }

    if (type === rewardAction.GET_LIST_POINT_LOG_CUSTOMER) {
      draft.listPointLogCustomer.loading = true
    }
    if (type === rewardAction.GET_LIST_POINT_LOG_CUSTOMER_OK) {
      draft.listPointLogCustomer.loading = false
      draft.listPointLogCustomer.data = payload
    }
    if (type === rewardAction.GET_LIST_POINT_LOG_CUSTOMER_ERR) {
      draft.listPointLogCustomer.loading = false
      draft.listPointLogCustomer.error = payload.error
    }

    if (type === rewardAction.GET_POINT_LOG_DETAIL) {
      draft.detail.loading = true
    }
    if (type === rewardAction.GET_POINT_LOG_DETAIL_OK) {
      draft.detail.loading = false
      draft.detail.data = payload
    }
    if (type === rewardAction.GET_POINT_LOG_DETAIL_ERR) {
      draft.detail.loading = false
      draft.detail.error = payload.error
    }
  })

export default reducer
