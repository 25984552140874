/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  HeaderMobile,
  SideBar,
  MenuList,
  DropdownMenu,
  MenuItem,
  NavList,
  NavItem,
  handleCloseSidebar,
  MenuItemLabel,
  MenuItemHeader,
  MenuItemHorizontalLine,
  MenuItemSelection,
  MenuSelect,
  Avatar,
  Icon,
} from 'bs-unified-ui'
import {
  Link,
  useParams,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import clsx from 'clsx'
import { Box, useTheme } from '@mui/material'
import { withStyles } from '~/themes/useStyles'
import { useGetPlanTier, useNavigationConfig } from './hook'
import {
  APPS,
  MENU_ITEM_TYPE,
  POPUP_ID_ITEMS,
  EXCEPTION_HIDDEN_IDS,
} from '~/constants/app'
import { useAuth } from '~/modules/auth/redux/hook'
import { MENU_KEY_NAME, NavPermission } from './navBarConfig'
import { useHasUnpickedOrders } from '~/redux/hooks/cantecFulfillment'
import style from './style'
import useHandleChangeStore from '~/utils/hooks/useHandleChangeStore'
import { useUser } from '~/redux/hooks/user'
import { PROFILE_TYPES } from '~/modules/auth/constants'
import { useGetStore } from '~/redux/hooks/store'
import { STORE_TYPES } from '~/constants/store'
import { useUserSession } from '~/utils/hooks/useUserSession'

const CLEAR_SESSION_APPLICATION = ['service', 'canfleet']

const UnifiedNavbar = ({ classes }) => {
  const theme = useTheme()
  const { storeId } = useParams()
  const activeStore = useGetStore()
  const { pathname } = useLocation()
  const { authState } = useAuth()
  const navigate = useNavigate()
  const [sessionData, setSessionData] = useUserSession()

  const {
    hasUnpickedOrders,
    hasUnpickedPickupAndDeliveryOrders,
    hasUnpickedMailOrders,
    hasUnpickedOnfleetDeliveryOrders,
  } = useHasUnpickedOrders(storeId)

  const { data } = useNavigationConfig(storeId)
  const {
    user: { organization },
  } = useUser()
  const { data: planTier } = useGetPlanTier({
    enabled: [PROFILE_TYPES.ECOMMERCE, PROFILE_TYPES.MULTI_STORE].includes(
      organization?.profile_type,
    ),
  })

  const { handleChangeStore } = useHandleChangeStore()

  const applications = useMemo(() => data?.section1 || [], [data?.section1])
  const bottomApplications = useMemo(
    () => data?.section3 || [],
    [data?.section3],
  )
  const bottomMenus = useMemo(() => data?.section4 || [], [data?.section4])
  const urlWithStoreID = useCallback(
    (url, _id) => (url || '').replace(':storeId', _id),
    [],
  )

  const reddots = useMemo(
    () => ({
      [MENU_KEY_NAME.FULFILLMENT]: hasUnpickedOrders,
      [MENU_KEY_NAME.PICKUP_AND_DELIVERY]: hasUnpickedPickupAndDeliveryOrders,
      [MENU_KEY_NAME.MAIL_ORDERS]: hasUnpickedMailOrders,
      [MENU_KEY_NAME.ONFLEET_DELIVERIES]: hasUnpickedOnfleetDeliveryOrders,
    }),
    [
      hasUnpickedOrders,
      hasUnpickedPickupAndDeliveryOrders,
      hasUnpickedOnfleetDeliveryOrders,
      storeId,
    ],
  )

  const isHiddenPlanAndBilling = useMemo(
    () =>
      [PROFILE_TYPES.ECOMMERCE, PROFILE_TYPES.MULTI_STORE].includes(
        organization?.profile_type,
      ) && !!planTier?.pricingInfo?.isFreemium,
    [planTier?.pricingInfo?.isFreemium, organization?.profile_type],
  )

  const checkActive = useCallback(
    (href) => !!matchPath({ path: href, exact: true }, pathname),
    [pathname],
  )
  const checkItemActive = useCallback(
    (item) =>
      (item.url && checkActive(item.url)) ||
      (item.hiddenHrefs || []).some((href) => checkActive(href)),
    [checkActive],
  )

  const tempAppActiveNameRef = useRef(null)

  const appActiveName = useMemo(() => {
    const tempData = [...(data.section1 || []), ...(data.section3 || [])]

    const checkAppActive = (item = {}) => {
      if (item.popup_items) {
        return (item.popup_items || []).some((popupItem = {}) => {
          if (popupItem.section2)
            return (popupItem.section2 || []).some(
              (s2 = {}) => s2.url && checkActive(s2.url),
            )
          if (popupItem.url) return popupItem.url && checkActive(popupItem.url)

          return false
        })
      }

      if (!item.url) return false

      return (
        checkActive(item.url) ||
        (item.section2 || []).some(
          (s2 = {}) =>
            checkItemActive(s2) || (s2.sub_items || []).some(checkItemActive),
        )
      )
    }

    const tempActivatedApps = tempData.filter(checkAppActive)

    const tempActivatedAppId =
      tempActivatedApps?.length === 1 || !tempAppActiveNameRef?.current
        ? tempActivatedApps?.[0]?.id
        : tempActivatedApps?.find(
            (t) => t?.id === tempAppActiveNameRef?.current,
          )?.id

    return tempActivatedAppId
  }, [data.section1, data.section3, checkActive])

  const popupItemActiveName = useMemo(() => {
    const tempPopupItems =
      data?.section3?.find((s) => !!s?.popup_items)?.popup_items || []
    const tempPopupItemActive = tempPopupItems.find(
      (item = {}) =>
        (item.url && checkActive(item.url)) ||
        (item.section2 || []).some((s2 = {}) => s2.url && checkActive(s2.url)),
    )

    return tempPopupItemActive?.id
  }, [data.section3, checkActive])

  const renderSubItems = useCallback(
    (subItems = []) => {
      if (!subItems.length) return []

      const tempSubItems = subItems
        .filter((sm) =>
          NavPermission[sm.id]
            ? NavPermission[sm.id](storeId)(authState.data)
            : true,
        )
        .map((sm) => ({
          ...sm,
          dot: reddots[sm.id],
        }))

      /* length sub items = 1 => using url in parent item */
      if (tempSubItems?.length <= 1) return []

      return tempSubItems
    },
    [data?.section2, storeId, authState.data, reddots],
  )

  const menu = useMemo(() => {
    const dataSection2 = (() => {
      const tempData = [
        ...(data?.section1 || []),
        ...(data?.section3 || []),
      ]?.find((s) => s?.id === appActiveName)

      if (tempData?.popup_items) {
        return (
          tempData?.popup_items?.find((s) => s.id === popupItemActiveName)
            ?.section2 || []
        )
      }

      return tempData?.section2 || []
    })()

    return dataSection2.map((m) => ({
      ...m,
      sub_items: renderSubItems(m.sub_items),
      dot: reddots[m.id],
      hasChildActive: (m.sub_items || []).some(checkItemActive),
    }))
  }, [
    data,
    appActiveName,
    popupItemActiveName,
    checkItemActive,
    pathname,
    reddots,
  ])

  const renderBottom = useMemo(
    () =>
      bottomApplications.map(({ id, url, popup_items, icon }) => {
        if (popup_items) {
          const getUrl = (popupItem = {}) => {
            if (popupItem.value === POPUP_ID_ITEMS.LOG_OUT) return '/logout'
            if (popupItem.url) return urlWithStoreID(popupItem.url, storeId)

            return null
          }

          const tempPopupItems = (popup_items || []).map((i = {}) => ({
            value: i.id,
            label: i.caption,
            icon: i.icon,
            url: i.url,
          }))

          return (
            <Box
              key={id}
              className={clsx(
                'bs-unified__nav-item',
                appActiveName === id && 'bs-unified__nav-item-active',
              )}
              sx={{
                '& .MuiFormControl-root': {
                  minWidth: 'unset',
                },
              }}
            >
              <MenuSelect
                options={tempPopupItems}
                onSelect={(_, v) => navigate(getUrl(v))}
                renderElement={
                  <Avatar sx={{ width: 30, height: 30 }} src={url} />
                }
                menuItemClassName={classes.menuItemPopup}
                dataTestId={id}
              />
            </Box>
          )
        }

        if (url) {
          return (
            <Link data-testid={id} key={id} to={urlWithStoreID(url, storeId)}>
              <NavItem key={id} id={icon} active={appActiveName === id} />
            </Link>
          )
        }

        return null
      }),
    [bottomApplications, appActiveName, storeId],
  )

  const renderMenu = useCallback(
    (menuList = []) =>
      menuList.map((item, index) => {
        if (
          item.id === EXCEPTION_HIDDEN_IDS.PLAN_AND_BILLING &&
          isHiddenPlanAndBilling
        )
          return null

        if (item.type === MENU_ITEM_TYPE.HEADER) {
          return (
            <MenuItemHeader
              key={index}
              wsName={item.label}
              title={item.caption || ''}
              url={item.url}
              target={item.target}
              captionIcon={item.captionIcon}
            />
          )
        }

        if (item.type === MENU_ITEM_TYPE.SELECTION) {
          const tempData = (item?.data || []).map((store = {}) => ({
            label: store.store_name,
            value: store.store_id,
          }))
          return (
            <MenuItemSelection
              key={index}
              title={item.caption || ''}
              wsName={item.label}
              value={storeId}
              options={tempData}
              onChange={(e) => handleChangeStore(e.target.value)}
              data-testid={item.id}
            />
          )
        }

        if (item.type === MENU_ITEM_TYPE.LABEL) {
          return <MenuItemLabel key={index} title={item.caption || ''} />
        }

        if (item.type === MENU_ITEM_TYPE.HORIZONTAL_LINE) {
          return (
            <div key={index}>
              <MenuItemHorizontalLine />
            </div>
          )
        }
        if (item.sub_items?.length) {
          return (
            <DropdownMenu
              key={item.id}
              icon={item.icon}
              buttonText={item.caption}
              dot={item.dot}
              hasChildActive={item.hasChildActive}
              dataTestId={item.id}
            >
              {item.sub_items?.map((subItem) => (
                <Link
                  data-testid={subItem?.id}
                  key={subItem?.id}
                  to={urlWithStoreID(subItem.url, storeId)}
                >
                  <MenuItem
                    key={subItem?.id}
                    isSub
                    active={checkItemActive(subItem)}
                    dot={subItem.dot}
                  >
                    {subItem?.caption}
                  </MenuItem>
                </Link>
              ))}
            </DropdownMenu>
          )
        }

        return (
          <Link
            data-testid={item.id}
            key={item.id}
            to={urlWithStoreID(item.url, storeId)}
            {...(item?.target === '_blank' ? { target: '_blank' } : {})}
          >
            <MenuItem
              key={item.id}
              icon={item.icon}
              active={checkItemActive(item)}
            >
              {item.caption}
              {item.id === EXCEPTION_HIDDEN_IDS.PLAN_AND_BILLING &&
              organization?.profile_type === PROFILE_TYPES.CANNABIS ? (
                <Icon
                  name="external-link"
                  color={theme.palette.primary.main}
                  className={classes.rightIcon}
                />
              ) : null}
              {item.id === EXCEPTION_HIDDEN_IDS.POS ? (
                <Icon
                  className={classes.posIcon}
                  style={{ width: 28, height: 16 }}
                  unset
                  name={(() => {
                    if (activeStore?.storeType === STORE_TYPES.COVA)
                      return 'cova_cannabis'
                    if (activeStore?.storeType === STORE_TYPES.GREENLINE)
                      return 'greenline_cannabis'
                    return ''
                  })()}
                />
              ) : null}
            </MenuItem>
          </Link>
        )
      }),
    [
      storeId,
      pathname,
      isHiddenPlanAndBilling,
      organization?.profile_type,
      activeStore?.storeType,
    ],
  )

  useEffect(() => {
    handleCloseSidebar()
  }, [pathname])

  useEffect(() => {
    tempAppActiveNameRef.current = appActiveName
  }, [appActiveName])

  return (
    <div className={classes.container}>
      <HeaderMobile />
      <SideBar
        renderMenu={
          <MenuList renderMenuBottom={renderMenu(bottomMenus)}>
            {renderMenu(menu)}
          </MenuList>
        }
        isMinimal={appActiveName === APPS.BREADSTACK}
      >
        <NavList renderBottom={renderBottom}>
          {applications.map(({ id, icon, url = '', target }) => (
            <Link
              data-testid={id}
              key={id}
              to={urlWithStoreID(url, storeId)}
              target={target}
              onClick={() => {
                if (
                  sessionData.location_id &&
                  CLEAR_SESSION_APPLICATION.includes(id)
                ) {
                  const { location_id, ...rest } = sessionData
                  setSessionData({
                    ...rest,
                  })
                }
              }}
            >
              <NavItem key={id} id={icon} active={appActiveName === id} />
            </Link>
          ))}
        </NavList>
      </SideBar>
    </div>
  )
}

UnifiedNavbar.propTypes = {
  classes: PropTypes.shape().isRequired,
}

export default withStyles(style)(UnifiedNavbar)
