import { useLayoutEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import Routers from '~/router'
import StorgeUtils from '~/utils/storage/memoryStorage'
import { GOOGLE_ANALYTIC_TRACKING_ID } from '~/constants/environment'

const useGATracking = () => {
  const location = useLocation()
  useLayoutEffect(() => {
    if (GOOGLE_ANALYTIC_TRACKING_ID) {
      const cached = StorgeUtils.get('GA_TRACKING')
      let route = (cached || {})[location.pathname]
      if (!route) {
        const matchedRouter = Routers.find((r) =>
          matchPath(r, location.pathname),
        )
        if (matchedRouter) {
          route = {
            path: matchedRouter.path,
            exact: matchedRouter.exact,
            routeName: matchedRouter.routeName,
          }
          StorgeUtils.set('GA_TRACKING', {
            ...cached,
            [location.pathname]: route,
          })
        }
      }

      window.gtag('config', GOOGLE_ANALYTIC_TRACKING_ID, {
        page_title: route ? route.routeName || route.path : location.pathname,
        page_path: route ? route.path : location.pathname,
      })
    }
  }, [location.pathname])
}

export default useGATracking
