import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, DialogContent, Grid } from '@mui/material'

import { GetApp, Close } from '@mui/icons-material'
import { withStyles } from '~/themes/useStyles'

import Button from '~/components/common/Button'
import style from './style'
import { CANTEC_TYPE_NAME, CANTEC_TYPE_SLUG } from '~/constants/store'
import storeApi from '~/services/apis/store'
import { PLUGIN_STORAGE_BASE_URL } from '~/constants/environment'

const PluginInstruction = ({ classes, type, onClose }) => {
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const name = CANTEC_TYPE_NAME[type] || ''
  const slug = CANTEC_TYPE_SLUG[type] || ''

  const getPluginInfo = async () => {
    try {
      setLoading(true)
      const res = await storeApi.getCantecPluginInfo(slug)

      const downloadUrl = `${(PLUGIN_STORAGE_BASE_URL || '').replace(/\/+$/, '')}/${res.slug}-${res.version}.zip`
      setUrl(downloadUrl)
    } catch {
      setError('Some thing went wrong! Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPluginInfo()
  }, [type])

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        Install {name} Plugin
        <Close
          role="presentation"
          onClick={onClose}
          className={classes.closeBtn}
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container className={classes.gridContainer}>
          <Grid item sm={12} md={10}>
            <h3>1. Download {name} Plugin</h3>
            <p>
              If download did not start automatically, please click the button
              below to download manually.
            </p>
            <form method="get" target="_blank" action={url}>
              <Button
                rounded
                type="submit"
                loading={loading}
                disabled={!loading && !url}
              >
                <GetApp />
                &nbsp;
                {loading ? 'PLEASE WAIT...' : 'DOWNLOAD PLUGIN'}
              </Button>
            </form>
            {error && <p className={classes.errorMessage}>{error}</p>}
          </Grid>

          <Grid item sm={12} md={10} className={classes.mt}>
            <h3>2. Find “Plugins” in WordPress Dashboard</h3>
            <p>
              In WordPress dashboard, go to Plugins &gt; Add New, click “Upload
              Plugin” on the page.
            </p>
          </Grid>
          <Grid item sm={12}>
            <img src="/img/plugin_step_2.png" alt="" role="presentation" />
          </Grid>

          <Grid item sm={12} md={10} className={classes.mt}>
            <h3>3. Upload Plugin and Install</h3>
            <p>
              Click “Choose File”, select the plugin that you just downloaded,
              and then click “Install Now”.
            </p>
          </Grid>
          <Grid item sm={12}>
            <img src="/img/plugin_step_3.png" alt="" />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  )
}

PluginInstruction.propTypes = {
  classes: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withStyles(style)(PluginInstruction)
