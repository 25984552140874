export const inventoryWarehousesAction = {
  GET_INVENTORY_WAREHOUSES: 'inventoryWarehouses.GET_INVENTORY_WAREHOUSES',
  GET_INVENTORY_WAREHOUSES_OK:
    'inventoryWarehouses.GET_INVENTORY_WAREHOUSES_OK',
  GET_INVENTORY_WAREHOUSES_ERR:
    'inventoryWarehouses.GET_INVENTORY_WAREHOUSES_ERR',

  GET_INVENTORY_WAREHOUSE_DETAIL:
    'inventoryWarehouses.GET_INVENTORY_WAREHOUSE_DETAIL',
  GET_INVENTORY_WAREHOUSE_DETAIL_OK:
    'inventoryWarehouses.GET_INVENTORY_WAREHOUSE_DETAIL_OK',
  GET_INVENTORY_WAREHOUSE_DETAIL_ERR:
    'inventoryWarehouses.GET_INVENTORY_WAREHOUSE_DETAIL_ERR',

  GET_INVENTORY_WAREHOUSE_CARRYING_STORE:
    'inventoryWarehouses.GET_INVENTORY_WAREHOUSE_CARRYING_STORE',
  GET_INVENTORY_WAREHOUSE_CARRYING_STORE_OK:
    'inventoryWarehouses.GET_INVENTORY_WAREHOUSE_CARRYING_STORE_OK',
  GET_INVENTORY_WAREHOUSE_CARRYING_STORE_ERR:
    'inventoryWarehouses.GET_INVENTORY_WAREHOUSE_CARRYING_STORE_ERR',

  ADD_NEW_INVENTORY_PRODUCT: 'inventoryWarehouses.ADD_NEW_INVENTORY_PRODUCT',
  ADD_NEW_INVENTORY_PRODUCT_OK:
    'inventoryWarehouses.ADD_NEW_INVENTORY_PRODUCT_OK',
  ADD_NEW_INVENTORY_PRODUCT_ERR:
    'inventoryWarehouses.ADD_NEW_INVENTORY_PRODUCT_ERR',

  UPDATE_NEW_INVENTORY_PRODUCT:
    'inventoryWarehouses.UPDATE_NEW_INVENTORY_PRODUCT',
  UPDATE_NEW_INVENTORY_PRODUCT_OK:
    'inventoryWarehouses.UPDATE_NEW_INVENTORY_PRODUCT_OK',
  UPDATE_NEW_INVENTORY_PRODUCT_ERR:
    'inventoryWarehouses.UPDATE_NEW_INVENTORY_PRODUCT_ERR',

  DELETE_INVENTORY_WAREHOUSE_DETAIL:
    'inventoryWarehouses.DELETE_INVENTORY_WAREHOUSE_DETAIL',
  DELETE_INVENTORY_WAREHOUSE_DETAIL_OK:
    'inventoryWarehouses.DELETE_INVENTORY_WAREHOUSE_DETAIL_OK',
  DELETE_INVENTORY_WAREHOUSE_DETAIL_ERR:
    'inventoryWarehouses.DELETE_INVENTORY_WAREHOUSE_DETAIL_ERR',

  CLEAR_INVENTORY_WAREHOUSE_DETAIL:
    'inventoryWarehouses.CLEAR_INVENTORY_WAREHOUSE_DETAIL',
  CLEAR_INVENTORY_WAREHOUSE_LIST:
    'inventoryWarehouses.CLEAR_INVENTORY_WAREHOUSE_LIST',

  GET_ALL_TAGS: 'inventoryWarehouses.GET_ALL_TAGS',
  GET_ALL_TAGS_OK: 'inventoryWarehouses.GET_ALL_TAGS_OK',
  GET_ALL_TAGS_ERR: 'inventoryWarehouses.GET_ALL_TAGS_ERR',
}

const getInventoryWarehouses = (
  params,
  { onSuccess = () => {}, onError = () => {} },
  tab,
) => ({
  type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSES,
  payload: {
    tab,
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const getInventoryWarehouseDetail = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_DETAIL,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

export const getInventoryWarehouseCarryingDetail = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: inventoryWarehousesAction.GET_INVENTORY_WAREHOUSE_CARRYING_STORE,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const addNewInventoryWarehouses = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: inventoryWarehousesAction.ADD_NEW_INVENTORY_PRODUCT,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const updateInventoryWarehouse = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: inventoryWarehousesAction.UPDATE_NEW_INVENTORY_PRODUCT,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const deleteInventoryWarehouse = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: inventoryWarehousesAction.DELETE_INVENTORY_WAREHOUSE_DETAIL,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const clearInventoryWarehouseDetail = () => ({
  type: inventoryWarehousesAction.CLEAR_INVENTORY_WAREHOUSE_DETAIL,
  payload: {},
})

const clearInventoryWarehouseList = () => ({
  type: inventoryWarehousesAction.CLEAR_INVENTORY_WAREHOUSE_LIST,
  payload: {},
})

const getAllTags = (params, { onSuccess = () => {}, onError = () => {} }) => ({
  type: inventoryWarehousesAction.GET_ALL_TAGS,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

export default {
  getInventoryWarehouses,
  getInventoryWarehouseDetail,
  getInventoryWarehouseCarryingDetail,
  updateInventoryWarehouse,
  addNewInventoryWarehouses,
  deleteInventoryWarehouse,
  clearInventoryWarehouseDetail,
  clearInventoryWarehouseList,
  getAllTags,
}
