import { useQuery } from '@tanstack/react-query'
import qs from 'querystring'
import httpClient from '~/modules/core/httpClient'
import { MOCK_NAVIGATION_DATA } from './constant'
import {
  set as setLocalStorage,
  get as getLocalStorage,
} from '~/utils/storage/localStorage'
import { queryFnWrapper } from '~/utils/utility'
import csClient from '~/modules/core/csClient'

const getNavigationList = ({ storeId = '' }) => {
  const params = {
    bs_store_id: storeId,
    flag_profile_3_new_setting: true,
    flag_new_locations: true,
  }
  return httpClient.json.get(
    `/unification/bs_navigation?${qs.stringify(params)}`,
  )
}

const getCachedNav = () =>
  getLocalStorage('cached_nav_bar') || MOCK_NAVIGATION_DATA

const setCachedNav = (data) => {
  setLocalStorage('cached_nav_bar', data)
}

export const useNavigationConfig = (storeId, enabled = true) => {
  const placeholderData = getCachedNav()

  return useQuery({
    queryKey: ['getNavigationList', storeId],
    queryFn: queryFnWrapper({
      queryFn: () => getNavigationList({ storeId }),
      onError: (e) => {
        // biome-ignore lint/suspicious/noConsole: reason
        console.error(e)
      },
    }),
    placeholderData,
    enabled,
    select: (data) => {
      if (!data) return {}
      setCachedNav(data)
      return data
    },
    retry: 10,
  })
}

const getPlanTier = () => csClient.json.get('payments/get-plan-tier')

export const useGetPlanTier = ({ enabled = true }) =>
  useQuery({
    queryKey: ['getPlanTier'],
    queryFn: () => getPlanTier(),
    initialData: null,
    enabled,
    select: (res) => res?.data || {},
  })
