import { useCallback, useEffect, useRef } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useParams } from 'react-router-dom'
import audioPendingOrder from '~/assets/audio/notify-repeat.aac'
import audioNewOrder from '~/assets/audio/notify.aac'
import pubsub from '~/utils/pushNotification/pubsub'
import { saveNotificationSettingsIDB } from '~/utils/storage/indexedDB'
import {
  set as setLocalStorage,
  get as getLocalStorage,
} from '~/utils/storage/localStorage'
import { AUTH_STORAGE_KEYS } from '~/constants/localStorage'
import { ROLES } from '~/modules/auth/constants'

import { convertToUTC0 } from '~/utils/datetime'
import { useSettings } from '~/redux/hooks/settings'
import { useSyncedStores, useAllStores } from '~/redux/hooks/store'
import { useUser } from '~/redux/hooks/user'
import calendarApi from '~/services/apis/calendar'

import sw from '~/utils/sw'
import { useAuth } from '~/modules/auth/redux/hook'
import { NOTIFICATION_SHIPPING_METHODS } from '~/constants/settings'
import { COURIER_VALUES } from '~/constants/fulfillment'
import logger from '~/utils/logger'

const NotificationController = () => {
  const {
    actions: settingsActions,
    notificationSettings: { data: notificationSettings },
  } = useSettings()
  const {
    actions: userActions,
    user: { stores },
    orgId,
  } = useUser()
  const { storeId: currentStoreId } = useParams()
  const authStores = useSyncedStores()
  const allStores = useAllStores()
  const { actions: authActions } = useAuth()

  const validStoresRef = useRef([])
  const allStoresRef = useRef([])
  const trackedTimeoutRef = useRef({})

  const isEnabled = (storeId, type) =>
    get(
      get(notificationSettings, `${storeId}.settings`, []).find(
        (s) => s.type === type,
      ),
      'enabled',
      false,
    )
  const isAll = (storeId, type) => {
    const arr = get(
      get(notificationSettings, `${storeId}.settings`, []).find(
        (s) => s.type === type,
      ),
      'warehouses',
      [],
    )

    if (arr.find((item) => item.all === false)) {
      return false
    }
    return true
  }

  const allowedWarehouses = useCallback(
    (storeId, type) => {
      const roleId = get(
        authStores.find((s) => s.storeId === storeId),
        'roleId',
      )
      if ([ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER].includes(roleId)) {
        return []
      }
      const store = stores?.find((s) => s.store_id === storeId)

      if (isEnabled(storeId, type)) {
        if (isAll(storeId, type)) {
          return get(store, 'linked_warehouses', []).map((w) => w.warehouse_id)
        }
        return get(
          get(notificationSettings, `${storeId}.settings`, []).find(
            (s) => s.type === type,
          ),
          'warehouses',
          [],
        )
          .filter((w) => w.warehouse_id)
          .map((w) => w.warehouse_id)
      }

      return []
    },
    [notificationSettings, authStores, stores],
  )

  useEffect(() => {
    pubsub.on('subscribeSuccessfully', () => {})

    return () => pubsub.off('subscribeSuccessfully', () => {})
  }, [])

  useEffect(() => {
    pubsub.on('message', (evt) => {
      if (
        evt.type === 'import_store' &&
        evt.data?.status === 'finish_importing'
      ) {
        logger.info('Check auth stage from Notification')
        authActions.checkAuthState()
        const storeList = allStores
          .filter((s) => s.authenticated !== undefined)
          .map((s) => ({ store_id: s.storeId }))
        const tempStoreIds = [
          ...storeList,
          ...(evt?.data?.storeId ? [{ store_id: evt?.data?.storeId }] : []),
        ]
        userActions.getStores({ stores_list: tempStoreIds })
      }
    })

    return () => pubsub.off('message', () => {})
  }, [])

  useEffect(() => {
    const storeList = allStores
      .filter((s) => s.authenticated !== undefined && s.orgId === orgId)
      .map((s) => ({ store_id: s.storeId }))
    if (!isEqual(allStoresRef.current, storeList) && orgId) {
      allStoresRef.current = storeList
      userActions.getStores({ stores_list: storeList })
    }
  }, [allStores, orgId])

  useEffect(() => {
    const settings = {}
    const ids = Object.keys(notificationSettings)
    ids.forEach((id) => {
      settings[id] = {
        cantec_delivery: allowedWarehouses(
          id,
          NOTIFICATION_SHIPPING_METHODS.CANTEC,
        ),
        cantec_local_pickup: allowedWarehouses(
          id,
          NOTIFICATION_SHIPPING_METHODS.LOCAL_PICKUP,
        ),
        bsof_local_shipping: allowedWarehouses(
          id,
          NOTIFICATION_SHIPPING_METHODS.ONFLEET,
        ),
        curbside_pickup: allowedWarehouses(
          id,
          NOTIFICATION_SHIPPING_METHODS.CURBSIDE_PICKUP,
        ),
        breadstack_canfleet_local_shipping: allowedWarehouses(
          id,
          NOTIFICATION_SHIPPING_METHODS.CANFLEET_DELIVERY,
        ),
        drive_through_pickup: allowedWarehouses(
          id,
          NOTIFICATION_SHIPPING_METHODS.CURBSIDE_PICKUP,
        ),
      }
    })

    const saveNotificationSettings = async () => {
      try {
        await saveNotificationSettingsIDB(settings)
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    saveNotificationSettings()
  }, [notificationSettings, authStores, allowedWarehouses])

  useEffect(() => {
    const ids = authStores.map((s) => s.storeId)
    if (!isEqual(validStoresRef.current, ids)) {
      validStoresRef.current = ids
      settingsActions.getNotificationSettings(ids)
    }
  }, [authStores])

  useEffect(() => {
    if (!isEmpty(notificationSettings)) {
      const notificationTimeFlag = getLocalStorage(
        AUTH_STORAGE_KEYS.NOTIFICATION_TIME_FLAG,
      )
      if (
        !isEqual(get(notificationTimeFlag, 'data', {}), notificationSettings)
      ) {
        setLocalStorage(
          AUTH_STORAGE_KEYS.NOTIFICATION_TIME_FLAG,
          JSON.stringify({
            data: { ...notificationSettings },
            timeFlag: Date.now(),
            storeChanged: currentStoreId,
          }),
        )
      }
    }
  }, [notificationSettings, currentStoreId])

  const countPendingOrders = useCallback(
    async (requestParams, requestType) => {
      try {
        const res = await calendarApi.countOrders(requestParams)

        if (isEmpty(res)) {
          return
        }

        res.forEach((store) => {
          let ordersGroups = {}
          const allowedWh = allowedWarehouses(
            store.store_id,
            requestType === NOTIFICATION_SHIPPING_METHODS.CANTEC_DELIVERY
              ? NOTIFICATION_SHIPPING_METHODS.CANTEC
              : requestType,
          )

          if (!isEmpty(allowedWh)) {
            ordersGroups = [
              ...get(store, `on-hold.${requestType}`, []),
              ...get(store, `processing.${requestType}`, []),
            ].reduce((acc, cur) => {
              if (!allowedWh.includes(cur.warehouse_id)) {
                return acc
              }

              return {
                ...acc,
                [cur.warehouse_id]: {
                  ...cur,
                  type:
                    requestType ===
                    NOTIFICATION_SHIPPING_METHODS.CANFLEET_DELIVERY
                      ? NOTIFICATION_SHIPPING_METHODS.BS_CANFLEET_LOCAL_SHIPPING
                      : requestType,
                  storeId: store.store_id,
                  storeName: store.store_name,
                  total_orders:
                    (acc?.[cur.warehouse_id]?.total_orders || 0) +
                    cur.total_orders,
                },
              }
            }, {})
          }
          if (!isEmpty(ordersGroups)) {
            Object.keys(ordersGroups).forEach((key) => {
              const {
                type,
                storeName,
                storeId,
                warehouse_id,
                warehouse_name,
                total_orders,
              } = ordersGroups[key]

              let path = ''
              const desc = warehouse_name || '-'

              if (type === NOTIFICATION_SHIPPING_METHODS.CANTEC_DELIVERY) {
                path = `/s/${storeId}/fulfillment/pickup-delivery?courier=${COURIER_VALUES.CANTEC}&warehouseId=${warehouse_id}`
              }
              if (type === NOTIFICATION_SHIPPING_METHODS.CURBSIDE_PICKUP) {
                path = `/s/${storeId}/fulfillment/pickup-delivery?courier=${COURIER_VALUES.CURBSIDE}&warehouseId=${warehouse_id}`
              }
              if (type === NOTIFICATION_SHIPPING_METHODS.LOCAL_PICKUP) {
                path = `/s/${storeId}/fulfillment/pickup-delivery?courier=${COURIER_VALUES.CUSTOMER}&warehouseId=${warehouse_id}`
              }
              if (
                type ===
                NOTIFICATION_SHIPPING_METHODS.BS_CANFLEET_LOCAL_SHIPPING
              ) {
                path = `/s/${storeId}/fulfillment/pickup-delivery?courier=${COURIER_VALUES.CANFLEET}&warehouseId=${warehouse_id}`
              }

              let orderTypeMsg = ''
              switch (type) {
                case NOTIFICATION_SHIPPING_METHODS.CANTEC_DELIVERY:
                  orderTypeMsg = 'Cantec Delivery'
                  break
                case NOTIFICATION_SHIPPING_METHODS.CURBSIDE_PICKUP:
                  orderTypeMsg = 'Curbside Pickup'
                  break
                case NOTIFICATION_SHIPPING_METHODS.BS_CANFLEET_LOCAL_SHIPPING:
                  orderTypeMsg = 'CanFleet'
                  break
                default:
                  orderTypeMsg = 'Local Pickup'
                  break
              }

              pubsub.emit('message', {
                type,
                data: {
                  message: `${total_orders} ${orderTypeMsg} ${total_orders === 1 ? 'Order' : 'Orders'} Pending`,
                  title: storeName,
                  desc,
                  path,
                  orderId: Date.now(),
                  storeId,
                  isGroup: true,
                },
              })

              const audioElement = new Audio(audioPendingOrder)
              audioElement.addEventListener('canplaythrough', () => {
                audioElement.play().catch(() => {
                  // follow https://developer.chrome.com/blog/autoplay/
                })
              })
            })
          }
        })
      } catch {
        // @TODO: do anything?
      }
    },
    [allowedWarehouses],
  )

  const parseRepeatArray = (settingsObj) => {
    let arr = []

    Object.keys(settingsObj).forEach((id) => {
      const tempSettings = get(settingsObj, `${id}.settings`, [])
      const cantecRepeat = get(
        tempSettings.find(
          (item) =>
            item.enabled && item.type === NOTIFICATION_SHIPPING_METHODS.CANTEC,
        ),
        'repeat_order',
        0,
      )
      const localRepeat = get(
        tempSettings.find(
          (item) =>
            item.enabled &&
            item.type === NOTIFICATION_SHIPPING_METHODS.LOCAL_PICKUP,
        ),
        'repeat_order',
        0,
      )
      const curbsideRepeat = get(
        tempSettings.find(
          (item) =>
            item.enabled &&
            item.type === NOTIFICATION_SHIPPING_METHODS.CURBSIDE_PICKUP,
        ),
        'repeat_order',
        0,
      )
      const canfleetRepeat = get(
        tempSettings.find(
          (item) =>
            item.enabled &&
            item.type === NOTIFICATION_SHIPPING_METHODS.CANFLEET_DELIVERY,
        ),
        'repeat_order',
        0,
      )
      const onfleetRepeat = get(
        tempSettings.find(
          (item) =>
            item.enabled && item.type === NOTIFICATION_SHIPPING_METHODS.ONFLEET,
        ),
        'repeat_order',
        0,
      )

      if (cantecRepeat > 0) {
        arr = [
          ...arr,
          {
            storeId: id,
            intervalTime: cantecRepeat,
            type: NOTIFICATION_SHIPPING_METHODS.CANTEC_DELIVERY,
          },
        ]
      }
      if (curbsideRepeat > 0) {
        arr = [
          ...arr,
          {
            storeId: id,
            intervalTime: curbsideRepeat,
            type: NOTIFICATION_SHIPPING_METHODS.CURBSIDE_PICKUP,
          },
        ]
      }
      if (localRepeat > 0) {
        arr = [
          ...arr,
          {
            storeId: id,
            intervalTime: localRepeat,
            type: NOTIFICATION_SHIPPING_METHODS.LOCAL_PICKUP,
          },
        ]
      }
      if (canfleetRepeat > 0) {
        arr = [
          ...arr,
          {
            storeId: id,
            intervalTime: canfleetRepeat,
            type: NOTIFICATION_SHIPPING_METHODS.CANFLEET_DELIVERY,
          },
        ]
      }
      if (onfleetRepeat > 0) {
        arr = [
          ...arr,
          {
            storeId: id,
            intervalTime: onfleetRepeat,
            type: NOTIFICATION_SHIPPING_METHODS.ONFLEET,
          },
        ]
      }
    })
    return arr
  }

  useEffect(() => {
    const repeatArray = parseRepeatArray(notificationSettings)

    if (!isEmpty(repeatArray)) {
      repeatArray.forEach(({ storeId, intervalTime, type }) => {
        let i = 1
        const notificationTimeFlag = getLocalStorage(
          AUTH_STORAGE_KEYS.NOTIFICATION_TIME_FLAG,
        )
        const offset = Date.now() - (notificationTimeFlag?.timeFlag || 0)
        const intervalTimeMilliseconds = intervalTime * 60000
        const delay =
          intervalTimeMilliseconds - (offset % intervalTimeMilliseconds)
        const timeout = i === 1 ? delay : intervalTimeMilliseconds

        const intervalId = setInterval(() => {
          const [startDate, endDate] = convertToUTC0()
          countPendingOrders(
            {
              start_date: startDate,
              end_date: endDate,
              store_id_list: [storeId],
            },
            type,
          )
          if (i > 1) {
            clearInterval(trackedTimeoutRef.current[storeId][type])
            trackedTimeoutRef.current[storeId][type] = setInterval(() => {
              const [start, end] = convertToUTC0()
              countPendingOrders(
                {
                  start_date: start,
                  end_date: end,
                  store_id_list: [storeId],
                },
                type,
              )
            }, intervalTimeMilliseconds)
          }
          i += 1
        }, timeout)

        if (trackedTimeoutRef.current[storeId]) {
          if (trackedTimeoutRef.current[storeId][type]) {
            clearInterval(trackedTimeoutRef.current[storeId][type])
          }
          trackedTimeoutRef.current[storeId][type] = intervalId
        } else {
          trackedTimeoutRef.current[storeId] = {
            [type]: intervalId,
          }
        }
      })
    }

    return () => {
      if (trackedTimeoutRef.current) {
        Object.keys(trackedTimeoutRef.current).forEach((key) => {
          Object.values(trackedTimeoutRef.current[key])?.forEach((intervalId) =>
            clearInterval(intervalId),
          )
        })
        trackedTimeoutRef.current = {}
      }
    }
  }, [notificationSettings])

  // FOR NEW ORDERS

  useEffect(() => {
    const emitData = (evt) => {
      if (evt.data.cmd === 'pushNotification') {
        const audioElement = new Audio(audioNewOrder)
        audioElement.addEventListener('canplaythrough', () => {
          audioElement.play().catch(() => {
            // follow https://developer.chrome.com/blog/autoplay/
          })
        })
      }

      pubsub.emit('message', evt.data.payload)
    }

    const initSw = async () => {
      await sw.register()
      sw.listen(emitData)
    }

    initSw().catch(() => true)

    return () => {
      sw.remove(emitData)
    }
  }, [])

  return null
}

export default NotificationController
