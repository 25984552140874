export const COLORS = {
  palette: {
    primaryBlue: '#2933C5',
    secondaryBlue1: '#2959C5',
    secondaryBlue2: '#B4BFD9',
    secondaryBlue3: '#EDF1FB',
    secondaryBlue4: '#4551C6',
    secondaryBlue5: '#6874CF',
    secondaryBlue6: '#8B97D8',
    secondaryBlue7: '#ADB9E1',
    secondaryBlue8: '#2D36BD',
    secondaryBlue9: '#FAFCFF',
    secondaryBlue10: '#0736C0',
    secondaryBlue11: '#5864CB',
    secondaryBlue12: '#91B1EA',
    secondaryBlue13: '#D7DCE9',
    secondaryBlue14: '#F7F8FE',
    blueGradientStart: '#2933C5',
    blueGradientEnd: '#B6C2E3',
    white: '#FFFFFF',
    black: '#2E2E2E',
    backgroundBlue: '#F8F9FF',
    backgroundBlue2: '#E0E8F2',
    backgroundGreen: '#00B79C',
    backgroundCova: '#9A8B56',
    backgroundGreenline: '#1DA469',
    success: '#23B69A',
    blue: '#2766B1',
  },
  accent: {
    mustardYellow1: '#F0CE12',
    mustardYellow2: '#FFF5BF',
    apricotOrange1: '#FFA92B',
    apricotOrange2: '#FFF6E9',
    rubyRed1: '#F64066',
    rubyRed2: '#FEEBEF',
    rubyRed3: '#F20000',
    viridianGreen1: '#1CB69A',
    viridianGreen2: '#E7F8F5',
    viridianGreen3: '#00B79C',
    starYellow: '#E1AD01',
    red: '#F64066',
    wooBg: '#a55892',
    shopifyBg: '#8BB74A',
  },
  gray: {
    textGray: '#2E2E2E',
    textGray1: '#414141',
    textGray2: '#74798C',
    textGray3: '#3B4757',
    textGray4: '#282E3A',
    textGray5: '#9F9F9F',
    disableGray: '#C7C7C7',
    disableGray1: '#EAEAEA',
    disableGray2: '#F4F4F4',
    disableGray3: '#F6F8FD',
    background: '#FDFDFF',
    background2: '#EEEEEE',
    background3: '#FAFAFA',
    background4: '#B6BFD7',
    darkGray: '#414141',
    grayBorder: '#E0E0E0',
    grayBorder2: '#E4E4E4',
    midGray: '#74798C',
    backgroundIcon: '#C3C3C3',
  },
}

// Double check when changing the bellow numbers
export const VIEWPORT_HEIGHT = {
  md: 768,
}

export const vh = {
  up: (key: string | number) =>
    `@media screen and (min-height: ${typeof key === 'number' ? key : VIEWPORT_HEIGHT[key]}px)`,
  down: (key: string | number) =>
    `@media screen and (max-height: ${typeof key === 'number' ? key : VIEWPORT_HEIGHT[key] - 1}px)`,
}
