import { transformTypeOptions } from '~/utils/common'

export const POINT_TYPE = {
  MANAGE_POINTS: 'manage-points',
  POINT_LOG: 'points-log',
}

export const POINT_TAB = transformTypeOptions(POINT_TYPE)

export const CUSTOMER_STATUS = {
  ALL: 'all-customers',
  APPROVED: 'approved',
  PENDING: 'pending',
  DENIED: 'denied',
}

export const CUSTOMER_STATUS_MAPPING_WITH_BACKEND_FIELD = {
  [CUSTOMER_STATUS.ALL]: 'all',
  [CUSTOMER_STATUS.APPROVED]: 'approved',
  [CUSTOMER_STATUS.PENDING]: 'pending',
  [CUSTOMER_STATUS.DENIED]: 'denied',
}

export const CUSTOMER_STATUS_WITHOUT_APPROVAL_PLUGIN = {
  ALL: 'all-customers',
}

export const COVA_CUSTOMER_STATUS = {
  POS: 'pos',
  ONLINE: 'online',
  POS_ONLINE: 'pos_online',
}

export const COVA_CUSTOMER_STATUS_OPTIONS = [
  { value: 'all-customers', label: 'All Customers' },
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'denied', label: 'Denied' },
  { value: 'pos', label: 'POS Only' },
  { value: 'online', label: 'Online Only' },
  { value: 'pos_online', label: 'POS + Online' },
]

export const COVA_CUSTOMER_STATUS_WITHOUT_APPROVAL_PLUGIN = [
  { value: 'all-customers', label: 'All Customers' },
  { value: 'pos', label: 'POS Only' },
  { value: 'online', label: 'Online Only' },
  { value: 'pos_online', label: 'POS + Online' },
]

export const convertStatusCovaCustomer = (status) => {
  switch (status) {
    case COVA_CUSTOMER_STATUS.POS:
      return 0
    case COVA_CUSTOMER_STATUS.ONLINE:
      return 1
    case COVA_CUSTOMER_STATUS.POS_ONLINE:
      return 2
    default:
      return undefined
  }
}

export const CUSTOMER_BULK_ACTION_OPTIONS = [
  { value: 'approved', label: 'Approve' },
  { value: 'denied', label: 'Deny' },
]

export const CUSTOMER_STATUS_OPTIONS = transformTypeOptions(CUSTOMER_STATUS)

export const CUSTOMER_STATUS_OPTIONS_WITHOUT_PLUGIN = transformTypeOptions(
  CUSTOMER_STATUS_WITHOUT_APPROVAL_PLUGIN,
)

export const DEFAULT_CUSTOMER_DETAIL = {
  first_name: '',
  last_name: '',
  username: '',
  email: '',
  copy_address: true,
  points: '',
  billing: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    city: '',
    country: '',
    postcode: '',
    state: '',
    address_1: '',
    address_2: '',
    company: '',
  },
  shipping: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    city: '',
    country: '',
    postcode: '',
    state: '',
    address_1: '',
    address_2: '',
    company: '',
  },
}

export const DEFAULT_CUSTOMER_DETAIL_STATE = {
  top_categories: [],
  top_products: [],
  ...DEFAULT_CUSTOMER_DETAIL,
}

export const FILTER_CUSTOMER_INFO = {
  username: '',
  first_name: '',
  last_name: '',
  address_line_1: '',
  address_line_2: '',
  country: [],
  state: [],
  city: '',
  postcode: '',
  phone: '',
  email: '',
  clv: {
    from: '',
    to: '',
  },
}

export const FILTER_CUSTOMER_ACTIVITY = {
  start_date: '',
  end_date: '',
  status: '',
  total_number_of_orders: {
    from: '',
    to: '',
  },
  total_value_of_orders: {
    from: '',
    to: '',
  },
  point: {
    from: '',
    to: '',
  },
  registered_date: {
    from: '',
    to: '',
  },
  last_login_date: {
    from: '',
    to: '',
  },
  payment_method_id: [],
  payment_method_title: null,
  product_ids: [],
  category_ids: [],
}

export const DEFAULT_CUSTOMER_FILTER = {
  ...FILTER_CUSTOMER_INFO,
  ...FILTER_CUSTOMER_ACTIVITY,
}

export const DEFAULT_COVA_CUSTOMER_FILTER = {
  ...FILTER_CUSTOMER_INFO,
  ...FILTER_CUSTOMER_ACTIVITY,
  ...{
    aoi: { from: '', to: '' },
    date_of_birth: { from: '', to: '' },
    last_order_date: { from: '', to: '' },
    customer_since_date: { from: '', to: '' },
    purchase_location_ids: [],
    catalog_ids: [],
    s_last_order: { from: '', to: '' },
    s_search: '',
    s_country_ids: [],
    s_province_ids: [],
  },
}

export const DEFAULT_GREENLINE_CUSTOMER_FILTER = {
  ...FILTER_CUSTOMER_INFO,
  ...FILTER_CUSTOMER_ACTIVITY,
  ...{
    aoi: { from: '', to: '' },
    date_of_birth: { from: '', to: '' },
    last_order_date: { from: '', to: '' },
    customer_since_date: { from: '', to: '' },
    purchase_location_ids: [],
    catalog_ids: [],
    s_last_order: { from: '', to: '' },
    s_search: '',
    s_country_ids: [],
    s_province_ids: [],
    greenline_product_ids: [],
  },
}

export const DEFAULT_SHOPIFY_CUSTOMER_FILTER = {
  clv: { from: '', to: '' },
  numberOfOrders: { from: '', to: '' },
  amountSpent: { from: '', to: '' },
  lastOrderDate: { from: '', to: '' },
  createdAt: { from: '', to: '' },
  paymentMethodId: '',
  productIds: [],
  collectionIds: [],
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  country: [],
  state: [],
  city: [],
  postcode: [],
}

export const CUSTOMER_FORM_TYPE = {
  BILLING: 'billing',
  SHIPPING: 'shipping',
}

export const getEventTitle = (type) => {
  switch (type) {
    case 'admin-adjustment':
      return 'Admin Adjustment'
    case 'order-placed':
      return 'Order Placed'
    case 'order-cancelled':
      return 'Order Cancelled'
    case 'order-refunded':
      return 'Order Refunded'
    case 'order-redeem':
      return 'Order Redeem'
    default:
      return ''
  }
}

export const EXPORT_HEADERS = [
  'id',
  'Customer Name',
  'Username',
  'Email',
  'CLV',
  'Total Orders',
  'Point',
  'Province',
  'Country',
  'Last Order Date',
]

export const CUSTOMER_ERROR = (error) => {
  switch (error) {
    case 'registration-error-email-exists':
      return 'Email existed'
    case 'registration-error-username-exists':
      return 'Username existed'
    default:
      return error
  }
}

export const FILTER_CUSTOMER_ORDERS = {
  coupon_exists: false,
  order_status: '',
  order_total: {
    form: '',
    to: '',
  },
  total_items: {
    form: '',
    to: '',
  },
  shipping_method: '',
  status: '',
}

export const CUSTOMER_NOTE_SOURCE = {
  CRM: 'crm',
  CHATSO: 'chatso',
}

export const FILTER_COVA_CUSTOMER_ORDERS = {
  order_status: '',
  order_total: {
    form: '',
    to: '',
  },
  total_items: {
    form: '',
    to: '',
  },
  start_date: '',
  end_date: '',
}

export const DEFAULT_COVA_CUSTOMER_DETAIL = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  address: {
    street_address_1: '',
    street_address_2: '',
    city: '',
    state: '',
    country: '',
  },
  dob: '',
  pricing_group: null,
  notes: '',
  clv: '',
  bs_customer_type: '',
  woo_customer_id: '',
  behaviour_data: {},
}

export const COVA_CUSTOMER_TYPES = {
  POS: 0,
  ONLINE: 1,
  POS_ONLINE: 2,
}

export const COVA_CUSTOMER_TABS = [
  {
    label: 'Online',
    value: COVA_CUSTOMER_TYPES.ONLINE.toString(),
  },
  {
    label: 'POS',
    value: COVA_CUSTOMER_TYPES.POS.toString(),
  },
]

export const COVA_CUSTOMER_ACTION_TYPE = {
  NOTE: 'note',
  CREATE: 'create',
  UPDATE: 'update',
  RESET_PASSWORD: 'reset_password',
  ATTACHMENT: 'attachment',
}

export const COVA_CUSTOMER_ACTION_LABEL = {
  [COVA_CUSTOMER_ACTION_TYPE.NOTE]: 'added a note',
  [COVA_CUSTOMER_ACTION_TYPE.CREATE]: 'Customer profile created',
  [COVA_CUSTOMER_ACTION_TYPE.UPDATE]: 'updated customer details',
  [COVA_CUSTOMER_ACTION_TYPE.RESET_PASSWORD]: 'reset password',
  [COVA_CUSTOMER_ACTION_TYPE.ATTACHMENT]: 'attached document',
}

export const COVA_ORDER_STATUS = {
  PENDING: 'pending',
  ON_HOLD: 'on-hold',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  REFUNDED: 'refunded',
  TRASH: 'trash',
}

export const COVA_CUSTOMER_ORDER_STATUS = [
  { value: 'pending', label: 'Pending' },
  { value: 'on-hold', label: 'On Hold' },
  { value: 'processing', label: 'Processing' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'failed', label: 'Failed' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'trash', label: 'Trash' },
]

export const COVA_FULFILLMENT_STATUS = {
  PENDING_PICKING: 'pending_picking',
  PENDING_PICKUP: 'pending_pickup',
  IN_TRANSIT: 'in_transit',
  COMPLETED: 'completed',
  EMPTY: 'empty',
  CANCEL: 'cancel',
  PICKING_AND_WAYBILL: 'picking_&_waybill',
  PENDING_DELIVERY: 'pending_delivery',
  TRACKING: 'tracking',
}

export const COVA_FULFILLMENT_STATUS_NAME = {
  [COVA_FULFILLMENT_STATUS.PENDING_PICKING]: {
    title: 'Pending Picking',
    color: '#E1AD01',
  },
  [COVA_FULFILLMENT_STATUS.PENDING_PICKUP]: {
    title: 'Pending Pickup',
    color: '#2E7FB2',
  },
  [COVA_FULFILLMENT_STATUS.IN_TRANSIT]: {
    title: 'In-Transit',
    color: '#2933C5',
  },
  [COVA_FULFILLMENT_STATUS.COMPLETED]: {
    title: 'Completed',
    color: '#1CB69A',
  },
  [COVA_FULFILLMENT_STATUS.EMPTY]: {
    title: '-',
    color: '#2E2E2E',
  },
  [COVA_FULFILLMENT_STATUS.CANCEL]: {
    title: 'Cancelled',
    color: '#F64066',
  },
  [COVA_FULFILLMENT_STATUS.PICKING_AND_WAYBILL]: {
    title: 'Picking & Waybill',
    color: '#E1AD01',
  },
  [COVA_FULFILLMENT_STATUS.PENDING_DELIVERY]: {
    title: 'Pending Delivery',
    color: '#2E7FB2',
  },
  [COVA_FULFILLMENT_STATUS.TRACKING]: {
    title: 'Completed',
    color: '#1CB69A',
  },
}

export const POS_PAYMENT_STATUS_TYPE = {
  PAY_IN_STORE: 'pay_in_store',
  E_TRANSFER: 'e_transfer',
  CREDIT_CARD: 'credit_card',
  PENDING_PAYMENT: 'pending_payment',
  PAID: 'paid',
  FAILED: 'failed',
}

export const POS_PAYMENT_STATUS_NAME = {
  [POS_PAYMENT_STATUS_TYPE.PAY_IN_STORE]: 'Paid In Store',
  [POS_PAYMENT_STATUS_TYPE.E_TRANSFER]: 'E-transfer',
  [POS_PAYMENT_STATUS_TYPE.CREDIT_CARD]: 'Credit Card',
  [POS_PAYMENT_STATUS_TYPE.PENDING_PAYMENT]: 'Pending Payment',
  [POS_PAYMENT_STATUS_TYPE.PAID]: 'Paid',
  [POS_PAYMENT_STATUS_TYPE.FAILED]: 'Failed',
}

export const POS_PAYMENT_STATUS_OPTIONS = [
  {
    value: POS_PAYMENT_STATUS_TYPE.PAY_IN_STORE,
    label: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.PAY_IN_STORE],
  },
  {
    value: POS_PAYMENT_STATUS_TYPE.E_TRANSFER,
    label: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.E_TRANSFER],
  },
  {
    value: POS_PAYMENT_STATUS_TYPE.CREDIT_CARD,
    label: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.CREDIT_CARD],
  },
  {
    value: POS_PAYMENT_STATUS_TYPE.PENDING_PAYMENT,
    label: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.PENDING_PAYMENT],
  },
  {
    value: POS_PAYMENT_STATUS_TYPE.PAID,
    label: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.PAID],
  },
  {
    value: POS_PAYMENT_STATUS_TYPE.FAILED,
    label: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.FAILED],
  },
]

export const POS_PAYMENT_STATUS_ICON = {
  [POS_PAYMENT_STATUS_TYPE.PAY_IN_STORE]: {
    icon: 'ic_round_money',
    title: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.PAY_IN_STORE],
    color: '#6A9DFF',
  },
  [POS_PAYMENT_STATUS_TYPE.E_TRANSFER]: {
    icon: 'ic_round_tick',
    title: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.E_TRANSFER],
    color: '#75BE00',
  },
  [POS_PAYMENT_STATUS_TYPE.CREDIT_CARD]: {
    icon: 'ic_round_tick',
    title: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.CREDIT_CARD],
    color: '#75BE00',
  },
  [POS_PAYMENT_STATUS_TYPE.PENDING_PAYMENT]: {
    icon: 'ic_round_warn',
    title: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.PENDING_PAYMENT],
    color: '#FFA92B',
  },
  [POS_PAYMENT_STATUS_TYPE.PAID]: {
    icon: 'ic_round_tick',
    title: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.PAID],
    color: '#75BE00',
  },
  [POS_PAYMENT_STATUS_TYPE.FAILED]: {
    icon: 'ic_round_fail',
    title: POS_PAYMENT_STATUS_NAME[POS_PAYMENT_STATUS_TYPE.FAILED],
    color: '#F64066',
  },
}

export const COVA_PAYMENT_STATUS_TYPE = {
  PAY_IN_STORE: 'pay_in_store',
  PENDING_COD: 'pending_cod',
  E_TRANSFER: 'e_transfer',
  PENDING_E_TRANSFER: 'pending_e_transfer',
  CREDIT_CARD: 'credit_card',
  PENDING_CREDIT_CARD: 'pending_credit_card',
  CHEQUE_RECEIVED: 'cheque_received',
  PENDING_CHEQUE: 'pending_cheque',
  FAILED: 'failed',
}

export const COVA_PAYMENT_STATUS_NAME = {
  [COVA_PAYMENT_STATUS_TYPE.E_TRANSFER]: 'E-transfer received',
  [COVA_PAYMENT_STATUS_TYPE.CREDIT_CARD]: 'Credit Card',
  [COVA_PAYMENT_STATUS_TYPE.PENDING_E_TRANSFER]: 'Pending E-transfer',
  [COVA_PAYMENT_STATUS_TYPE.PAY_IN_STORE]: 'Paid In Store',
  [COVA_PAYMENT_STATUS_TYPE.PENDING_CREDIT_CARD]: 'Pending Credit Card',
  [COVA_PAYMENT_STATUS_TYPE.PENDING_COD]: 'Pending Payment',
  [COVA_PAYMENT_STATUS_TYPE.CHEQUE_RECEIVED]: 'Cheque Received',
  [COVA_PAYMENT_STATUS_TYPE.PENDING_CHEQUE]: 'Pending Cheque',
  [COVA_PAYMENT_STATUS_TYPE.FAILED]: 'Failed Payment',
}

export const COVA_PAYMENT_STATUS_OPTIONS = [
  {
    value: COVA_PAYMENT_STATUS_TYPE.E_TRANSFER,
    label: COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.E_TRANSFER],
  },
  {
    value: COVA_PAYMENT_STATUS_TYPE.CREDIT_CARD,
    label: COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.CREDIT_CARD],
  },
  {
    value: COVA_PAYMENT_STATUS_TYPE.PENDING_E_TRANSFER,
    label:
      COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.PENDING_E_TRANSFER],
  },
  {
    value: COVA_PAYMENT_STATUS_TYPE.PAY_IN_STORE,
    label: COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.PAY_IN_STORE],
  },
  {
    value: COVA_PAYMENT_STATUS_TYPE.PENDING_CREDIT_CARD,
    label:
      COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.PENDING_CREDIT_CARD],
  },
  {
    value: COVA_PAYMENT_STATUS_TYPE.PENDING_COD,
    label: COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.PENDING_COD],
  },
  {
    value: COVA_PAYMENT_STATUS_TYPE.CHEQUE_RECEIVED,
    label: COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.CHEQUE_RECEIVED],
  },
  {
    value: COVA_PAYMENT_STATUS_TYPE.PENDING_CHEQUE,
    label: COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.PENDING_CHEQUE],
  },
  {
    value: COVA_PAYMENT_STATUS_TYPE.FAILED,
    label: COVA_PAYMENT_STATUS_NAME[COVA_PAYMENT_STATUS_TYPE.FAILED],
  },
]

export const COVA_CUSTOMER_SHIPPING_TYPE = {
  FLAT_RATE: 'flat_rate',
  FREE_SHIPPING: 'free_shipping',
  LOCAL_PICKUP: 'local_pickup',
  CANADA_POST: 'canada_post_shipping_by_nosites_left',
  CANADA_POST_XPRESS: 'canada_post',
  ONFLEET_LOCAL_DELIVERY: 'bsof_local_shipping',
  ONFLEET_DELIVERY: 'onfleet_delivery',
  CURBSIDE_PICKUP: 'curbside_pickup',
  CANTEC_DELIVERY: 'cantec_delivery',
  CANTEC_LOCAL_PICKUP: 'cantec_local_pickup',
  CANFLEET_DELIVERY: 'breadstack_canfleet_local_shipping',
  CANFLEET_LOCAL_SHIPPING: 'canfleet_local_shipping',
  DRIVE_THRU: 'drive_through_pickup',
  OTHERS: 'others',
  TWO_POINT_DELIVERY: 'two_point_delivery',
}

export const COVA_PAYMENT_STATUS_ICON = {
  [COVA_PAYMENT_STATUS_TYPE.PAY_IN_STORE]: {
    icon: 'ic_round_money',
    title: 'Paid In Store',
    color: '#6A9DFF',
  },
  [COVA_PAYMENT_STATUS_TYPE.PENDING_COD]: {
    icon: 'ic_round_warn',
    title: 'Pending Payment',
    color: '#FFA92B',
  },
  [COVA_PAYMENT_STATUS_TYPE.E_TRANSFER]: {
    icon: 'ic_round_tick',
    title: 'E-transfer Received',
    color: '#75BE00',
  },
  [COVA_PAYMENT_STATUS_TYPE.PENDING_E_TRANSFER]: {
    icon: 'ic_round_warn',
    title: 'Pending E-transfer',
    color: '#FFA92B',
  },
  [COVA_PAYMENT_STATUS_TYPE.CREDIT_CARD]: {
    icon: 'ic_round_tick',
    title: 'Credit Card',
    color: '#75BE00',
  },
  [COVA_PAYMENT_STATUS_TYPE.PENDING_CREDIT_CARD]: {
    icon: 'ic_round_warn',
    title: 'Pending Credit Card',
    color: '#FFA92B',
  },
  [COVA_PAYMENT_STATUS_TYPE.CHEQUE_RECEIVED]: {
    icon: 'ic_round_tick',
    title: 'Cheque Received',
    color: '#75BE00',
  },
  [COVA_PAYMENT_STATUS_TYPE.PENDING_CHEQUE]: {
    icon: 'ic_round_warn',
    title: 'Pending Cheque',
    color: '#FFA92B',
  },
  [COVA_PAYMENT_STATUS_TYPE.FAILED]: {
    icon: 'ic_round_fail',
    title: 'Failed Payment',
    color: '#86BC39',
  },
}

export const ARRAY_REGULAR_SHIPPING = [
  COVA_CUSTOMER_SHIPPING_TYPE.FLAT_RATE,
  COVA_CUSTOMER_SHIPPING_TYPE.FREE_SHIPPING,
  COVA_CUSTOMER_SHIPPING_TYPE.CANADA_POST,
  COVA_CUSTOMER_SHIPPING_TYPE.CANADA_POST_XPRESS,
]

export const COVA_CUSTOMER_SHIPPING_ICON = {
  [COVA_CUSTOMER_SHIPPING_TYPE.FLAT_RATE]: {
    icon: 'ic_shipping_regular',
    title: 'Mail order',
    color: '#099FDE',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.FREE_SHIPPING]: {
    icon: 'ic_shipping_regular',
    title: 'Mail order',
    color: '#099FDE',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.LOCAL_PICKUP]: {
    icon: 'ic_shipping_others',
    title: 'Others',
    color: '#717D96',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.CANADA_POST]: {
    icon: 'ic_shipping_regular',
    title: 'Mail order',
    color: '#099FDE',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.CANADA_POST_XPRESS]: {
    icon: 'ic_shipping_regular',
    title: 'Mail order',
    color: '#099FDE',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.ONFLEET_LOCAL_DELIVERY]: {
    icon: 'ic_onfleet',
    title: 'Onfleet',
    color: '#A983F4',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.CANFLEET_LOCAL_SHIPPING]: {
    icon: 'ic_onfleet',
    title: 'Onfleet',
    color: '#A983F4',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.ONFLEET_DELIVERY]: {
    icon: 'ic_onfleet',
    title: 'Onfleet',
    color: '#A983F4',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.CURBSIDE_PICKUP]: {
    icon: 'ic_shipping_curbside',
    title: 'Curbside pickup',
    color: '#39BCB2',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.CANTEC_DELIVERY]: {
    icon: 'ic_shipping_others',
    title: 'Others',
    color: '#717D96',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.CANFLEET_DELIVERY]: {
    icon: 'ic_shipping_canfleet',
    title: 'CanFleet',
    color: '#D9732C',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.CANTEC_LOCAL_PICKUP]: {
    icon: 'ic_shipping_store',
    title: 'In-store pickup',
    color: '#86BC39',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.DRIVE_THRU]: {
    icon: 'ic_shipping_drive_thru',
    title: 'Drive thru',
    color: '#EEA212',
  },
  [COVA_CUSTOMER_SHIPPING_TYPE.TWO_POINT_DELIVERY]: {
    icon: 'ic_two_point',
    title: '2Point',
    color: '#2B645E',
  },
}

export const COVA_PAYMENT_RECEIVED_TYPE = {
  PAID: [
    COVA_PAYMENT_STATUS_TYPE.E_TRANSFER,
    COVA_PAYMENT_STATUS_TYPE.CREDIT_CARD,
    COVA_PAYMENT_STATUS_TYPE.CHEQUE_RECEIVED,
  ],
  UNPAID: [
    COVA_PAYMENT_STATUS_TYPE.PAY_IN_STORE,
    COVA_PAYMENT_STATUS_TYPE.PENDING_COD,
    COVA_PAYMENT_STATUS_TYPE.PENDING_E_TRANSFER,
    COVA_PAYMENT_STATUS_TYPE.PENDING_CREDIT_CARD,
    COVA_PAYMENT_STATUS_TYPE.PENDING_CHEQUE,
  ],
}

export const COVA_SHIPPING_METHOD_TYPE = {
  TIME: [
    COVA_CUSTOMER_SHIPPING_TYPE.CANTEC_LOCAL_PICKUP,
    COVA_CUSTOMER_SHIPPING_TYPE.CURBSIDE_PICKUP,
    COVA_CUSTOMER_SHIPPING_TYPE.CANTEC_DELIVERY,
    COVA_CUSTOMER_SHIPPING_TYPE.CANFLEET_DELIVERY,
    COVA_CUSTOMER_SHIPPING_TYPE.DRIVE_THRU,
    COVA_CUSTOMER_SHIPPING_TYPE.ONFLEET_LOCAL_DELIVERY,
  ],
  WITHOUT_TIME: [
    COVA_CUSTOMER_SHIPPING_TYPE.FLAT_RATE,
    COVA_CUSTOMER_SHIPPING_TYPE.FREE_SHIPPING,
    COVA_CUSTOMER_SHIPPING_TYPE.LOCAL_PICKUP,
    COVA_CUSTOMER_SHIPPING_TYPE.CANADA_POST,
    COVA_CUSTOMER_SHIPPING_TYPE.CANADA_POST_XPRESS,
    COVA_CUSTOMER_SHIPPING_TYPE.ONFLEET_DELIVERY,
  ],
}

export const COVA_TIME_TYPE = {
  PICKUP: [
    COVA_CUSTOMER_SHIPPING_TYPE.CANTEC_LOCAL_PICKUP,
    // COVA_CUSTOMER_SHIPPING_TYPE.LOCAL_PICKUP,
    COVA_CUSTOMER_SHIPPING_TYPE.CURBSIDE_PICKUP,
    COVA_CUSTOMER_SHIPPING_TYPE.DRIVE_THRU,
  ],
  DELIVERY: [
    // COVA_CUSTOMER_SHIPPING_TYPE.FLAT_RATE,
    // COVA_CUSTOMER_SHIPPING_TYPE.FREE_SHIPPING,
    COVA_CUSTOMER_SHIPPING_TYPE.ONFLEET_LOCAL_DELIVERY,
    COVA_CUSTOMER_SHIPPING_TYPE.CANFLEET_DELIVERY,
    // COVA_CUSTOMER_SHIPPING_TYPE.CANADA_POST,
    // COVA_CUSTOMER_SHIPPING_TYPE.CANADA_POST_XPRESS,
    COVA_CUSTOMER_SHIPPING_TYPE.CANTEC_DELIVERY,
    // COVA_CUSTOMER_SHIPPING_TYPE.ONFLEET_DELIVERY,
  ],
}

export const DEFAULT_PAGE = 1

export const DEFAULT_PAGE_LIMIT = 5

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  NON_DISCLOSED: 'non_disclosed',
}

export const GENDER_OPTIONS = [
  { value: GENDER.MALE, label: 'Male' },
  { value: GENDER.FEMALE, label: 'Female' },
  { value: GENDER.NON_DISCLOSED, label: 'Non-disclosed' },
]

export const COVA_CUSTOMER_SHIPPING_NAME = {
  PICKUP: [COVA_CUSTOMER_SHIPPING_TYPE.CANTEC_LOCAL_PICKUP],
  CURBSIDE: [COVA_CUSTOMER_SHIPPING_TYPE.CURBSIDE_PICKUP],
  DELIVERY: [
    COVA_CUSTOMER_SHIPPING_TYPE.ONFLEET_LOCAL_DELIVERY,
    COVA_CUSTOMER_SHIPPING_TYPE.ONFLEET_DELIVERY,
    COVA_CUSTOMER_SHIPPING_TYPE.CANTEC_DELIVERY,
    COVA_CUSTOMER_SHIPPING_TYPE.CANFLEET_DELIVERY,
  ],
}
