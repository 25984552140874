import cantecClient from './cantec'

const url = {
  BILL: {
    LIST: (store_id) => `/admin/breadstack/stores/${store_id}/bills`,
    DETAIL: (id) => `/admin/breadstack/bills/${id}`,
    STORES: '/admin/breadstack/stores',
  },
}

export const getList = (store_id) =>
  cantecClient.get(url.BILL.LIST(store_id), {
    params: {},
  })

export const findOne = (id) => cantecClient.get(url.BILL.DETAIL(id))

export const getStores = () => cantecClient.get(url.BILL.STORES)

const billApi = {
  getList,
  findOne,
  getStores,
}

export default billApi
