import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import userActions from '../actions/user'
import { useAuth } from '~/modules/auth/redux/hook'
import { getRole, inventoryRoles } from '~/modules/auth/conditions'
import {
  getUser,
  getUserLoading,
  getUserError,
  getStoreLoading,
  getAuthStores,
  getOrgLoading,
} from '../selectors/user'

export const useUser = () => {
  const { storeId } = useParams()

  const user = useSelector(getUser)
  const loading = useSelector(getUserLoading)
  const error = useSelector(getUserError)
  const storeLoading = useSelector(getStoreLoading)
  const orgLoading = useSelector(getOrgLoading)

  const authStores = useSelector(getAuthStores)
  const orgId = authStores?.[storeId]?.orgId
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(userActions, dispatch),
    [dispatch],
  )

  return {
    actions,
    user,
    loading,
    error,
    orgId,
    storeLoading,
    orgLoading,
  }
}

export const useUserRole = () => {
  const { storeId } = useParams()

  if (!storeId) return null
  const { authState } = useAuth()
  return getRole(storeId)(authState.data)
}

export const useInRole = (r, storeId) => {
  if (!storeId) return null
  const { authState } = useAuth()
  return r(storeId)(authState.data)
}

export const useHasInventoryRole = (storeId) => {
  if (!storeId) return null
  const { authState } = useAuth()
  return inventoryRoles(storeId)(authState.data)
}

export const useUserPlugins = () => {
  const user = useSelector(getUser)
  return useMemo(() => user.plugins, [user.plugins])
}

export const useSelectedProfile = () => {
  const { user } = useUser()
  return useMemo(
    () => user?.organization?.profile_type,
    [user?.organization?.profile_type],
  )
}
