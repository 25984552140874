import { styled } from '@mui/material'
import { COLORS } from '~/themes/common'

export const StyledNotificationContainer = styled('div')(() => ({
  background: '#FFF',
  width: 350,
  boxShadow: '0px 3px 6px #2933C533',
  borderRadius: 10,
  cursor: 'pointer',
  marginBottom: 10,
  overflow: 'hidden',
  fontSize: 14,

  '&:last-of-type': {
    marginBottom: 0,
  },
}))

export const StyledMeta = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: COLORS.palette.secondaryBlue3,
  padding: '6px 10px',
  lineHeight: '18px',
  fontWeight: 'normal',
}))

export const StyledMessage = styled('span')(() => ({
  display: 'inline-block',
  color: COLORS.gray.textGray1,
  letterSpacing: 0.28,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '&.highlight': {
    background: COLORS.gray.midGray,
    borderRadius: 10,
    padding: '0 10px',
    color: '#fff',
  },
}))

export const StyledTime = styled('span')(() => ({
  color: COLORS.gray.textGray1,
  textTransform: 'uppercase',
  marginLeft: 10,
  whiteSpace: 'nowrap',
}))

export const StyledNotificationContent = styled('div')(() => ({
  display: 'flex',
  padding: '8px 0 8px 10px',
  alignItems: 'center',
}))

export const StyledIcon = styled('div')(() => ({
  flex: '0 0 40px',
  marginRight: 20,
  '& img': {
    width: '100%',
  },
}))

export const StyledInfo = styled('div')(() => ({
  flex: 1,
  overflow: 'hidden',
}))

export const StyledTitle = styled('h5')(() => ({
  margin: 0,
  fontSize: '0.875rem',
  fontWeight: 500,
  color: COLORS.gray.textGray1,
  wordBreak: 'break-word',
}))

export const StyledDesc = styled('p')(() => ({
  margin: '1px 0 0',
  lineHeight: 1.5,
  fontWeight: 'normal',
  color: COLORS.gray.textGray1,
  wordBreak: 'break-word',
}))

export const StyledCloseButtonContainer = styled('div')(() => ({
  padding: '9px 15px',
}))

export const StyledCloseButton = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 25,
  height: 25,
  padding: 0,
  background: 'transparent',
  cursor: 'pointer',
  borderRadius: '50%',
  border: 'none',
  transition: 'all .2s ease-in-out',

  '& svg': {
    width: 14,
    color: COLORS.gray.textGray1,
  },

  '&:hover': {
    background: '#DDDDDD',
    '& svg': {
      color: '#000',
    },
  },
}))
