import produce from 'immer'
import { SETTING_ACTION } from '../actions/settings'
import { sortTaxClasses } from '~/utils/settings'

const initialState = {
  notification: {
    loading: false,
    data: {},
    error: '',
  },
  taxClasses: {
    loading: false,
    data: [],
    error: '',
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload = {} } = action
    if (type === SETTING_ACTION.GET_NOTIFICATION_SETTING) {
      draft.notification.loading = true
      draft.notification.error = ''
    }
    if (type === SETTING_ACTION.GET_NOTIFICATION_SETTING_OK) {
      draft.notification.loading = false
      draft.notification.data = payload
    }
    if (type === SETTING_ACTION.GET_NOTIFICATION_SETTING_ERR) {
      draft.notification.loading = false
      draft.notification.error = payload
    }
    if (type === SETTING_ACTION.UPDATE_NOTIFICATION_SETTING) {
      draft.notification.loading = true
      draft.notification.error = ''
    }
    if (type === SETTING_ACTION.UPDATE_NOTIFICATION_SETTING_OK) {
      draft.notification.loading = false
      draft.notification.data = { ...draft.notification.data, ...payload }
    }
    if (type === SETTING_ACTION.UPDATE_NOTIFICATION_SETTING_ERR) {
      draft.notification.loading = false
      draft.notification.error = payload
    }

    if (
      type === SETTING_ACTION.GET_TAX_CLASSES ||
      type === SETTING_ACTION.CREATE_TAX_CLASS ||
      type === SETTING_ACTION.REMOVE_TAX_CLASS
    ) {
      draft.taxClasses.loading = true
      draft.taxClasses.error = ''
    }
    if (type === SETTING_ACTION.GET_TAX_CLASSES_SUCCESS) {
      draft.taxClasses.loading = false
      draft.taxClasses.data = sortTaxClasses(
        (payload?.items || []).filter((item) => item.name && item.slug),
      )
    }
    if (type === SETTING_ACTION.CREATE_TAX_CLASS_SUCCESS) {
      draft.taxClasses.loading = false
      draft.taxClasses.data = sortTaxClasses([
        ...draft.taxClasses.data,
        payload,
      ])
    }
    if (type === SETTING_ACTION.REMOVE_TAX_CLASS_SUCCESS) {
      draft.taxClasses.loading = false
      draft.taxClasses.data = sortTaxClasses(
        draft.taxClasses.data.filter((x) => x.slug !== payload?.slug),
      )
    }
    if (
      type === SETTING_ACTION.GET_TAX_CLASSES_FAILED ||
      type === SETTING_ACTION.CREATE_TAX_CLASS_FAILED ||
      type === SETTING_ACTION.REMOVE_TAX_CLASS_FAILED
    ) {
      draft.taxClasses.loading = false
      draft.taxClasses.error = payload
    }
  })

export default reducer
