import {
  analyticRoles,
  customerRolesView,
  dashboardRoles,
  fulfillmentRoles,
  loyaltyRolesView,
  mediaRoles,
  onfleetRoles,
  onlyWooCustomerRolesEditable,
  onlyWooInventoryRoles,
  onlyWooProductRoles,
  orderRoles,
  pointRewardRoles,
  productRolesEditable,
  productRolesView,
} from '~/modules/auth/conditions'

export const MENU_KEY_NAME = {
  DASHBOARD: 'breadstack_dashboard',
  ALL_PRODUCTS: 'breadstack_product',
  ALL_PRODUCT: 'breadstack_all_products',
  ADD_NEW_PRODUCT: 'breadstack_add_new_product',
  PERFORMANCE: 'breadstack_performance',
  CATEGORIES: 'breadstack_categories',
  TAG: 'breadstack_tags',
  ATTRIBUTES: 'breadstack_attributes',
  COMMENTS: 'breadstack_comments',

  ALL_ORDERS: 'breadstack_order',
  ALL_ORDER: 'breadstack_all_orders',
  ADD_NEW_ORDER: 'breadstack_create_new_order',

  ALL_CUSTOMERS: 'breadstack_customer',
  ALL_CUSTOMER: 'breadstack_all_customers',
  ADD_NEW_CUSTOMER: 'breadstack_add_new_customer',

  LOYALTY: 'breadstack_loyalty',
  ALL_PROMOTIONS: 'breadstack_promotions',
  ALL_COUPON: 'breadstack_coupons',
  POINT_AND_REWARDS: 'breadstack_point_and_reward',

  INSIGHTS: 'breadstack_insight',
  INSIGHT_PRODUCTS: 'breadstack_insight_products',
  INSIGHT_REVENUE: 'breadstack_revenue',
  INSIGHT_VISITORS: 'breadstack_visitors',
  INSIGHT_REPORTS: 'breadstack_reports',
  INSIGHT_ANALYTIC: 'breadstack_analytics', // for cova & greenline

  INVENTORY: 'breadstack_inventory',
  ALL_INVENTORY: 'breadstack_all_inventories',
  TRANSFERS: 'breadstack_transfers',
  ADJUSTMENTS: 'breadstack_adjustments',

  FULFILLMENT: 'breadstack_fulfillment',
  PICKUP_AND_DELIVERY: 'breadstack_pickup_and_delivery',
  MAIL_ORDERS: 'breadstack_mail_orders',
  ONFLEET_DELIVERIES: 'breadstack_onfleet',
  COMPLETED_ORDERS: 'breadstack_completed_orders',

  MEDIA: 'breadstack_media_library',
}

export const NAV_BAR_CONFIG = {
  [MENU_KEY_NAME.DASHBOARD]: '/s/:storeId/dashboard',
  [MENU_KEY_NAME.ALL_PRODUCTS]: '/s/:storeId/products',
  [MENU_KEY_NAME.ALL_PRODUCT]: '/s/:storeId/products',
  [MENU_KEY_NAME.ADD_NEW_PRODUCT]: '/s/:storeId/products/create',
  [MENU_KEY_NAME.PERFORMANCE]: '/s/:storeId/products/performance',
  [MENU_KEY_NAME.CATEGORIES]: '/s/:storeId/products/categories',
  [MENU_KEY_NAME.TAG]: '/s/:storeId/products/tags',
  [MENU_KEY_NAME.ATTRIBUTES]: '/s/:storeId/products/attributes',
  [MENU_KEY_NAME.COMMENTS]: '/s/:storeId/products/comments',

  [MENU_KEY_NAME.ALL_ORDERS]: '/s/:storeId/orders',
  [MENU_KEY_NAME.ALL_ORDER]: '/s/:storeId/orders',
  [MENU_KEY_NAME.ADD_NEW_ORDER]: '/s/:storeId/orders/create',

  [MENU_KEY_NAME.ALL_CUSTOMERS]: '/s/:storeId/customers',
  [MENU_KEY_NAME.ALL_CUSTOMER]: '/s/:storeId/customers',
  [MENU_KEY_NAME.ADD_NEW_CUSTOMER]: '/s/:storeId/customers/create',

  [MENU_KEY_NAME.LOYALTY]: '/s/:storeId/loyalty',
  [MENU_KEY_NAME.ALL_PROMOTIONS]: '/s/:storeId/loyalty/promotion',
  [MENU_KEY_NAME.ALL_COUPON]: '/s/:storeId/loyalty/coupons',
  [MENU_KEY_NAME.POINT_AND_REWARDS]: '/s/:storeId/loyalty/point-reward',

  [MENU_KEY_NAME.INSIGHTS]: '/s/:storeId/insights',
  [MENU_KEY_NAME.INSIGHT_PRODUCTS]:
    '/s/:storeId/insights/report/inventory-report',
  [MENU_KEY_NAME.INSIGHT_REVENUE]: '/s/:storeId/insights/report/sales-report',
  [MENU_KEY_NAME.INSIGHT_VISITORS]:
    '/s/:storeId/insights/report/web-user-report',
  [MENU_KEY_NAME.INSIGHT_REPORTS]: '/s/:storeId/insights/custom-report',

  [MENU_KEY_NAME.INVENTORY]: '/s/:storeId/inventory',
  [MENU_KEY_NAME.ALL_INVENTORY]: '/s/:storeId/inventory/warehouses',
  [MENU_KEY_NAME.TRANSFERS]: '/s/:storeId/inventory/transfer',
  [MENU_KEY_NAME.ADJUSTMENTS]: '/s/:storeId/inventory/adjustment',

  [MENU_KEY_NAME.FULFILLMENT]: '/s/:storeId/fulfillment',
  [MENU_KEY_NAME.PICKUP_AND_DELIVERY]:
    '/s/:storeId/fulfillment/pickup-delivery',
  [MENU_KEY_NAME.MAIL_ORDERS]: '/s/:storeId/fulfillment/mail-orders',
  [MENU_KEY_NAME.ONFLEET_DELIVERIES]:
    '/s/:storeId/fulfillment/onfleet-deliveries',
  [MENU_KEY_NAME.COMPLETED_ORDERS]: '/s/:storeId/fulfillment/completed-orders',

  [MENU_KEY_NAME.MEDIA]: '/s/:storeId/media/all_file/grid',
}

export const NavPermission = {
  [MENU_KEY_NAME.DASHBOARD]: dashboardRoles,
  [MENU_KEY_NAME.ALL_PRODUCTS]: productRolesView,
  [MENU_KEY_NAME.ALL_PRODUCT]: productRolesView,
  [MENU_KEY_NAME.ADD_NEW_PRODUCT]: onlyWooProductRoles,
  [MENU_KEY_NAME.PERFORMANCE]: productRolesView,
  [MENU_KEY_NAME.CATEGORIES]: productRolesEditable,
  [MENU_KEY_NAME.TAG]: productRolesEditable,
  [MENU_KEY_NAME.ATTRIBUTES]: onlyWooProductRoles,
  [MENU_KEY_NAME.COMMENTS]: productRolesEditable,

  [MENU_KEY_NAME.ALL_ORDERS]: orderRoles,
  [MENU_KEY_NAME.ALL_ORDER]: orderRoles,
  [MENU_KEY_NAME.ADD_NEW_ORDER]: orderRoles,

  [MENU_KEY_NAME.ALL_CUSTOMERS]: customerRolesView,
  [MENU_KEY_NAME.ALL_CUSTOMER]: customerRolesView,
  [MENU_KEY_NAME.ADD_NEW_CUSTOMER]: onlyWooCustomerRolesEditable,

  [MENU_KEY_NAME.LOYALTY]: loyaltyRolesView,
  [MENU_KEY_NAME.ALL_PROMOTIONS]: loyaltyRolesView,
  [MENU_KEY_NAME.ALL_COUPON]: loyaltyRolesView,
  [MENU_KEY_NAME.POINT_AND_REWARDS]: pointRewardRoles,

  [MENU_KEY_NAME.INSIGHTS]: analyticRoles,
  [MENU_KEY_NAME.INSIGHT_PRODUCTS]: analyticRoles,
  [MENU_KEY_NAME.INSIGHT_REVENUE]: analyticRoles,
  [MENU_KEY_NAME.INSIGHT_VISITORS]: analyticRoles,
  [MENU_KEY_NAME.INSIGHT_REPORTS]: analyticRoles,
  [MENU_KEY_NAME.INSIGHT_ANALYTIC]: analyticRoles,

  [MENU_KEY_NAME.INVENTORY]: onlyWooInventoryRoles,
  [MENU_KEY_NAME.ALL_INVENTORY]: onlyWooInventoryRoles,
  [MENU_KEY_NAME.TRANSFERS]: onlyWooInventoryRoles,
  [MENU_KEY_NAME.ADJUSTMENTS]: onlyWooInventoryRoles,

  [MENU_KEY_NAME.FULFILLMENT]: fulfillmentRoles,
  [MENU_KEY_NAME.PICKUP_AND_DELIVERY]: fulfillmentRoles,
  [MENU_KEY_NAME.MAIL_ORDERS]: fulfillmentRoles,
  [MENU_KEY_NAME.ONFLEET_DELIVERIES]: onfleetRoles,
  [MENU_KEY_NAME.COMPLETED_ORDERS]: fulfillmentRoles,

  [MENU_KEY_NAME.MEDIA]: mediaRoles,
}
