export const shippingAction = Object.freeze({
  GET_SHIPPING_ZONES: 'shipping.GET_SHIPPING_ZONES',
  GET_SHIPPING_ZONES_OK: 'shipping.GET_SHIPPING_ZONES_OK',
  GET_SHIPPING_ZONES_ERR: 'shipping.GET_SHIPPING_ZONES_ERR',

  UPDATE_SHIPPING_ZONES: 'shipping.UPDATE_SHIPPING_ZONES',
  UPDATE_SHIPPING_ZONES_OK: 'shipping.UPDATE_SHIPPING_ZONES_OK',
  UPDATE_SHIPPING_ZONES_ERR: 'shipping.UPDATE_SHIPPING_ZONES_ERR',

  GET_SHIPPING_CLASSES: 'shipping.GET_SHIPPING_CLASSES',
  GET_SHIPPING_CLASSES_OK: 'shipping.GET_SHIPPING_CLASSES_OK',
  GET_SHIPPING_CLASSES_ERR: 'shipping.GET_SHIPPING_CLASSES_ERR',

  ADD_SHIPPING_CLASS: 'shipping.ADD_SHIPPING_CLASS',
  ADD_SHIPPING_CLASS_OK: 'shipping.ADD_SHIPPING_CLASS_OK',
  ADD_SHIPPING_CLASS_ERR: 'shipping.ADD_SHIPPING_CLASS_ERR',

  UPDATE_SHIPPING_CLASS: 'shipping.UPDATE_SHIPPING_CLASS',
  UPDATE_SHIPPING_CLASS_OK: 'shipping.UPDATE_SHIPPING_CLASS_OK',
  UPDATE_SHIPPING_CLASS_ERR: 'shipping.UPDATE_SHIPPING_CLASS_ERR',

  DELETE_SHIPPING_CLASS: 'shipping.DELETE_SHIPPING_CLASS',
  DELETE_SHIPPING_CLASS_OK: 'shipping.DELETE_SHIPPING_CLASS_OK',
  DELETE_SHIPPING_CLASS_ERR: 'shipping.DELETE_SHIPPING_CLASS_ERR',
  SET_SHIPPING_ZONES: 'shipping.SET_SHIPPING_ZONES',
})

const getShippingZones = (
  params,
  callbacks = {
    onSuccess: () => {},
    onError: () => {},
  },
) => ({
  type: shippingAction.GET_SHIPPING_ZONES,
  payload: params,
  callbacks,
})

const setShippingZones = ({ loading, data }) => ({
  type: shippingAction.SET_SHIPPING_ZONES,
  payload: { loading, data },
})

const getShippingClasses = (
  params,
  callbacks = {
    onSuccess: () => {},
    onError: () => {},
  },
) => ({
  type: shippingAction.GET_SHIPPING_CLASSES,
  payload: params,
  callbacks,
})

const addShippingClass = (
  params,
  callbacks = {
    onSuccess: () => {},
    onError: () => {},
  },
) => ({
  type: shippingAction.ADD_SHIPPING_CLASS,
  payload: params,
  callbacks,
})

const updateShippingClass = (
  params,
  callbacks = {
    onSuccess: () => {},
    onError: () => {},
  },
) => ({
  type: shippingAction.UPDATE_SHIPPING_CLASS,
  payload: params,
  callbacks,
})

const deleteShippingClass = (
  params,
  callbacks = {
    onSuccess: () => {},
    onError: () => {},
  },
) => ({
  type: shippingAction.DELETE_SHIPPING_CLASS,
  payload: params,
  callbacks,
})

export default {
  getShippingZones,
  getShippingClasses,
  addShippingClass,
  updateShippingClass,
  deleteShippingClass,
  setShippingZones,
}
