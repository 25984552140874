import { all, takeLatest, put, fork, call } from 'redux-saga/effects'

import skuTagsApi from '~/services/apis/skuTags'

import Actions, { SKU_TAGS_ACTIONS } from '~/redux/actions/skuTags'

export function* getSkuTagsList(action) {
  try {
    const resp = yield call(skuTagsApi.getList, action.payload)
    const newResp = resp?.items?.map((i) => ({ id: i._id, ...i }))
    yield put(Actions.getSkuTagsListSuccess({ ...resp, items: newResp }))
  } catch (e) {
    yield put(Actions.getSkuTagsListFail(e))
  }
}

export function* getSkuTagsDetail(action) {
  try {
    const resp = yield call(skuTagsApi.findOne, action.payload)
    yield put(Actions.getSkuTagDetailSuccess({ tag_id: resp._id, ...resp }))
  } catch (error) {
    yield put(Actions.getSkuTagDetailFail(error))
  }
}

export function* updateSkuTags(action) {
  try {
    const resp = yield call(skuTagsApi.updateOne, action.payload)
    yield put(Actions.updateSkuTagsOk(resp))
    action.onSuccess(resp)
  } catch (e) {
    yield put(Actions.updateSkuTagsFail(e))
    action.onFail(e)
  }
}

export function* createSkuTag(action) {
  try {
    const resp = yield call(skuTagsApi.create, action.payload)
    yield put(Actions.createSkuTagOk(resp))
    action.onSuccess(resp)
  } catch (e) {
    yield put(Actions.createSkuTagFail(e))
    action.onFail(e)
  }
}

export function* watchSkuTags() {
  yield all([
    takeLatest(SKU_TAGS_ACTIONS.GET_SKU_TAGS_LIST, getSkuTagsList),
    takeLatest(SKU_TAGS_ACTIONS.GET_SKU_TAGS_DETAIL, getSkuTagsDetail),
    takeLatest(SKU_TAGS_ACTIONS.UPDATE_SKU_TAGS, updateSkuTags),
    takeLatest(SKU_TAGS_ACTIONS.CREATE_SKU_TAGS, createSkuTag),
  ])
}

export default function* skuTags() {
  yield fork(watchSkuTags)
}
