import { lazyRetry } from '~/utils/lazyRetry'
import { ORDER_ROUTES, ADMIN_ROUTES } from '~/constants/router'
import { orderRoles } from '~/modules/auth/conditions'
import { FLAGS } from '~/constants/flags'

const OrderList = lazyRetry(() => import('~/pages/Orders/OrderList'))
const OrderDetail = lazyRetry(() => import('~/pages/Orders/OrderDetail'))
const CovaOrderList = lazyRetry(() => import('~/pages/Orders/CovaOrderList'))
const CovaOrderListV2 = lazyRetry(
  () => import('~/pages/Orders/CovaOrderListV2'),
)
const CovaOrderDetail = lazyRetry(
  () => import('~/pages/Orders/CovaOrderDetail'),
)
const GreenlineOrderList = lazyRetry(
  () => import('~/pages/Orders/GreenlineOrderList'),
)
const GreenlineOrderDetail = lazyRetry(
  () => import('~/pages/Orders/GreenlineOrderDetail'),
)
const ShopifyOrderList = lazyRetry(
  () => import('~/pages/Orders/ShopifyOrderList'),
)
const ShopifyOrderDetail = lazyRetry(
  () => import('~/pages/Orders/ShopifyOrderDetail'),
)

const OrderRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${ORDER_ROUTES.ORDERS}`,
    component: OrderList,
    covaComponent: CovaOrderList,
    greenlineComponent: GreenlineOrderList,
    shopifyComponent: ShopifyOrderList,
    covaComponentV2: CovaOrderListV2,
    featureFlag: FLAGS.ACD_12640,
    layout: 'admin',
    exact: true,
    roles: orderRoles,
    routeName: 'Orders List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ORDER_ROUTES.TRASH_ORDERS}`,
    component: OrderList,
    layout: 'admin',
    exact: true,
    roles: orderRoles,
    xProps: {
      isTrash: true,
    },
    routeName: 'Orders Trash List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ORDER_ROUTES.CREATE_ORDER}`,
    component: OrderDetail,
    layout: 'admin',
    exact: true,
    roles: orderRoles,
    routeName: 'Order Create',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ORDER_ROUTES.EDIT_ORDER}`,
    component: OrderDetail,
    covaComponent: CovaOrderDetail,
    greenlineComponent: GreenlineOrderDetail,
    shopifyComponent: ShopifyOrderDetail,
    layout: 'admin',
    exact: true,
    roles: orderRoles,
    xProps: {
      isEditing: true,
    },
    routeName: 'Order Edit',
  },
]

export default OrderRouters
