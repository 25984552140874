import produce from 'immer'

import { PRODUCT_ACTION } from '../actions/product'
import {
  DEFAULT_PRODUCT_LIST_DATA,
  makeDefaultProduct,
} from '../default/product'

const initialState = {
  data: DEFAULT_PRODUCT_LIST_DATA,
  loading: false,
  detail: makeDefaultProduct(),
  loadingDetail: false,
  error: '',
  cachedProducts: {},
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case PRODUCT_ACTION.FETCH_PRODUCT_LIST:
        // draft.data = {}
        draft.loading = true
        draft.error = ''
        break
      case PRODUCT_ACTION.SET_LIST_DATA:
        draft.data = payload
        draft.loading = false
        draft.error = ''
        break
      case PRODUCT_ACTION.SET_LIST_ERROR:
        draft.loading = false
        draft.error = payload
        break

      case PRODUCT_ACTION.FETCH_DETAIL:
        draft.detail = makeDefaultProduct()
        draft.loadingDetail = true
        draft.error = ''
        break
      case PRODUCT_ACTION.SET_DETAIL_DATA_ERROR:
        draft.loadingDetail = false
        draft.error = payload
        break
      case PRODUCT_ACTION.SET_DETAIL_DATA:
        draft.loadingDetail = false
        draft.error = ''
        draft.detail = payload
        break
      case PRODUCT_ACTION.SET_CACHED_PRODUCTS:
        draft.cachedProducts = { ...draft.cachedProducts, ...payload }
        break
      default:
        break
    }
  })

export default reducer
