import { Avatar, Link as BtnLink } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CustomContentProps, useSnackbar } from 'notistack'
import React, { useCallback, useState } from 'react'
import { COLORS } from '~/themes/common'
import CloseIcon from '@mui/icons-material/Close'
import { exportTypePrefix, getCSVLink } from '~/utils/helpers/csvDownload'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import {
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledIcon,
  StyledInfo,
  StyledMessage,
  StyledMeta,
  StyledNotificationContainer,
  StyledNotificationContent,
  StyledTime,
  StyledTitle,
} from './common'

interface ExportFileNotification extends CustomContentProps {
  data: any
}

const useStyles = makeStyles(() => ({
  primary: {
    color: '#fff',
    backgroundColor: COLORS.palette.primaryBlue,
  },
}))

const ExportFile = React.forwardRef<HTMLDivElement, ExportFileNotification>(
  ({ id, data }, ref) => {
    const classes = useStyles()
    const {
      type,
      data: { message, title, desc, exportId },
      shownTime,
    } = data
    const [loading, setLoading] = useState(false)
    const { closeSnackbar } = useSnackbar()

    const handleDownload = useCallback(() => {
      setLoading(true)
      getCSVLink(exportId)
        .then((res) => {
          if (res?.signed_url) {
            window.open(res.signed_url, '_blank')
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }, [exportId])

    return (
      <StyledNotificationContainer ref={ref} role="presentation">
        <StyledMeta>
          <StyledMessage>{message}</StyledMessage>
          <StyledTime>{shownTime}</StyledTime>
        </StyledMeta>
        <StyledNotificationContent>
          <StyledIcon>
            <Avatar className={classes.primary}>
              <InsertDriveFileIcon fontSize="small" />
            </Avatar>
          </StyledIcon>
          <StyledInfo>
            <StyledTitle>
              {exportTypePrefix[type] || ''} {title}
            </StyledTitle>
            <BtnLink
              component="button"
              variant="body2"
              onClick={handleDownload}
              disabled={loading}
            >
              {desc}
            </BtnLink>
          </StyledInfo>
          <StyledCloseButtonContainer>
            <StyledCloseButton
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                closeSnackbar(id)
              }}
            >
              <CloseIcon />
            </StyledCloseButton>
          </StyledCloseButtonContainer>
        </StyledNotificationContent>
      </StyledNotificationContainer>
    )
  },
)

export default ExportFile
