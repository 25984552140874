import { useMutation, useQuery } from '@tanstack/react-query'
import { showDialog } from '~/components/common/Dialog'
import { dialogParams } from '~/components/common/Dialog/CustomDialog'
import httpClient from '~/modules/core/httpClient'
import onfleetApi from '~/services/apis/onfleet'
import { queryFnWrapper } from '~/utils/utility'
import HttpConstants from '~/modules/core/httpClient/constants'
import { get as getItem } from '~/utils/storage/memoryStorage'
import { paymentListMapper } from './mapper'
import { INTEGRATIONS_PROFILE3_TYPE } from '~/constants/settings'

const url = {
  PAYFIRMA: 'payfirma',
  MONERIS: 'moneris',
  BLAZEPAY: 'blazepay',
  AEROPAY: 'aeropay',
  COVAPAY: 'covapay',
  BIRCHMOUNT: '/stores/loyalty-birchmount',
  STICKYCARD: '/stores/loyalty-stickycard',
  ALPINE: '/stores/loyalty-alpine-iq',
  PAYMENT_LIST: 'payments',
  PAYMENT_PAYFIRMA: '/stores/payfirma',
  PAYMENT_MONERIS: '/stores/moneris',
  CANFLEET: 'canfleet/integration',
}

export const useOnfleetList = ({ onSuccess, onError }) =>
  useQuery({
    queryKey: ['getOnfleetStores'],
    queryFn: queryFnWrapper({
      queryFn: () => onfleetApi.getOnfleetStore(),
      onSuccess,
      onError,
    }),
    initialData: [],
    select: (data) => {
      if (!data) return []
      return data?.map((item = {}) => ({
        id: item._id,
        storeId: item._id,
        storeName: item.store_name,
        key: item.of_api_key,
        secret: item.of_webhooks_secret,
        enableOf: item.enable_of,
        create_onfleet_pickup_task: item.create_onfleet_pickup_task,
      }))
    },
  })

export const useOnfleetUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: onfleetApi.updateOnfleetStore,
    onSuccess,
    onError,
  })

const updateTwoPointDelivery = (params) =>
  httpClient.json.post('/stores/tpd', params)

export const useTwoPointDeliveryUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: updateTwoPointDelivery,
    onSuccess,
    onError,
  })

const updateHomerunDelivery = (params) =>
  httpClient.json.post('/stores/homerun', params)

export const useHomerunDeliveryUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: updateHomerunDelivery,
    onSuccess,
    onError,
  })

const updateAlpine = (params) => httpClient.json.post(url.ALPINE, params)

export const useAlpineUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: updateAlpine,
    onSuccess,
    onError,
  })

const deleteAlpine = (params) =>
  httpClient.json.delete(url.ALPINE, {
    data: params,
  })

export const useAlpineDelete = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: deleteAlpine,
    onSuccess,
    onError,
  })

const updateBirchmount = (params) =>
  httpClient.json.post(url.BIRCHMOUNT, params)

export const useBirchmountUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: updateBirchmount,
    onSuccess,
    onError,
  })

const deleteBirchmount = (params) =>
  httpClient.json.delete(url.BIRCHMOUNT, {
    data: params,
  })

export const useBirchmountDelete = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: deleteBirchmount,
    onSuccess,
    onError,
  })

const updateStickycard = (params) =>
  httpClient.json.post(url.STICKYCARD, params)

export const useStickycardUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: updateStickycard,
    onSuccess,
    onError,
  })

const deleteStickycard = (params) =>
  httpClient.json.delete(url.STICKYCARD, {
    data: params,
  })

export const useStickycardDelete = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: deleteStickycard,
    onSuccess,
    onError,
  })

const getCredentialDataList = () =>
  httpClient.json.get(
    `/organizations/${getItem(HttpConstants.ORGANIZATION_ID)}/shipstation`,
  )
const updateStoreCredentials = ({ ...params }) =>
  httpClient.json.put(
    `/organizations/${getItem(HttpConstants.ORGANIZATION_ID)}/shipstation`,
    params,
  )

export const useGetCredentialList = () =>
  useQuery({
    queryKey: ['getCredentialDataList'],
    queryFn: queryFnWrapper({
      queryFn: () => getCredentialDataList(),
      onError: (e) => showDialog(dialogParams.error(e)),
    }),
    initialData: null,
    select: (data) => {
      if (!data) return []
      return data?.map((item = {}) => ({
        id: item._id,
        storeId: item._id,
        storeName: item.store_name,
        key: item.ss_credentials?.find((cre) => cre?.selected)?.key || '',
        secret: item.ss_credentials?.find((cre) => cre?.selected)?.secret || '',
        enableOf: item.enable_ss_credential,
      }))
    },
    enabled: !!getItem(HttpConstants.ORGANIZATION_ID),
  })

export const useUpdateStoreCredentials = (params) =>
  useMutation({
    ...params,
    mutationFn: updateStoreCredentials,
  })

const getStatusIntegrations = (storeId) =>
  httpClient.json.get(`/stores/${storeId}/integrations`)

const getPaymentList = (orgId) =>
  httpClient.json.get(`/organizations/${orgId}/${url.PAYMENT_LIST}`)

const blazePayUpdate = async ({
  storeId,
  locationId,
  key,
  enableOf,
  merchantId,
}) => {
  await httpClient.json.post(`/stores/${url.BLAZEPAY}`, () => ({
    warehouse_id: locationId,
    store_id: storeId,
    client_id: key,
    connection: enableOf,
    merchant_id: merchantId,
  }))
}

const aeroPayUpdate = async ({
  storeId,
  locationId,
  aeropayKey,
  enableOf,
  aeropayMerchantId,
  aeropaySecret,
  storeLocationId,
}) => {
  await httpClient.json.post(`/stores/${url.AEROPAY}`, () => ({
    warehouse_id: locationId,
    location_id: storeLocationId,
    merchant_location_id: storeLocationId,
    store_id: storeId,
    aeropay_api_key: aeropayKey,
    aeropay_api_secret: aeropaySecret,
    connection: enableOf,
    aeropay_merchant_id: aeropayMerchantId,
  }))
}

const covaPayUpdate = async ({
  storeId,
  locationId,
  enableOf,
  storeLocationId,
  companyId,
  username,
  password,
  clientId,
  clientSecret,
}) => {
  await httpClient.json.post(`/stores/${url.COVAPAY}`, () => ({
    store_id: storeId,
    warehouse_id: locationId,
    connection: enableOf,
    company_id: companyId,
    location_id: storeLocationId,
    username,
    password,
    client_id: clientId,
    client_secret: clientSecret,
  }))
}

const covaPayDelete = (item) =>
  httpClient.json.delete(`/stores/${url.COVAPAY}`, {
    data: {
      store_id: item.storeId,
      warehouse_id: item.locationId,
      company_id: item.companyId,
      location_id: item.storeLocationId,
    },
  })

const payfirmaUpdate = async ({
  storeId,
  locationId,
  key,
  secret,
  enableOf,
}) => {
  await httpClient.json.post(`/stores/${url.PAYFIRMA}`, () => ({
    warehouse_id: locationId,
    store_id: storeId,
    client_id: key,
    client_secret: secret,
    connection: enableOf,
  }))
}

const updatePayfirmaStatus = async ({
  locationId,
  key,
  secret,
  storeId,
  enableOf,
}) =>
  httpClient.post(url.PAYMENT_PAYFIRMA, {
    warehouse_id: locationId,
    client_id: key,
    client_secret: secret,
    store_id: storeId,
    connection: enableOf,
  })

const monerisUpdate = async ({
  storeId,
  locationId,
  key,
  secret,
  enableOf,
  monerisCheckoutId,
}) => {
  await httpClient.json.post(`/stores/${url.MONERIS}`, () => ({
    warehouse_id: locationId,
    store_id: storeId,
    moneris_store_id: key,
    moneris_api_token: secret,
    connection: enableOf,
    moneris_checkout_id: monerisCheckoutId,
  }))
}

const updateMonerisStatus = async ({
  locationId,
  key,
  secret,
  storeId,
  enableOf,
  monerisCheckoutId,
}) =>
  httpClient.post(url.PAYMENT_MONERIS, {
    warehouse_id: locationId,
    moneris_store_id: key,
    moneris_api_token: secret,
    store_id: storeId,
    connection: enableOf,
    moneris_checkout_id: monerisCheckoutId,
  })

export const useGetStatusIntegrations = (storeId) =>
  useQuery({
    queryKey: ['statusIntegrations', storeId],
    queryFn: () => getStatusIntegrations(storeId),
    initialData: {},
    select: (res) => {
      if (!res) return {}
      return {
        [INTEGRATIONS_PROFILE3_TYPE.AEROPAY]: res?.aeropay,
        [INTEGRATIONS_PROFILE3_TYPE.COVAPAY]: res?.covapay,
        [INTEGRATIONS_PROFILE3_TYPE.BLAZEPAY]: res?.blazepay,
        [INTEGRATIONS_PROFILE3_TYPE.ALPINE]: res?.alpineiq,
        [INTEGRATIONS_PROFILE3_TYPE.SPRINGBIG]: res?.springbig,
        [INTEGRATIONS_PROFILE3_TYPE.PAYFIRMA]: res?.payfirma,
        [INTEGRATIONS_PROFILE3_TYPE.MONERIES]: res?.moneris,
        [INTEGRATIONS_PROFILE3_TYPE.ONFLEET]: res?.onfleet,
        [INTEGRATIONS_PROFILE3_TYPE.SHIPSTATION]: res?.shipstation,
        [INTEGRATIONS_PROFILE3_TYPE.TWOPOINT]: res?.tpd,
        [INTEGRATIONS_PROFILE3_TYPE.HOMERUN]: res?.homerun,
        [INTEGRATIONS_PROFILE3_TYPE.BIRCHMOUNT]: res?.birchmount,
        [INTEGRATIONS_PROFILE3_TYPE.STICKYCARD]: res?.stickycard,
        [INTEGRATIONS_PROFILE3_TYPE.CANFLEET]: res?.canfleet,
      }
    },
  })

export const usePaymentList = (orgId) =>
  useQuery({
    queryKey: ['paymentList', orgId],
    queryFn: () => getPaymentList(orgId),
    initialData: [],
    select: paymentListMapper,
  })

export const useAeroPayUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: aeroPayUpdate,
    onSuccess,
    onError,
  })

export const useCovaPayUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: covaPayUpdate,
    onSuccess,
    onError,
  })

export const useCovaPayDelete = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: covaPayDelete,
    onSuccess,
    onError,
  })

export const useBlazePayUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: blazePayUpdate,
    onSuccess,
    onError,
  })

export const usePayfirmaUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: payfirmaUpdate,
    onSuccess,
    onError,
  })

export const usePayfirmaUpdateStatus = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: updatePayfirmaStatus,
    onSuccess,
    onError,
  })

export const useMonerisUpdate = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: monerisUpdate,
    onSuccess,
    onError,
  })

export const useMonerisUpdateStatus = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: updateMonerisStatus,
    onSuccess,
    onError,
  })

const getCanfleetData = (storeId) =>
  httpClient.json.get(`/stores/${storeId}/${url.CANFLEET}`)

export const useGetCanfleet = (storeId) =>
  useQuery({
    queryKey: ['canfleetData', storeId],
    queryFn: () => getCanfleetData(storeId),
    initialData: null,
    select: (res) => ({
      connected: res?.connected,
      cfOrgId: res?.cf_org_id,
      store: {
        id: res?.store?.store_id,
        storeName: res?.store?.store_name,
        storeId: res?.store?.store_id,
        canfleetApiKey: res?.store?.canfleet_api_key,
        enableCanfleet: res?.store?.enable_canfleet,
        pluginConnection: !!res?.store?.canfleet_plugin_connection_status,
      },
      forceManualConnection: !!res?.require_canfleet_connect_manually,
      userExistsInCf: !!res?.user_exists_in_cf,
    }),
  })

const connectCanfleet = ({ store_id }) =>
  httpClient.json.post(`/stores/${store_id}/${url.CANFLEET}`)

export const useConnectCanfleet = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: connectCanfleet,
    onSuccess,
    onError,
  })

const updateCanfleet = ({ store_id, enableCanfleet }) =>
  httpClient.json.put(`/stores/${store_id}/${url.CANFLEET}`, {
    enabled: enableCanfleet,
  })

export const useUpdateCanfleet = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: updateCanfleet,
    onSuccess,
    onError,
  })

const deleteConnectionCanfleet = ({ store_id }) =>
  httpClient.json.delete(`/stores/${store_id}/${url.CANFLEET}`)

export const useDeleteConnectionCanfleet = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: deleteConnectionCanfleet,
    onSuccess,
    onError,
  })

const checkPluginConnection = ({ store_id }) =>
  httpClient.json.post(
    `/canfleet/check_connection_by_token/${store_id}`,
    () => ({
      source: 'breadstack',
    }),
  )

export const useCheckPluginConnection = ({ onSuccess, onError }) =>
  useMutation({
    mutationFn: checkPluginConnection,
    onSuccess,
    onError,
  })
