import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  CATEGORIES: '/stores/woocommerce/products/categories/',
  POS_CATEGORIES: '/cova/categories/',
}

const ACTION = {
  LIST_ALL_CATEGORIES: 'list_all_categories',
}

const listAllOnlyName = (params) =>
  httpClient.post(url.CATEGORIES, {
    action: ACTIONS.LIST_ALL_ONLY_NAME,
    parameters: params,
  })

const getList = (params) =>
  httpClient.post(url.CATEGORIES, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })

const getShopifyCollection = (storeId, keyword) =>
  httpClient.json.get(
    `/shopify/stores/${storeId}/collections?search_parameter=${keyword}`,
  )

const create = (newCategory) =>
  httpClient.post(url.CATEGORIES, {
    action: ACTIONS.CREATE,
    parameters: {
      item_data: newCategory,
    },
  })

const findOne = (id) =>
  httpClient.post(url.CATEGORIES, {
    action: ACTIONS.READ,
    parameters: { id },
  })

const findMulti = (ids) =>
  httpClient.post(url.CATEGORIES, {
    action: ACTIONS.READ_MULTI,
    parameters: { ids },
  })

const findMultiPOS = (ids) =>
  httpClient.post(url.POS_CATEGORIES, {
    action: ACTIONS.READ_MULTI,
    parameters: { ids },
  })

const removeOne = (id) =>
  httpClient.post(url.CATEGORIES, {
    action: ACTIONS.DELETE,
    parameters: { id },
  })

const updateOne = (params) =>
  httpClient.post(url.CATEGORIES, {
    action: ACTIONS.UPDATE,
    parameters: {
      item_data: params,
    },
  })

const batchUpdate = (params) =>
  httpClient.post(url.CATEGORIES, {
    action: ACTIONS.BATCH_UPDATE,
    parameters: params,
  })

const listAllPOSOnlyName = (params) =>
  httpClient.post(url.POS_CATEGORIES, {
    action: ACTION.LIST_ALL_CATEGORIES,
    parameters: params,
  })

const categoryApi = {
  listAllPOSOnlyName,
  listAllOnlyName,
  getList,
  create,
  findOne,
  findMulti,
  findMultiPOS,
  removeOne,
  updateOne,
  batchUpdate,
  getShopifyCollection,
}

export default categoryApi
