import produce from 'immer'
import { DEFAULT_LIST_DATA } from '../default/share'
import { CANTEC_FULFILLMENT_ACTION } from '../actions/cantecFulfillment'
import { isNumeric } from '~/utils/utility'

const initialState = {
  pendingPickingList: DEFAULT_LIST_DATA,
  loadingPendingPickingList: false,
  pendingPickingListError: '',
  pendingPickupList: DEFAULT_LIST_DATA,
  loadingPendingPickupList: false,
  pendingPickupListError: '',
  pickedUpList: DEFAULT_LIST_DATA,
  loadingPickedUpList: false,
  pickedUpListError: '',
  linkWarehouses: {
    private_pools: [],
    is_public: false,
    is_courier: false,
  },
  loadingLinkWarehouses: false,
  linkWarehouseError: '',
  onlineDrivers: DEFAULT_LIST_DATA,
  loadingOnlineDrivers: false,
  onlineDriverError: '',
  statistic: {
    number_of_pending_picking_orders: 0,
    number_of_pending_pickup_orders: 0,
    number_of_picked_up_orders: 0,
  },
  incompletedOrders: [],
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case CANTEC_FULFILLMENT_ACTION.FETCH_PENDING_PICKING_LIST: {
        draft.loadingPendingPickingList = true
        draft.pendingPickingListError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_PENDING_PICKING_LIST: {
        draft.pendingPickingList = payload
        draft.statistic.number_of_pending_picking_orders = payload.items.length
        draft.loadingPendingPickingList = false
        draft.pendingPickingListError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_PENDING_PICKING_LIST_ERROR: {
        draft.loadingPendingPickingList = false
        draft.pendingPickingListError = payload
        break
      }
      case CANTEC_FULFILLMENT_ACTION.FETCH_PENDING_PICKUP_LIST: {
        draft.loadingPendingPickupList = true
        draft.pendingPickupListError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_PENDING_PICKUP_LIST: {
        draft.pendingPickupList = payload
        draft.statistic.number_of_pending_pickup_orders = payload.items.length
        draft.loadingPendingPickupList = false
        draft.pendingPickupListError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_PENDING_PICKUP_LIST_ERROR: {
        draft.loadingPendingPickupList = false
        draft.pendingPickupListError = payload
        break
      }
      case CANTEC_FULFILLMENT_ACTION.FETCH_PICKED_UP_LIST: {
        draft.loadingPickedUpList = true
        draft.pickedUpListError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_PICKED_UP_LIST: {
        if (action.extraParams.isLoadingMore) {
          draft.pickedUpList = {
            ...draft.pickedUpList,
            items: [
              ...draft.pickedUpList.items,
              ...payload.items.filter(
                (item) =>
                  !draft.pickedUpList.items.find(
                    (currentItem) => currentItem.id === item.id,
                  ),
              ),
            ],
          }
        } else {
          draft.pickedUpList = payload
        }
        draft.statistic.number_of_picked_up_orders = isNumeric(
          payload.total_items,
        )
          ? payload.total_items
          : draft.statistic.number_of_picked_up_orders
        draft.loadingPickedUpList = false
        draft.pickedUpListError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_PICKED_UP_LIST_ERROR: {
        draft.loadingPickedUpList = false
        draft.pickedUpListError = payload
        break
      }
      case CANTEC_FULFILLMENT_ACTION.FETCH_LINK_WAREHOUSES: {
        draft.loadingLinkWarehouses = true
        draft.linkWarehouseError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_LINK_WAREHOUSE_LIST: {
        draft.linkWarehouses = payload
        draft.loadingLinkWarehouses = false
        draft.linkWarehouseError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_LINK_WAREHOUSE_LIST_ERROR: {
        draft.loadingLinkWarehouses = false
        draft.linkWarehouseError = payload
        break
      }
      case CANTEC_FULFILLMENT_ACTION.FETCH_ONLINE_DRIVERS: {
        draft.loadingOnlineDrivers = true
        draft.onlineDriverError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_ONLINE_DRIVER_LIST: {
        if (payload.current_page === 1) {
          draft.onlineDrivers = {
            ...payload,
            items: payload.data,
          }
        } else {
          draft.onlineDrivers = {
            ...payload,
            items: [...draft.onlineDrivers.items, ...payload.data],
          }
        }
        draft.loadingOnlineDrivers = false
        draft.onlineDriverError = ''
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_ONLINE_DRIVER_LIST_ERROR: {
        draft.loadingOnlineDrivers = false
        draft.onlineDriverError = payload
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_COUNT_ORDERS: {
        draft.statistic = {
          number_of_pending_picking_orders: isNumeric(payload.pending_picking)
            ? payload.pending_picking
            : draft.statistic.number_of_pending_picking_orders,
          number_of_pending_pickup_orders: isNumeric(payload.pending_pickup)
            ? payload.pending_pickup
            : draft.statistic.number_of_pending_pickup_orders,
          number_of_picked_up_orders: isNumeric(payload.picked_up)
            ? payload.picked_up
            : draft.statistic.number_of_picked_up_orders,
        }
        break
      }
      case CANTEC_FULFILLMENT_ACTION.SET_INCOMPLETED_ORDERS: {
        draft.incompletedOrders = payload
        break
      }
      default:
        break
    }
  })

export default reducer
