import {
  createSubscription,
  getSubscription,
  askPermission,
} from './pushManager'

/**
 * Checks if Push notification and service workers are supported by your browser
 */
const isSupportedSw = () => 'serviceWorker' in navigator
const isSupportedPushNoti = () => 'PushManager' in window

interface CheckFunction {
  (): boolean
}

const check = (...conds: CheckFunction[]): Promise<boolean> =>
  new Promise((resolve, reject) => {
    if (conds.every((c) => c())) return resolve(true)
    return reject(
      new Error(
        'Service workers and some API are not supported. Thus some features might not work as expected in your browser.',
      ),
    )
  })
const register = (swFile = '/sw.js') =>
  check(isSupportedSw).then(() => navigator.serviceWorker.register(swFile))
const listen = (cb, evt = 'message', opts = false) =>
  check(isSupportedSw).then(() =>
    navigator.serviceWorker.addEventListener(evt, cb, opts),
  )
const remove = (cb, evt = 'message') =>
  check(isSupportedSw).then(() =>
    navigator.serviceWorker.removeEventListener(evt, cb),
  )

// Push Notification
const sw = {
  pushManager: {
    createSubscription: () =>
      check(isSupportedSw, isSupportedPushNoti).then(createSubscription),
    getSubscription: () =>
      check(isSupportedSw, isSupportedPushNoti).then(getSubscription),
    askPermission: () =>
      check(isSupportedSw, isSupportedPushNoti).then(askPermission),
  },
  register,
  listen,
  remove,
}

export default sw
