import produce from 'immer'
import { shippingAction } from '../actions/shipping'
import { handleShippingZonesData } from '~/utils/shipping'

const initialState = {
  shippingZones: {
    data: [],
    loading: false,
    error: {},
  },
  shippingClasses: {
    data: [],
    loading: false,
    creating: false,
    updating: false,
    error: {},
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case shippingAction.GET_SHIPPING_ZONES:
        draft.shippingZones.loading = true
        break
      case shippingAction.GET_SHIPPING_ZONES_OK:
        draft.shippingZones.loading = false
        draft.shippingZones.data = handleShippingZonesData(payload)
        break
      case shippingAction.GET_SHIPPING_ZONES_ERR:
        draft.shippingZones.loading = false
        draft.shippingZones.error = payload
        break

      case shippingAction.SET_SHIPPING_ZONES:
        draft.shippingZones.data = payload.data
        draft.shippingZones.loading = payload.loading
        break
      case shippingAction.GET_SHIPPING_CLASSES:
        draft.shippingClasses.loading = true
        break
      case shippingAction.GET_SHIPPING_CLASSES_OK:
        draft.shippingClasses.loading = false
        draft.shippingClasses.data = payload
        break
      case shippingAction.GET_SHIPPING_CLASSES_ERR:
        draft.shippingClasses.loading = false
        draft.shippingClasses.error = payload
        break

      case shippingAction.UPDATE_SHIPPING_CLASS:
        draft.shippingClasses.updating = true
        break
      case shippingAction.UPDATE_SHIPPING_CLASS_OK:
        draft.shippingClasses.updating = false
        break
      case shippingAction.UPDATE_SHIPPING_CLASS_ERR:
        draft.shippingClasses.updating = false
        break

      case shippingAction.ADD_SHIPPING_CLASS:
        draft.shippingClasses.creating = true
        break
      case shippingAction.ADD_SHIPPING_CLASS_OK:
        draft.shippingClasses.creating = false
        break
      case shippingAction.ADD_SHIPPING_CLASS_ERR:
        draft.shippingClasses.creating = false
        break

      case shippingAction.DELETE_SHIPPING_CLASS:
        draft.shippingClasses.loading = true
        break
      case shippingAction.DELETE_SHIPPING_CLASS_OK:
        draft.shippingClasses.loading = false
        break
      case shippingAction.DELETE_SHIPPING_CLASS_ERR:
        draft.shippingClasses.loading = false
        break

      default:
        break
    }
  })

export default reducer
