import { Components, Theme } from '@mui/material'
import { COLORS } from '~/themes/common'

const components: Components<Omit<Theme, 'components'>> = {
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        fontSize: 14,
        color: COLORS.palette.black,
        '&::placeholder': {
          fontSize: 14,
          color: COLORS.gray.textGray2,
        },
      },
      root: {
        background: COLORS.palette.secondaryBlue3,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        marginTop: 8,
        marginLeft: -8,
        paddingLeft: 8,
        paddingRight: 8,
        boxShadow: '0px 2px 10px #D6DEF2',
        borderRadius: 5,
        maxWidth: 400,
      },
    },
  },
} as Components<Omit<Theme, 'components'>>

export default components
