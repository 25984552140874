export const ANALYTIC_ACTION = {
  GET_TIME_ZONES: 'analytic.GET_TIME_ZONES',
  GET_TIME_ZONES_SUCCESS: 'analytic.GET_TIME_ZONES_SUCCESS',
  GET_TIME_ZONES_ERROR: 'analytic.GET_TIME_ZONES_ERROR',

  FETCH_CUSTOM_ALL: 'analytic.FETCH_ALL_CUSTOM_REPORT',
  FETCH_CUSTOM_ALL_SUCCESS: 'analytic.FETCH_ALL_CUSTOM_REPORT_SUCCESS',
  FETCH_CUSTOM_ALL_ERROR: 'analytic.FETCH_ALL_CUSTOM_REPORT_ERROR',

  FETCH_DEFAULT_REPORT: 'analytic.FETCH_DEFAULT_REPORT',
  FETCH_DEFAULT_REPORT_SUCCESS: 'analytic.FETCH_DEFAULT_REPORT_SUCCESS',
  FETCH_DEFAULT_REPORT_ERROR: 'analytic.FETCH_DEFAULT_REPORT_ERROR',
  CLEAR_DEFAULT_REPORT: 'analytic.CLEAR_REPORT_ERROR',

  CREATE_CUSTOM_REPORT: 'analytic.CREATE',
  CREATE_CUSTOM_REPORT_SUCCESS: 'analytic.CREATE_CUSTOM_REPORT_SUCCESS',
  CREATE_CUSTOM_REPORT_ERROR: 'analytic.CREATE_CUSTOM_REPORT_ERROR',

  EDIT_CUSTOM_REPORT: 'analytic.EDIT_CUSTOM_REPORT',
  EDIT_CUSTOM_REPORT_SUCCESS: 'analytic.EDIT_CUSTOM_REPORT_SUCCESS',
  EDIT_CUSTOM_REPORT_ERROR: 'analytic.EDIT_CUSTOM_REPORT_ERROR',

  DELETE_CUSTOM_REPORT: 'analytic.DELETE_CUSTOM_REPORT',
  DELETE_CUSTOM_REPORT_SUCCESS: 'analytic.DELETE_CUSTOM_REPORT_SUCCESS',
  DELETE_CUSTOM_REPORT_ERROR: 'analytic.DELETE_CUSTOM_REPORT_ERROR',

  GET: 'analytic.GET',
  UPDATE: 'analytic.UPDATE',
  REMOVE: 'analytic.REMOVE',
  GET_GENERATED_REPORTS: 'analytic.GET_GENERATED_REPORTS',
  GET_GENERATED_REPORT: 'analytic.GET_GENERATED_REPORT',
  GET_DEFAULT_REPORT: 'analytic.GET_DEFAULT_REPORT',
}

export const fetchAllCustomReports = () => ({
  type: ANALYTIC_ACTION.FETCH_CUSTOM_ALL,
})

export const fetchDefaultReport = (params) => ({
  type: ANALYTIC_ACTION.FETCH_DEFAULT_REPORT,
  payload: params,
})

export const clearDefaultReport = () => ({
  type: ANALYTIC_ACTION.CLEAR_DEFAULT_REPORT,
})

export const createCustomReport = (
  payload,
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: ANALYTIC_ACTION.CREATE_CUSTOM_REPORT,
  payload,
  callbacks: {
    onSuccess,
    onError,
  },
})

export const updateCustomReport = (
  payload,
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: ANALYTIC_ACTION.EDIT_CUSTOM_REPORT,
  payload,
  callbacks: {
    onSuccess,
    onError,
  },
})

export const deleteCustomReport = (
  payload,
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: ANALYTIC_ACTION.DELETE_CUSTOM_REPORT,
  payload,
  callbacks: {
    onSuccess,
    onError,
  },
})
