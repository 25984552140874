import { transformTypeOptions } from '~/utils/common'

export const WAREHOUSE_OPERATION_STATUS = {
  PARTIAL_CHECK_IN: 'partial_check_in',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  IN_TRANSIT: 'in_transit',
}

export const WAREHOUSE_OPERATION_STATUS_OPTIONS = [
  {
    value: WAREHOUSE_OPERATION_STATUS.IN_TRANSIT,
    label: 'In-transit',
  },
  {
    value: WAREHOUSE_OPERATION_STATUS.PARTIAL_CHECK_IN,
    label: 'Partial Check-in',
  },
  {
    value: WAREHOUSE_OPERATION_STATUS.COMPLETED,
    label: 'Completed',
  },
  {
    value: WAREHOUSE_OPERATION_STATUS.CANCELLED,
    label: 'Cancelled',
  },
]

export const DEFAULT_INVENTORY_PRODUCT_DETAIL = {
  _id: '',
  name: '',
  image: null,
  sku: '',
  upc_code: '',
  bin_location: '',
  brand: '',
  cost: null,
  weight: null,
  length: null,
  width: null,
  height: null,
  available: null,
  reserved: null,
  tags: [],
  decription: '',
  warehouse: '',
  aisle: '',
  vendor_name: '',
  vendor_product_id: '',
  vendor_sku: '',
}

export const WAREHOUSE_OPERATION_TYPES = {
  TRANSFER: 'transfer',
  TRANSFER_IN: 'transfer-in',
  TRANSFER_OUT: 'transfer-out',
  ADJUSTMENT: 'adjustment',
}

export const WAREHOUSE_OPERATION_OPTIONS = transformTypeOptions({
  TRANSFER: WAREHOUSE_OPERATION_TYPES.TRANSFER,
  ADJUSTMENT: WAREHOUSE_OPERATION_TYPES.ADJUSTMENT,
})

export const WAREHOUSE_TRANSFER_TYPE_OPTIONS = transformTypeOptions({
  TRANSFER_IN: WAREHOUSE_OPERATION_TYPES.TRANSFER_IN,
  TRANSFER_OUT: WAREHOUSE_OPERATION_TYPES.TRANSFER_OUT,
})

export const ADJUSTMENT_REASON = {
  DAMAGED: 'damaged',
  DEFECT: 'defect',
  MISSING: 'missing',
  STOCKTAKE: 'stocktake',
  PROMOTION: 'promotion',
  NEW_PRODUCTION: 'new_production',
  PURCHASE_ORDER: 'purchase_order',
  RETURN: 'return',
  INCREASE_RESERVE: 'increase_reserve',
  DECREASE_RESERVE: 'decrease_reserve',
}

export const ADJUSTMENT_PRODUCT_TYPE = {
  AVAILABLE: 'available',
  RESERVED: 'reserved',
}

export const ADJUSTMENT_PRODUCT_TEXT = {
  AVAILABLE: 'Available Products',
  RESERVED: 'Reserved Products',
}

export const ADJUSTMENT_TYPE = {
  CORRECTION: 'correction',
  PRODUCTION: 'production',
  RESERVED: 'reserved',
}

export const PRODUCT_TYPE_ADJUST_TYPE_RELATION = {
  [ADJUSTMENT_PRODUCT_TYPE.AVAILABLE]: [
    ADJUSTMENT_TYPE.CORRECTION,
    ADJUSTMENT_TYPE.PRODUCTION,
  ],
  [ADJUSTMENT_PRODUCT_TYPE.RESERVED]: [ADJUSTMENT_TYPE.RESERVED],
}

export const ADJUST_TYPE_ADJUST_REASON_RELATION = {
  [ADJUSTMENT_TYPE.CORRECTION]: [
    ADJUSTMENT_REASON.DAMAGED,
    ADJUSTMENT_REASON.DEFECT,
    ADJUSTMENT_REASON.MISSING,
    ADJUSTMENT_REASON.STOCKTAKE,
    ADJUSTMENT_REASON.PROMOTION,
  ],
  [ADJUSTMENT_TYPE.PRODUCTION]: [
    ADJUSTMENT_REASON.NEW_PRODUCTION,
    ADJUSTMENT_REASON.PURCHASE_ORDER,
    ADJUSTMENT_REASON.RETURN,
  ],
  [ADJUSTMENT_TYPE.RESERVED]: [
    ADJUSTMENT_REASON.INCREASE_RESERVE,
    ADJUSTMENT_REASON.DECREASE_RESERVE,
  ],
}

export const ADJUSTMENT_TEXT = {
  [ADJUSTMENT_TYPE.CORRECTION]: 'Edit / Correction',
  [ADJUSTMENT_TYPE.PRODUCTION]: 'Production',
  [ADJUSTMENT_TYPE.RESERVED]: 'Reserve',
}

// TRANSFER IN CONSTANTS

export const CHECK_IN_PRODUCT_STATUS = {
  NORMAL: 'normal',
  DAMAGED: 'damaged',
  MISSING: 'missing',
}

export const CHECK_IN_PRODUCT_OPTIONS = transformTypeOptions(
  CHECK_IN_PRODUCT_STATUS,
)

export const ADJUSTMENT_PRODUCT_TABLE_TEXT = {
  available: 'Available Products',
  reserved: 'Reserved Products',
}

export const isShowQtyIncreaseField = (adjustReason) =>
  ![
    ADJUSTMENT_REASON.DECREASE_RESERVE,
    ADJUSTMENT_REASON.DAMAGED,
    ADJUSTMENT_REASON.DEFECT,
    ADJUSTMENT_REASON.MISSING,
    ADJUSTMENT_REASON.PROMOTION,
  ].includes(adjustReason)

export const isShowQtyDecreaseField = (adjustReason) =>
  ![
    ADJUSTMENT_REASON.INCREASE_RESERVE,
    ADJUSTMENT_REASON.NEW_PRODUCTION,
    ADJUSTMENT_REASON.PURCHASE_ORDER,
    ADJUSTMENT_REASON.RETURN,
  ].includes(adjustReason)

export const INVENTORY_WAREHOUSE_TAB_TYPE = {
  ALL_SKU: 'allSku',
  IN_STOCK: 'inStock',
  NO_STOCK: 'noStock',
  WITHOUT_STORE: 'withoutStore',
}

export const INVENTORY_WAREHOUSE_TAB_LIST = [
  {
    label: 'All SKU Items',
    value: INVENTORY_WAREHOUSE_TAB_TYPE.ALL_SKU,
    filters: {},
    subLabel: '0',
  },
  {
    label: 'In Stock',
    value: INVENTORY_WAREHOUSE_TAB_TYPE.IN_STOCK,
    filters: {
      available: {
        min_value: 1,
      },
    },
    subLabel: '0',
  },
  {
    label: 'No Stock',
    value: INVENTORY_WAREHOUSE_TAB_TYPE.NO_STOCK,
    filters: {
      available: {
        min_value: 0,
        max_value: 0,
      },
    },
    subLabel: '0',
  },
  {
    label: 'Without Store',
    value: INVENTORY_WAREHOUSE_TAB_TYPE.WITHOUT_STORE,
    filters: {
      carrying_stores: '-',
    },
    subLabel: '0',
  },
]

export const DEFAULT_INVENTORY_PRODUCT_LIST = {
  [INVENTORY_WAREHOUSE_TAB_TYPE.ALL_SKU]: {
    loading: false,
    items: [],
    total_items: 0,
    total_pages: 0,
    error: '',
  },
  [INVENTORY_WAREHOUSE_TAB_TYPE.IN_STOCK]: {
    loading: false,
    items: [],
    total_items: 0,
    total_pages: 0,
    error: '',
  },
  [INVENTORY_WAREHOUSE_TAB_TYPE.NO_STOCK]: {
    loading: false,
    items: [],
    total_items: 0,
    total_pages: 0,
    error: '',
  },
  [INVENTORY_WAREHOUSE_TAB_TYPE.WITHOUT_STORE]: {
    loading: false,
    items: [],
    total_items: 0,
    total_pages: 0,
    error: '',
  },
}

export const DESCRIPTION_LIST = [
  {
    value: 'created_order',
    label: 'Order',
  },
  {
    value: 'fulfilled_order',
    label: 'Order Fulfilled',
  },
  {
    value: 'updated_order',
    label: 'Order Updated',
  },
  {
    value: 'created_adjustment',
    label: 'Inventory Adjustment',
  },
  {
    value: 'created_transfer',
    label: 'Transfer Out',
  },
  {
    value: 'deleted_transfer',
    label: 'Transfer Cancelled',
  },
  {
    value: 'checked_in_transfer',
    label: 'Transfer In',
  },
  {
    value: 'driver_picked_up_order',
    label: 'Driver Picked Up',
  },
  {
    value: 'other',
    label: 'Other Logs',
  },
]

export const DEFAULT_TRANSFEROUT = {
  from_warehouse: '',
  to_warehouse: '',
  line_items: [],
  check_in_logs: [],
  description: '',
}

export const DEFAULT_TRANSFER_FILTERS = {
  transfer_type: '',
  search_parameter: '',
  from: [],
  to: [],
  status: [],
}

export const DEFAULT_ADJUSTMENT_FILTERS = {
  search_parameter: '',
  operator_id: [],
  inventory_id: [],
  created_from: '',
  created_to: '',
  adjust_type: [],
  adjust_reason: [],
}
export const DEFAULT_COVA_ADJUSTMENT_FILTERS = {
  adjustment_id: '',
  operator_id: [],
  inventory_id: [],
  created_from: '',
  created_to: '',
  adjust_type: '',
  adjust_reason: [],
}
