import logger from '~/utils/logger'
import firebase from './config'
import httpClient from '~/modules/core/httpClient'

const url = {
  NOTIFY_CREATED_FB: '/auth/notify-fb-created',
}
const notifyCreatedFB = () => httpClient.json.post(url.NOTIFY_CREATED_FB)

const signIn = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password)
const signOut = () => firebase.auth().signOut()
const getCurrentUser = () =>
  new Promise((resolve) =>
    firebase.auth().onAuthStateChanged((user) => resolve(user)),
  )
const getClaimsToken = () =>
  getCurrentUser()
    .then((user) => {
      if (!user) return null
      return user?.getIdTokenResult()
    })
    .then((data) => data || null)
const getToken = () =>
  getCurrentUser().then((user) => {
    if (!user) return null
    return user.getIdToken()
  })

const forceUpdateToken = () =>
  getCurrentUser().then((user) => {
    if (!user) return null
    return user.getIdToken(true)
  })

const signUp = async (email, password, name, isEmailVerify = false) => {
  try {
    const uc = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
    if (name) {
      await uc.user.updateProfile({ displayName: name })
    }
    if (isEmailVerify) {
      await notifyCreatedFB()
      await signOut()
    }
    return { uid: uc.user.uid, emailVerified: !isEmailVerify }
  } catch (error) {
    logger.error('Error signing up', error)
    if (error.code === 'auth/email-already-in-use') {
      try {
        const userCredential = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
        const userToken = await userCredential.user.getIdTokenResult()
        if (userToken?.claims?.is_breadstack) {
          throw error
        }
        if (!userCredential.user.emailVerified && isEmailVerify) {
          await notifyCreatedFB()
          await signOut()
        }
        return {
          uid: userCredential.user.uid,
          emailVerified: userCredential.user.emailVerified,
        }
      } catch (ex) {
        await signOut()
        if (ex.code === 'auth/internal-error') {
          throw new Error(
            'Breadstack is integrated with the Breadstack Account system. It looks like you’ve got a Breadstack Account already, please enter your password to continue.',
          )
        } else {
          throw ex
        }
      }
    } else {
      throw error
    }
  }
}

const sendEmailVerification = () =>
  firebase.auth().currentUser.sendEmailVerification()
const isEnableMFA = () =>
  firebase.auth().currentUser.multiFactor.enrolledFactors.length > 0
const getUserFirebaseId = () => firebase.auth().currentUser?.uid
const checkEmailVerification = (code) => firebase.auth().applyActionCode(code)

const fbCreateUser = (email, password) =>
  firebase.auth().createUserWithEmailAndPassword(email, password)

const register = (email, password, name, isEmailVerify) =>
  new Promise((resolve, reject) => {
    fbCreateUser(email, password)
      .then((response) => {
        if (name) {
          response.user.updateProfile({
            displayName: name,
          })
        }
        if (isEmailVerify) response.user.sendEmailVerification()
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

const resetPassword = (email) =>
  firebase.auth().sendPasswordResetEmail(email, {
    url: window.location.origin,
  })

const updatePassword = (code, newPassword) =>
  firebase.auth().confirmPasswordReset(code, newPassword)

const changePassword = (password) =>
  firebase.auth().currentUser.updatePassword(password)

const signInWithCustomToken = (token) =>
  firebase.auth().signInWithCustomToken(token)

const getRefreshToken = () =>
  getCurrentUser().then((user) => {
    if (!user) return null
    return user.refreshToken
  })

const getEnrolledPhoneFactors = () => {
  const user = firebase.auth().currentUser
  logger.info('enrolledFactors', user.enrolledFactors)
  return user.multiFactor.enrolledFactors.find(
    (factor) => factor.factorId === 'phone',
  )
}

const unenrollMFA = async (factor) => {
  let sFactor = factor
  const auth = firebase.auth()
  if (!sFactor) {
    sFactor = auth.currentUser.multiFactor.enrolledFactors.find(
      (f) => f.factorId === 'phone',
    )
  }
  if (sFactor) await auth.currentUser.multiFactor.unenroll(sFactor)
}

const getMFSession = () => firebase.auth().currentUser.multiFactor.getSession()

const enrollMFA = (phoneCredential, displayName) => {
  const multiFactorAssertion =
    firebase.auth.PhoneMultiFactorGenerator.assertion(phoneCredential)
  return firebase
    .auth()
    .currentUser.multiFactor.enroll(multiFactorAssertion, displayName)
}

const getLoginMethods = async () => {
  const user = firebase.auth().currentUser
  if (user) {
    try {
      const idTokenResult = await user.getIdTokenResult()
      return idTokenResult.signInProvider
    } catch (error) {
      logger.error('Error getting login methods', error)
      return false
    }
  } else {
    logger.error('Error getting login methods', 'No user found')
    return false
  }
}

const authFunctions = {
  signIn,
  signOut,
  getCurrentUser,
  getUserFirebaseId,
  getToken,
  sendEmailVerification,
  checkEmailVerification,
  register,
  resetPassword,
  updatePassword,
  changePassword,
  signInWithCustomToken,
  getRefreshToken,
  isEnableMFA,
  unenrollMFA,
  getEnrolledPhoneFactors,
  enrollMFA,
  getMFSession,
  signUp,
  getClaimsToken,
  forceUpdateToken,
  getLoginMethods,
}

export { firebase }
export default authFunctions
