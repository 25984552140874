import { useCallback, useMemo } from 'react'
import {
  getSessionWithKey,
  storeSessionWithKey,
} from '../sessionStorage/sessionStorageManager'

/**
 * Standard IO of the list settings with the session storage
 * @param {string} keyForSettingOfCurrentList Key in the session storage to save setting. Eg: PRODUCT_SETTINGS
 * @returns {Array}
 */
const useListSetting = (keyForSettingOfCurrentList) => {
  const listSettingJson = getSessionWithKey(keyForSettingOfCurrentList)

  const listSetting = useMemo(
    () => (listSettingJson ? JSON.parse(listSettingJson) : {}),
    [listSettingJson],
  )

  const updateListSetting = useCallback(
    (newSetting) => {
      storeSessionWithKey(
        keyForSettingOfCurrentList,
        JSON.stringify(newSetting),
      )
    },
    [keyForSettingOfCurrentList],
  )

  return [listSetting, updateListSetting]
}

export { useListSetting }
