import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'
import pubsub from '~/utils/pushNotification/pubsub'

const AppUpdateNotification = () => {
  const { enqueueSnackbar } = useSnackbar()
  const showNotification = useCallback((evt) => {
    if (evt.type === 'app_updated') {
      enqueueSnackbar('', {
        variant: 'appUpdate',
        autoHideDuration: 15000,
        data: {
          type: 'app_updated',
          id: 'app_updated',
          data: {
            message: 'App updated',
            title: 'A new version has just released!',
          },
          shownTime: moment().format('hh:mm A'),
        },
      })
    }
  }, [])

  useEffect(() => {
    pubsub.on('message', showNotification)
    return () => pubsub.off('message', showNotification)
  }, [showNotification])

  return null
}

export default AppUpdateNotification
