/* eslint-disable prefer-destructuring */
export const BASE_URL = import.meta.env.VITE_REACT_APP_BASE_URL

export const CHATSO_API_URL = import.meta.env.VITE_REACT_APP_CHATSO_API_URL

export const CHATSO_MEDIA_URL = import.meta.env.VITE_REACT_APP_CS_MEDIA_URL

export const SSO_AUTH_API_URL = import.meta.env.VITE_REACT_APP_SSO_AUTH_API_URL

export const BASE_URL_CANTEC = import.meta.env.VITE_REACT_APP_BASE_URL_CANTEC

export const PENDO_API_KEY = import.meta.env.VITE_REACT_APP_PENDO_API_KEY

export const GOOGLE_ANALYTIC_TRACKING_ID = import.meta.env.VITE_GA_TRACKING_ID

export const REACT_APP_GOOGLE_API_KEY = import.meta.env
  .VITE_REACT_APP_GOOGLE_API_KEY

export const REACT_APP_CANTEC_LOCATION_WEBSOCKET = import.meta.env
  .VITE_REACT_APP_CANTEC_LOCATION_WEBSOCKET

export const CANFLEET_URL = import.meta.env.VITE_REACT_APP_CANFLEET_URL

export const REACT_APP_SENTRY_DNS = import.meta.env.VITE_REACT_APP_SENTRY_DNS

export const REACT_APP_ENV = import.meta.env.VITE_REACT_APP_ENV

export const PLUGIN_STORAGE_BASE_URL = import.meta.env
  .VITE_PLUGIN_STORAGE_BASE_URL

export const PLUGIN_BASE_URL = import.meta.env.VITE_PLUGIN_BASE_URL

export const REACT_APP_BS_DEBUG_MODE = import.meta.env
  .VITE_REACT_APP_BS_DEBUG_MODE

export const REACT_APP_CHATSO_URL = import.meta.env.VITE_REACT_APP_CHATSO_URL

export const REACT_APP_FEATURE_FLAG_URL = import.meta.env
  .VITE_REACT_APP_FEATURE_FLAG_URL

export const REACT_APP_FEATURE_FLAG_KEY = import.meta.env
  .VITE_REACT_APP_FEATURE_FLAG_KEY

export const REACT_APP_FIREBASE_API_KEY = import.meta.env
  .VITE_REACT_APP_FIREBASE_API_KEY

export const REACT_APP_FIREBASE_AUTH_DOMAIN = import.meta.env
  .VITE_REACT_APP_FIREBASE_AUTH_DOMAIN

export const REACT_APP_FIREBASE_DATABASE_URL = import.meta.env
  .VITE_REACT_APP_FIREBASE_DATABASE_URL

export const REACT_APP_FIREBASE_PROJECT_ID = import.meta.env
  .VITE_REACT_APP_FIREBASE_PROJECT_ID

export const REACT_APP_FIREBASE_STORAGE_BUCKET = import.meta.env
  .VITE_REACT_APP_FIREBASE_STORAGE_BUCKET

export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = import.meta.env
  .VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID

export const REACT_APP_FIREBASE_APP_ID = import.meta.env
  .VITE_REACT_APP_FIREBASE_APP_ID

export const REACT_APP_FIREBASE_MEASUREMENT_ID = import.meta.env
  .VITE_REACT_APP_FIREBASE_MEASUREMENT_ID

export const REACT_APP_MODE = import.meta.env.MODE

export const SSO_COOKIE_DOMAIN = import.meta.env.VITE_SSO_COOKIE_DOMAIN
export const VITE_GREENLINE_URL = import.meta.env.VITE_GREENLINE_HUB_URL

export const VITE_REACT_APP_CHATSO_WIDGET_ASSETS = import.meta.env
  .VITE_REACT_APP_CHATSO_WIDGET_ASSETS

export const HOTJAR_SITE_ID = import.meta.env.VITE_HOTJAR_SITE_ID

export const HOTJAR_VERSION = import.meta.env.VITE_HOTJAR_VERSION

export const APP_SHA_VERSION = import.meta.env.VITE_APP_SHA_VERSION
