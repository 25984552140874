import { SnackbarProvider } from 'notistack'
import NewOrder from './Components/NewOrder'
import { useAuth } from '~/modules/auth/redux/hook'
import { useCallback, useEffect, useState } from 'react'
import sw from '~/utils/sw'
import webpushApi from '~/services/apis/webpush'
import get from 'lodash/get'
import pubsub from '~/utils/pushNotification/pubsub'
import NewOrderNotification from './NewOrderNotification'
import RepeatOrder from './Components/RepeatOrder'
import AppUpdate from './Components/AppUpdate'
import AppUpdateNotification from './AppUpdateNotification'
import ImportStore from './Components/ImportStore'
import PrivateProductNotification from './PrivateProductNotification'
import PrivateProduct from './Components/PrivateProduct'
import ImportStoreNotification from './ImportStoreNotification'
import ExportFileNotification from './ExportFileNotification'
import ExportFile from './Components/ExportFile'
import {
  increaseNewNotiDocumentName,
  reduceNewNotiDocumentName,
} from '~/utils/notification'

declare module 'notistack' {
  interface VariantOverrides {
    newOrder: {
      order: any
    }
    repeatOrder: {
      data: any
    }
    appUpdate: {
      data: any
    }
    importStore: {
      data: any
    }
    privateProduct: {
      data: any
    }
    exportFile: {
      data: any
    }
  }
}

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [browserSubscription, setBrowserSubscription] = useState(null)
  const {
    authState: {
      data: { userId },
    },
  } = useAuth()

  const readAndUpdateSub = useCallback(async () => {
    if (!userId || !browserSubscription) return
    try {
      const resp = await webpushApi.read()

      const respSubscription =
        (resp && resp.find((s) => s.user_id === userId)) || []
      const respSubscriptionInfo = get(
        respSubscription,
        'subscription_info',
        [],
      )

      if (
        respSubscriptionInfo.every(
          (sub) => sub?.endpoint !== browserSubscription?.endpoint,
        )
      ) {
        webpushApi.subscribe({
          subscription_info: [...respSubscriptionInfo, browserSubscription],
          subscription_topics: ['woocommerce.order.created'],
          subscription_settings: get(
            respSubscription,
            'subscription_settings',
            [],
          ),
        })
      }
      pubsub.emit('subscribeSuccessfully')
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [browserSubscription, userId])

  useEffect(() => {
    const getSub = async () => {
      const existedSub = await sw.pushManager.getSubscription()

      if (existedSub) {
        setBrowserSubscription(existedSub)
      } else {
        const newSub = await sw.pushManager.createSubscription()
        setBrowserSubscription(newSub)
      }
    }

    getSub().catch(() => true)
    sw.pushManager.askPermission().catch(() => true)
  }, [])

  useEffect(() => {
    readAndUpdateSub().catch(() => true)
  }, [readAndUpdateSub])

  useEffect(() => {
    const emitData = (evt) => {
      pubsub.emit('message', evt.data.payload)
    }

    const initSw = async () => {
      await sw.register()
      sw.listen(emitData)
    }

    initSw().catch(() => true)

    return () => {
      sw.remove(emitData)
    }
  }, [])

  useEffect(() => {
    pubsub.on('subscribeSuccessfully', () => {})
    return () => pubsub.off('unsubscribeSuccessfully', () => {})
  }, [])

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      maxSnack={10}
      Components={{
        newOrder: NewOrder,
        repeatOrder: RepeatOrder,
        appUpdate: AppUpdate,
        importStore: ImportStore,
        privateProduct: PrivateProduct,
        exportFile: ExportFile,
      }}
      onClose={() => {
        reduceNewNotiDocumentName()
      }}
      onEnter={() => {
        increaseNewNotiDocumentName()
      }}
    >
      <NewOrderNotification />
      <AppUpdateNotification />
      <ImportStoreNotification />
      <PrivateProductNotification />
      <ExportFileNotification />
      {children}
    </SnackbarProvider>
  )
}

export default NotificationProvider
