import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES } from '~/constants/router'
import { CREATE_STORE_STEPS, ONBOARDING_TYPES } from '~/constants/onboarding'
import { EXTENDED_AUTH_TYPE } from '~/constants/auth'
import { ONBOARD_LOCATIONS } from '~/constants/pos'

const PublicLegacy = lazyRetry(() => import('~/layouts/PublicLegacy'))
const UnifiedAuth = lazyRetry(() => import('~/layouts/UnifiedAuth'))
const Blank = lazyRetry(() => import('~/layouts/Blank'))
const OnboardingLayout = lazyRetry(() => import('~/layouts/OnboardingLayout'))
const Onboarding = lazyRetry(() => import('~/pages/Onboarding'))
const SignOut = lazyRetry(() => import('~/pages/Authentication/SignOut'))
const Page404 = lazyRetry(() => import('~/pages/4xx/404'))
const PageOops = lazyRetry(() => import('~/pages/4xx/Oops'))
const WorkspaceSwitcher = lazyRetry(() => import('~/pages/WorkspaceSwitcher'))
const SignUpLayout = lazyRetry(() => import('~/layouts/SignUpLayout'))
const Invitation = lazyRetry(() => import('~/pages/Invitation'))
const LegacyOnboarding = lazyRetry(() => import('~/pages/Legacy/Onboarding'))
const OnboardLocations = lazyRetry(
  () => import('~/pages/Settings/Pos/Locations/OnboardLocations'),
)
const VerifyPhone = lazyRetry(() => import('~/pages/Account/VerifyPhone'))

const CustomRouters = [
  {
    path: '/oops',
    component: PageOops,
    layout: PublicLegacy,
    exact: true,
    routeName: 'Oops',
  },
  {
    path: '/:page/:orgId?/s/:storeId?/:step?',
    component: Onboarding,
    layout: OnboardingLayout,
    exact: true,
    routeName: 'Onboarding',
  },
  {
    path: `${EXTENDED_AUTH_TYPE.ONBOARDING}/:type?/:step?`,
    component: LegacyOnboarding,
    layout: PublicLegacy,
    exact: true,
    routeName: 'Onboarding - Old',
  },
  {
    path: `/:page/${ONBOARDING_TYPES.USER_INFO}`,
    component: Onboarding,
    layout: OnboardingLayout,
    exact: true,
    routeName: 'Onboarding',
    bsTheme: true,
  },
  {
    path: `/:page/:orgId?/${CREATE_STORE_STEPS.SYNC}`,
    component: Onboarding,
    layout: OnboardingLayout,
    exact: true,
    routeName: 'Onboarding',
  },
  {
    path: ADMIN_ROUTES.LOGOUT,
    component: SignOut,
    layout: Blank,
    exact: true,
    routeName: 'Logout',
  },
  {
    path: '/404',
    component: Page404,
    layout: PublicLegacy,
    exact: true,
    routeName: '404',
  },
  {
    path: `${ADMIN_ROUTES.WORKSPACE_SWITCHER}`,
    component: WorkspaceSwitcher,
    routeName: 'Workspace Switcher',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.SETTINGS}/pos/${ONBOARD_LOCATIONS}`,
    component: OnboardLocations,
    exact: true,
    routeName: 'Onboard Stores',
  },
  {
    path: '/invitation',
    component: Invitation,
    layout: SignUpLayout,
    exact: true,
    routeName: 'Invitation',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.ACCOUNT}/verify-phone`,
    component: VerifyPhone,
    layout: UnifiedAuth,
    exact: true,
    routeName: 'Account - My Account - Verify Account',
  },
]

export default CustomRouters
