/* eslint-disable no-param-reassign */
import produce, { original } from 'immer'

import { makeAtlas, nestCategories } from '~/utils/category'
import { htmlDecode } from '~/utils/utility'
import { CATEGORY_ACTION } from '../actions/category'
import {
  DEFAULT_CATEGORY_LIST_DATA,
  DEFAULT_CATEGORY_DETAIL,
} from '../default/category'

const initialState = {
  data: DEFAULT_CATEGORY_LIST_DATA,
  loading: false,
  atlas: {},
  error: '',
  netDataItems: [], // with level + sort
  levelledData: [], // with paging

  detail: DEFAULT_CATEGORY_DETAIL,
  loadingDetail: false,
  errorDetail: '',
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case CATEGORY_ACTION.FETCH_LIST:
        draft.loading = true
        draft.error = ''
        break
      case CATEGORY_ACTION.SET_LIST_DATA: {
        let mAtlas = {}
        mAtlas = makeAtlas(payload?.items)
        draft.data = payload
        draft.atlas = mAtlas
        draft.loading = false
        draft.error = ''
        break
      }
      case CATEGORY_ACTION.SET_LIST_ERROR:
        draft.loading = false
        draft.error = payload
        break

      case CATEGORY_ACTION.SORT_CATEGORY_LIST:
        const { page, pageSize, sort, search_parameter } = payload
        const atlas = original(draft.atlas)
        // sorting, levelling list
        const sortId = sort && Object.keys(sort)[0]
        const desc = sort && sort[sortId]
        const sortingDataItems = nestCategories(atlas, {
          startId: 0,
          level: 0,
          sortId: sortId || 'name',
          desc: desc || 1,
          keyword: search_parameter,
        })

        // paging, pageSize = number of top most categories (lv1)
        const start = pageSize * (page - 1)
        const end = pageSize * page

        const levelledData = []
        let count = 0
        for (let i = 0; i < sortingDataItems.length; i += 1) {
          if (sortingDataItems[i].level === 1) {
            count += 1
            if (count > end) break
          }
          if (count > start)
            levelledData.push({
              ...sortingDataItems[i],
              name: htmlDecode(sortingDataItems[i].name),
            })
        }

        draft.netDataItems = sortingDataItems
        draft.levelledData = levelledData

        break

      case CATEGORY_ACTION.FETCH_DETAIL:
        draft.loadingDetail = true
        draft.errorDetail = ''
        break
      case CATEGORY_ACTION.SET_DETAIL_DATA:
        draft.loadingDetail = false
        draft.errorDetail = ''
        draft.detail = payload
        break
      case CATEGORY_ACTION.SET_DETAIL_DATA_ERROR:
        draft.loadingDetail = false
        draft.errorDetail = payload
        break

      default:
        break
    }
  })

export default reducer
