import { ADMIN_ROUTES } from '~/constants/router'
import { adminRoles, dashboardRoles } from '~/modules/auth/conditions'
import { lazyRetry } from '~/utils/lazyRetry'

const WooDashboard = lazyRetry(() => import('~/pages/Dashboard/woo'))
const CovaDashboard = lazyRetry(() => import('~/pages/Dashboard/cova'))
const GreenlineDashboard = lazyRetry(
  () => import('~/pages/Dashboard/greenline'),
)
const AdminOptions = lazyRetry(() => import('~/pages/AdminOptions'))
const Profile = lazyRetry(() => import('~/pages/Profile'))
const Home = lazyRetry(() => import('~/pages/Home'))
const Settings = lazyRetry(() => import('~/pages/Settings/WooSetting'))
const EcommerceSetting = lazyRetry(() => import('~/pages/Settings/Ecommerce'))
const PosSetting = lazyRetry(() => import('~/pages/Settings/Pos'))
const Users = lazyRetry(() => import('~/pages/Settings/Users'))
const Locations = lazyRetry(() => import('~/pages/Settings/Locations'))
const IntegrationsSetting = lazyRetry(
  () => import('~/pages/Settings/Integrations'),
)
const IntegrationsProfile3 = lazyRetry(
  () => import('~/pages/Settings/IntegrationsProfile3'),
)
const CompanySetting = lazyRetry(() => import('~/pages/Settings/Company'))
const MyAccount = lazyRetry(() => import('~/pages/Account/MyAccount'))
const Notification = lazyRetry(() => import('~/pages/Account/Notification'))
const ChatShortcuts = lazyRetry(() => import('~/pages/Account/ChatShortcuts'))

const AdminRouter = [
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.WOO_SETTINGS}/:id?/:tab?`,
    component: Settings,
    layout: 'admin',
    exact: true,
    routeName: 'Setting',
    roles: adminRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.SETTINGS}/users/:userId?`,
    component: Users,
    layout: 'admin',
    exact: true,
    routeName: 'Setting - Users',
    bsTheme: true,
    roles: adminRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.SETTINGS}/locations/:locationId?/:tab?`,
    component: Locations,
    layout: 'admin',
    exact: true,
    routeName: 'Setting - Locations',
    bsTheme: true,
    roles: adminRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.SETTINGS}/ecommerce/:tab?/:id?`,
    component: EcommerceSetting,
    layout: 'admin',
    exact: true,
    routeName: 'Setting - Ecommerce',
    bsTheme: true,
    roles: adminRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.SETTINGS}/pos/:tab?`,
    component: PosSetting,
    layout: 'admin',
    exact: true,
    routeName: 'Setting - Pos',
    bsTheme: true,
    roles: adminRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.SETTINGS}/integrations/:site?`,
    component: IntegrationsSetting,
    covaComponent: IntegrationsProfile3,
    greenlineComponent: IntegrationsProfile3,
    layout: 'admin',
    exact: true,
    routeName: 'Setting - Integrations',
    bsTheme: true,
    roles: adminRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.SETTINGS}/company`,
    component: CompanySetting,
    layout: 'admin',
    exact: true,
    routeName: 'Setting - Company',
    bsTheme: true,
    roles: adminRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.ACCOUNT}/my-account`,
    component: MyAccount,
    layout: 'admin',
    exact: true,
    routeName: 'Account - My Account',
    bsTheme: true,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.ACCOUNT}/notification`,
    component: Notification,
    layout: 'admin',
    exact: true,
    routeName: 'Account - Notification',
    bsTheme: true,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.ACCOUNT}/chat-shortcuts`,
    component: ChatShortcuts,
    layout: 'admin',
    exact: true,
    routeName: 'Account - My Account',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.ADMIN_OPTIONS}/:id?/:tab?/:action?`,
    component: AdminOptions,
    layout: 'admin',
    exact: true,
    routeName: 'Admin Options',
    roles: adminRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.USER_PROFILE}`,
    component: Profile,
    layout: 'admin',
    exact: true,
    routeName: 'User Profile',
  },
  {
    path: `${ADMIN_ROUTES.STORE}`,
    component: WooDashboard,
    covaComponent: CovaDashboard,
    greenlineComponent: GreenlineDashboard,
    layout: 'admin',
    exact: true,
    routeName: 'Dashboard',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.DASHBOARD}`,
    component: WooDashboard,
    covaComponent: CovaDashboard,
    greenlineComponent: GreenlineDashboard,
    layout: 'admin',
    routeName: 'Dashboard',
    roles: dashboardRoles,
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.DASHBOARD_SERVICE}`,
    component: WooDashboard,
    covaComponent: CovaDashboard,
    greenlineComponent: GreenlineDashboard,
    layout: 'admin',
    routeName: 'Dashboard',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.DASHBOARD_MARKETING}`,
    component: WooDashboard,
    covaComponent: CovaDashboard,
    greenlineComponent: GreenlineDashboard,
    layout: 'admin',
    routeName: 'Dashboard',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${ADMIN_ROUTES.HOME}`,
    component: Home,
    covaComponent: Home,
    greenlineComponent: Home,
    layout: 'admin',
    routeName: 'Home',
    bsTheme: true,
  },
]

export default AdminRouter
