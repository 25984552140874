import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, COMMENT_ROUTES } from '~/constants/router'

import { productRolesEditable } from '~/modules/auth/conditions'

const CommentList = lazyRetry(() => import('~/pages/Comment/List'))
const CommentForm = lazyRetry(() => import('~/pages/Comment/Form'))

const CommentRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${COMMENT_ROUTES.COMMENTS}`,
    component: CommentList,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    routeName: 'Comments List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${COMMENT_ROUTES.EDIT}`,
    component: CommentForm,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    xProps: {
      isEditing: true,
    },
    routeName: 'Comment Edit',
  },
]

export default CommentRouters
