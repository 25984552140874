import { createSelector } from 'reselect'
import { COMPOSITE_PLUGIN_NAME } from '~/constants/product'
import { isNewerVersion } from '~/utils/utility'

export const getProductList = (state) => state.product.data
export const getProductListLoading = (state) => state.product.loading
export const getProductListError = (state) => state.product.error

export const getProductDetail = (state) => state.product.detail
export const getProductDetailLoading = (state) => state.product.loadingDetail
export const getProductDetailError = (state) => state.product.error

const getActivePlugins = (state) => state.auth?.plugins?.active_plugins
export const hasCompositeProduct = createSelector(
  getActivePlugins,
  (plugins) =>
    plugins && plugins[0] ? !!plugins[0][COMPOSITE_PLUGIN_NAME] : false,
)

export const hasSupportedCompositeVersion = createSelector(
  getActivePlugins,
  (plugins) =>
    plugins &&
    plugins[0] &&
    (plugins[0][COMPOSITE_PLUGIN_NAME] === true ||
      (typeof plugins[0][COMPOSITE_PLUGIN_NAME] === 'string' &&
        isNewerVersion('8.1.1', plugins[0][COMPOSITE_PLUGIN_NAME]))),
)

export const getCachedProducts = (state) => state.product.cachedProducts
