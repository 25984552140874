import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, CATEGORY_ROUTES } from '~/constants/router'

import { productRolesEditable } from '~/modules/auth/conditions'

const CategoryList = lazyRetry(() => import('~/pages/Category/CategoryList'))
const CategoryForm = lazyRetry(() => import('~/pages/Category/CategoryForm'))
const ShopifyCategoryList = lazyRetry(
  () => import('~/pages/Category/ShopifyCategoryList'),
)
const ShopifyCategoryForm = lazyRetry(
  () => import('~/pages/Category/ShopifyCategoryForm'),
)

const CategoryRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${CATEGORY_ROUTES.LIST}`,
    component: CategoryList,
    covaComponent: CategoryList,
    greenlineComponent: CategoryList,
    shopifyComponent: ShopifyCategoryList,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    routeName: 'Categories List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CATEGORY_ROUTES.EDIT}`,
    component: CategoryForm,
    covaComponent: CategoryForm,
    greenlineComponent: CategoryForm,
    shopifyComponent: ShopifyCategoryForm,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    xProps: {
      isEditing: true,
    },
    routeName: 'Category Edit',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CATEGORY_ROUTES.CREATE}`,
    component: CategoryForm,
    covaComponent: CategoryForm,
    greenlineComponent: CategoryForm,
    shopifyComponent: ShopifyCategoryForm,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    routeName: 'Category Create',
  },
]

export default CategoryRouters
