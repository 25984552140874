import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, POINT_AND_REWARDS_ROUTERS } from '~/constants/router'
import {
  pointRewardRoles,
  pointRewardRolesEditable,
} from '~/modules/auth/conditions'

const Point = lazyRetry(() => import('~/pages/PointReward'))

const PointAndRewardRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${POINT_AND_REWARDS_ROUTERS.POINT}/:id?`,
    component: Point,
    layout: 'admin',
    exact: true,
    roles: pointRewardRoles,
    editRoles: pointRewardRolesEditable,
    routeName: 'Points & Rewards',
  },
]

export default PointAndRewardRouters
