import qs from 'querystring'

export const convertValue = (number = 0) => {
  if (number >= 1000000) {
    return number / 100000
  }
  if (number >= 1000) {
    return number / 100
  }
  if (number >= 100) {
    return number / 10
  }

  return number
}

export const transformTypeToTitle = (text) => {
  if (!text) return ''
  const str = text.split('-')
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
  }
  return str.join(' ')
}

export const transformIdToTitle = (text) => {
  const str = text.split('_')
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
  }
  return str.join(' ')
}

export const transformTypeOptions = (list) =>
  Object.values(list).map((value) => ({
    value,
    label: transformTypeToTitle(value),
  }))

export const urlWithStoreID = (url, storeId) =>
  `${storeId ? `/s/${storeId}` : ''}${url}`

export const urlWithStoreIDAndFilter = (url, storeId) =>
  `${storeId ? `/s/${storeId}` : ''}${url}`

export const formatNumberWithDot = (number = 0) =>
  number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0

export const formatNumberWithSign = (number = 0) => {
  if (number >= 1000000000) {
    return `${(number / 1000000000).toFixed(2).replace(/\.0$/, '')}G`
  }
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(2).replace(/\.0$/, '')}M`
  }
  if (number >= 10000) {
    return `${(number / 1000).toFixed(2).replace(/\.0$/, '')}K`
  }
  return parseFloat(number.toFixed(2))
}

export const formatPriceWithSign = (number = 0) => {
  if (number >= 1000000000) {
    return `${(number / 1000000000).toFixed(2).replace(/\.0$/, '')}G`
  }
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(2).replace(/\.0$/, '')}M`
  }
  if (number >= 10000) {
    return `${(number / 1000).toFixed(2).replace(/\.0$/, '')}K`
  }
  return Number.parseFloat(number).toFixed(2)
}

export const formatDecimalNumber = (number = 0) => parseFloat(number.toFixed(2))

export const formatIntegerNumber = (number = 0) => parseFloat(number.toFixed(0))

export const formatCurrency = (cost, currency = '$') => {
  if (!cost) return `${currency}0`
  if (cost.toString().includes('-'))
    return `-${currency}${cost.toString().replace(/^-/, '')}`
  return `${currency}${cost}`
}

export const getParams = (query) => qs.parse(query.replace('?', '')) || {}

// migration from V1
const convertFilters = ({ sortId, desc, ...params }) => {
  const newFilters = {
    ...params,
    ...(sortId && desc ? { sort: { [sortId]: Number(desc) } } : {}),
  }
  Object.keys(newFilters).forEach((key) => {
    if (key !== 'sort') {
      if (
        key !== 'search_parameter' &&
        typeof newFilters[key] === 'string' &&
        newFilters[key].includes('[') &&
        newFilters[key].includes(']')
      ) {
        // array
        // eslint-disable-next-line
        const arr = newFilters[key].replace(/[\[\]]+/g, '').split(',')
        newFilters[key] = arr
      } else if (key.includes('.')) {
        const arr = key.split('.')
        const itemKey = arr[0]
        const itemComponent = arr[1]
        // object contains .
        if (newFilters[itemKey]) {
          newFilters[itemKey][itemComponent] = newFilters[key]
        } else {
          newFilters[itemKey] = { [itemComponent]: newFilters[key] }
        }
        delete newFilters[key]
      }
      switch (key) {
        case 'product_ids':
          newFilters[key] = newFilters[key].map((id) => Number(id))
          break
        case 'variation_ids':
          newFilters[key] = newFilters[key].map((id) => Number(id))
          break
        case 'category_ids':
          newFilters[key] = newFilters[key].map((id) => Number(id))
          break
        case 'purchase_location_ids':
          newFilters[key] = newFilters[key].map((id) => Number(id))
          break
        case 'clv.from':
          newFilters.clv.from = Number(newFilters.clv.from)
          break
        case 'clv.to':
          newFilters.clv.to = Number(newFilters.clv.to)
          break
        case 'total_number_of_orders.from':
          newFilters.total_number_of_orders.from = Number(
            newFilters.total_number_of_orders.from,
          )
          break
        case 'total_number_of_orders.to':
          newFilters.total_number_of_orders.to = Number(
            newFilters.total_number_of_orders.to,
          )
          break
        case 'total_value_of_orders.from':
          newFilters.total_value_of_orders.from = Number(
            newFilters.total_value_of_orders.from,
          )
          break
        case 'total_value_of_orders.to':
          newFilters.total_value_of_orders.to = Number(
            newFilters.total_value_of_orders.to,
          )
          break
        case 'point.from':
          newFilters.point.from = Number(newFilters.point.from)
          break
        case 'point.to':
          newFilters.point.to = Number(newFilters.point.to)
          break
        case 'online_location_ids':
          newFilters[key] = newFilters[key].map((id) => Number(id))
          break
        case 'pos_location_ids':
          newFilters[key] = newFilters[key].map((id) => Number(id))
          break
        default:
      }
    }
  })
  return newFilters
}

export const convertFieldInFilters = (filters) => {
  const newFilters = { ...filters }
  // not push empty filters to url
  // eslint-disable-next-line
  for (const key in newFilters) {
    if (!newFilters[key]) delete newFilters[key]
  }

  Object.keys(newFilters).forEach((ft) => {
    if (
      newFilters[ft] &&
      typeof newFilters[ft] === 'object' &&
      newFilters[ft].length &&
      typeof newFilters[ft].length === 'number'
    ) {
      // array
      if (newFilters[ft].length > 0) {
        newFilters[ft] = `[${newFilters[ft].join(',')}]`
      } else {
        delete newFilters[ft]
      }
    } else if (newFilters[ft] && typeof newFilters[ft] === 'object') {
      // object
      const arr = Object.keys(newFilters[ft]).filter(
        (item) =>
          newFilters[ft][item] ||
          (typeof newFilters[ft][item] === 'number' &&
            newFilters[ft][item] === 0),
      )
      if (arr.length > 0) {
        arr.forEach((key) => {
          newFilters[`${ft}.${key}`] = newFilters[ft][key]
        })
        delete newFilters[ft]
      } else {
        delete newFilters[ft]
      }
    } else if (!filters[ft]) {
      // other type
      delete newFilters[ft]
    }
  })
  return newFilters
}

export const getSortParams = (sort) => {
  let sortParams = {}
  if (sort && Object.keys(sort).length > 0) {
    const key = Object.keys(sort)[0]
    sortParams = {
      sortId: key,
      desc: Number(sort[key]),
    }
  }
  return sortParams
}

export const convertFiltersFromLocation = (locationSearch) =>
  convertFilters(qs.parse(locationSearch.substring(1))) // remove the first '?' character of url search

export const convertFiltersToLocation = ({ storeId, sort, ...params }) =>
  qs.stringify({
    ...convertFieldInFilters(params),
    ...getSortParams(sort),
  })

export const unifiedConvertToLocation = ({ storeId, ...params }) =>
  qs.stringify({
    ...convertFieldInFilters(params),
  })

// end migration from V1

export const isValidEmailFormat = (str) => {
  const re =
    /^[-a-z0-9~!$%^&*_=+}{\\'?]+(\.[-a-z0-9~!$%^&*_=+}{\\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z][a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/

  return re.test(String(str).toLowerCase())
}

export const toLowerCase = (str) => (str || '').toLowerCase()

// export const compareTwoValues = (a, b, desc) => {
//   if (typeof a === 'number' && typeof b === 'number') {
//     return (a > b ? 1 : -1) * (desc || 1)
//   }
//   if (typeof a === 'string' && typeof b === 'string') {
//     return (a > b ? 1 : -1) * (desc || 1)
//   }
//   return 1
// }

export const preventKeyEnter = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

export const camelToSnakeCase = (str) =>
  str ? str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`) : ''

export const snakeToCamel = (s) =>
  s ? s.replace(/(_\w)/g, (k) => k[1].toUpperCase()) : ''
