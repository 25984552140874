import { Avatar } from '@mui/material'
import { CustomContentProps, useSnackbar } from 'notistack'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import {
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledDesc,
  StyledIcon,
  StyledInfo,
  StyledMessage,
  StyledMeta,
  StyledNotificationContainer,
  StyledNotificationContent,
  StyledTime,
  StyledTitle,
} from './common'

interface NewOrderNotificationProps extends CustomContentProps {
  order: any
}

const NewOrder = React.forwardRef<HTMLDivElement, NewOrderNotificationProps>(
  ({ id, order }, ref) => {
    const {
      data: { message, title, desc, path },
      shownTime,
    } = order
    const { closeSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const handleClick = () => {
      closeSnackbar(id)
      navigate(path)
    }

    return (
      <StyledNotificationContainer
        ref={ref}
        role="presentation"
        onClick={handleClick}
      >
        <StyledMeta>
          <StyledMessage>{message}</StyledMessage>
          <StyledTime>{shownTime}</StyledTime>
        </StyledMeta>
        <StyledNotificationContent>
          <StyledIcon>
            <img src={''} alt="" />
          </StyledIcon>
          <StyledInfo>
            <StyledTitle>{title}</StyledTitle>
            <StyledDesc>{desc}</StyledDesc>
          </StyledInfo>
          <StyledCloseButtonContainer>
            <StyledCloseButton
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                closeSnackbar(id)
              }}
            >
              <CloseIcon />
            </StyledCloseButton>
          </StyledCloseButtonContainer>
        </StyledNotificationContent>
      </StyledNotificationContainer>
    )
  },
)

export default NewOrder
