import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  WAREHOUSE_ADJUSTMENTS: 'warehouses/adjustments/',
}

const create = ({ params, warehouseId }) =>
  httpClient.post(url.WAREHOUSE_ADJUSTMENTS, {
    action: ACTIONS.CREATE,
    warehouse_id: warehouseId,
    parameters: params,
  })

const getDetail = ({ params, warehouseId }) =>
  httpClient.post(url.WAREHOUSE_ADJUSTMENTS, {
    action: ACTIONS.READ,
    warehouse_id: warehouseId,
    parameters: params,
  })
const getList = ({ params, warehouseId }) =>
  httpClient.post(url.WAREHOUSE_ADJUSTMENTS, {
    action: ACTIONS.PAGINATE,
    warehouse_id: warehouseId,
    parameters: params,
  })

const exportCSV = ({ warehouseId, ...parameters }) =>
  httpClient.post(url.WAREHOUSE_ADJUSTMENTS, {
    action: ACTIONS.EXPORT,
    warehouse_id: warehouseId,
    parameters,
  })

const warehouseAdjustments = {
  getList,
  getDetail,
  create,
  exportCSV,
}

export default warehouseAdjustments
