export const MOCK_NAVIGATION_DATA = {
  section1: [
    {
      id: 'breadstack',
      url: 'https://int-v2.breadstackcrm.com',
      caption: null,
      icon: 'breadstack_color',
    },
    {
      id: 'chatso',
      url: 'https://web.int.chatso.io',
      caption: null,
      icon: 'chatso_black',
    },
    {
      id: 'canfleet',
      url: 'https://int-app.canteccouriers.com',
      caption: null,
      icon: 'canfleet_black',
    },
  ],
  section2: [
    {
      id: 'breadstack_dashboard',
      url: '/s/:storeId/dashboard',
      caption: 'Dashboard',
      icon: 'dashboard',
    },
    {
      id: 'breadstack_product',
      sub_items: [
        {
          id: 'breadstack_all_products',
          url: '/s/:storeId/products',
          caption: 'All Products',
          hiddenHrefs: ['/s/:storeId/products/:productId/edit'],
          icon: null,
        },
        {
          id: 'breadstack_add_new_product',
          url: '/s/:storeId/products/create',
          caption: 'Add New Product',
          icon: null,
        },
        {
          id: 'breadstack_performance',
          url: '/s/:storeId/products/performance',
          caption: 'Performance',
          icon: null,
        },
        {
          id: 'breadstack_categories',
          url: '/s/:storeId/products/categories',
          caption: 'Categories',
          hiddenHrefs: [
            '/s/:storeId/products/categories/create',
            '/s/:storeId/products/categories/:categoryId/edit',
          ],
          icon: null,
        },
        {
          id: 'breadstack_tags',
          url: '/s/:storeId/products/tags',
          caption: 'Tags',
          hiddenHrefs: [
            '/s/:storeId/products/tags/create',
            '/s/:storeId/products/tags/:tagId/edit',
          ],
          icon: null,
        },
        {
          id: 'breadstack_attributes',
          url: '/s/:storeId/products/attributes',
          caption: 'Attributes',
          hiddenHrefs: [
            '/s/:storeId/products/attributes/create',
            '/s/:storeId/products/attributes/:attributeId/edit',
          ],
          icon: null,
        },
        {
          id: 'breadstack_comments',
          url: '/s/:storeId/products/comments',
          caption: 'Comments',
          hiddenHrefs: [
            '/s/:storeId/products/:productId/comments/:commentId/edit',
          ],
          icon: null,
        },
      ],
      url: null,
      caption: 'Products',
      icon: 'product',
    },
    {
      id: 'breadstack_order',
      url: '/s/:storeId/orders',
      caption: 'Orders',
      hiddenHrefs: ['/s/:storeId/orders/:orderId/edit'],
      icon: 'order',
      sub_items: [
        {
          id: 'breadstack_all_orders',
          url: '/s/:storeId/orders',
          caption: 'All Orders',
          hiddenHrefs: ['/s/:storeId/orders/:orderId/edit'],
          icon: null,
        },
        {
          id: 'breadstack_create_new_order',
          url: '/s/:storeId/orders/create',
          caption: 'Create New Order',
          icon: null,
        },
      ],
    },
    {
      id: 'breadstack_customer',
      url: '/s/:storeId/customers',
      caption: 'Customers',
      hiddenHrefs: ['/s/:storeId/customers/:id/view'],
      icon: 'customer',
      sub_items: [
        {
          id: 'breadstack_all_customers',
          url: '/s/:storeId/customers',
          caption: 'All Customers',
          hiddenHrefs: ['/s/:storeId/customers/:id/view'],
          icon: null,
        },
        {
          id: 'breadstack_add_new_customer',
          url: '/s/:storeId/customers/create',
          caption: 'Add New Customer',
          icon: null,
        },
      ],
    },
    {
      id: 'breadstack_loyalty',
      sub_items: [
        {
          id: 'breadstack_promotions',
          url: '/s/:storeId/loyalty/promotion',
          caption: 'All Promotions',
          hiddenHrefs: [
            '/s/:storeId/loyalty/promotion/:tab',
            '/s/:storeId/loyalty/promotion/:tab/:action',
            '/s/:storeId/loyalty/promotion/:tab/:action/:promotionId',
          ],
          icon: null,
        },
        {
          id: 'breadstack_coupons',
          url: '/s/:storeId/loyalty/coupons',
          caption: 'Coupons',
          hiddenHrefs: [
            '/s/:storeId/loyalty/coupons/create',
            '/s/:storeId/loyalty/coupons/:couponId/edit',
          ],
          icon: null,
        },
        {
          id: 'breadstack_point_and_reward',
          url: '/s/:storeId/loyalty/point-reward',
          caption: 'Points & Rewards',
          hiddenHrefs: ['/s/:storeId/loyalty/point-reward/points-log'],
          icon: null,
        },
      ],
      url: '/s/:storeId/loyalty',
      caption: 'Loyalty',
      icon: 'loyalty',
    },
    {
      id: 'breadstack_insight',
      sub_items: [
        {
          id: 'breadstack_insight_products',
          url: '/s/:storeId/insights/report/inventory-report',
          caption: 'Products',
          icon: null,
        },
        {
          id: 'breadstack_revenue',
          url: '/s/:storeId/insights/report/sales-report',
          caption: 'Revenue',
          icon: null,
        },
        {
          id: 'breadstack_visitors',
          url: '/s/:storeId/insights/report/web-user-report',
          caption: 'Visitors',
          icon: null,
        },
        {
          id: 'breadstack_reports',
          url: '/s/:storeId/insights/custom-report',
          caption: 'Reports',
          hiddenHrefs: [
            '/s/:storeId/insights/custom-report/create',
            '/s/:storeId/insights/custom-report/edit/:id?',
            '/s/:storeId/insights/custom-report/view/:id?',
          ],
          icon: null,
        },
      ],
      url: '/s/:storeId/insights',
      caption: 'Insights',
      icon: 'insight',
    },
    {
      id: 'breadstack_inventory',
      sub_items: [
        {
          id: 'breadstack_all_inventories',
          url: '/s/:storeId/inventory/warehouses',
          caption: 'All Inventories',
          icon: null,
          hiddenHrefs: [
            '/s/:storeId/inventory/warehouses/create',
            '/s/:storeId/inventory/warehouses/:id',
          ],
        },
        {
          id: 'breadstack_transfers',
          url: '/s/:storeId/inventory/transfer',
          caption: 'Transfers',
          icon: null,
          hiddenHrefs: ['/s/:storeId/inventory/transfer/:tab?/:id?'],
        },
        {
          id: 'breadstack_adjustments',
          url: '/s/:storeId/inventory/adjustment',
          caption: 'Adjustments',
          icon: null,
          hiddenHrefs: ['/s/:storeId/inventory/adjustment/:tab?/:id?'],
        },
      ],
      url: '/s/:storeId/inventory',
      caption: 'Inventory',
      icon: 'inventory',
    },
    {
      id: 'breadstack_fulfillment',
      sub_items: [
        {
          id: 'breadstack_pickup_and_delivery',
          url: '/s/:storeId/fulfillment/pickup-delivery',
          caption: 'Pickup & Delivery',
          icon: null,
        },
        {
          id: 'breadstack_mail_orders',
          url: '/s/:storeId/fulfillment/mail-orders',
          caption: 'Mail Orders',
          icon: null,
        },
        {
          id: 'breadstack_onfleet',
          url: '/s/:storeId/fulfillment/onfleet-deliveries',
          caption: 'Onfleet Deliveries',
          icon: null,
        },
        {
          id: 'breadstack_completed_orders',
          url: '/s/:storeId/fulfillment/completed-orders',
          caption: 'Completed Orders',
          icon: null,
        },
      ],
      url: '/s/:storeId/fulfillment',
      caption: 'Fulfillment',
      icon: 'fulfillment',
    },
    {
      id: 'breadstack_media_library',
      url: '/s/:storeId/media/all_file/grid',
      caption: 'Media Library',
      icon: 'media',
    },
  ],
  section3: [
    {
      id: 'log_out',
      url: '/logout',
      caption: null,
      icon: 'log_out',
    },
  ],
  section4: [],
}
