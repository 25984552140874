import { CHATSO_API_URL } from '~/constants/environment'
import { HttpClient } from '../httpClient/client'
import {
  addCsWsIdHeader,
  addHeader,
  basePath,
  defaultResponseErrorHandler,
  handleDefaultChatsoResponse,
  jwtHeader,
  timeout,
} from '../httpClient/interceptors'

class HttpJson extends HttpClient {
  static create() {
    const defaultHttp = new HttpJson()
    defaultHttp.useReq('basePath', basePath(CHATSO_API_URL))
    defaultHttp.useReq('timeout', timeout())
    defaultHttp.useReq(
      'defaultHeader',
      addHeader(
        () => ({ 'Content-Type': 'application/json' }),
        jwtHeader,
        addCsWsIdHeader,
      ),
    )
    defaultHttp.useRes(
      'handleDefaultChatsoResponse',
      handleDefaultChatsoResponse,
    )
    defaultHttp.useRes(
      'defaultResponseErrorHandler',
      undefined,
      defaultResponseErrorHandler,
    )

    return defaultHttp
  }

  get(url, cfg) {
    return this._client.get(url, cfg)
  }

  delete(url, cfg) {
    return this._client.delete(url, cfg)
  }

  post(url, data, cfg) {
    return this._client.post(url, data, cfg)
  }

  put(url, data, cfg) {
    return this._client.put(url, data, cfg)
  }

  patch(url, data, cfg) {
    return this._client.patch(url, data, cfg)
  }
}

export default HttpJson
