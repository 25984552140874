import { takeLatest, all, fork, call, put } from 'redux-saga/effects'
import commentApi from '~/services/apis/comment'

import Actions, { COMMENT_ACTION } from '~/redux/actions/comment'

export function* fetchCommentList(action) {
  try {
    const resp = yield call(commentApi.getList, action.payload)
    yield put(
      Actions.setCommentListData({
        ...resp,
        page: resp?.page || action.payload?.page,
      }),
    )
  } catch (e) {
    yield put(Actions.setCommentListError(e))
  }
}

export function* fetchCommentDetail(action) {
  try {
    const resp = yield call(
      commentApi.findOne,
      action.payload.id,
      action.payload.product_id,
    )
    yield put(Actions.setCommentDetail(resp))
  } catch (e) {
    yield put(Actions.setCommentDetailError(e))
  }
}

export function* watchComment() {
  yield all([
    takeLatest(COMMENT_ACTION.FETCH_LIST, fetchCommentList),
    takeLatest(COMMENT_ACTION.FETCH_DETAIL, fetchCommentDetail),
  ])
}

export default function* tag() {
  yield fork(watchComment)
}
