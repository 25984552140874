/* eslint-disable no-param-reassign */
import produce from 'immer'
import { SHARE_ACTION } from '../actions/share'
import { FILTER_TYPE } from '~/constants/share'
import countryData from '~/assets/data/country.json'
import { convertLocationListName } from '~/utils/share'

const initialState = {
  category: {
    data: [],
    loading: false,
    error: '',
  },
  locations: {
    data: convertLocationListName(countryData),
    loading: false,
    error: '',
  },

  filters: {
    [FILTER_TYPE.ORDER]: [],
    [FILTER_TYPE.CUSTOMER]: [],
    [FILTER_TYPE.PRODUCT_PERFORMANCE]: [],

    loadingList: false,
    adding: false,
    updating: false,
    deleting: false,

    error: '',
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case SHARE_ACTION.GET_CATEGORY_LIST:
        draft.category.loading = true
        draft.category.error = ''
        break
      case SHARE_ACTION.GET_CATEGORY_LIST_OK:
        draft.category.loading = false
        draft.category.data = payload
        break
      case SHARE_ACTION.GET_CATEGORY_LIST_ERR:
        draft.category.loading = false
        draft.category.error = payload
        break

      case SHARE_ACTION.GET_LOCATIONS:
        draft.locations.loading = true
        draft.locations.error = ''
        break
      case SHARE_ACTION.GET_LOCATIONS_SUCCESS:
        draft.locations.loading = false
        draft.locations.data = payload
        break
      case SHARE_ACTION.GET_LOCATIONS_FAILED:
        draft.locations.loading = false
        draft.locations.error = payload
        break

      case SHARE_ACTION.GET_FILTER_LIST:
        draft.filters.loadingList = true
        draft.filters.error = ''
        break

      case SHARE_ACTION.GET_FILTER_LIST_OK:
        draft.filters.loadingList = false
        draft.filters[payload.type] = payload.data
        break

      case SHARE_ACTION.GET_FILTER_LIST_ERR:
        draft.filters.loadingList = false
        draft.filters.error = payload
        break

      case SHARE_ACTION.ADD_FILTER:
        draft.filters.adding = true
        draft.filters.error = ''
        break

      case SHARE_ACTION.ADD_FILTER_OK:
        draft.filters.adding = false
        draft.filters[payload.filter_type] = draft.filters[
          payload.filter_type
        ].concat([payload])
        break

      case SHARE_ACTION.ADD_FILTER_ERR:
        draft.filters.adding = false
        draft.filters.error = payload
        break

      case SHARE_ACTION.UPDATE_FILTER:
        draft.filters.updating = true
        draft.filters.error = ''
        break

      case SHARE_ACTION.UPDATE_FILTER_OK:
        const cloneList = [...draft.filters[payload.type]]
        // eslint-disable-next-line no-underscore-dangle
        cloneList[cloneList.findIndex((item) => item._id === payload._id)] = {
          ...payload,
        }
        draft.filters[payload.type] = cloneList
        draft.filters.updating = false
        break

      case SHARE_ACTION.UPDATE_FILTER_ERR:
        draft.filters.updating = false
        draft.filters.error = payload
        break

      case SHARE_ACTION.DELETE_FILTER:
        draft.filters.deleting = true
        draft.filters.error = ''
        break

      case SHARE_ACTION.DELETE_FILTER_OK:
        draft.filters[payload.type] = draft.filters[payload.type]
          // eslint-disable-next-line no-underscore-dangle
          .filter((item) => item._id !== payload._id)
        draft.filters.deleting = false
        break

      case SHARE_ACTION.DELETE_FILTER_ERR:
        draft.filters.deleting = false
        draft.filters.error = payload
        break

      default:
        break
    }
  })

export default reducer
