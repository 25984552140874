type Listener = (payload: any) => void

interface IObservable {
  listeners: { [key: string]: Listener[] }

  subscribe(evt: string, listener: Listener): void
  unSubscribe(evt: string, listener: Listener): void
  broadcast(evt: string, payload: any): void
}

const observable: IObservable = {
  listeners: {},

  subscribe(evt: string, listener: Listener) {
    if (typeof listener !== 'function') {
      throw new Error('Expected the listener to be a function.')
    }

    if (typeof evt !== 'string') {
      throw new Error('Expected the event to be a string.')
    }

    if (!(evt in this.listeners)) this.listeners[evt] = []

    this.listeners[evt].push(listener)
  },

  unSubscribe(evt: string, listener: Listener) {
    if (!(evt in this.listeners)) return

    this.listeners[evt] = this.listeners[evt].filter((l) => l !== listener)
  },

  broadcast(evt: string, payload: any) {
    if (!(evt in this.listeners)) return

    this.listeners[evt].forEach((listener: Listener) => {
      listener(payload)
    })
  },
}

export default {
  emit: (evt: string, payload: Listener) => {
    observable.broadcast(evt, payload)
  },
  on: (evt: string, cb: Listener) => {
    observable.subscribe(evt, cb)
  },
  off: (evt: string, cb: Listener) => {
    observable.unSubscribe(evt, cb)
  },
}
