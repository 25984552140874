/* eslint-disable */
import axios from 'axios'
import { get as getItem } from '~/utils/storage/memoryStorage'
import {
  basePath,
  timeout,
  addHeader,
  jwtHeader,
  defaultResponseHandler,
  defaultResponseErrorHandler,
  orgAndStoreHeader,
} from './interceptors'
import { jsonToFormData } from './utils'

// axios.defaults.withCredentials = true;
class HttpClient {
  /**
   * Get params from request
   * @param {Function} paramsCb
   * @returns {Object}
   */
  static getParams = (paramsCb) => {
    const params = {
      ...(getItem('STORE_ID') ? { store_id: getItem('STORE_ID') } : {}),
      ...(getItem('ORGANIZATION_ID')
        ? { organization_id: getItem('ORGANIZATION_ID') }
        : {}),
    }
    return paramsCb(params)
  }
  constructor() {
    this._client = axios.create()
    this._reqInterceptors = {}
    this._resInterceptors = {}
  }
  useReq(key, fulfilledFn, rejectedFn) {
    this._reqInterceptors[key] = this._client.interceptors.request.use(
      fulfilledFn,
      rejectedFn,
    )
    return this
  }
  useRes(key, fulfilledFn, rejectedFn) {
    this._resInterceptors[key] = this._client.interceptors.response.use(
      fulfilledFn,
      rejectedFn,
    )
    return this
  }
  ejectReq(key) {
    if (
      this._reqInterceptors[key] === undefined ||
      this._reqInterceptors === null
    )
      return this
    this._client.interceptors.request.eject(this._reqInterceptors[key])
    return this
  }
  ejectRes(key) {
    if (
      this._resInterceptors[key] === undefined ||
      this._resInterceptors === null
    )
      return this
    this._client.interceptors.response.eject(this._resInterceptors[key])
    return this
  }
  request(cfg) {
    this._client.request(cfg)
  }
  get(url, cfg) {
    this._client.get(url, cfg)
  }
  delete(url, cfg) {
    this._client.delete(url, cfg)
  }
  head(url, cfg) {
    this._client.head(url, cfg)
  }
  options(url, cfg) {
    this._client.options(url, cfg)
  }
  post(url, data, cfg) {
    this._client.post(url, data, cfg)
  }
  put(url, data, cfg) {
    this._client.put(url, data, cfg)
  }
  patch(url, data, cfg) {
    this._client.patch(url, data, cfg)
  }
  getUri(cfg) {
    this._client.getUri(cfg)
  }
}
class HttpFormData extends HttpClient {
  static create() {
    const defaultHttp = new HttpFormData()
    defaultHttp.useReq('basePath', basePath())
    defaultHttp.useReq('timeout', timeout())
    defaultHttp.useReq(
      'defaultHeader',
      addHeader(
        () => ({ 'Content-Type': 'multipart/form-data' }),
        jwtHeader,
        orgAndStoreHeader,
      ),
    )
    defaultHttp.useReq('add')

    defaultHttp.useRes('defaultResponseHandler', defaultResponseHandler)
    defaultHttp.useRes(
      'defaultResponseErrorHandler',
      undefined,
      defaultResponseErrorHandler,
    )
    return defaultHttp
  }
  constructor() {
    super()
  }
  post(url, paramsCb, cfg) {
    if (paramsCb && !['object', 'function'].includes(typeof paramsCb))
      throw new Error('Invalid type')
    if (paramsCb && Array.isArray(paramsCb)) throw new Error('Invalid type')
    if (typeof paramsCb === 'function') {
      const requestParams = HttpClient.getParams(
        (params) => jsonToFormData(paramsCb(params)),
        cfg,
      )
      return this._client.post(url, requestParams, cfg)
    }
    const requestParams = HttpClient.getParams((params) =>
      jsonToFormData({ ...params, ...paramsCb }),
    )
    return this._client.post(url, requestParams, cfg)
  }

  put(url, paramsCb, cfg) {
    if (paramsCb && !['object', 'function'].includes(typeof paramsCb))
      throw new Error('Invalid type')
    if (paramsCb && Array.isArray(paramsCb)) throw new Error('Invalid type')
    if (typeof paramsCb === 'function') {
      const requestParams = HttpClient.getParams(
        (params) => jsonToFormData(paramsCb(params)),
        cfg,
      )
      return this._client.put(url, requestParams, cfg)
    }
    const requestParams = HttpClient.getParams((params) =>
      jsonToFormData({ ...params, ...paramsCb }),
    )
    return this._client.put(url, requestParams, cfg)
  }

  patch(url, paramsCb, cfg) {
    if (paramsCb && !['object', 'function'].includes(typeof paramsCb))
      throw new Error('Invalid type')
    if (paramsCb && Array.isArray(paramsCb)) throw new Error('Invalid type')
    if (typeof paramsCb === 'function') {
      const requestParams = HttpClient.getParams(
        (params) => jsonToFormData(paramsCb(params)),
        cfg,
      )
      return this._client.patch(url, requestParams, cfg)
    }
    const requestParams = HttpClient.getParams((params) =>
      jsonToFormData({ ...params, ...paramsCb }),
    )
    return this._client.patch(url, requestParams, cfg)
  }

  delete(url, cfg) {
    return this._client.delete(url, cfg)
  }

  get(url, cfg) {
    return this._client.get(url, cfg)
  }
}
class HttpJson extends HttpClient {
  static create() {
    const defaultHttp = new HttpJson()
    defaultHttp.useReq('basePath', basePath())
    defaultHttp.useReq('timeout', timeout())
    defaultHttp.useReq(
      'defaultHeader',
      addHeader(
        () => ({ 'Content-Type': 'application/json' }),
        jwtHeader,
        orgAndStoreHeader,
      ),
    )
    defaultHttp.useRes('defaultResponseHandler', defaultResponseHandler)
    defaultHttp.useRes(
      'defaultResponseErrorHandler',
      undefined,
      defaultResponseErrorHandler,
    )
    return defaultHttp
  }
  constructor() {
    super()
  }

  post(url, paramsCb, cfg) {
    if (paramsCb && !['object', 'function'].includes(typeof paramsCb))
      throw new Error('Invalid type')
    if (paramsCb && Array.isArray(paramsCb)) throw new Error('Invalid type')
    if (typeof paramsCb === 'function') {
      const requestParams = HttpClient.getParams(paramsCb)
      return this._client.post(url, requestParams, cfg)
    }
    const requestParams = HttpClient.getParams((params) => ({
      ...params,
      ...paramsCb,
    }))
    return this._client.post(url, requestParams, cfg)
  }

  put(url, paramsCb, cfg) {
    if (paramsCb && !['object', 'function'].includes(typeof paramsCb))
      throw new Error('Invalid type')
    if (paramsCb && Array.isArray(paramsCb)) throw new Error('Invalid type')
    if (typeof paramsCb === 'function') {
      const requestParams = HttpClient.getParams(paramsCb)
      return this._client.put(url, requestParams, cfg)
    }
    const requestParams = HttpClient.getParams((params) => ({
      ...params,
      ...paramsCb,
    }))
    return this._client.put(url, requestParams, cfg)
  }

  patch(url, paramsCb, cfg) {
    if (paramsCb && !['object', 'function'].includes(typeof paramsCb))
      throw new Error('Invalid type')
    if (paramsCb && Array.isArray(paramsCb)) throw new Error('Invalid type')
    if (typeof paramsCb === 'function') {
      const requestParams = HttpClient.getParams(paramsCb)
      return this._client.patch(url, requestParams, cfg)
    }
    const requestParams = HttpClient.getParams((params) => ({
      ...params,
      ...paramsCb,
    }))
    return this._client.patch(url, requestParams, cfg)
  }

  delete(url, cfg) {
    return this._client.delete(url, cfg)
  }

  get(url, cfg) {
    return this._client.get(url, cfg)
  }
}

export { HttpClient, HttpFormData, HttpJson }
