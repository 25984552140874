import { makeStyles } from '@mui/styles'
import { CustomContentProps, useSnackbar } from 'notistack'
import React from 'react'
import { COLORS } from '~/themes/common'
import clsx from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import {
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledInfo,
  StyledMeta,
  StyledNotificationContainer,
  StyledNotificationContent,
  StyledTime,
  StyledTitle,
} from './common'

interface AppUpdateNotificationProps extends CustomContentProps {
  data: any
}

const useStyles = makeStyles(() => ({
  notification: {
    background: '#FFF',
    width: 350,
    boxShadow: '0px 3px 6px #2933C533',
    borderRadius: 10,
    cursor: 'pointer',
    marginBottom: 10,
    overflow: 'hidden',
    fontSize: 14,

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  meta: {
    display: 'flex',
    justifyContent: 'space-between',
    background: COLORS.palette.secondaryBlue3,
    padding: '6px 10px',
    lineHeight: '18px',
    fontWeight: 'normal',
  },
  time: {
    color: COLORS.gray.textGray1,
    textTransform: 'uppercase',
    marginLeft: 10,
    whiteSpace: 'nowrap',
  },
  content: {
    display: 'flex',
    padding: '8px 0 8px 10px',
    alignItems: 'center',
  },
  info: {
    flex: 1,
    overflow: 'hidden',
  },
  title: {
    margin: 0,
    fontSize: '0.875rem',
    fontWeight: 500,
    color: COLORS.gray.textGray1,
    wordBreak: 'break-word',
  },
  closeButtonContainer: {
    padding: '9px 15px',
  },
  appUpdatedLabel: {
    padding: '13px 15px',
  },
  closeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    height: 25,
    padding: 0,
    background: 'transparent',
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'none',
    transition: 'all .2s ease-in-out',

    '& svg': {
      width: 14,
      color: COLORS.gray.textGray1,
    },

    '&:hover': {
      background: '#DDDDDD',
      '& svg': {
        color: '#000',
      },
    },
  },
  shownTime: {
    minWidth: 60,
  },
}))

const AppUpdate = React.forwardRef<HTMLDivElement, AppUpdateNotificationProps>(
  ({ id, data }, ref) => {
    const classes = useStyles()
    const {
      data: { message, title },
      shownTime,
    } = data
    const { closeSnackbar } = useSnackbar()
    return (
      <StyledNotificationContainer ref={ref} role="presentation">
        <StyledMeta>
          <span>{message}</span>
          <StyledTime>{shownTime}</StyledTime>
        </StyledMeta>
        <StyledNotificationContent>
          <StyledInfo>
            <StyledTitle className={classes.appUpdatedLabel}>
              {title}
            </StyledTitle>
          </StyledInfo>
          <StyledCloseButtonContainer>
            <StyledCloseButton
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                closeSnackbar(id)
              }}
            >
              <CloseIcon />
            </StyledCloseButton>
          </StyledCloseButtonContainer>
        </StyledNotificationContent>
      </StyledNotificationContainer>
    )
  },
)

export default AppUpdate
