import httpClient from '~/modules/core/httpClient'

const url = {
  ORGANIZATIONS: '/organizations',
  ORG_CREATE: '/organizations/create/',
  ORG_READ: '/organizations/read/',
  ORG_UPDATE: '/organizations/update/',
}

const ACTIONS = {
  READ_SS_CREDENTIALS: 'read_ss_credentials',
  BATCH_SS_CREDENTIALS: 'batch_ss_credentials',
}

const create = ({ organization_name }) =>
  httpClient.post(url.ORG_CREATE, () => ({ organization_name }))

const get = ({ organizations_list }) =>
  httpClient.post(url.ORG_READ, () => ({ organizations_list }))

const update = ({ organization_name }) =>
  httpClient.post(url.ORG_UPDATE, ({ organization_id }) => ({
    organization_id,
    organization_name,
  }))

const readSSCredentials = () =>
  httpClient.post(url.ORGANIZATIONS, ({ organization_id, store_id }) => ({
    organization_id,
    store_id,
    action: ACTIONS.READ_SS_CREDENTIALS,
    parameters: {},
  }))

const batchSSCredentials = (params) =>
  httpClient.post(url.ORGANIZATIONS, {
    action: ACTIONS.BATCH_SS_CREDENTIALS,
    parameters: params,
  })

const organizationApi = {
  create,
  get,
  update,
  readSSCredentials,
  batchSSCredentials,
}

export default organizationApi
