import { lazyRetry } from '~/utils/lazyRetry'
import { FULFILLMENT_ROUTES, ADMIN_ROUTES } from '~/constants/router'
import { fulfillmentRoles, onfleetRoles } from '~/modules/auth/conditions'

const PickAndDelivery = lazyRetry(
  () => import('~/pages/Fulfillment/PickAndDelivery'),
)
const MailOrders = lazyRetry(() => import('~/pages/Fulfillment/MailOrders'))
const OnfleetDeliveries = lazyRetry(
  () => import('~/pages/Fulfillment/OnfleetDeliveries'),
)
const CompletedOrders = lazyRetry(
  () => import('~/pages/Fulfillment/CompletedOrders'),
)
const RedirectOldURL = lazyRetry(
  () => import('~/pages/Fulfillment/RedirectOldURL'),
)

const FulfillmentRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${FULFILLMENT_ROUTES.PICKUP_DELIVERY}`,
    component: PickAndDelivery,
    layout: 'admin',
    exact: true,
    roles: fulfillmentRoles,
    routeName: 'Fulfillment/Pickup-delivery',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${FULFILLMENT_ROUTES.MAIL_ORDERS}`,
    component: MailOrders,
    layout: 'admin',
    exact: true,
    roles: fulfillmentRoles,
    routeName: 'Fulfillment/Mail-orders',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${FULFILLMENT_ROUTES.ONFLEET_DELIVERIES}`,
    component: OnfleetDeliveries,
    layout: 'admin',
    exact: true,
    roles: onfleetRoles,
    routeName: 'Fulfillment/Onfleet-deliveries',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${FULFILLMENT_ROUTES.COMPLETED_ORDERS}`,
    component: CompletedOrders,
    layout: 'admin',
    exact: true,
    roles: fulfillmentRoles,
    routeName: 'Fulfillment/Completed-orders',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${FULFILLMENT_ROUTES.FULFILLMENT}`,
    component: RedirectOldURL,
    layout: 'admin',
    exact: true,
    roles: true,
    routeName: 'Fulfillment/',
  },
]

export default FulfillmentRouters
