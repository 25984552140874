export const defaultStoreParams = {
  flex_rate_enabled: true,
  flex_rule: 'customer_100',
  rate_paid_client: 1.0,
  add_on_fee: 0,
  free_within_distance: 10,
  subsidized_shipping: 20,
  free_shipping_if_order_total_above: 150,
  redirect_url: `${window.location.origin}/onboarding/sync`,
}

export const CANTEC_TYPE = {
  CANTEC_DELIVERY: 'CANTEC_DELIVERY',
  CANTEC_LOCAL_PICKUP: 'CANTEC_LOCAL_PICKUP',
  POINT_INTEGRATION: 'POINT_INTEGRATION',
  SHIPPING_TRACKING: 'SHIPPING_TRACKING',
  USER_APPROVAL: 'USER_APPROVAL',
  PRODUCT_OPTIONS: 'PRODUCT_OPTIONS',
}

export const CANTEC_TYPE_NAME = {
  [CANTEC_TYPE.CANTEC_DELIVERY]: 'Cantec Delivery',
  [CANTEC_TYPE.CANTEC_LOCAL_PICKUP]: 'Cantec Local Pickup',
  [CANTEC_TYPE.POINT_INTEGRATION]: 'Point Integration',
  [CANTEC_TYPE.SHIPPING_TRACKING]: 'Shipping Tracking',
  [CANTEC_TYPE.USER_APPROVAL]: 'User Approval',
  [CANTEC_TYPE.PRODUCT_OPTIONS]: 'Product Options',
}

export const CANTEC_TYPE_SLUG = {
  [CANTEC_TYPE.CANTEC_DELIVERY]: 'cantec-delivery',
  [CANTEC_TYPE.CANTEC_LOCAL_PICKUP]: 'cantec-local-pickup',
}

export const STOCK_DISPLAY_FORMAT = [
  {
    value: 'never_show_quantity_remaining_in_stock',
    label: 'Never show quantity remaining in stock',
  },
  {
    value: 'always_show_quantity_remaining_in_stock',
    label: 'Always show quantity remaining in stock',
  },
]

export const SKU_MERGE_OPTIONS = [
  {
    value: 'rename_sku',
    label: 'Keep two items with the same SKU as distinct',
    description:
      'Both of the SKU numbers will be kept. The new added one will be named as xxxx-duplicate.',
  },
  {
    value: 'merge_quantity',
    label: 'Merge two items with the same SKU, and merge the quantities',
    description:
      'The SKU numbers will be merged into one. The item quantity will be added.',
  },
  {
    value: 'ignore_quantity_in_new_store',
    label: 'Merge two items with the same SKU, but do not merge the quantities',
    description:
      'The SKU numbers will be merged into one. The item quantity the existing warehouses will remain the same.',
  },
]

export const STORE_TYPES = {
  WOO: 'woocommerce',
  COVA: 'cova',
  GREENLINE: 'greenline',
  SHOPIFY: 'shopify',
}

export const STORE_SHORT_TYPE_NAMES = {
  [STORE_TYPES.WOO]: 'Woo',
  [STORE_TYPES.COVA]: 'Cova',
  [STORE_TYPES.GREENLINE]: 'Greenline',
  [STORE_TYPES.SHOPIFY]: 'Shopify',
}

export const STORE_BADGE_COLOR = {
  [STORE_TYPES.WOO]: '#7951a9',
  [STORE_TYPES.COVA]: '#34404f',
  [STORE_TYPES.GREENLINE]: '#1DA469',
}

export const WORKSPACE_GROUP_TYPE = {
  COVA: 'cova',
  GREENLINE: 'greenline',
  WOO: 'woo',
  SHOPIFY: 'shopify',
  CANFLEET: 'canfleet',
  OTHER: 'other',
}

export const WORKSPACE_GROUP_NAME = {
  [WORKSPACE_GROUP_TYPE.COVA]: 'POS Cova',
  [WORKSPACE_GROUP_TYPE.GREENLINE]: 'POS Greenline',
  [WORKSPACE_GROUP_TYPE.WOO]: 'Woocommerce store(s)',
  [WORKSPACE_GROUP_TYPE.SHOPIFY]: 'Shopify store(s)',
  [WORKSPACE_GROUP_TYPE.CANFLEET]: 'CanFleet',
  [WORKSPACE_GROUP_TYPE.OTHER]: 'Other store(s)',
}
