import httpClient from '~/modules/core/httpClient'
import * as Common from './common'

const ACTIONS = Object.freeze({
  ...Common.ACTIONS,
  READ_DIRECT_PAGINATE: 'read_direct_paginate',
})

const url = Object.freeze({
  ATTRIBUTE: '/stores/woocommerce/products/attributes/',
  TERM: '/stores/woocommerce/products/attributes/terms/',
})

const getList = (params) =>
  httpClient.post(url.ATTRIBUTE, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })

const createTerm = (attrId, name) =>
  httpClient.post(url.TERM, {
    action: ACTIONS.CREATE,
    parameters: {
      parent_id: attrId,
      item_data: [{ name }],
    },
  })

const findOne = async (id) => {
  const attribute = await httpClient.post(url.ATTRIBUTE, {
    action: ACTIONS.READ,
    parameters: { id },
  })
  try {
    const terms = await httpClient.post(url.TERM, {
      action: ACTIONS.READ_DIRECT_PAGINATE,
      parameters: { limit: 100, page: 1, parent_id: Number(id) },
    })
    attribute.termList = terms.items
    attribute.oldTermList = terms.items
  } catch (e) {
    attribute.termList = []
    attribute.oldTermList = []
  }
  return attribute
}

const createOne = ({ name, slug, order_by, has_archives }) =>
  httpClient.post(url.ATTRIBUTE, {
    action: ACTIONS.CREATE,
    parameters: {
      item_data: {
        name,
        slug: slug || name.replace(/\s+/g, '-').toLowerCase().slice(0, 25),
        type: 'select',
        order_by: order_by || 'menu_order',
        has_archives: has_archives || false,
      },
    },
  })

const updateOne = (data) =>
  httpClient.post(url.ATTRIBUTE, {
    action: ACTIONS.UPDATE,
    parameters: {
      item_data: {
        ...data,
        slug:
          data.slug ||
          data.name.replace(/\s+/g, '-').toLowerCase().slice(0, 25),
      },
    },
  })

const createWithTerms = async (data) => {
  let res = await createOne(data)
  if (res.id) {
    if (data.termList && data.termList.length > 0) {
      const termRes = await httpClient.post(url.TERM, {
        action: ACTIONS.CREATE,
        parameters: {
          parent_id: res.id,
          item_data: data.termList.map((term) => ({
            name: term.name,
            slug: term.slug,
            description: term.description,
          })),
        },
      })
      if (termRes.message) {
        return termRes.message
      }
      res.attribute_terms = termRes.created
      res = await updateOne(res)
    }
    return res
  }
  return res.message || ''
}

const batchUpdateTerms = ({
  attributeId,
  create = [],
  update = [],
  deleteArray = [],
}) =>
  httpClient.post(url.TERM, {
    action: ACTIONS.BATCH_UPDATE,
    parameters: {
      attribute_id: attributeId,
      data: {
        create,
        update,
        delete: deleteArray,
      },
    },
  })

const findTerms = ({ parent_id, limit = 100, page = 0 }) =>
  httpClient.post(url.TERM, {
    action: ACTIONS.READ_DIRECT_PAGINATE,
    parameters: { limit, page, parent_id },
  })

const editWithTerms = async (data) => {
  const { id } = data
  const { termList, oldTermList, ...rest } = data
  if (id) {
    const dataToCreate = termList.filter((x) => !x.id)
    const dataToEdit = termList.filter((x) => !!x.id)
    const dataToDelete = oldTermList
      .filter((x) => !termList.find((item) => item.id === x.id))
      .map((x) => x.id)
    if (
      dataToCreate.length !== 0 ||
      dataToEdit.length !== 0 ||
      dataToDelete.length !== 0
    ) {
      await batchUpdateTerms({
        attributeId: id,
        create: dataToCreate,
        update: dataToEdit,
        deleteArray: dataToDelete,
      })
    }
  }
  const attrTerms = await findTerms({ parent_id: id, limit: -1, page: 1 })
  rest.attribute_terms = attrTerms.items
  const res = await updateOne(rest)
  res.termList = res.attribute_terms
  res.oldTermList = res.attribute_terms
  return res
}

const batchUpdate = ({ create = [], update = [], deleteArray = [] }) =>
  httpClient.post(url.ATTRIBUTE, {
    action: ACTIONS.BATCH_UPDATE,
    parameters: {
      data: {
        create,
        update,
        delete: deleteArray,
      },
    },
  })

const removeOne = (id) =>
  httpClient.post(url.ATTRIBUTE, {
    action: ACTIONS.DELETE,
    parameters: { id },
  })

export default {
  getList,
  createTerm,
  findOne,
  createOne,
  createWithTerms,
  editWithTerms,
  batchUpdate,
  removeOne,
}
