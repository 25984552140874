import React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { withStyles } from '~/themes/useStyles'
import style from './style'

const Downloading = ({ classes, display, loading }) => (
  <>
    {display && (
      <div className={classes.notificationContainer}>
        <div className={classes.notificationTrack}>
          <div role="presentation" className={classes.notification}>
            <div className={classes.meta}>
              <span className={classes.message}>Downloading</span>
            </div>
            <div className={classes.content}>
              <Box display="flex" width="100%" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={loading} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">
                    {`${Math.round(loading)}%`}
                  </Typography>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
)

Downloading.propTypes = {
  classes: PropTypes.shape().isRequired,
  loading: PropTypes.number.isRequired,
  display: PropTypes.bool.isRequired,
}

export default withStyles(style)(Downloading)
