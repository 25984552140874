import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  REWARD: '/stores/woocommerce/points_rewards',
}

const getList = (parameters) =>
  httpClient.post(url.REWARD, {
    action: ACTIONS.READ_DIRECT_PAGINATE,
    parameters,
  })

const getListPointLog = (parameters) =>
  httpClient.post(url.REWARD, {
    action: ACTIONS.READ_DIRECT_LOG_PAGINATE,
    parameters,
  })

const getListPointLogCustomer = (parameters) =>
  httpClient.post(url.REWARD, {
    action: ACTIONS.READ_LOG,
    parameters,
  })

const getDetail = (parameters) =>
  httpClient.post(url.REWARD, {
    action: ACTIONS.READ,
    parameters,
  })

const updateOne = (parameters) =>
  httpClient.post(url.REWARD, {
    action: ACTIONS.UPDATE_POINTS,
    parameters,
  })

const rewardApi = {
  getList,
  getListPointLog,
  getListPointLogCustomer,
  getDetail,
  updateOne,
}

export default rewardApi
