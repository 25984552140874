import { createTheme, ThemeOptions } from '@mui/material/styles'
import typography from './typography'
import palette from './palette'
import components from './components'

declare module '@mui/material/styles' {
  interface Palette {
    textPrimary: {
      main: string
    }
  }
  interface PaletteOptions {
    textPrimary?: {
      main: string
    }
  }
}

const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography,
  palette,
  components,
} as ThemeOptions

const theme = createTheme(themeOptions)

export default theme
