export const CREDENTIAL_ACTION = {
  FETCH_LIST: 'credential.FETCH_LIST',
  SET_LIST_DATA: 'credential.SET_LIST_DATA',
  SET_LIST_ERROR: 'credential.SET_LIST_ERROR',
  UPDATE_ORG_CREDENTIALS: 'credential.UPDATE_ORG_CREDENTIALS',
  UPDATE_ORG_CREDENTIALS_SUCCESS: 'credential.UPDATE_ORG_CREDENTIALS_SUCCESS',
  UPDATE_ORG_CREDENTIALS_ERROR: 'credential.UPDATE_ORG_CREDENTIALS_ERROR',
  UPDATE_STORE_CREDENTIALS: 'credential.UPDATE_STORE_CREDENTIALS',
  UPDATE_STORE_CREDENTIALS_SUCCESS:
    'credential.UPDATE_STORE_CREDENTIALS_SUCCESS',
  UPDATE_STORE_CREDENTIALS_ERROR: 'credential.UPDATE_STORE_CREDENTIALS_ERROR',
}

export const fetchCredentialList = (params) => ({
  type: CREDENTIAL_ACTION.FETCH_LIST,
  payload: params,
})

export const setCredentialListData = (listData) => ({
  type: CREDENTIAL_ACTION.SET_LIST_DATA,
  payload: listData,
})

export const setCredentialListError = (error) => ({
  type: CREDENTIAL_ACTION.SET_LIST_ERROR,
  payload: error,
})

export const updateOrgCredentials = (
  params,
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: CREDENTIAL_ACTION.UPDATE_ORG_CREDENTIALS,
  payload: params,
  callbacks: {
    onSuccess,
    onError,
  },
})

export const updateOrgCredentialsSuccess = (store_id, data) => ({
  type: CREDENTIAL_ACTION.UPDATE_ORG_CREDENTIALS_SUCCESS,
  payload: {
    store_id,
    data,
  },
})

export const updateOrgCredentialsError = (error) => ({
  type: CREDENTIAL_ACTION.UPDATE_ORG_CREDENTIALS_ERROR,
  payload: error,
})

export const updateStoreCredentials = (
  store_id,
  params,
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: CREDENTIAL_ACTION.UPDATE_STORE_CREDENTIALS,
  payload: {
    store_id,
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

export const updateStoreCredentialsSuccess = (store_id, data) => ({
  type: CREDENTIAL_ACTION.UPDATE_STORE_CREDENTIALS_SUCCESS,
  payload: {
    store_id,
    data,
  },
})

export const updateStoreCredentialsError = (error) => ({
  type: CREDENTIAL_ACTION.UPDATE_STORE_CREDENTIALS_ERROR,
  payload: error,
})

export default {
  fetchCredentialList,
  setCredentialListData,
  setCredentialListError,
  updateOrgCredentials,
  updateOrgCredentialsSuccess,
  updateOrgCredentialsError,
  updateStoreCredentials,
  updateStoreCredentialsSuccess,
  updateStoreCredentialsError,
}
