import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'
import { useAuth } from '~/modules/auth/redux/hook'
import { useAllStores } from '~/redux/hooks/store'
import { useUser } from '~/redux/hooks/user'
import pubsub from '~/utils/pushNotification/pubsub'
import logger from '~/utils/logger'

const ImportStoreNotification = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { actions: authActions } = useAuth()
  const allStores = useAllStores()
  const { actions: userActions } = useUser()

  const showNotification = useCallback((evt) => {
    if (evt.type === 'import_store') {
      enqueueSnackbar('', {
        variant: 'importStore',
        autoHideDuration: 15000,
        onEnter: () => {
          if (evt.data?.status === 'finish_importing') {
            logger.info('Check auth stage from Notification')
            authActions.checkAuthState()
            const storeList = allStores
              .filter((s) => s.authenticated !== undefined)
              .map((s) => ({ store_id: s.storeId }))
            const tempStoreIds = [
              ...storeList,
              ...(evt?.data?.storeId ? [{ store_id: evt?.data?.storeId }] : []),
            ]
            userActions.getStores({ stores_list: tempStoreIds })
          }
        },
        data: {
          type: 'import_store',
          id: 'import_store',
          data: {
            ...evt.data,
          },
          shownTime: moment().format('hh:mm A'),
        },
      })
    }
  }, [])

  useEffect(() => {
    pubsub.on('message', showNotification)
    return () => pubsub.off('message', showNotification)
  }, [showNotification])

  return null
}

export default ImportStoreNotification
