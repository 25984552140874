import produce from 'immer'
import { paymentAction } from '../actions/payment'

const initialState = {
  paymentList: {
    data: [],
    loading: false,
    error: '',
    sync: false,
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case paymentAction.GET_PAYMENT:
      case paymentAction.UPDATE_PAYMENT:
      case paymentAction.REORDER_PAYMENT:
        draft.paymentList.loading = true
        draft.paymentList.error = ''
        break

      case paymentAction.SYNC_WCM_PAYMENT:
        draft.paymentList.sync = true
        draft.paymentList.loading = true
        draft.paymentList.error = ''
        break

      case paymentAction.GET_PAYMENT_SUCCESS:
        draft.paymentList.loading = false
        draft.paymentList.data = payload
        break

      case paymentAction.UPDATE_PAYMENT_SUCCESS:
        draft.paymentList.loading = false
        draft.paymentList.data = draft.paymentList.data.map((item) => {
          if (item.id === payload?.id) {
            return payload
          }
          return item
        })
        break

      case paymentAction.REORDER_PAYMENT_SUCCESS:
        draft.paymentList.loading = false
        draft.paymentList.data = payload
        break

      case paymentAction.SYNC_WCM_PAYMENT_SUCCESS:
        draft.paymentList.loading = false
        draft.paymentList.sync = false
        draft.paymentList.data = payload
        break

      case paymentAction.GET_PAYMENT_ERR:
      case paymentAction.UPDATE_PAYMENT_ERR:
      case paymentAction.SYNC_WCM_PAYMENT_ERR:
        draft.paymentList.loading = false
        draft.paymentList.sync = false
        draft.paymentList.error = payload
        break
      case paymentAction.REORDER_PAYMENT_ERR:
        draft.paymentList.loading = false
        draft.paymentList.data = [...draft.paymentList.data]
        draft.paymentList.error = payload
        break
      default:
        break
    }
  })

export default reducer
