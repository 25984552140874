import { trimObject } from '~/utils/customer'

export const companyDetailMapper = (data) => {
  if (!data) {
    return {}
  }
  return {
    name: data.company_name || '',
    phone: data.phone || '',
    email: data.email || '',
    addressLine1: data.address_line_1,
    addressLine2: data.address_line_2,
    country: data.country,
    city: data.city,
    state: data.state,
    postalCode: data.postal_code,
  }
}

export const mapCompanyToApi = (data) => {
  const convertData = {
    company_name: data.name,
    phone: data.phone,
    email: data.email,
    address_line_1: data.addressLine1,
    address_line_2: data.addressLine2,
    country: data.country,
    city: data.city,
    state: data.state,
    postal_code: data.postalCode,
    orgId: data.orgId,
  }

  return trimObject(convertData)
}

export const userListMapper = (data) => {
  if (!data) {
    return {
      data: [],
      limit: 0,
      total_items: 0,
      total_pages: 0,
      page: 0,
    }
  }
  return data
}

export const locationListMapper = (data) => {
  if (!data) {
    return {
      items: [],
      limit: 0,
      total_items: 0,
      total_pages: 0,
      page: 0,
    }
  }

  return data
}

export const userDetailMapper = (data) => {
  if (!data) {
    return {}
  }
  return {
    userId: data.user_id,
    email: data.user_email,
    isOwner: data.is_owner,
    isGa: data.is_ga,
    assignedStores:
      (data.assigned_stores || []).map((s) => ({
        roleId: s?.role_id,
        storeId: s?.store_id,
        permissions: s?.permissions,
      })) || [],
    fullName: data.full_name || '',
    firstName: data.first_name,
    lastName: data.last_name,
    service: data.service_display_name || '',
    photoUrl: data.photo_url,
    isPendingUser: !data?.joined_at,
    locationsRoles: data.location_roles || {},
  }
}

export const paymentListMapper = (paymentData) => {
  const warehouseList = paymentData.reduce(
    (res, data) =>
      res.concat(
        data.warehouses.map((item) => ({
          id: item._id,
          locationId: item._id, // This is the warehouse id
          merchantId: item?.merchant_id,
          paymentType: item.payment_type ? item.payment_type : '',
          lastUpdate: item.last_update,
          locationName: item.name,
          key: item.client_id || item.moneris_store_id,
          secret: item.client_secret || item.moneris_api_token,
          enableOf: item.connection,
          storeId: data.store_id,
          storeName: data.store_name,
          monerisCheckoutId: item.moneris_checkout_id,
          storeLocationId: item.location_id || item.merchant_location_id,
          aeropayKey: item.aeropay_api_key,
          aeropaySecret: item.aeropay_api_secret,
          aeropayMerchantId: item.aeropay_merchant_id,
          companyId: item.company_id,
          username: item.username,
          password: item.password,
          clientId: item.client_id,
          clientSecret: item.client_secret,
          isExistedKey:
            !!item.client_id ||
            !!item.moneris_store_id ||
            !!item.aeropay_api_key ||
            !!item.covapay_client_key,
        })),
      ),
    [],
  )
  return warehouseList
}

export const paymentMethodListMapper = (data) => {
  if (!data || (data && data.length === 0)) {
    return []
  }

  return data
}
