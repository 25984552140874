import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, PRODUCT_ROUTES } from '~/constants/router'
import {
  productRolesEditable,
  productRolesView,
} from '~/modules/auth/conditions'

const ProductList = lazyRetry(() => import('~/pages/Products/ProductList'))
const ProductForm = lazyRetry(() => import('~/pages/Products/ProductForm'))
const CovaProductList = lazyRetry(
  () => import('~/pages/Products/CovaProductList'),
)
const CovaProductForm = lazyRetry(
  () => import('~/pages/Products/CovaProductForm'),
)
const GreenlineProductForm = lazyRetry(
  () => import('~/pages/Products/GreenlineProductForm'),
)
const GreenlineProductList = lazyRetry(
  () => import('~/pages/Products/GreenlineProductList'),
)
const ShopifyProductList = lazyRetry(
  () => import('~/pages/Products/ShopifyProductList'),
)
const ShopifyProductForm = lazyRetry(
  () => import('~/pages/Products/ShopifyProductForm'),
)

const ProductRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${PRODUCT_ROUTES.PRODUCTS}`,
    component: ProductList,
    covaComponent: CovaProductList,
    greenlineComponent: GreenlineProductList,
    shopifyComponent: ShopifyProductList,
    layout: 'admin',
    exact: true,
    roles: productRolesView,
    editRoles: productRolesEditable,
    routeName: 'Products List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${PRODUCT_ROUTES.TRASH_PRODUCTS}`,
    component: ProductList,
    layout: 'admin',
    exact: true,
    roles: productRolesView,
    editRoles: productRolesEditable,
    xProps: {
      isTrash: true,
    },
    routeName: 'Products Trash List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${PRODUCT_ROUTES.CREATE_PRODUCT}`,
    component: ProductForm,
    covaComponent: CovaProductForm,
    greenlineComponent: GreenlineProductForm,
    shopifyComponent: ShopifyProductForm,
    layout: 'admin',
    exact: true,
    roles: productRolesEditable,
    routeName: 'Product Create',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${PRODUCT_ROUTES.EDIT_PRODUCT}`,
    component: ProductForm,
    covaComponent: CovaProductForm,
    greenlineComponent: GreenlineProductForm,
    shopifyComponent: ShopifyProductForm,
    layout: 'admin',
    exact: true,
    roles: productRolesView,
    editRoles: productRolesEditable,
    xProps: {
      isEditing: true,
    },
    routeName: 'Product Edit',
  },
]

export default ProductRouters
