import { takeLatest, put, call, fork } from 'redux-saga/effects'
import { whAdjustmentActions } from '../actions/warehouseAdjustments'
import warehouseAdjustmentsApis from '~/services/apis/warehouseAdjustments'
import { parseAdjustmentData } from '~/utils/inventoryWarehouses'

export function* getWHAdjustment(action) {
  try {
    const response = yield call(warehouseAdjustmentsApis.getList, {
      params: action.payload.params,
      warehouseId: action.warehouseId,
    })
    yield put({
      type: whAdjustmentActions.GET_LIST_WH_ADJUSTMENTS_OK,
      payload: parseAdjustmentData(response),
    })
    action.callbacks.onSuccess(parseAdjustmentData(response))
  } catch (error) {
    action.callbacks.onError(error)
    yield put({
      type: whAdjustmentActions.GET_LIST_WH_ADJUSTMENTS_ERR,
      payload: error,
    })
  }
}

export function* watchGetWHAdjustment() {
  yield takeLatest(whAdjustmentActions.GET_LIST_WH_ADJUSTMENTS, getWHAdjustment)
}

export default function* warehouseAdjustment() {
  yield fork(watchGetWHAdjustment)
}
