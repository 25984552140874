import httpClient from '~/modules/core/httpClient'
import * as Common from './common'

const ACTIONS = {
  ...Common.ACTIONS,
}
const url = {
  PAYMENT_METHODS: '/stores/woocommerce/payment_gateways',
}

const getPaymentMethods = async () => {
  try {
    const resp = await httpClient.post(url.PAYMENT_METHODS, {
      action: ACTIONS.READ,
      parameters: {},
    })
    return resp
    // eslint-disable-next-line
  } catch (e) {}
  return []
}

const reorderPaymentMethods = async (params) => {
  try {
    const resp = await httpClient.post(url.PAYMENT_METHODS, {
      action: ACTIONS.UPDATE_ORDERS,
      parameters: { payments_list: params },
    })
    return resp
    // eslint-disable-next-line
  } catch (e) {}
  return {}
}

const updatePaymentStatus = async (params) => {
  try {
    const resp = await httpClient.post(url.PAYMENT_METHODS, {
      action: ACTIONS.UPDATE,
      parameters: params,
    })
    return resp
    // eslint-disable-next-line
  } catch (e) {}
  return []
}

const syncPaymentMethodsWithWoo = async () => {
  try {
    const resp = await httpClient.post(url.PAYMENT_METHODS, {
      action: ACTIONS.SYNC,
      parameters: {},
    })
    return resp
    // eslint-disable-next-line
  } catch (e) {}
  return []
}

const paymentApi = {
  getPaymentMethods,
  updatePaymentStatus,
  reorderPaymentMethods,
  syncPaymentMethodsWithWoo,
}

export default paymentApi
