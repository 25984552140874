import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, COUPON_ROUTERS } from '~/constants/router'
import { couponRolesEditable, couponRolesView } from '~/modules/auth/conditions'

const CouponList = lazyRetry(() => import('~/pages/Coupon/CouponList'))
const CovaCouponList = lazyRetry(() => import('~/pages/Coupon/CovaCouponList'))
const GreenlineCouponList = lazyRetry(
  () => import('~/pages/Coupon/GreenlineCouponList'),
)
const CouponForm = lazyRetry(() => import('~/pages/Coupon/Form'))
const CovaCouponForm = lazyRetry(() => import('~/pages/Coupon/CovaForm'))
const GreenlineCouponForm = lazyRetry(
  () => import('~/pages/Coupon/GreenlineForm'),
)
const ShopifyCouponList = lazyRetry(
  () => import('~/pages/Coupon/ShopifyCouponList'),
)
const ShopifyCouponForm = lazyRetry(() => import('~/pages/Coupon/ShopifyForm'))

const CouponRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${COUPON_ROUTERS.LIST}`,
    component: CouponList,
    covaComponent: CovaCouponList,
    greenlineComponent: GreenlineCouponList,
    shopifyComponent: ShopifyCouponList,
    layout: 'admin',
    exact: true,
    roles: couponRolesView,
    editRoles: couponRolesEditable,
    routeName: 'Coupons List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${COUPON_ROUTERS.CREATE}`,
    component: CouponForm,
    covaComponent: CovaCouponForm,
    greenlineComponent: GreenlineCouponForm,
    shopifyComponent: ShopifyCouponForm,
    layout: 'admin',
    exact: true,
    roles: couponRolesEditable,
    routeName: 'Coupons Create',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${COUPON_ROUTERS.UPDATE}`,
    component: CouponForm,
    covaComponent: CovaCouponForm,
    greenlineComponent: GreenlineCouponForm,
    shopifyComponent: ShopifyCouponForm,
    layout: 'admin',
    exact: true,
    roles: couponRolesView,
    editRoles: couponRolesEditable,
    xProps: {
      isEditing: true,
    },
    routeName: 'Coupons Edit',
  },
]

export default CouponRouters
