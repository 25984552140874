import { useCallback, useEffect, useRef, useState } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useParams } from 'react-router-dom'
import pubsub from '~/utils/pushNotification/pubsub'
import { saveNotificationSettingsIDB } from '~/utils/storage/indexedDB'
import {
  set as setLocalStorage,
  get as getLocalStorage,
} from '~/utils/storage/localStorage'
import { AUTH_STORAGE_KEYS } from '~/constants/localStorage'
import { ROLES } from '~/modules/auth/constants'

import { convertToUTC0 } from '~/utils/datetime'
import { useSyncedStores, useAllStores } from '~/redux/hooks/store'
import { useUser } from '~/redux/hooks/user'
import calendarApi from '~/services/apis/calendar'

import {
  NOTIFICATION_ORDER_TYPES,
  NOTIFICATION_SHIPPING_METHODS,
} from '~/constants/settings'
import { groupOrders, isAll, isEnabled } from '~/utils/notification'
import { useNotificationSettings } from '../hooks/useNotificationSettings'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { playNewOrderSound, playRepeatSound } from '~/utils/playSounds'

const NewOrderNotification = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [notificationSettings, setNotificationSettings] = useState({})
  const {
    actions: userActions,
    user: { stores },
    orgId,
  } = useUser()
  const { storeId: currentStoreId } = useParams()
  const authStores = useSyncedStores()
  const allStores = useAllStores()

  const validStoresRef = useRef([])
  const allStoresRef = useRef([])
  const trackedTimeoutRef = useRef({})

  const allowedWarehouses = useCallback(
    (settings, storeId, type) => {
      const roleId = get(
        authStores.find((s) => s.storeId === storeId),
        'roleId',
      )
      if ([ROLES.SERVICE_AGENT, ROLES.SERVICE_MANAGER].includes(roleId)) {
        return []
      }
      const store = stores?.find((s) => s.store_id === storeId)

      if (isEnabled(settings, storeId, type)) {
        if (isAll(settings, storeId, type)) {
          return get(store, 'linked_warehouses', []).map((w) => w.warehouse_id)
        }
        return get(
          get(settings, `${storeId}.settings`, []).find((s) => s.type === type),
          'warehouses',
          [],
        )
          .filter((w) => w.warehouse_id)
          .map((w) => w.warehouse_id)
      }

      return []
    },
    [authStores, stores],
  )

  const handleGetNotificationSettingsSuccess = useCallback(
    (res) => {
      setNotificationSettings(res)
      const settings = {}
      const ids = Object.keys(res)
      ids.forEach((id) => {
        settings[id] = {
          mail_order: allowedWarehouses(
            res,
            id,
            NOTIFICATION_ORDER_TYPES.MAIL_ORDER,
          ),
          local_delivery: allowedWarehouses(
            res,
            id,
            NOTIFICATION_ORDER_TYPES.LOCAL_DELIVERY,
          ),
          pickup: allowedWarehouses(res, id, NOTIFICATION_ORDER_TYPES.PICKUP),
        }
      })

      const saveNotificationSettings = async () => {
        try {
          await saveNotificationSettingsIDB(settings)
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
      saveNotificationSettings()
    },
    [authStores, allowedWarehouses],
  )

  const showNotification = useCallback(
    (evt) => {
      const evtStoreId = evt?.data?.storeId
      const duration = get(
        get(notificationSettings, `${[evtStoreId]}.settings`, []).find(
          (s) => s.type === evt.type,
        ),
        'notification_duration',
        1,
      )
      if (
        [
          NOTIFICATION_ORDER_TYPES.LOCAL_DELIVERY,
          NOTIFICATION_ORDER_TYPES.MAIL_ORDER,
          NOTIFICATION_ORDER_TYPES.PICKUP,
        ].includes(evt.type)
      ) {
        enqueueSnackbar('', {
          variant: 'newOrder',
          onEnter: () => {
            playNewOrderSound()
          },
          autoHideDuration: duration * 60 * 1000,
          order: {
            type: evt.type,
            data: evt.data,
            id: evt.data.orderId,
            shownTime: moment().format('hh:mm A'),
          },
        })
      }
    },
    [notificationSettings],
  )

  const { mutate: getNotificationSettings } = useNotificationSettings({
    onSuccess: handleGetNotificationSettingsSuccess,
  })

  useEffect(() => {
    const storeList = allStores
      .filter((s) => s.authenticated !== undefined && s.orgId === orgId)
      .map((s) => ({ store_id: s.storeId }))
    if (!isEqual(allStoresRef.current, storeList) && orgId) {
      allStoresRef.current = storeList
      userActions.getStores({ stores_list: storeList })
    }
  }, [allStores, orgId])

  useEffect(() => {
    const ids = authStores.map((s) => s.storeId)
    if (!isEqual(validStoresRef.current, ids)) {
      validStoresRef.current = ids
      getNotificationSettings(ids)
    }
  }, [authStores])

  useEffect(() => {
    if (!isEmpty(notificationSettings)) {
      const notificationTimeFlag = getLocalStorage(
        AUTH_STORAGE_KEYS.NOTIFICATION_TIME_FLAG,
      )
      if (
        !isEqual(get(notificationTimeFlag, 'data', {}), notificationSettings)
      ) {
        setLocalStorage(
          AUTH_STORAGE_KEYS.NOTIFICATION_TIME_FLAG,
          JSON.stringify({
            data: { ...notificationSettings },
            timeFlag: Date.now(),
            storeChanged: currentStoreId,
          }),
        )
      }
    }
  }, [notificationSettings, currentStoreId])

  useEffect(() => {
    pubsub.on('message', showNotification)
    return () => pubsub.off('message', showNotification)
  }, [showNotification])

  // Repeat Pending orders
  const countPendingOrders = useCallback(
    async (settings, requestParams, requestType) => {
      try {
        const res = await calendarApi.countOrders(requestParams)

        if (isEmpty(res)) {
          return
        }

        res.forEach((store) => {
          let ordersGroups = {}
          const allowedWh = allowedWarehouses(
            settings,
            store.store_id,
            requestType,
          )

          if (!isEmpty(allowedWh)) {
            ordersGroups = groupOrders(store, requestType).reduce(
              (acc, cur) => {
                if (!allowedWh.includes(cur.warehouse_id)) {
                  return acc
                }

                return {
                  ...acc,
                  [cur.warehouse_id]: {
                    ...cur,
                    type: requestType,
                    storeId: store.store_id,
                    storeName: store.store_name,
                    total_orders:
                      (acc?.[cur.warehouse_id]?.total_orders || 0) +
                      cur.total_orders,
                  },
                }
              },
              {},
            )
          }
          if (!isEmpty(ordersGroups)) {
            Object.keys(ordersGroups).forEach((key) => {
              const {
                type,
                storeName,
                storeId,
                warehouse_id,
                warehouse_name,
                total_orders,
                methodId,
              } = ordersGroups[key]

              if (total_orders <= 0) {
                return
              }

              let path = ''
              const desc = warehouse_name || '-'

              if (type === NOTIFICATION_ORDER_TYPES.MAIL_ORDER) {
                path = `/s/${storeId}/fulfillment/mail-orders?warehouseId=${warehouse_id}`
              }
              if (type === NOTIFICATION_ORDER_TYPES.LOCAL_DELIVERY) {
                path = `/s/${storeId}/fulfillment/pickup-delivery?warehouseId=${warehouse_id}`
                if (methodId === NOTIFICATION_SHIPPING_METHODS.ONFLEET) {
                  path = `/s/${storeId}/fulfillment/onfleet-deliveries?warehouseId=${warehouse_id}`
                }
              }
              if (type === NOTIFICATION_ORDER_TYPES.PICKUP) {
                path = `/s/${storeId}/fulfillment/pickup-delivery?&warehouseId=${warehouse_id}`
              }

              let orderTypeMsg = ''
              switch (type) {
                case NOTIFICATION_ORDER_TYPES.MAIL_ORDER:
                  orderTypeMsg = 'Mail'
                  break
                case NOTIFICATION_ORDER_TYPES.LOCAL_DELIVERY:
                  orderTypeMsg = 'Local Delivery'
                  break
                case NOTIFICATION_ORDER_TYPES.PICKUP:
                  orderTypeMsg = 'Pickup'
                  break
                default:
                  orderTypeMsg = 'Mail'
                  break
              }

              const duration = get(
                get(settings, `${[storeId]}.settings`, []).find(
                  (s) => s.type === type,
                ),
                'notification_duration',
                1,
              )
              enqueueSnackbar('', {
                variant: 'repeatOrder',
                autoHideDuration: duration * 60 * 1000,
                data: {
                  type,
                  data: {
                    message: `${total_orders} ${orderTypeMsg} ${total_orders === 1 ? 'Order' : 'Orders'} Pending`,
                    title: storeName,
                    desc,
                    path,
                    orderId: Date.now(),
                    storeId,
                  },
                  shownTime: moment().format('hh:mm A'),
                },
              })
              playRepeatSound()
            })
          }
        })
      } catch {
        // @TODO: do anything?
      }
    },
    [allowedWarehouses],
  )

  const parseRepeatArray = (settingsObj) => {
    let arr = []

    Object.keys(settingsObj).forEach((id) => {
      const tempSettings = get(settingsObj, `${id}.settings`, [])
      const mailOrderRepeat = get(
        tempSettings.find(
          (item) =>
            item.enabled && item.type === NOTIFICATION_ORDER_TYPES.MAIL_ORDER,
        ),
        'repeat_order',
        0,
      )
      const localDeliveryRepeat = get(
        tempSettings.find(
          (item) =>
            item.enabled &&
            item.type === NOTIFICATION_ORDER_TYPES.LOCAL_DELIVERY,
        ),
        'repeat_order',
        0,
      )
      const pickupRepeat = get(
        tempSettings.find(
          (item) =>
            item.enabled && item.type === NOTIFICATION_ORDER_TYPES.PICKUP,
        ),
        'repeat_order',
        0,
      )

      if (mailOrderRepeat > 0) {
        arr = [
          ...arr,
          {
            storeId: id,
            intervalTime: mailOrderRepeat,
            type: NOTIFICATION_ORDER_TYPES.MAIL_ORDER,
          },
        ]
      }
      if (localDeliveryRepeat > 0) {
        arr = [
          ...arr,
          {
            storeId: id,
            intervalTime: localDeliveryRepeat,
            type: NOTIFICATION_ORDER_TYPES.LOCAL_DELIVERY,
          },
        ]
      }
      if (pickupRepeat > 0) {
        arr = [
          ...arr,
          {
            storeId: id,
            intervalTime: pickupRepeat,
            type: NOTIFICATION_ORDER_TYPES.PICKUP,
          },
        ]
      }
    })
    return arr
  }

  useEffect(() => {
    const repeatArray = parseRepeatArray(notificationSettings)

    if (!isEmpty(repeatArray)) {
      repeatArray.forEach(({ storeId, intervalTime, type }) => {
        let i = 1
        const notificationTimeFlag = getLocalStorage(
          AUTH_STORAGE_KEYS.NOTIFICATION_TIME_FLAG,
        )
        const offset = Date.now() - (notificationTimeFlag?.timeFlag || 0)
        const intervalTimeMilliseconds = intervalTime * 60000
        const delay =
          intervalTimeMilliseconds - (offset % intervalTimeMilliseconds)
        const timeout = i === 1 ? delay : intervalTimeMilliseconds

        const intervalId = setInterval(() => {
          const [startDate, endDate] = convertToUTC0()
          countPendingOrders(
            notificationSettings,
            {
              start_date: startDate,
              end_date: endDate,
              store_id_list: [storeId],
            },
            type,
          )
          if (i > 1) {
            clearInterval(trackedTimeoutRef.current[storeId][type])
            trackedTimeoutRef.current[storeId][type] = setInterval(() => {
              const [start, end] = convertToUTC0()
              countPendingOrders(
                notificationSettings,
                {
                  start_date: start,
                  end_date: end,
                  store_id_list: [storeId],
                },
                type,
              )
            }, intervalTimeMilliseconds)
          }
          i += 1
        }, timeout)

        if (trackedTimeoutRef.current[storeId]) {
          if (trackedTimeoutRef.current[storeId][type]) {
            clearInterval(trackedTimeoutRef.current[storeId][type])
          }
          trackedTimeoutRef.current[storeId][type] = intervalId
        } else {
          trackedTimeoutRef.current[storeId] = {
            [type]: intervalId,
          }
        }
      })
    }

    return () => {
      if (trackedTimeoutRef.current) {
        Object.keys(trackedTimeoutRef.current).forEach((key) => {
          Object.values(trackedTimeoutRef.current[key])?.forEach((intervalId) =>
            clearInterval(intervalId),
          )
        })
        trackedTimeoutRef.current = {}
      }
    }
  }, [notificationSettings])

  return null
}

export default NewOrderNotification
