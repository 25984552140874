import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import settingsActions from '../actions/settings'
import { getNotificationSettings, getTaxClasses } from '../selectors/settings'

export const useSettings = () => {
  const notificationSettings = useSelector(getNotificationSettings)
  const taxClasses = useSelector(getTaxClasses)

  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(settingsActions, dispatch),
    [dispatch],
  )

  return {
    actions,
    notificationSettings,
    taxClasses,
  }
}
