import get from 'lodash/get'
import moment from 'moment'
import {
  PICKING_ERROR_MSG,
  PICKING_INTERNAL_ERROR,
  NOTIFY_DRIVER_REQUIRED_FIELDS,
  PICKING_STATUS,
  TIME_OF_COMPLETION_TYPE,
  PICKING_CANTEC_PICKED_STATUS,
  LAST_ACTIVITY,
  ORDER_COURIER_VALUES,
  SHIPPING_METHOD_VALUES,
  CANFLEET_TRACKING_STATUS,
} from '~/constants/fulfillment'
import { STORE_TYPES } from '~/constants/store'
import { convertGmtToLocalTime } from '~/utils/datetime'
import { isNullOrUndefined, htmlDecode } from '~/utils/utility'

export const isReship = (task) =>
  Boolean(
    task &&
      (task.picking_status === PICKING_STATUS.FAILED_TO_SHIP ||
        task?.canfleet_task?.status === CANFLEET_TRACKING_STATUS.RETURNED),
  )
export const isCurrentlyPicking = (task) =>
  Boolean(task && task.picking_status === PICKING_STATUS.PICKING)
export const isDonePicking = (task) =>
  Boolean(task && task.picking_status === PICKING_STATUS.DONE_PICKING)
export const isPrinted = (task) => Boolean(task && task.printed)

export const mapNotifyDriver = (tasks) => {
  if (!Array.isArray(tasks)) return []

  return tasks.map((task) => ({
    ...task,
    expected_delivery_date: task.expected_delivery_date
      ? convertGmtToLocalTime(
          task.expected_delivery_date,
          'YYYY-MM-DDTHH:mm:ssZ',
        )
      : undefined,
    ordered_date: task.ordered_date
      ? convertGmtToLocalTime(task.ordered_date, 'YYYY-MM-DDTHH:mm:ssZ')
      : undefined,
  }))
}

export const handleNotifyDriverError = (e) => {
  const {
    response: { status, data },
  } = e

  if (
    status === 404 &&
    data.error_code === PICKING_INTERNAL_ERROR.ALL_DRIVERS_BUSY
  ) {
    const error = {
      status,
      msg: '',
      internalErrorCode: PICKING_INTERNAL_ERROR.ALL_DRIVERS_BUSY,
    }

    throw error
  }

  if (
    status === 409 &&
    data.error_code === PICKING_INTERNAL_ERROR.DUPLICATE_ASSIGN_TASK
  ) {
    const error = {
      status,
      msg: PICKING_ERROR_MSG.ALREADY_ASSIGNED,
      internalErrorCode: PICKING_INTERNAL_ERROR.DUPLICATE_ASSIGN_TASK,
    }

    throw error
  }

  if (
    status === 500 &&
    data.error_code === PICKING_INTERNAL_ERROR.INVALID_SHIPPING_ADDRESS
  ) {
    throw PICKING_ERROR_MSG.INVALID_SHIPPING_ADDRESS
  }

  if (
    status === 400 &&
    data.error_code === PICKING_INTERNAL_ERROR.DRIVER_MAX_CAPACITY
  ) {
    const error = {
      status,
      msg: '',
      internalErrorCode: PICKING_INTERNAL_ERROR.DRIVER_MAX_CAPACITY,
    }

    throw error
  }
  if (data.error_code) {
    const error = {
      status,
      msg: data.message,
      internalErrorCode: PICKING_INTERNAL_ERROR.ERROR_MESSAGE_FROM_BACKEND,
    }

    throw error
  }
}

export const checkMissingRequireFields = (cleanTasks) => {
  const missingAddressTasks = cleanTasks.find(
    (task) => !task.shipping_info.address_1,
  )
  if (missingAddressTasks) {
    // eslint-disable-next-line
    throw 'Missing Customer Address'
  }
  const missingRequiredFieldsTasks = cleanTasks.find((task) =>
    NOTIFY_DRIVER_REQUIRED_FIELDS.find((requiredField) =>
      isNullOrUndefined(get(task, requiredField)),
    ),
  )
  if (missingRequiredFieldsTasks) {
    // eslint-disable-next-line
    throw 'Missing some required fields'
  }
}

export const sortOrders = (arr) =>
  arr.sort((o1, o2) => {
    const { scheduled_completion: scheduledOrder1 } = o1
    const { scheduled_completion: scheduledOrder2 } = o2

    if (
      scheduledOrder1.type === TIME_OF_COMPLETION_TYPE.END_OF_DAY &&
      scheduledOrder2.type === TIME_OF_COMPLETION_TYPE.SPECIFIC_TIME
    ) {
      return 1
    }

    if (
      scheduledOrder1.type === TIME_OF_COMPLETION_TYPE.SPECIFIC_TIME &&
      scheduledOrder2.type === TIME_OF_COMPLETION_TYPE.SPECIFIC_TIME &&
      new Date(scheduledOrder1.data) - new Date(scheduledOrder2.data)
    ) {
      return 1
    }

    if (
      scheduledOrder1.type === scheduledOrder2.type &&
      scheduledOrder1.type === TIME_OF_COMPLETION_TYPE.SPECIFIC_TIME &&
      scheduledOrder1.data === scheduledOrder2.data &&
      o1.order_id > o2.order_id
    ) {
      return 1
    }

    if (
      scheduledOrder1.type === scheduledOrder2.type &&
      scheduledOrder1.type === TIME_OF_COMPLETION_TYPE.END_OF_DAY &&
      convertGmtToLocalTime(scheduledOrder1.data, 'MM/DD/YYYY') ===
        convertGmtToLocalTime(scheduledOrder2.data, 'MM/DD/YYYY') &&
      o1.order_id > o2.order_id
    ) {
      return 1
    }

    return -1
  })

export const mapPendingPickingCantecOrders = (orders) =>
  orders.map((order) => {
    const mappedOrder = { ...order }
    if (order.shiping_method_id === SHIPPING_METHOD_VALUES.CANTEC_DELIVERY) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CANTEC_DELIVERY
    } else if (
      order.shiping_method_id === SHIPPING_METHOD_VALUES.CURBSIDE_PICKUP
    ) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CURBSIDE_PICKUP
    } else if (order.shiping_method_id === SHIPPING_METHOD_VALUES.DRIVE_THRU) {
      mappedOrder.courier = ORDER_COURIER_VALUES.DRIVE_THRU
    } else if (
      order.shiping_method_id ===
      SHIPPING_METHOD_VALUES.BS_CANFLEET_LOCAL_SHIPPING
    ) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CANFLEET_DELIVERY
    } else {
      mappedOrder.courier = ORDER_COURIER_VALUES.CUSTOMER_PICKUP
    }
    mappedOrder.order_id = mappedOrder.id
    const fixedScheduleCompletion = order.schedule_completion
      ? order.schedule_completion
      : order.date_created_gmt
    mappedOrder.scheduled_completion =
      order.is_end_of_day || order.no_time_selection
        ? {
            data: fixedScheduleCompletion,
            type: TIME_OF_COMPLETION_TYPE.END_OF_DAY,
          }
        : {
            data: fixedScheduleCompletion,
            type: TIME_OF_COMPLETION_TYPE.SPECIFIC_TIME,
          }
    mappedOrder.section_date = fixedScheduleCompletion
    return mappedOrder
  })

export const mapPendingPickupCantecOrders = (orders) =>
  orders.map((order) => {
    const mappedOrder = { ...order }
    if (order.shiping_method_id === SHIPPING_METHOD_VALUES.CANTEC_DELIVERY) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CANTEC_DELIVERY
      mappedOrder.driver = mappedOrder.cantec_data?.driver
    } else if (
      order.shiping_method_id === SHIPPING_METHOD_VALUES.CURBSIDE_PICKUP
    ) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CURBSIDE_PICKUP
    } else if (order.shiping_method_id === SHIPPING_METHOD_VALUES.DRIVE_THRU) {
      mappedOrder.courier = ORDER_COURIER_VALUES.DRIVE_THRU
    } else if (
      order.shiping_method_id ===
      SHIPPING_METHOD_VALUES.BS_CANFLEET_LOCAL_SHIPPING
    ) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CANFLEET_DELIVERY
    } else {
      mappedOrder.courier = ORDER_COURIER_VALUES.CUSTOMER_PICKUP
    }
    mappedOrder.order_id = mappedOrder.id
    const fixedScheduleCompletion = order.schedule_completion
      ? order.schedule_completion
      : order.date_created_gmt
    mappedOrder.scheduled_completion =
      order.is_end_of_day || order.no_time_selection
        ? {
            data: fixedScheduleCompletion,
            type: TIME_OF_COMPLETION_TYPE.END_OF_DAY,
          }
        : {
            data: fixedScheduleCompletion,
            type: TIME_OF_COMPLETION_TYPE.SPECIFIC_TIME,
          }
    mappedOrder.section_date = fixedScheduleCompletion
    return mappedOrder
  })

export const mapPickedUpCantecOrders = (orders) =>
  orders.map((order) => {
    const mappedOrder = { ...order }
    mappedOrder.order_id = mappedOrder.id
    if (order.shiping_method_id === SHIPPING_METHOD_VALUES.CANTEC_DELIVERY) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CANTEC_DELIVERY
      if (mappedOrder.cantec_data) {
        if (
          mappedOrder.cantec_data.last_activity ===
          LAST_ACTIVITY.IN_TRANSIT_FOR_RETURN
        ) {
          mappedOrder.status = 'in_transit'
        } else {
          switch (mappedOrder.cantec_data.order_status) {
            case PICKING_CANTEC_PICKED_STATUS.PICKED_UP:
              mappedOrder.status = 'in_transit'
              break
            case PICKING_CANTEC_PICKED_STATUS.COMPLETED:
              mappedOrder.status = 'completed'
              break
            default:
              mappedOrder.status = ''
          }
        }
        mappedOrder.driver = mappedOrder.cantec_data.driver
      }
    } else if (
      order.shiping_method_id === SHIPPING_METHOD_VALUES.CURBSIDE_PICKUP
    ) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CURBSIDE_PICKUP
    } else if (order.shiping_method_id === SHIPPING_METHOD_VALUES.DRIVE_THRU) {
      mappedOrder.courier = ORDER_COURIER_VALUES.DRIVE_THRU
    } else if (
      order.shiping_method_id ===
      SHIPPING_METHOD_VALUES.BS_CANFLEET_LOCAL_SHIPPING
    ) {
      mappedOrder.courier = ORDER_COURIER_VALUES.CANFLEET_DELIVERY
      if (mappedOrder.canfleet_task) {
        switch (mappedOrder.canfleet_task.status) {
          case CANFLEET_TRACKING_STATUS.RETURNING:
          case CANFLEET_TRACKING_STATUS.DELIVERING:
            mappedOrder.status = 'in_transit'
            break
          case CANFLEET_TRACKING_STATUS.COMPLETED:
            mappedOrder.status = 'completed'
            break
          default:
            mappedOrder.status =
              mappedOrder.status === 'completed' ? 'completed' : ''
            break
        }
      }
    } else {
      mappedOrder.courier = ORDER_COURIER_VALUES.CUSTOMER_PICKUP
    }
    const fixedScheduleCompletion = order.schedule_completion
      ? order.schedule_completion
      : order.date_created_gmt
    mappedOrder.scheduled_completion =
      order.is_end_of_day || order.no_time_selection
        ? {
            data: fixedScheduleCompletion,
            type: TIME_OF_COMPLETION_TYPE.END_OF_DAY,
          }
        : {
            data: fixedScheduleCompletion,
            type: TIME_OF_COMPLETION_TYPE.SPECIFIC_TIME,
          }
    mappedOrder.section_date = fixedScheduleCompletion
    return mappedOrder
  })

export const mapPickingRegularOrders = (orders) =>
  orders.map((order) => {
    const mappedOrder = { ...order }
    mappedOrder.section_date = order.date_created_gmt
    return mappedOrder
  })

export const mapPendingDeliveringRegularOrders = (orders) =>
  orders.map((order) => {
    const mappedOrder = { ...order }
    mappedOrder.section_date = order.date_created_gmt
    return mappedOrder
  })

export const mapTrackingRegularOrders = (orders) =>
  orders.map((order) => {
    const mappedOrder = { ...order }
    mappedOrder.section_date = order.date_created_gmt
    return mappedOrder
  })

export const getTz = () => moment().format('Z')

export const getReceiptNumber = (item, storeType) => {
  switch (storeType) {
    case STORE_TYPES.COVA:
      return item.cova_meta_data?.ReceiptNumber || ''
    case STORE_TYPES.GREENLINE:
      return item.greenline_mapping?.customId || ''
    default:
      return ''
  }
}

export const renderVariationName = (pName, metaData) => {
  const variation = metaData?.find((m) => m.key === 'variations')?.display_value
  return htmlDecode(`${pName}${variation ? ` - ${variation}` : ''}`)
}
