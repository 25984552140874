export const getPendingPickingList = (state) =>
  state.cantecFulfillment.pendingPickingList
export const getPendingPickingListLoading = (state) =>
  state.cantecFulfillment.loadingPendingPickingList
export const getPendingPickingListError = (state) =>
  state.cantecFulfillment.pendingPickingListError

export const getPendingPickupList = (state) =>
  state.cantecFulfillment.pendingPickupList
export const getPendingPickupListLoading = (state) =>
  state.cantecFulfillment.loadingPendingPickupList
export const getPendingPickupListError = (state) =>
  state.cantecFulfillment.pendingPickupListError

export const getPickedUpList = (state) => state.cantecFulfillment.pickedUpList
export const getPickedUpListLoading = (state) =>
  state.cantecFulfillment.loadingPickedUpList
export const getPickedUpListError = (state) =>
  state.cantecFulfillment.pickedUpListError

export const getLinkWarehouseList = (state) =>
  state.cantecFulfillment.linkWarehouses
export const getLinkWarehouseLoading = (state) =>
  state.cantecFulfillment.loadingLinkWarehouses
export const getLinkWarehouseError = (state) =>
  state.cantecFulfillment.linkWarehouseError

export const getOnlineDriverList = (state) =>
  state.cantecFulfillment.onlineDrivers
export const getOnlineDriverLoading = (state) =>
  state.cantecFulfillment.loadingOnlineDrivers
export const getOnlineDriverError = (state) =>
  state.cantecFulfillment.onlineDriverError

export const getCantecStatistic = (state) => state.cantecFulfillment.statistic

export const getIncompletedOrders = (state) =>
  state.cantecFulfillment.incompletedOrders
