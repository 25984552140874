import { useMemo } from 'react'
import {
  getSessionWithKey,
  storeSessionWithKey,
} from '../sessionStorage/sessionStorageManager'

const USER_SESSION_SETTINGS = 'USER_SESSION_SETTINGS'

const useUserSession = () => {
  const userSessionJson = getSessionWithKey(USER_SESSION_SETTINGS)

  const userSession = useMemo(
    () => (userSessionJson ? JSON.parse(userSessionJson) : {}),
    [userSessionJson],
  )

  const updateUserSession = (newSetting) => {
    storeSessionWithKey(USER_SESSION_SETTINGS, JSON.stringify(newSetting))
  }

  return [userSession, updateUserSession]
}

export { useUserSession }
