import produce from 'immer'
import { LOCATION_ACTION } from '../actions/location'

const initialState = {
  data: [],
  loading: false,
  error: '',
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case LOCATION_ACTION.FETCH_LIST:
        draft.loading = true
        draft.error = ''
        break
      case LOCATION_ACTION.SET_LIST_DATA:
        draft.data = payload
        draft.loading = false
        draft.error = ''
        break
      case LOCATION_ACTION.SET_LIST_ERROR:
        draft.loading = false
        draft.error = payload
        break
      default:
        break
    }
  })

export default reducer
