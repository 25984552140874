import httpClient from '~/modules/core/httpClient'
import * as Common from './common'
import { shortProductData } from '~/utils/product'
import { diffObj, isNullOrUndefined } from '~/utils/utility'
import { PRODUCT_TYPE } from '~/constants/product'
import { makeDefaultProduct } from '~/redux/default/product'
import { mapLocationIds } from '~/pages/Promotion/Shared/hooks/mapper'

const url = {
  PRODUCT: '/stores/woocommerce/products/',
  POS_PRODUCT: '/cova/products/',
}

const ACTIONS = {
  ...Common.ACTIONS,
  READ_PRODUCT_VARIATION: 'read_product_variations',
  UPDATE_VARIATION: 'batch_update_product_variations',
  RESTORE_PRODUCT: 'restore_with_sku',
  SEARCH_INVENTORY_TO_LINK: 'search_inventory_to_link',
  SEARCH_SUITABLE_COMPOSITE_PRODUCT: 'search_suitable_composite_components',
  SEARCH_SUITABLE_COMPOSITE_PRODUCT_V2:
    'search_suitable_composite_components_v2',
  READ_INVENTORY_QUANTITY: 'read_inventory_in_linked_warehouses',
  CHECK_SKU_TO_LINK: 'check_sku_to_link',
}

const searchInventory = (keyword, limit = 25) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.SEARCH_INVENTORY_TO_LINK,
    parameters: {
      search_parameter: keyword,
      limit,
    },
  })

const makeProductParams = (data) => ({
  item_type: 'product',
  item_data: data,
})

const getSuitableCompositeProduct = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.SEARCH_SUITABLE_COMPOSITE_PRODUCT,
    parameters: params,
  })

const searchSuitableCompositeProduct = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.SEARCH_SUITABLE_COMPOSITE_PRODUCT_V2,
    parameters: params,
  })

const getList = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })

const getShopifyProductList = (storeId, params) =>
  httpClient.get(`shopify/stores/${storeId}/products`, {
    params,
  })

const getCovaProductList = (storeId, params) =>
  httpClient.get(`/cova/stores/${storeId}/products`, {
    params,
  })

const getGreenlineProductList = (storeId, params) =>
  httpClient.get(`/greenline/stores/${storeId}/products`, {
    params,
  })

const getCovaProductVariations = (storeId, params) => {
  const { locationIds, search } = params
  return httpClient.json.get(
    `/cova/stores/${storeId}/products/variations${mapLocationIds(locationIds)}&search=${search}`,
  )
}

const getGreenlineProductVariations = (storeId, params) => {
  const { locationIds, search } = params
  return httpClient.json.get(
    `/stores/${storeId}/products/search${mapLocationIds(locationIds)}&types=variable&types=variation&search=${search}`,
  )
}

const getCovaList = (params) =>
  httpClient.post(url.POS_PRODUCT, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })

const exportList = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.EXPORT_V2,
    parameters: params,
  })

const findOne = (id?, orgId?, storeId?) =>
  httpClient.post(url.PRODUCT, (params) => ({
    ...params,
    organization_id: orgId || params.organization_id,
    store_id: storeId || params.store_id,
    action: ACTIONS.READ,
    parameters: { id: Number(id) },
  }))

const findMulti = (ids?, fields?) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.READ_MULTI,
    parameters: { ids, fields },
  })

const mappingIds = (ids) => (ids || []).map((id) => `ids=${id}`).join('&')

const findMultiShopify = (storeId, ids) =>
  httpClient.get(`shopify/stores/${storeId}/products?${mappingIds(ids)}`)

const findMultiPOS = (ids) =>
  httpClient.post(url.POS_PRODUCT, {
    action: ACTIONS.READ_MULTI,
    parameters: { ids },
  })

const getVariations = (id, isTrash = false, orgId, storeId) =>
  httpClient.post(url.PRODUCT, (params) => ({
    ...params,
    organization_id: orgId || params.organization_id,
    store_id: storeId || params.store_id,
    action: ACTIONS.READ_PRODUCT_VARIATION,
    parameters: { id, is_trash: isTrash },
  }))

const restoreProduct = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.RESTORE_PRODUCT,
    parameters: params,
  })

const updateOne = (productParams) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.UPDATE,
    parameters: makeProductParams(productParams),
  })

const removeOne = (id) => {
  const productParams = {
    item_type: 'product',
    id,
  }
  return httpClient.post(url.PRODUCT, {
    action: ACTIONS.DELETE,
    parameters: productParams,
  })
}

const mapVariationAttribute = (data) =>
  data.map((x) => ({
    ...x,
    ...(x.attributes
      ? {
          attributes: x.attributes
            .filter((y) => y.option !== 'Any')
            .map(({ id, option, name }) => {
              if (id === 0) return { id, option, name }
              return { id, option }
            }),
        }
      : {}),
  }))

const mapListVariationParams = (
  productId,
  variationToCreate,
  variationToDelete,
  variationToUpdate,
) => ({
  product_id: productId,
  data: {
    create: mapVariationAttribute(variationToCreate),
    delete: variationToDelete,
    update: mapVariationAttribute(variationToUpdate),
  },
})

const updateListVariation = async (
  productId,
  createVariation = [],
  deleteVariation = [],
  updateVariation = [],
) => {
  const params = mapListVariationParams(
    productId,
    createVariation,
    deleteVariation,
    updateVariation,
  )
  const variationResp = await httpClient.post(url.PRODUCT, {
    action: ACTIONS.UPDATE_VARIATION,
    parameters: params,
  })
  return variationResp
}

const batchUpdate = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.BATCH_UPDATE,
    parameters: params,
  })

const deleteMany = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.DELETE_MANY,
    parameters: params,
  })

const setStatus = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.SET_STATUS,
    parameters: params,
  })

const restoreProductWithoutSku = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.RESTORE,
    parameters: params,
  })

const createOne = (productParams) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.CREATE,
    parameters: productParams,
  })

const handleLinkedProduct = async (product) => {
  // TODO: need optimize that, we should call api once time
  let upsell_ids = []
  let cross_sell_ids = []
  if (product) {
    if (product.upsell_ids && product.upsell_ids.length > 0) {
      upsell_ids = await findMulti(product.upsell_ids)
    }
    if (product.cross_sell_ids && product.cross_sell_ids.length > 0) {
      cross_sell_ids = await findMulti(product.cross_sell_ids)
    }
  }
  return {
    ...product,
    upsell_ids,
    cross_sell_ids,
  }
}

const createSimpleProduct = async (product) =>
  createOne(makeProductParams(product))
const createVariableProduct = async ({
  variationContainers,
  oldVariationContainer,
  variations: _variations,
  ...product
}) => {
  const productParams = makeProductParams(product)
  const warnings = []
  // eslint-disable-next-line no-useless-catch
  try {
    let productResp = await createOne(productParams)
    const productId = productResp.id
    const variations = (variationContainers || [])
      .filter((x) => x.delete !== true)
      .map(
        ({
          idx,
          id,
          _id,
          _created,
          _updated,
          _link,
          options,
          deleting,
          barcode,
          ...x
        }) => ({
          ...x,
          shipping_class:
            x.shipping_class === 'same_as_parent'
              ? productResp.shipping_class
              : x.shipping_class,
          tax_class:
            x.tax_class === 'same_as_parent'
              ? productResp.tax_class
              : x.tax_class,
        }),
      )
    if (variations.length > 0) {
      const variationResp = await updateListVariation(productId, variations)
      if (variationResp) {
        // check error variations
        const errorVariations = variationResp.errors
        if (errorVariations.length > 0) {
          const message = Array.from(
            new Set(
              errorVariations
                .filter((err) => err?.item_created?.error?.message)
                .map((err) => err?.item_created?.error?.message),
            ),
          ).join(',')
          if (!variationResp.created || variationResp.created.length === 0) {
            await removeOne(productId)
            throw message
          } else {
            warnings.push({ message })
          }
        }
        // update variations
        productResp = {
          ...productResp,
          variations: variationResp.created.map((x) => (x.id ? x.id : x)),
        }
      }
    }
    return warnings.length > 0 ? { ...productResp, warnings } : productResp
  } catch (e) {
    throw e
  }
}
const createCompositeProduct = async ({
  composite_components,
  composite_scenarios,
  ...product
}) => {
  const aliveComponents = (composite_components || []).filter((x) => !x.delete)
  const productParams = makeProductParams({
    ...product,
    composite_components: aliveComponents.map(({ tempId, ...c }) => c),
  })
  let productResp = await createOne(productParams)
  const components = (productResp.composite_components || []).map((c, idx) => ({
    ...c,
    tempId: aliveComponents[idx].tempId,
  }))
  const mapLocalIds = components.reduce(
    (acc, c, idx) => ({ ...acc, [aliveComponents[idx].tempId]: c.id }),
    {},
  )
  if (composite_scenarios && composite_scenarios.length > 0) {
    const scenarios = (composite_scenarios || [])
      .filter((x) => !x.delete)
      .map(({ configuration, actions, tempId, ...s }) => ({
        ...s,
        configuration: (configuration || []).reduce((confs, c) => {
          if (!c.delete && mapLocalIds[c.component_id]) {
            confs.push({
              options_modifier: c.options_modifier,
              component_id: mapLocalIds[c.component_id],
              ...(c.component_options
                ? { component_options: c.component_options }
                : {}),
            })
          }
          return confs
        }, []),
        actions: (actions || []).map((act) => {
          switch (act?.action_id) {
            case 'conditional_components': {
              const hidden_components = act.is_active
                ? (act.hidden_components || [])
                    .filter((idx) => !!mapLocalIds[idx])
                    .map((idx) => mapLocalIds[idx])
                : []
              return {
                ...act,
                hidden_components,
                is_active: hidden_components.length > 0 && act.is_active,
              }
            }
            case 'conditional_options':
              return {
                ...act,
                hidden_options: (act?.hidden_options || []).reduce(
                  (confs, c) => {
                    if (!c.delete && mapLocalIds[c.component_id]) {
                      confs.push({
                        ...c,
                        component_id: mapLocalIds[c.component_id],
                      })
                    }
                    return confs
                  },
                  [],
                ),
              }
            default:
              return act
          }
        }),
      }))
    // update variations
    productResp = await updateOne({
      id: productResp.id,
      composite_scenarios: scenarios,
    })
  }
  return productResp
}
const create = async (product) => {
  const shortData = shortProductData(product, makeDefaultProduct(product.type))
  let afterSaved = {}
  switch (product.type) {
    case PRODUCT_TYPE.VARIABLE:
      afterSaved = await createVariableProduct({
        ...makeDefaultProduct(PRODUCT_TYPE.VARIABLE),
        ...shortData,
      })
      break
    case PRODUCT_TYPE.COMPOSITE:
      afterSaved = await createCompositeProduct(
        {
          ...makeDefaultProduct(PRODUCT_TYPE.COMPOSITE),
          ...shortData,
        },
        // product,
      )
      break
    case PRODUCT_TYPE.SIMPLE:
    default:
      afterSaved = await createSimpleProduct({
        ...makeDefaultProduct(PRODUCT_TYPE.SIMPLE),
        ...shortData,
      })
      break
  }
  afterSaved = await handleLinkedProduct(afterSaved)
  return afterSaved
}

const updateSimpleProduct = async (product) => {
  const newProduct = { ...product }
  if (newProduct.type === PRODUCT_TYPE.SIMPLE) {
    newProduct.attributes = []
  }
  return updateOne(newProduct)
}

const hasSaveBefore = (shortProduct, origin) => {
  switch (true) {
    case shortProduct.type !== origin.type:
      return true
    case shortProduct.type === PRODUCT_TYPE.VARIABLE &&
      Object.keys(shortProduct).includes('attributes'):
      return true
    default:
      return false
  }
}

const updateVariableProduct = async (
  {
    variationContainers,
    oldVariationContainer,
    variations: _variations,
    ...shortProduct
  },
  origin,
  product,
) => {
  const warnings = []
  // eslint-disable-next-line no-useless-catch
  try {
    let productResp = {}
    let isUpdatedBasicFields = false
    let isUpdatedVariations = false
    if (hasSaveBefore(shortProduct, origin)) {
      productResp = await updateOne(shortProduct)
      isUpdatedBasicFields = true
    }
    let newsVariations = []
    if (
      JSON.stringify(variationContainers) !==
      JSON.stringify(oldVariationContainer)
    ) {
      const lastProductImageId =
        (origin &&
          origin.images &&
          origin.images.length > 0 &&
          origin.images[0]?.id) ||
        0
      const handledVariations = (variationContainers || []).map((x) => ({
        ...x,
        image:
          x.image && lastProductImageId > 0 && x.image.id === lastProductImageId
            ? null
            : x.image,
        shipping_class:
          x.shipping_class === 'same_as_parent'
            ? product.shipping_class
            : x.shipping_class,
        tax_class:
          x.tax_class === 'same_as_parent' ? product.tax_class : x.tax_class,
      }))
      const mapOldVariation = (oldVariationContainer || []).reduce((acc, v) => {
        // eslint-disable-next-line no-param-reassign
        acc[v.id] = v
        return acc
      }, {})
      const deletedOldVarions = (oldVariationContainer || []).filter(
        (v) => !(variationContainers || []).find((n) => n.id === v.id),
      )
      const deletedVariations = handledVariations
        .filter((x) => x.id && x.id > 0 && x.deleting)
        .concat(deletedOldVarions)
        .map((x) => x.id)
      const createdVariations = handledVariations.filter(
        (x) => (!x.id || x.id <= 0) && x.deleting !== true,
      )
      const updatedVariations = handledVariations
        .filter((x) => x.id && x.id > 0 && x.deleting !== true)
        .map((x) =>
          diffObj(x, mapOldVariation[x.id] || {}, (_, v) => v, ['id', 'sku']),
        )
      const variationsResp = await updateListVariation(
        product.id,
        createdVariations,
        deletedVariations,
        updatedVariations,
      )
      isUpdatedVariations = true
      if (variationsResp) {
        if (variationsResp?.created)
          newsVariations = newsVariations.concat(variationsResp.created)
        if (variationsResp?.updated)
          newsVariations = newsVariations.concat(variationsResp.updated)

        // check error variations
        const errorVariations = variationsResp.errors
        if (errorVariations.length > 0) {
          const message = Array.from(
            new Set(
              errorVariations
                .filter(
                  (err) =>
                    (err?.item_created || err?.item_updated)?.error?.message,
                )
                .map(
                  (err) =>
                    (err?.item_created || err?.item_updated)?.error?.message,
                ),
            ),
          ).join(',')
          warnings.push({ message })
        }
      }
    }
    if (
      isUpdatedVariations ||
      (Object.keys(shortProduct).length > 2 && !isUpdatedBasicFields)
    ) {
      if (Object.keys(shortProduct).length > 2 && !isUpdatedBasicFields) {
        productResp = await updateOne(shortProduct)
      }
      if (Object.keys(productResp)?.length === 0) {
        productResp = await findOne(product.id)
      }
      productResp = {
        ...productResp,
        ...(isUpdatedVariations
          ? { variations: newsVariations.map((x) => x.id || x) }
          : {}),
      }
    }
    return { ...origin, ...productResp, warnings }
  } catch (e) {
    throw e
  }
}

const updateCompositeProduct = async (
  { composite_components, composite_scenarios, ...product },
  origin,
) => {
  const savedComponents = (composite_components || []).filter(
    (x) => !((x.tempId || '').startsWith('component_') && x.delete),
  )
  const aliveComponents = savedComponents.filter((x) => !x.delete)
  let productResp = await updateOne({
    ...product,
    ...(JSON.stringify(composite_components) !==
    JSON.stringify(origin.composite_components)
      ? {
          composite_components: savedComponents.map(({ tempId, ...c }) =>
            c.delete ? { id: c.id, delete: c.delete } : c,
          ),
        }
      : {}),
  })
  const components = (productResp.composite_components || []).map((c, idx) => ({
    ...c,
    tempId: aliveComponents[idx].tempId,
  }))
  const mapLocalIds = components.reduce(
    (acc, c, idx) => ({ ...acc, [aliveComponents[idx].tempId]: c.id }),
    {},
  )
  if (
    !isNullOrUndefined(composite_scenarios) &&
    JSON.stringify(composite_scenarios) !==
      JSON.stringify(origin.composite_scenarios)
  ) {
    const savedScenarios = (composite_scenarios || []).filter(
      (x) => !((x.tempId || '').startsWith('scenario_') && x.delete),
    )
    const scenarios = (savedScenarios || []).map(
      ({ configuration, actions, tempId, ...s }) =>
        s.delete
          ? { id: s.id, delete: s.delete }
          : {
              ...s,
              configuration: (configuration || []).reduce((confs, c) => {
                if (!c.delete && mapLocalIds[c.component_id]) {
                  confs.push({
                    options_modifier: c.options_modifier,
                    component_id: mapLocalIds[c.component_id],
                    ...(c.component_options
                      ? { component_options: c.component_options }
                      : {}),
                  })
                }
                return confs
              }, []),
              actions: (actions || []).map((act) => {
                switch (act?.action_id) {
                  case 'conditional_components': {
                    const hidden_components = act.is_active
                      ? (act.hidden_components || [])
                          .filter((idx) => !!mapLocalIds[idx])
                          .map((idx) => mapLocalIds[idx])
                      : []
                    return {
                      ...act,
                      hidden_components,
                      is_active: hidden_components.length > 0 && act.is_active,
                    }
                  }
                  case 'conditional_options':
                    return {
                      ...act,
                      hidden_options: (act?.hidden_options || []).reduce(
                        (confs, c) => {
                          if (!c.delete && mapLocalIds[c.component_id]) {
                            confs.push({
                              ...c,
                              component_id: mapLocalIds[c.component_id],
                            })
                          }
                          return confs
                        },
                        [],
                      ),
                    }
                  default:
                    return act
                }
              }),
            },
    )
    // update scenarios
    productResp = await updateOne({
      id: productResp.id,
      composite_scenarios: scenarios,
    })
  }
  return productResp
}
const update = async (product, origin) => {
  const shortData: any = shortProductData(product, origin)
  let afterSaved = {}
  switch (product.type) {
    case PRODUCT_TYPE.VARIABLE:
      afterSaved = await updateVariableProduct(shortData, origin, product)
      break
    case PRODUCT_TYPE.COMPOSITE:
      afterSaved = await updateCompositeProduct(shortData, origin)
      break
    case PRODUCT_TYPE.SIMPLE:
    default:
      afterSaved = await updateSimpleProduct(shortData)
      break
  }
  afterSaved = await handleLinkedProduct(afterSaved)
  return afterSaved
}

const getInventoryQuantityBySku = (params) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.READ_INVENTORY_QUANTITY,
    parameters: params,
  })

const checkSkuToLink = (sku) =>
  httpClient.post(url.PRODUCT, {
    action: ACTIONS.CHECK_SKU_TO_LINK,
    parameters: {
      sku,
    },
  })

const getShopifyCollection = (storeId, params) =>
  httpClient.get(`/shopify/stores/${storeId}/collections`, {
    params,
  })

const productApi = {
  searchInventory,
  getCovaList,
  findOne,
  findMulti,
  findMultiShopify,
  findMultiPOS,
  getSuitableCompositeProduct,
  searchSuitableCompositeProduct,
  getList,
  exportList,
  getVariations,
  restoreProduct,
  removeOne,
  batchUpdate,
  setStatus,
  updateOne,
  restoreProductWithoutSku,
  create,
  update,
  deleteMany,
  getInventoryQuantityBySku,
  checkSkuToLink,
  getCovaProductList,
  getCovaProductVariations,
  getGreenlineProductVariations,
  getGreenlineProductList,
  getShopifyProductList,
  getShopifyCollection,
}

export default productApi
