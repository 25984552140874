import React from 'react'

const NavContext = React.createContext()

export function usePrompt(options) {
  const {
    isBlocking,
    setBlockNavigation,
    setException,
    setConfirmParams,
    setBeforeNavigateHandler,
    showModal,
    isShowConfirm,
  } = React.useContext(NavContext)
  React.useEffect(() => {
    setBlockNavigation(options ? options.defaultActive : false)
    if (options?.exception && typeof exception === 'function') {
      setException(options.exception)
    } else {
      setException(null)
    }
    setConfirmParams(options?.dialogParams)
    return () => {
      // TODO: handle reset prompt state navigation
    }
  }, [])

  return {
    isBlocking,
    setBlockNavigation,
    setNavigateException: setException,
    setConfirmParams,
    setBeforeNavigateHandler,
    showModal,
    isShowConfirm,
  }
}

export function useChangeStoreCallback(callback, deps = []) {
  const { registerChangeStoreHandler } = React.useContext(NavContext)
  React.useEffect(() => {
    if (callback) {
      const unregister = registerChangeStoreHandler(callback)
      return () => unregister()
    }
    return () => {}
  }, deps)
  return registerChangeStoreHandler
}

export function useStoreHandler() {
  return React.useContext(NavContext).storeHandler
}

export default NavContext
