import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation, matchPath, useParams } from 'react-router-dom'
import Guard from '~/modules/auth/components/Guard'
import { useAuth } from '~/modules/auth/redux/hook'
import { useUser } from '~/redux/hooks/user'
import SystemRouter from '~/router/SystemRouters'

const RoleGuard = ({ children }) => {
  const { storeId } = useParams()
  const { authState } = useAuth()
  const { user } = useUser()
  const location = useLocation()

  const checkMatchPath = useCallback(
    (route) => {
      const { pathname } = location
      return !!matchPath({ path: route.path }, pathname)
    },
    [location],
  )

  const findRoute = useCallback(
    (routes) => {
      let foundRoute = null
      for (let i = 0; i < routes.length; i += 1) {
        const route = routes[i]
        if (route.children) {
          foundRoute = findPage(route.children)
          if (foundRoute) {
            break
          }
        }
        if (checkMatchPath(route)) {
          return route
        }
      }
      return foundRoute
    },
    [checkMatchPath],
  )

  const hasPermissionRole = () => {
    const route = findRoute(SystemRouter)
    if (route && !route.roles) {
      return true
    }
    if (
      route &&
      (route.roles === true ||
        route.roles(storeId, user.plugins)(authState.data))
    ) {
      return true
    }
    return false
  }

  return (
    <Guard
      accessWhen={hasPermissionRole}
      fallbackComp={<Navigate to={`/s/${storeId}/home`} push />}
    >
      {children}
    </Guard>
  )
}

RoleGuard.defaultProps = {}

RoleGuard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

export default RoleGuard
