import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  SETTINGS: '/users/settings/',
  USER_JOIN: '/organizations/users/join/',
  USER_PROFILE: '/users/update/',
  USER_EXPORT: '/users/export',
  USER_UPDATE_AVATAR: '/unification/users/avatar',
}

const updateSetting = (user_settings) =>
  httpClient.json.post(url.SETTINGS, { user_settings, action: ACTIONS.UPDATE })

const join = (invite_token) =>
  httpClient
    .create()
    .ejectRes('defaultResponseErrorHandler')
    .post(url.USER_JOIN, () => ({
      invite_token,
      action: ACTIONS.UPDATE,
    }))

const updateProfile = (params) =>
  httpClient.patch(url.USER_PROFILE, () => params)

const updateAvataProfile = (params) =>
  httpClient.json.patch(url.USER_PROFILE, () => params)

const updateAvatarUserInvite = (params) =>
  httpClient.json.post(url.USER_UPDATE_AVATAR, () => params)

const userApi = {
  updateSetting,
  join,
  updateProfile,
  updateAvataProfile,
  updateAvatarUserInvite,
}

export default userApi
