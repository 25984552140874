import produce from 'immer'
import { ATTRIBUTE_ACTION } from '../actions/attribute'
import {
  DEFAULT_ATTRIBUTE_LIST_DATA,
  DEFAULT_ATTRIBUTE_DETAIL,
} from '../default/attribute'

const initialState = {
  data: DEFAULT_ATTRIBUTE_LIST_DATA,
  loading: false,
  detail: DEFAULT_ATTRIBUTE_DETAIL,
  loadingDetail: false,
  error: '',
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case ATTRIBUTE_ACTION.FETCH_LIST:
        draft.loading = true
        draft.error = ''
        break
      case ATTRIBUTE_ACTION.SET_LIST_DATA:
        draft.data = payload
        draft.loading = false
        draft.error = ''
        break
      case ATTRIBUTE_ACTION.SET_LIST_ERROR:
        draft.loading = false
        draft.error = payload
        break
      case ATTRIBUTE_ACTION.FETCH_DETAIL:
        draft.detail = DEFAULT_ATTRIBUTE_DETAIL
        draft.loadingDetail = true
        draft.error = ''
        break
      case ATTRIBUTE_ACTION.SET_DETAIL_DATA_ERROR:
        draft.loadingDetail = false
        draft.error = payload
        break
      case ATTRIBUTE_ACTION.SET_DETAIL_DATA:
        draft.loadingDetail = false
        draft.error = ''
        draft.detail = payload
        break
      default:
        break
    }
  })

export default reducer
