import { takeLatest, all, fork, call, put } from 'redux-saga/effects'
import Actions, { CREDENTIAL_ACTION } from '~/redux/actions/credential'
import organizationApi from '~/services/apis/organization'
import storeApi from '~/services/apis/store'
import { showDialog } from '~/components/common/Dialog'
import { dialogParams } from '~/components/common/Dialog/CustomDialog'

export function* fetchCredentialList(action) {
  try {
    const resp = yield call(organizationApi.readSSCredentials, action.payload)
    yield put(Actions.setCredentialListData(resp))
  } catch (e) {
    yield put(Actions.setCredentialListError(e))
  }
}

export function* updateOrgCredential(action) {
  try {
    const resp = yield call(organizationApi.batchSSCredentials, action.payload)
    yield put(Actions.fetchCredentialList())
    yield put(Actions.updateOrgCredentialsSuccess(resp))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.updateOrgCredentialsError(e))
    action.callbacks.onError(e)
    showDialog(dialogParams.error(e))
  }
}

export function* updateStoreCredential(action) {
  try {
    yield call(storeApi.batchSSCredentials, action.payload)
    const resp = yield call(storeApi.readSSCredentials, action.payload.store_id)
    yield put(
      Actions.updateStoreCredentialsSuccess(action.payload.store_id, resp),
    )
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.updateStoreCredentialsError(e))
    yield action.callbacks.onError(e)
    showDialog(dialogParams.error(e))
  }
}

export function* watchCredential() {
  yield all([
    takeLatest(CREDENTIAL_ACTION.FETCH_LIST, fetchCredentialList),
    takeLatest(CREDENTIAL_ACTION.UPDATE_ORG_CREDENTIALS, updateOrgCredential),
    takeLatest(
      CREDENTIAL_ACTION.UPDATE_STORE_CREDENTIALS,
      updateStoreCredential,
    ),
  ])
}

export default function* credential() {
  yield fork(watchCredential)
}
