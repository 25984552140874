import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, PERFORMANCE_ROUTES } from '~/constants/router'
import {
  productRolesEditable,
  productRolesView,
} from '~/modules/auth/conditions'

const PerformanceList = lazyRetry(
  () => import('~/pages/Performance/PerformanceList'),
)
const ShopifyProductList = lazyRetry(
  () => import('~/pages/Products/ShopifyProductList'),
)

const PerformanceRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${PERFORMANCE_ROUTES.PERFORMANCE_PRODUCTS}`,
    component: PerformanceList,
    covaComponent: PerformanceList,
    greenlineComponent: PerformanceList,
    shopifyComponent: ShopifyProductList,
    layout: 'admin',
    exact: true,
    roles: productRolesView,
    editRoles: productRolesEditable,
    routeName: 'Performance Products',
  },
]

export default PerformanceRouters
