import uniq from 'lodash/uniq'
import httpClient from '~/modules/core/httpClient'
import * as Common from './common'
import { mapCouponToCreate } from '~/utils/coupon'
import productApi from './product'
import categoryApi from './category'
import { PRODUCT_TYPE } from '~/constants/product'

const url = {
  COUPON: '/stores/woocommerce/coupons/',
}

const ACTIONS = {
  ...Common.ACTIONS,
  READ_COUPON_VARIATION: 'read_coupon_variations',
  UPDATE_VARIATION: 'batch_update_coupon_variations',
}

const getList = (params) =>
  httpClient.post(url.COUPON, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })

const findOne = async (id) => {
  let resp: any = {}
  // eslint-disable-next-line no-useless-catch
  try {
    resp = await httpClient.post(url.COUPON, {
      action: ACTIONS.READ,
      parameters: { id: Number(id) },
    })
  } catch (e) {
    throw e
  }

  try {
    let products = []
    let excluded_products = []

    let product_categories = []
    let excluded_product_categories = []

    const product_ids = (resp.product_ids || []).concat(
      resp.excluded_product_ids || [],
    )
    const res_products = product_ids.length
      ? await productApi.findMulti(product_ids)
      : []
    const variation_parent_ids = uniq(
      (res_products || [])
        .filter((p) => p.type === PRODUCT_TYPE.VARIATION && p.parent_id)
        .map((p) => p.parent_id),
    )
    let map_parents = {}
    if (variation_parent_ids?.length) {
      const parent_product = await productApi.findMulti(variation_parent_ids, [
        'id',
        'name',
        'type',
      ])
      map_parents = parent_product.reduce(
        (acc, p) => ({ ...acc, [p.id]: p }),
        {},
      )
    }
    const map_products = (res_products || []).reduce(
      (acc, p) => ({
        ...acc,
        [p.id]: {
          ...p,
          ...(p.type === PRODUCT_TYPE.VARIATION && p.parent_id
            ? {
                parent_name: map_parents[p.parent_id]?.name || '',
              }
            : {}),
        },
      }),
      {},
    )

    if (resp.product_ids && resp.product_ids.length > 0) {
      products = resp.product_ids.map((pId) => map_products[pId])
    }
    if (resp.excluded_product_ids && resp.excluded_product_ids.length > 0) {
      excluded_products = resp.excluded_product_ids.map(
        (pId) => map_products[pId],
      )
    }

    if (resp.product_categories && resp.product_categories.length > 0) {
      product_categories = await categoryApi.findMulti(resp.product_categories)
    }
    if (
      resp.excluded_product_categories &&
      resp.excluded_product_categories.length > 0
    ) {
      excluded_product_categories = await categoryApi.findMulti(
        resp.excluded_product_categories,
      )
    }

    return {
      ...resp,
      free_shipping: Boolean(resp.free_shipping),
      products: products.map((p) => ({ ...p, value: p.id, label: p.name })),
      excluded_products: excluded_products.map((p) => ({
        ...p,
        value: p.id,
        label: p.name,
      })),
      product_categories: product_categories.map((p) => ({
        ...p,
        value: p.id,
        label: p.name,
      })),
      excluded_product_categories: excluded_product_categories.map((p) => ({
        ...p,
        value: p.id,
        label: p.name,
      })),
    }
  } catch (e) {
    return {
      ...resp,
      free_shipping: Boolean(resp.free_shipping),
      products: [],
      excluded_products: [],
      product_categories: [],
      excluded_categories: [],
    }
  }
}

const findMulti = (ids) =>
  httpClient.post(url.COUPON, {
    action: ACTIONS.READ_MULTI,
    parameters: { ids },
  })
const findMany = (codes, organization_id, store_id) =>
  httpClient.post(url.COUPON, {
    organization_id,
    store_id,
    action: ACTIONS.READ_MANY,
    parameters: { codes },
  })

const updateOne = (couponParams) =>
  httpClient.post(url.COUPON, {
    action: ACTIONS.UPDATE,
    parameters: couponParams,
  })

const removeOne = (id) => {
  const couponParams = {
    id: Number(id),
  }
  return httpClient.post(url.COUPON, {
    action: ACTIONS.DELETE,
    parameters: couponParams,
  })
}

const remove = (ids) => {
  const params = {
    data: { delete: ids },
  }
  return httpClient.post(url.COUPON, {
    action: ACTIONS.BATCH_UPDATE,
    parameters: params,
  })
}

const create = async (coupon) => {
  const couponParams = {
    item_data: mapCouponToCreate(coupon),
  }
  // eslint-disable-next-line no-useless-catch
  try {
    const resp = await httpClient.post(url.COUPON, {
      action: ACTIONS.CREATE,
      parameters: couponParams,
    })
    return resp
  } catch (e) {
    throw e
  }
}

const update = async (coupon) => {
  let couponParams = {}
  let resp = null
  // eslint-disable-next-line no-useless-catch
  try {
    couponParams = {
      item_type: 'coupon',
      item_data: Object.assign(mapCouponToCreate(coupon), {
        id: Number(coupon.id),
      }),
    }
    resp = await updateOne(couponParams)
    return resp
  } catch (e) {
    throw e
  }
}

const batchUpdate = (params) =>
  httpClient.post(url.COUPON, {
    action: ACTIONS.BATCH_UPDATE,
    parameters: params,
  })

const couponApi = {
  findOne,
  findMulti,
  getList,
  create,
  removeOne,
  remove,
  update,
  batchUpdate,
  findMany,
}

export default couponApi
