import { transformTypeOptions } from '~/utils/common'
import { STORE_TYPES } from '~/constants/store'

export const ADMIN_OPTIONS_TYPE = {
  STORE: 'stores',
  WAREHOUSE: 'warehouse',
  // SKU: 'SKU-management',
  // FULFILLMENT_INTEGRATION: 'fulfillment-integration',
  INTEGRATIONS: 'integrations',
}

export const ADMIN_OPTIONS_LOCATIONS_TYPE = {
  STORE: 'stores',
  WAREHOUSE: 'warehouse',
  // FULFILLMENT_INTEGRATION: 'fulfillment-integration',
  // CREDIT_CARD_INTEGRATION: 'payment-integration',
  INTEGRATIONS: 'integrations',
}

export const ADMIN_OPTIONS_TAB_PROFILE3 = [
  { value: ADMIN_OPTIONS_TYPE.STORE, label: 'Online Stores' },
  { value: ADMIN_OPTIONS_TYPE.WAREHOUSE, label: 'Locations' },
  { value: ADMIN_OPTIONS_TYPE.INTEGRATIONS, label: 'Integrations' },
]

export const ADMIN_OPTIONS_TAB = transformTypeOptions(ADMIN_OPTIONS_TYPE)
export const ADMIN_OPTIONS_LOCATIONS_TAB = transformTypeOptions(
  ADMIN_OPTIONS_LOCATIONS_TYPE,
)

export const LINK_STORE_PATH: {
  [key: string]: string
} = Object.keys(STORE_TYPES).reduce(
  (acc, cur) => ({ ...acc, [cur]: `link-${STORE_TYPES[cur]}` }),
  {},
)

export const LINK_WOO_STEP = {
  GENERAL: 'general',
  WAREHOUSE: 'warehouse',
  CATEGORY: 'category',
  VERIFICATION: 'verification',
}
export const REGEX_PAGE_SCROLLABLE = [
  /products$/g,
  /coupons$/g,
  /categories$/g,
  /tags$/g,
  /orders+$/g,
  /warehouses/g,
  /warehouse-operations/g,
  /fulfillment/g,
]

export const LINK_COVA_STEP = {
  GENERAL: 'general',
  CATEGORIES: 'categories',
  WAREHOUSE: 'warehouse',
  VERIFICATION: 'verification',
}

export const LINK_WOO_NEXT_STEP = {
  [LINK_WOO_STEP.GENERAL]: LINK_WOO_STEP.WAREHOUSE,
  [LINK_WOO_STEP.WAREHOUSE]: LINK_WOO_STEP.CATEGORY,
}

export const LINK_WOO_PREV_STEP = {
  [LINK_WOO_STEP.CATEGORY]: LINK_WOO_STEP.WAREHOUSE,
  [LINK_WOO_STEP.WAREHOUSE]: LINK_WOO_STEP.GENERAL,
}

export const LINK_COVA_NEXT_STEP = {
  [LINK_COVA_STEP.GENERAL]: LINK_COVA_STEP.WAREHOUSE,
  [LINK_COVA_STEP.WAREHOUSE]: LINK_COVA_STEP.CATEGORIES,
  [LINK_COVA_STEP.CATEGORIES]: LINK_COVA_STEP.VERIFICATION,
}

export const LINK_COVA_PREV_STEP = {
  [LINK_COVA_STEP.CATEGORIES]: LINK_COVA_STEP.WAREHOUSE,
  [LINK_COVA_STEP.VERIFICATION]: LINK_COVA_STEP.CATEGORIES,
}

export const LINK_WOO_STEPPER = [
  { value: LINK_WOO_STEP.GENERAL, label: 'General Store Info' },
  { value: LINK_WOO_STEP.WAREHOUSE, label: 'Inventory & Address' },
  { value: LINK_WOO_STEP.CATEGORY, label: 'Store Category' },
  { value: LINK_WOO_STEP.VERIFICATION, label: 'WooCommerce Verification' },
]

export const LINK_COVA_STEPPER = [
  { value: LINK_COVA_STEP.GENERAL, label: 'General Store Info' },
  { value: LINK_COVA_STEP.WAREHOUSE, label: 'Warehouse Info' },
  { value: LINK_COVA_STEP.CATEGORIES, label: 'Match Categories' },
  { value: LINK_COVA_STEP.VERIFICATION, label: 'Store Theme' },
]

export const LINK_GREENLINE_STEP = {
  GENERAL: 'general',
  CATEGORIES: 'categories',
  WAREHOUSE: 'warehouse',
  VERIFICATION: 'verification',
}

export const LINK_GREENLINE_NEXT_STEP = {
  [LINK_GREENLINE_STEP.GENERAL]: LINK_GREENLINE_STEP.WAREHOUSE,
  [LINK_GREENLINE_STEP.WAREHOUSE]: LINK_GREENLINE_STEP.CATEGORIES,
  [LINK_GREENLINE_STEP.CATEGORIES]: LINK_GREENLINE_STEP.VERIFICATION,
}

export const LINK_GREENLINE_PREV_STEP = {
  [LINK_GREENLINE_STEP.CATEGORIES]: LINK_GREENLINE_STEP.WAREHOUSE,
  [LINK_GREENLINE_STEP.VERIFICATION]: LINK_GREENLINE_STEP.CATEGORIES,
}

export const LINK_GREENLINE_STEPPER = [
  { value: LINK_GREENLINE_STEP.GENERAL, label: 'General Store Info' },
  { value: LINK_GREENLINE_STEP.WAREHOUSE, label: 'Warehouse Info' },
  { value: LINK_GREENLINE_STEP.CATEGORIES, label: 'Match Categories' },
  { value: LINK_GREENLINE_STEP.VERIFICATION, label: 'Store Theme' },
]

export const PLATFORM_OPTIONS = [
  {
    value: `${LINK_STORE_PATH.WOO}`,
    label: 'WooCommerce',
  },
  {
    value: `${LINK_STORE_PATH.COVA}`,
    label: 'Cova',
  },
  {
    value: `${LINK_STORE_PATH.GREENLINE}`,
    label: 'Greenline',
  },
]

export const PLATFORM_MULTI_STORE_OPTIONS = [
  {
    value: `${LINK_STORE_PATH.WOO}`,
    label: 'WooCommerce',
  },
]

export const SKU_TABS = {
  SKU_PRODUCTS: 'sku-products',
  SKU_TAGS: 'sku-tags',
}

export const SKU_OPTIONS = [
  { label: 'SKU Products', value: SKU_TABS.SKU_PRODUCTS },
  { label: 'SKU Tags', value: SKU_TABS.SKU_TAGS },
]

export const FULFILLMENT_INTEGRATION_TABS = {
  SHIPSTATION: 'shipstation',
  ONFLEET: 'onfleet',
  CANFLEET: 'canfleet',
}

export const FULFILLMENT_INTEGRATION_OPTIONS = [
  { label: 'Shipstation', value: FULFILLMENT_INTEGRATION_TABS.SHIPSTATION },
  { label: 'Onfleet', value: FULFILLMENT_INTEGRATION_TABS.ONFLEET },
  { label: 'CanFleet', value: FULFILLMENT_INTEGRATION_TABS.CANFLEET },
]

export const WAYBILL_OPTIONS_VALUE = {
  SAME_AS_WAREHOUSE_ADDRESS: 'same_as_warehouse_address',
  CUSTOMIZE_ADDRESS: 'customize_address',
  SAME_AS_RETURN_ADDRESS: 'same_as_return_address',
}

export const RETURN_OPTIONS_VALUE = {
  SAME_AS_WAREHOUSE_ADDRESS: 'same_as_warehouse_address',
  CUSTOMIZE_ADDRESS: 'customize_address',
  SAME_AS_WAYBILL_ADDRESS: 'same_as_waybill_address',
}

export const WAYBILL_OPTIONS = [
  {
    value: WAYBILL_OPTIONS_VALUE.SAME_AS_WAREHOUSE_ADDRESS,
    label: 'Same As Address Above',
  },
  {
    value: WAYBILL_OPTIONS_VALUE.CUSTOMIZE_ADDRESS,
    label: 'Customize Waybill Address',
  },
  {
    value: WAYBILL_OPTIONS_VALUE.SAME_AS_RETURN_ADDRESS,
    label: 'Same As Return Address',
  },
]

export const RETURN_OPTIONS = [
  {
    value: RETURN_OPTIONS_VALUE.SAME_AS_WAREHOUSE_ADDRESS,
    label: 'Same As Address Above',
  },
  {
    value: RETURN_OPTIONS_VALUE.CUSTOMIZE_ADDRESS,
    label: 'Customize Return Address',
  },
  {
    value: RETURN_OPTIONS_VALUE.SAME_AS_WAYBILL_ADDRESS,
    label: 'Same As Waybill Address',
  },
]

export const ADD_WAREHOUSE_ACTION = 'add-warehouses'

export const COVA_MATCH_CATEGORY_UNCATEGORIZED = {
  id: 'uncategorized',
  name: 'Uncategorized',
}
export const GREENLINE_MATCH_CATEGORY_UNCATEGORIZED = {
  id: 'uncategorized',
  name: 'Uncategorized',
}

export const PAYMENT_TYPE = {
  PAYFIRMA: 'payfirma',
  MONERIS: 'moneris',
  NO_PROVIDER: 'noprovider',
}

export const DEFAULT_PAYMENT_DIALOG = {
  paymentType: PAYMENT_TYPE.NO_PROVIDER,
  // moneris
  monerisStoreId: '',
  monerisApiToken: '',
  // payfirma
  clientId: '',
  clientSecret: '',
}

export const PAYMENT_PROVIDER = {
  [PAYMENT_TYPE.PAYFIRMA]: 'Payfirma/Merrco',
  [PAYMENT_TYPE.MONERIS]: 'Moneris',
}

export const STORE_STATUS = {
  ADDING_WAREHOUSE: 'adding',
  SYNCED: 'synced',
}

export const WAREHOUSE_STATUS = {
  SYNCING: 'syncing',
  SYNCED: 'synced',
}

export const ADD_WAREHOUSE_STEP = {
  GENERAL: 'general',
  THRESHOLD: 'threshold',
  HOURS: 'hours',
  DELIVERY_ZONES: 'delivery_zones',
}

export const DELIVERY_ZONES_SETS = {
  DRAWN_ZONES: 'polygon',
  DISTANCE_RADIUS: 'radius',
  POSTAL_CODE: 'postal_code',
}

export const AREA_SETS = {
  [DELIVERY_ZONES_SETS.DRAWN_ZONES]: 'zone',
  [DELIVERY_ZONES_SETS.DISTANCE_RADIUS]: 'radius',
  [DELIVERY_ZONES_SETS.POSTAL_CODE]: 'zipcode',
}

export const DELIVERY_ZONES_SETS_OPTIONS = [
  { value: DELIVERY_ZONES_SETS.DRAWN_ZONES, label: 'Drawn Zones' },
  { value: DELIVERY_ZONES_SETS.DISTANCE_RADIUS, label: 'Distance Radius' },
  { value: DELIVERY_ZONES_SETS.POSTAL_CODE, label: 'ZIP/postal Code' },
]

export const DELIVERY_ZONES_OPTIONS = [
  { value: DELIVERY_ZONES_SETS.DRAWN_ZONES, label: 'Drawn zones' },
  { value: DELIVERY_ZONES_SETS.DISTANCE_RADIUS, label: 'Distance radius' },
  { value: DELIVERY_ZONES_SETS.POSTAL_CODE, label: 'ZIP/postal code' },
]

export const HOURS_TYPE = {
  INSTORE_PICKUP: 'instore-pickup',
  DELIVERY: 'delivery',
  CURBSIDE: 'curbside',
}

export const ORDER_LIMIT_TYPE = {
  ALL: 'all',
  INDIVIDUAL: 'individual',
}

export const WAREHOUSE_PICKING_UP_WINDOW_OPTIONS = [
  {
    value: 15,
    label: '15 minutes each',
  },
  {
    value: 30,
    label: '30 minutes each',
  },
  {
    value: 60,
    label: '1 hour each',
  },
  {
    value: 90,
    label: '1.5 hours each',
  },
  {
    value: 120,
    label: '2 hours each',
  },
  {
    value: 150,
    label: '2.5 hours each',
  },
  {
    value: 180,
    label: '3 hours each',
  },
  {
    value: 210,
    label: '3.5 hours each',
  },
  {
    value: 240,
    label: '4 hours each',
  },
  {
    value: 270,
    label: '4.5 hours each',
  },
  {
    value: 300,
    label: '5 hours each',
  },
]

export const WAREHOUSE_GAP_TIME_OPTIONS = [
  {
    value: 15,
    label: '15 minutes',
  },
  {
    value: 30,
    label: '30 minutes',
  },
  {
    value: 60,
    label: '1 hour',
  },
  {
    value: 90,
    label: '1.5 hours',
  },
  {
    value: 120,
    label: '2 hours',
  },
  {
    value: 150,
    label: '2.5 hours',
  },
  {
    value: 180,
    label: '3 hours',
  },
  {
    value: 210,
    label: '3.5 hours',
  },
  {
    value: 240,
    label: '4 hours',
  },
  {
    value: 270,
    label: '4.5 hours',
  },
  {
    value: 300,
    label: '5 hours',
  },
]

export const DAYS_OF_WEEK_OPTIONS = [
  {
    value: 'sunday',
    label: 'Sunday',
    shortLabel: 'Sun',
  },
  {
    value: 'monday',
    label: 'Monday',
    shortLabel: 'Mon',
  },
  {
    value: 'tuesday',
    label: 'Tuesday',
    shortLabel: 'Tue',
  },
  {
    value: 'wednesday',
    label: 'Wednesday',
    shortLabel: 'Web',
  },
  {
    value: 'thursday',
    label: 'Thursday',
    shortLabel: 'Thu',
  },
  {
    value: 'friday',
    label: 'Friday',
    shortLabel: 'Fri',
  },
  {
    value: 'saturday',
    label: 'Saturday',
    shortLabel: 'Sat',
  },
]

export const DEFAULT_DISTANCE_TYPE = {
  KILOMETER: 'Km',
  METER: 'm',
}

export const DISTANCE_TYPE = {
  [DEFAULT_DISTANCE_TYPE.KILOMETER]: 'kilometer',
  [DEFAULT_DISTANCE_TYPE.METER]: 'meter',
}

export const DEFAULT_24_HOURS = {
  FROM: '00:00',
  TO: '24:00',
}

export const CANFLEET_STATUS_VALUE = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  NOCONNECT: 'noconnect',
}
export const CANFLEET_STATUS = {
  [CANFLEET_STATUS_VALUE.CONNECTED]: 'Connected',
  [CANFLEET_STATUS_VALUE.DISCONNECTED]: 'Disconnected',
  [CANFLEET_STATUS_VALUE.NOCONNECT]: '-',
}

export const INTEGRATIONS_TYPE = {
  CANFLEET: 'canfleet',
  MONERIS: 'moneris',
  ONFLEET: 'onfleet',
  PAYFIRMA: 'payfirma',
  SHIPSTATION: 'shipstation',
}

export const INTEGRATIONS_NAME = {
  [INTEGRATIONS_TYPE.CANFLEET]: 'CanFleet',
  [INTEGRATIONS_TYPE.MONERIS]: 'Moneris',
  [INTEGRATIONS_TYPE.ONFLEET]: 'Onfleet',
  [INTEGRATIONS_TYPE.PAYFIRMA]: 'Payfirma/Merrco',
  [INTEGRATIONS_TYPE.SHIPSTATION]: 'Shipstation',
}
