import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useGetStore } from '~/redux/hooks/store'
import { useAuthPlugins, useAuthState } from '~/modules/auth/redux/hook'
import { STORE_TYPES } from '~/constants/store'
import RouteProvider from '~/providers/RouteProvider'
import { useDebugMode } from '~/utils/hooks/useDebugMode'
import { theme as bsTheme } from 'bs-unified-ui'
import { ThemeProvider } from '@mui/material'
import { REACT_APP_MODE } from '~/constants/environment'
import { FLAGS } from '~/constants/flags'

const RouteWithStore = ({ routeConfig, ...props }) => {
  const activeStore = useGetStore()
  const authState = useAuthState()
  const authPlugins = useAuthPlugins()

  // LIST_FLAG_RELEASE_PRODUCTION => list flag ready for production
  const LIST_FLAG_RELEASE_PRODUCTION = []

  // LIST_FLAG_USE_DEBUG_MODE => list flag ready for testing
  const LIST_FLAG_USE_DEBUG_MODE = [FLAGS.ACD_12519, FLAGS.ACD_12342]
  const isDebugMode = useDebugMode()

  const useNewUI = useMemo(() => {
    if (routeConfig.featureFlag) {
      if (LIST_FLAG_RELEASE_PRODUCTION.includes(routeConfig.featureFlag)) {
        return true
      }

      if (LIST_FLAG_USE_DEBUG_MODE.includes(routeConfig.featureFlag)) {
        return isDebugMode
      }

      return REACT_APP_MODE === 'development'
    }

    return false
  }, [routeConfig.featureFlag, isDebugMode])

  const readOnly =
    routeConfig.editRoles &&
    activeStore?.storeId &&
    !routeConfig.editRoles(activeStore?.storeId, authPlugins)(authState.data)

  const renderComponent = useCallback(
    (routeProps) => {
      const rProps = {
        ...routeProps.match?.params,
        ...routeConfig.xProps,
        url: routeProps.location?.pathname,
        path: routeProps.match?.path,
        store: activeStore,
        readOnly,
      }
      switch (activeStore?.storeType) {
        case STORE_TYPES.COVA:
          if (useNewUI && routeConfig.covaComponentV2) {
            return (
              <ThemeProvider theme={bsTheme}>
                <RouteProvider value={rProps}>
                  <routeConfig.covaComponentV2 {...rProps} {...routeProps} />
                </RouteProvider>
              </ThemeProvider>
            )
          }

          if (routeConfig.covaComponent) {
            return (
              <RouteProvider value={rProps}>
                <routeConfig.covaComponent {...rProps} {...routeProps} />
              </RouteProvider>
            )
          }
        // eslint-disable-next-line no-fallthrough
        case STORE_TYPES.GREENLINE:
          if (useNewUI && routeConfig.greenlineComponentV2) {
            return (
              <ThemeProvider theme={bsTheme}>
                <RouteProvider value={rProps}>
                  <routeConfig.greenlineComponentV2
                    {...rProps}
                    {...routeProps}
                  />
                </RouteProvider>
              </ThemeProvider>
            )
          }

          if (routeConfig.greenlineComponent) {
            return (
              <RouteProvider value={rProps}>
                <routeConfig.greenlineComponent {...rProps} {...routeProps} />
              </RouteProvider>
            )
          }
        // eslint-disable-next-line no-fallthrough
        case STORE_TYPES.SHOPIFY:
          if (routeConfig.shopifyComponent) {
            return (
              <RouteProvider value={rProps}>
                <routeConfig.shopifyComponent {...rProps} {...routeProps} />
              </RouteProvider>
            )
          }
        // eslint-disable-next-line no-fallthrough
        default:
          return (
            <RouteProvider value={rProps}>
              <routeConfig.component {...rProps} {...routeProps} />
            </RouteProvider>
          )
      }
    },
    [activeStore, routeConfig, readOnly, useNewUI],
  )

  return <>{renderComponent(props)}</>
}

RouteWithStore.propTypes = {
  routeConfig: PropTypes.shape().isRequired,
}

export default RouteWithStore
