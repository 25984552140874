import produce from 'immer'
import { TAG_ACTION } from '../actions/tag'
import { DEFAULT_TAG_DETAIL, DEFAULT_TAG_LIST_DATA } from '../default/tag'

const initialState = {
  data: DEFAULT_TAG_LIST_DATA,
  allTagData: [],
  detail: DEFAULT_TAG_DETAIL,
  loading: false,
  error: '',
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case TAG_ACTION.FETCH_LIST:
        draft.loading = true
        draft.error = ''
        break
      case TAG_ACTION.FETCH_ALL_LIST:
        draft.loading = true
        draft.error = ''
        break
      case TAG_ACTION.SET_ALL_LIST_DATA:
        draft.allTagData = payload
        draft.loading = false
        draft.error = ''
        break
      case TAG_ACTION.SET_LIST_DATA:
        draft.data = payload
        draft.loading = false
        draft.error = ''
        break
      case TAG_ACTION.SET_ALL_LIST_ERROR:
      case TAG_ACTION.SET_LIST_ERROR:
        draft.loading = false
        draft.error = payload
        break
      case TAG_ACTION.FETCH_DETAIL:
        draft.detail = DEFAULT_TAG_DETAIL
        draft.loadingDetail = true
        draft.error = ''
        break
      case TAG_ACTION.SET_DETAIL_DATA_ERROR:
        draft.loadingDetail = false
        draft.error = payload
        break
      case TAG_ACTION.SET_DETAIL_DATA:
        draft.loadingDetail = false
        draft.error = ''
        draft.detail = payload
        break
      default:
        break
    }
  })

export default reducer
