/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect'

export const getUser = (state) => state.user.data
export const getUserLoading = (state) => state.user.loading
export const getUserError = (state) => state.user.error
export const getStoreLoading = (state) => state.user.storeLoading
export const getOrgLoading = (state) => state.user.orgLoading
export const getAuthStores = (state) => state?.auth?.data?.stores
export const getUserStores = (state) => state?.user?.data?.stores

export const getMapUserStores = createSelector(getUserStores, (stores) =>
  (stores || []).reduce((acc, s) => {
    acc[s.store_id] = s
    return acc
  }, {}),
)

export const getStoreInfo = (storeId) =>
  createSelector(getUserStores, (stores) =>
    (stores || []).find((s) => s.store_id === storeId),
  )
