import PropTypes from 'prop-types'

import { useAuth } from '~/modules/auth/redux/hook'

const Guard = ({ accessWhen, children, fallbackComp }) => {
  const { authState } = useAuth()

  const isAllowedAccess = accessWhen(authState.data)

  if (!isAllowedAccess) return fallbackComp

  return children

  // if (STATE.IDLE) return <IDLE />
  // if (STATE.LOADING) return <LOADING />
  // if (STATE.SUCCESS) return <SUCCESS />
  // if (STATE.ERROR) return <ERROR />
}

Guard.defaultProps = {
  accessWhen: () => false,
}

Guard.propTypes = {
  children: PropTypes.node,
}

Guard.defaultProps = {
  fallbackComp: null,
}

export default Guard
