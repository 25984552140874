export const rewardAction = Object.freeze({
  GET_LIST_REWARD: 'customerReward.GET_LIST_REWARD',
  GET_LIST_REWARD_OK: 'customerReward.GET_LIST_REWARD_OK',
  GET_LIST_REWARD_ERR: 'customerReward.GET_LIST_REWARD_ERR',

  GET_LIST_POINT_LOG: 'customerReward.GET_LIST_POINT_LOG',
  GET_LIST_POINT_LOG_OK: 'customerReward.GET_LIST_POINT_LOG_OK',
  GET_LIST_POINT_LOG_ERR: 'customerReward.GET_LIST_POINT_LOG_ERR',

  UPDATE_CUSTOMER_POINT: 'customerReward.UPDATE_CUSTOMER_POINT',
  UPDATE_CUSTOMER_POINT_OK: 'customerReward.UPDATE_CUSTOMER_POINT_OK',
  UPDATE_CUSTOMER_POINT_ERR: 'customerReward.UPDATE_CUSTOMER_POINT_ERR',

  GET_LIST_POINT_LOG_CUSTOMER: 'customerReward.GET_LIST_POINT_LOG_CUSTOMER',
  GET_LIST_POINT_LOG_CUSTOMER_OK:
    'customerReward.GET_LIST_POINT_LOG_CUSTOMER_OK',
  GET_LIST_POINT_LOG_CUSTOMER_ERR:
    'customerReward.GET_LIST_POINT_LOG_CUSTOMER_ERR',

  GET_POINT_LOG_DETAIL: 'customerReward.GET_POINT_LOG_DETAIL',
  GET_POINT_LOG_DETAIL_OK: 'customerReward.GET_POINT_LOG_DETAIL_OK',
  GET_POINT_LOG_DETAIL_ERR: 'customerReward.GET_POINT_LOG_DETAIL_ERR',
})

const getListRewardsCustomer = (params) => ({
  type: rewardAction.GET_LIST_REWARD,
  payload: params,
})

const getListPointsLog = (params) => ({
  type: rewardAction.GET_LIST_POINT_LOG,
  payload: params,
})

const updateOneCustomerPoint = (params, onSuccess = () => {}) => ({
  type: rewardAction.UPDATE_CUSTOMER_POINT,
  payload: params,
  callbacks: {
    onSuccess,
  },
})

const getListPointsLogCustomer = (params, onSuccess = () => {}) => ({
  type: rewardAction.GET_LIST_POINT_LOG_CUSTOMER,
  payload: params,
  callbacks: {
    onSuccess,
  },
})

const getPointsLogDetail = (params, onSuccess = () => {}) => ({
  type: rewardAction.GET_POINT_LOG_DETAIL,
  payload: params,
  callbacks: {
    onSuccess,
  },
})

export default {
  getListRewardsCustomer,
  getListPointsLog,
  updateOneCustomerPoint,
  getListPointsLogCustomer,
  getPointsLogDetail,
}
