/* eslint-disable no-shadow */
import { useEffect } from 'react'

import { set, clear } from '~/utils/storage/localStorage'

const SYNCED_SIGN_OUT_EVENT = '__SYNCED_SIGN_OUT__'

export const syncedSignOut = () => {
  set(SYNCED_SIGN_OUT_EVENT, Date.now())
  clear(SYNCED_SIGN_OUT_EVENT)
}

export const useSyncedSignOut = () => {
  useEffect(() => {
    const syncedSignOut = (evt) => {
      if (evt.key === SYNCED_SIGN_OUT_EVENT && !document.hasFocus())
        window.location.replace('/login')
    }

    window.addEventListener('storage', syncedSignOut)
    return () => {
      window.removeEventListener('storage', syncedSignOut)
    }
  }, [])
}
