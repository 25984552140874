import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  SKU: 'warehouses/sku/',
}

export const getList = (params) =>
  httpClient.post(url.SKU, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })

export const create = (params) =>
  httpClient.post(url.SKU, {
    action: ACTIONS.CREATE,
    parameters: params,
  })

export const findOne = (params) =>
  httpClient.post(url.SKU, {
    action: ACTIONS.READ,
    parameters: params,
  })

export const updateOne = (params) =>
  httpClient.post(url.SKU, {
    action: ACTIONS.UPDATE,
    parameters: params,
  })

export const updateMany = (params) =>
  httpClient.post(url.SKU, {
    action: ACTIONS.UPDATE_MANY,
    parameters: params,
  })

export const removeOne = async (params) => {
  const res = await httpClient.post(url.SKU, {
    action: ACTIONS.DELETE,
    parameters: params,
  })
  return res
}

const skuApi = {
  getList,
  create,
  findOne,
  updateOne,
  updateMany,
  removeOne,
}

export default skuApi
