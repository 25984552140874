import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, CUSTOMER_ROUTES } from '~/constants/router'
import {
  customerRolesEditable,
  customerRolesView,
  customerSendEmail,
} from '~/modules/auth/conditions'
import { FLAGS } from '~/constants/flags'

const CustomerForm = lazyRetry(() => import('~/pages/Customer/'))
const CustomerList = lazyRetry(() => import('~/pages/Customer/'))
const CustomerDetail = lazyRetry(() => import('~/pages/Customer'))
const CovaCustomerForm = lazyRetry(
  () => import('~/pages/Customer/CovaCustomerForm'),
)
const CovaCustomerDetail = lazyRetry(
  () => import('~/pages/Customer/CovaCustomerDetail'),
)
const CovaCustomerList = lazyRetry(
  () => import('~/pages/Customer/CovaCustomerList'),
)
const GreenlineCustomerList = lazyRetry(
  () => import('~/pages/Customer/GreenlineCustomerList'),
)
const GreenlineCustomerDetail = lazyRetry(
  () => import('~/pages/Customer/GreenlineCustomerDetail'),
)
const ShopifyCustomerList = lazyRetry(
  () => import('~/pages/Customer/ShopifyCustomerList'),
)
const ShopifyCustomerDetail = lazyRetry(
  () => import('~/pages/Customer/ShopifyCustomerDetail'),
)
const SendEmail = lazyRetry(() => import('~/pages/Customer/SendEmail'))

const CovaCustomerListV2 = lazyRetry(
  () => import('~/pages/Customer/CovaCustomerListV2'),
)

const CovaCustomerDetailV2 = lazyRetry(
  () => import('~/pages/Customer/CovaCustomerDetailV2'),
)

const GreenlineCustomerListV2 = lazyRetry(
  () => import('~/pages/Customer/GreenlineCustomerListV2'),
)

const GreenlineCustomerDetailV2 = lazyRetry(
  () => import('~/pages/Customer/GreenlineCustomerDetailV2'),
)

const CustomerRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.LIST}`,
    component: CustomerList,
    covaComponent: CovaCustomerList,
    greenlineComponent: GreenlineCustomerList,
    shopifyComponent: ShopifyCustomerList,
    covaComponentV2: CovaCustomerListV2,
    greenlineComponentV2: GreenlineCustomerListV2,
    featureFlag: FLAGS.ACD_12342,
    layout: 'admin',
    exact: true,
    roles: customerRolesView,
    editRoles: customerRolesEditable,
    routeName: 'Customers List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.SERVICE}`,
    component: CustomerList,
    covaComponent: CovaCustomerList,
    greenlineComponent: GreenlineCustomerList,
    shopifyComponent: ShopifyCustomerList,
    layout: 'admin',
    exact: true,
    roles: customerRolesView,
    editRoles: customerRolesEditable,
    routeName: 'Customers List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.MARKETING}`,
    component: CustomerList,
    covaComponent: CovaCustomerList,
    greenlineComponent: GreenlineCustomerList,
    shopifyComponent: ShopifyCustomerList,
    layout: 'admin',
    exact: true,
    roles: customerRolesView,
    editRoles: customerRolesEditable,
    routeName: 'Customers List',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.DETAIL}`,
    component: CustomerDetail,
    covaComponent: CovaCustomerDetail,
    greenlineComponent: GreenlineCustomerDetail,
    shopifyComponent: ShopifyCustomerDetail,
    covaComponentV2: CovaCustomerDetailV2,
    greenlineComponentV2: GreenlineCustomerDetailV2,
    featureFlag: FLAGS.ACD_12519,
    layout: 'admin',
    exact: true,
    // roles: customerRolesView,
    // editRoles: customerRolesEditable,
    routeName: 'Customer Detail',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.CREATE}`,
    component: CustomerForm,
    covaComponent: CovaCustomerForm,
    layout: 'admin',
    exact: true,
    roles: customerRolesEditable,
    routeName: 'Customer Create',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${CUSTOMER_ROUTES.SEND_EMAIL}`,
    component: SendEmail,
    covaComponent: SendEmail,
    layout: 'admin',
    exact: true,
    roles: customerSendEmail,
    routeName: 'Customer Send Email',
  },
]

export default CustomerRouters
