import httpClient from '~/modules/core/httpClient'
import ssoClient from '~/modules/core/ssoClient'
import fbAuth from '~/modules/firebase'

const url = {
  READ: '/users/read/',
  SIGN_UP_WHEN_FB_EXIST: '/auth/sign-up-fb',
  LOGOUT: '/auth/logout',
  REFRESH_TOKEN: '/auth/refresh-token',
  RENEW_TOKEN: '/auth/renew-token',
  GENERATE_CUSTOM_TOKEN: '/auth/gen-custom-token',
  SSO_AUTH: 'auth',
  SSO_CLEAR_SESSION: 'clear-session',
  VERIFY_EMAIL: '/auth/verify-email',
  RESEND_VERIFY_EMAIL: '/auth/resend-verify-email',
}

const signIn = (email, password) => fbAuth.signIn(email, password)
const getCurrentUser = () => fbAuth.getCurrentUser()
const getClaimsToken = () => fbAuth.getClaimsToken()
const getUserFirebaseId = () => fbAuth.getUserFirebaseId()
const signInWithCustomToken = (customToken) =>
  fbAuth.signInWithCustomToken(customToken)
const generateCustomToken = () =>
  httpClient.json.post(url.GENERATE_CUSTOM_TOKEN)
const signUpWhenFbExist = (payload) =>
  httpClient.json.post(url.SIGN_UP_WHEN_FB_EXIST, payload)
const signOut = () => fbAuth.signOut()
const get = () => httpClient.post(url.READ, () => ({}))
const sendEmailVerification = () => fbAuth.sendEmailVerification()
const checkEmailVerification = (code) => fbAuth.checkEmailVerification(code)
const verifyEmail = (email, oobCode) =>
  httpClient.json.post(url.VERIFY_EMAIL, () => ({ email, oob_code: oobCode }))
const resendVerifyEmail = (uid) =>
  httpClient.json.post(url.RESEND_VERIFY_EMAIL, () => ({ uid }))
const register = (email, password, name, isEmailVerify) =>
  fbAuth.register(email, password, name, isEmailVerify)
const resetPassword = (email) => fbAuth.resetPassword(email)
const updatePassword = (code, newPassword) =>
  fbAuth.updatePassword(code, newPassword)
const changePassword = (password) => fbAuth.changePassword(password)
const fetchRefreshToken = (refreshToken) =>
  httpClient.json.post(url.REFRESH_TOKEN, () => ({
    refresh_token: refreshToken,
  }))
const renewToken = () => httpClient.json.post(url.RENEW_TOKEN)
const getRefreshToken = () => fbAuth.getRefreshToken()
const ssoAuth = () => ssoClient.json.post(url.SSO_AUTH, {})
const isEnableMFA = () => fbAuth.isEnableMFA()
const unenrollMFA = () => fbAuth.unenrollMFA()
const getEnrolledPhoneFactors = () => fbAuth.getEnrolledPhoneFactors()

const ssoClearSession = () => ssoClient.json.post(url.SSO_CLEAR_SESSION, {})
const signUp = (email, password, name, isEmailVerify) =>
  fbAuth.signUp(email, password, name, isEmailVerify)
const forceUpdateToken = () => fbAuth.forceUpdateToken()
const getLoginMethods = () => fbAuth.getLoginMethods()

const authApi = {
  signIn,
  signInWithCustomToken,
  generateCustomToken,
  fetchRefreshToken,
  renewToken,
  signOut,
  get,
  ssoAuth,
  ssoClearSession,
  getCurrentUser,
  getUserFirebaseId,
  sendEmailVerification,
  checkEmailVerification,
  register,
  resetPassword,
  updatePassword,
  changePassword,
  signUpWhenFbExist,
  getRefreshToken,
  isEnableMFA,
  unenrollMFA,
  getEnrolledPhoneFactors,
  signUp,
  verifyEmail,
  getClaimsToken,
  resendVerifyEmail,
  forceUpdateToken,
  getLoginMethods,
}

export default authApi
