import { ONBOARDING_LINK_STORE_STEPS } from '~/constants/auth'
import {
  DEFAULT_CALENDAR_VIEW,
  DEFAULT_FILTER_VIEW,
  DEFAULT_MANUALLY_TIMEZONE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TIMEZONE_SETTING,
  DEFAULT_PRINTING_SIZE,
} from '~/constants/settings'
import { isStoreImportFailed } from './store'
import { STORE_TYPES } from '~/constants/store'

export const getStoreType = (url) => {
  if (url.contains(ONBOARDING_LINK_STORE_STEPS.CATEGORY))
    return ONBOARDING_LINK_STORE_STEPS.CATEGORY
  if (url.contains(ONBOARDING_LINK_STORE_STEPS.VERIFICATION))
    return ONBOARDING_LINK_STORE_STEPS.VERIFICATION
  return ONBOARDING_LINK_STORE_STEPS.GENERAL
}

// TODO: Need optimize the below function with function convertStoreListOptions()
export const convertTopbarStoreOptions = (
  organizations,
  stores,
  mapUserStores = {},
) =>
  (organizations || []).reduce((values, org) => {
    const childrenStores = (stores || [])
      .filter((s) => s.orgId === org.id)
      .filter((s) => !isStoreImportFailed(mapUserStores[s.storeId]))
      .map((s) => ({
        ...s,
        value: s.storeId,
        label: `${s.storeName}${!s.syncCompleted ? ' (Syncing...)' : ''}`,
      }))

    if (childrenStores.length === 0) return values

    values.push({ value: org.id, label: org.name, title: true })
    return values.concat(childrenStores)
  }, [])

export const convertStoreListOptions = (organizations, stores) =>
  (organizations || []).reduce((values, org) => {
    const childrenStores = (stores || [])
      .filter((s) => s.orgId === org.id)
      .map((s) => ({
        ...s,
        value: s.storeId,
        label: s.storeName,
      }))

    if (childrenStores.length === 0) return values

    values.push({
      value: org.id,
      label: org.name,
      title: true,
      isGroup: true,
    })
    return values.concat(childrenStores)
  }, [])

// eslint-disable-next-line
export const convertLocationListOptions = (organizations, stores, locations) =>
  (organizations || []).reduce((values, org) => {
    let childrenStores = (stores || [])
      .filter((s) => s.orgId === org.id)
      .map((s) => ({
        ...s,
        value: s.storeId,
        label: s.storeName,
      }))

    if (childrenStores?.some((s) => s.storeType === STORE_TYPES.GREENLINE)) {
      const store = childrenStores[0]
      const warehouses = locations?.find((o) => o._id === store.orgId)
        ?.stores?.[0]?.warehouses
      childrenStores = (warehouses || [])?.map((w) => ({
        ...w,
        storeType: STORE_TYPES.GREENLINE,
        value: w.location_id,
        label: w.name,
      }))
    }
    if (childrenStores?.some((s) => s.storeType === STORE_TYPES.COVA)) {
      const store = childrenStores[0]
      const warehouses = locations?.find((o) => o._id === store.orgId)
        ?.stores?.[0]?.warehouses
      childrenStores = (warehouses || [])?.map((w) => ({
        ...w,
        storeType: STORE_TYPES.COVA,
        value: w?.cova_metadata?.LocationId,
        label: w.name,
      }))
    }
    if (childrenStores.length === 0) return values
    values.push({
      value: org.id,
      label: org.name,
      title: true,
      isGroup: true,
    })
    return values.concat(childrenStores)
  }, [])

export const mapSettingData = ({
  user_settings,
  default_org: init_default_org,
  default_store: init_default_store,
  default_location: init_default_location,
}) => {
  if (user_settings) {
    const {
      general_settings: { default_org, default_store, default_location },
      store_settings: [
        {
          display_settings: { filters, list_pagination, product_view },
          fulfillment_settings: { calendar_view, printing_size },
          timezone_settings: { setting: tz_setting, timezone: tz_timezone },
        },
      ],
    } = user_settings

    return {
      default_org,
      default_store,
      default_location,
      filters,
      list_pagination,
      product_view,
      calendar_view,
      printing_size,
      tz_setting,
      tz_timezone,
    }
  }
  return {
    default_org: init_default_org,
    default_store: init_default_store,
    default_location: init_default_location,
    filters: DEFAULT_FILTER_VIEW,
    list_pagination: DEFAULT_PAGE_SIZE,
    product_view: '',
    calendar_view: DEFAULT_CALENDAR_VIEW,
    printing_size: DEFAULT_PRINTING_SIZE,
    tz_setting: DEFAULT_TIMEZONE_SETTING,
    tz_timezone: DEFAULT_MANUALLY_TIMEZONE,
  }
}

export const mapUserData = (data) => ({
  user_settings: mapSettingData(data),
})

export const mapUpdateSettingData = ({
  default_org,
  default_store,
  filters,
  list_pagination,
  product_view,
  calendar_view,
  printing_size,
  tz_setting,
  tz_timezone,
}) => ({
  general_settings: { default_org, default_store },
  store_settings: {
    display_settings: {
      filters,
      list_pagination,
      product_view,
    },
    fulfillment_settings: {
      calendar_view,
      printing_size,
    },
    timezone_settings: {
      setting: tz_setting,
      timezone: tz_timezone,
    },
    store_id: '',
  },
})

export const mapOrganizationData = ({
  organization_name,
  organization_id,
  _created,
  profile_type,
}) => ({
  organization_name,
  organization_id,
  _created,
  profile_type,
})
