import logger from '~/utils/logger'

export const ERROR_CODES = {
  INVITATION_EXPIRED: 4001,
  INVALID_LOGIN_CREDENTIALS: 'INVALID_LOGIN_CREDENTIALS',
  UNSUPPORTED_FIRST_FACTOR: 'auth/unsupported-first-factor',
  INVALID_PHONE_NUMBER: 'auth/invalid-phone-number',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  CODE_EXPIRED: 'auth/code-expired',
  INVALID_VERIFICATION_CODE: 'auth/invalid-verification-code',
  REQUIRE_LOGIN_AGAIN: 'auth/requires-recent-login',
  CREDENTIAL_TOO_OLD_LOGIN_AGAIN: 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN',
  INVALID_MULTI_FACTOR_SESSION: 'auth/invalid-multi-factor-session',
  AUTH_INTERNAL_ERROR: 'auth/internal-error',
}

export const formatFirebaseError = (error, resetPw = false) => {
  logger.info(error, 'error firebase')
  switch (true) {
    case error?.message?.includes(ERROR_CODES.INVALID_LOGIN_CREDENTIALS):
      return resetPw
        ? 'Current Password is incorrect'
        : 'Email/Password is incorrect'
    case error?.message?.includes('(auth/too-many-requests)'):
      return 'Access to this account has been temporarily disabled due to many failed change password attempts.'
    case error?.message?.includes('(auth/wrong-password)'):
      return 'Incorrect password'
    case error?.message?.includes('(auth/user-not-found)'):
      return resetPw
        ? 'Current Password is incorrect'
        : 'Email/Password is incorrect'
    case error?.message?.includes('(auth/requires-recent-login)'):
      return 'Your credential has been expired, please login again'
    case error?.message?.includes('(auth/email-already-in-use)'):
      return 'The email address is already in use by another account.'
    case error?.message?.includes('(auth/invalid-email)'):
      return 'The email address is not valid.'
    case error?.message?.includes('(auth/operation-not-allowed)'):
      return 'Email/password accounts are not enabled. Enable email/password in the Firebase Console, under the Auth tab.'
    case error?.message?.includes('(auth/weak-password)'):
      return 'The password is not strong enough.'
    case error?.message?.includes('(auth/invalid-login-credentials)'):
      return resetPw
        ? 'Current Password is incorrect'
        : 'Email/Password is incorrect'
    case error?.message?.includes(
      '(auth/account-exists-with-different-credential)',
    ):
      return 'An account already exists with the same email address but different sign-in credentials.'
    case error?.code === ERROR_CODES.INVALID_PHONE_NUMBER:
      return 'Invalid phone number'
    case error?.code === ERROR_CODES.TOO_MANY_REQUESTS:
      return 'Too many resent code requests. Please try again later.'
    case error?.code === ERROR_CODES.INVALID_VERIFICATION_CODE:
    case error?.code === ERROR_CODES.CODE_EXPIRED:
      return 'Verification code is invalid or expired.'
    // case error?.code === ERROR_CODES.UNSUPPORTED_FIRST_FACTOR:
    //   return 'Please login again to enable 2 Factor Authentication'
    // case error?.code === ERROR_CODES.REQUIRE_LOGIN_AGAIN:
    //   return 'This action is security sensitive. Please login again before disabling 2 Factor Authentication.'
    case error?.message === 'Internal error encountered.':
    case error?.message?.includes('SESSION_EXPIRED'):
    case error?.code === ERROR_CODES.INVALID_MULTI_FACTOR_SESSION:
    case error?.code === ERROR_CODES.AUTH_INTERNAL_ERROR:
      return 'Service is temporary unavailable. Please try again later.'
    default:
      return error?.message || error
  }
}
