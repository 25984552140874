import { makeStyles } from '@mui/styles'
import { CustomContentProps, useSnackbar } from 'notistack'
import React from 'react'
import { COLORS } from '~/themes/common'
import storeStartImporting from '~/assets/icons/start_importing.svg'
import storeFinishImporting from '~/assets/icons/finish_importing.svg'
import CloseIcon from '@mui/icons-material/Close'
import {
  StyledCloseButton,
  StyledIcon,
  StyledNotificationContainer,
  StyledNotificationContent,
  StyledTitle,
} from './common'

interface ImportStoreNotificationProps extends CustomContentProps {
  data: any
}

const useStyles = makeStyles(() => ({
  descRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: COLORS.gray.textGray1,
    fontSize: 12,
    lineHeight: '20px',
  },
  shownTime: {
    minWidth: 60,
  },
  importStore: {
    flex: 1,
    padding: '0px 8px 0px 8px',
  },
}))

const icons = {
  start_importing: storeStartImporting,
  finish_importing: storeFinishImporting,
}

const ImportStore = React.forwardRef<
  HTMLDivElement,
  ImportStoreNotificationProps
>(({ id, data }, ref) => {
  const classes = useStyles()
  const {
    data: { message, title, status },
    shownTime,
  } = data
  const { closeSnackbar } = useSnackbar()

  return (
    <StyledNotificationContainer ref={ref} role="presentation">
      <StyledNotificationContent>
        <StyledIcon>
          <img src={icons[status]} alt="" />
        </StyledIcon>
        <div className={classes.importStore}>
          <div className={classes.descRow}>
            <StyledTitle>{title}</StyledTitle>
            <div>
              <StyledCloseButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  closeSnackbar(id)
                }}
              >
                <CloseIcon />
              </StyledCloseButton>
            </div>
          </div>
          <div>
            <div className={classes.descRow}>
              <div>{message}</div>
              <div className={classes.shownTime}>{shownTime}</div>
            </div>
          </div>
        </div>
      </StyledNotificationContent>
    </StyledNotificationContainer>
  )
})

export default ImportStore
