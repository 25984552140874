import produce from 'immer'

import { BILL_ACTIONS } from '../actions/bill'
import { DEFAULT_BILL_LIST_DATA, DEFAULT_BILL_DETAIL } from '../default/bill'

const initialState = {
  data: DEFAULT_BILL_LIST_DATA,
  loading: false,
  error: null,

  detail: DEFAULT_BILL_DETAIL,
  loadingDetail: false,
  errorDetail: null,

  stores: [],
  loadingStores: false,
  errorStores: null,
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case BILL_ACTIONS.GET_BILL_LIST:
        draft.loading = true
        draft.error = null
        break
      case BILL_ACTIONS.GET_BILL_LIST_OK:
        draft.data = {
          ...draft.data,
          items: payload,
          total_items: payload.length,
        }
        draft.loading = false
        break
      case BILL_ACTIONS.GET_BILL_LIST_ERR:
        draft.loading = false
        draft.error = payload
        break

      case BILL_ACTIONS.LOCAL_PAGINATE:
        draft.data = {
          ...draft.data,
          total_pages: Math.ceil(draft.data.items.length / payload.pagination),
          page: payload.page,
          pageItems: draft.data.items.slice(
            payload.pagination * (payload.page - 1),
            payload.pagination * payload.page,
          ),
        }
        break

      case BILL_ACTIONS.GET_BILL_DETAIL:
        draft.loadingDetail = true
        draft.errorDetail = null
        break
      case BILL_ACTIONS.GET_BILL_DETAIL_OK:
        draft.detail = payload
        draft.loadingDetail = false
        break
      case BILL_ACTIONS.GET_BILL_DETAIL_ERR:
        draft.loadingDetail = false
        draft.errorDetail = payload
        break

      case BILL_ACTIONS.GET_BILL_STORES:
        draft.loadingStores = true
        draft.errorStores = null
        break
      case BILL_ACTIONS.GET_BILL_STORES_OK:
        draft.stores = payload
        draft.loadingStores = false
        break
      case BILL_ACTIONS.GET_BILL_STORES_ERR:
        draft.loadingStores = false
        draft.errorStores = payload
        break

      default:
        break
    }
  })

export default reducer
