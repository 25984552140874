import { Avatar } from '@mui/material'
import { CustomContentProps, useSnackbar } from 'notistack'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import {
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledDesc,
  StyledIcon,
  StyledInfo,
  StyledMessage,
  StyledMeta,
  StyledNotificationContainer,
  StyledNotificationContent,
  StyledTime,
  StyledTitle,
} from './common'

interface RepeatOrderNotificationProps extends CustomContentProps {
  data: any
}

const RepeatOrder = React.forwardRef<
  HTMLDivElement,
  RepeatOrderNotificationProps
>(({ id, data }, ref) => {
  const {
    data: { message, title, desc, path },
    shownTime,
  } = data
  const { closeSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleClick = () => {
    closeSnackbar(id)
    navigate(path)
  }

  return (
    <StyledNotificationContainer
      ref={ref}
      role="presentation"
      onClick={handleClick}
    >
      <StyledMeta>
        <StyledMessage className="highlight">{message}</StyledMessage>
        <StyledTime>{shownTime}</StyledTime>
      </StyledMeta>
      <StyledNotificationContent>
        <StyledIcon>
          <img src={''} alt="" />
        </StyledIcon>
        <StyledInfo>
          <StyledTitle>{title}</StyledTitle>
          <StyledDesc>{desc}</StyledDesc>
        </StyledInfo>
        <StyledCloseButtonContainer>
          <StyledCloseButton
            type="button"
            onClick={(e) => {
              e.stopPropagation()
              closeSnackbar(id)
            }}
          >
            <CloseIcon />
          </StyledCloseButton>
        </StyledCloseButtonContainer>
      </StyledNotificationContent>
    </StyledNotificationContainer>
  )
})

export default RepeatOrder
