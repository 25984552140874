import { lazy } from 'react'
import { RETRY_LAZY_REFRESHED } from '~/constants/localStorage'
import {
  set as setLocalStorage,
  get as getLocalStorage,
} from '~/utils/storage/localStorage'

export const lazyRetry = (componentImport) =>
  lazy(
    () =>
      new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
          getLocalStorage(RETRY_LAZY_REFRESHED) || 'false',
        )
        // try to import the component
        componentImport()
          .then((component) => {
            setLocalStorage(RETRY_LAZY_REFRESHED, 'false')
            resolve(component)
            // eslint-disable-next-line consistent-return
          })
          .catch((error) => {
            if (!hasRefreshed) {
              // not been refreshed yet
              setLocalStorage(RETRY_LAZY_REFRESHED, 'true')
              window.location.reload()
            }
            reject(error) // Default error behaviour as already tried refresh
          })
      }),
  )
