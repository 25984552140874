import { takeLatest, all, fork, call, put } from 'redux-saga/effects'
import cantecFulfillmentApi from '~/services/apis/cantecFulfillment'
import calendarApi from '~/services/apis/calendar'
import Actions, {
  CANTEC_FULFILLMENT_ACTION,
} from '~/redux/actions/cantecFulfillment'

export function* fetchPendingPickingOrders(action) {
  try {
    const resp = yield call(
      cantecFulfillmentApi.getPendingOrders,
      action.payload,
    )
    yield put(Actions.setPendingPicking(resp))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.setPendingPickingError(e))
  }
}

export function* fetchPendingPickupOrders(action) {
  try {
    const resp = yield call(
      cantecFulfillmentApi.getPendingOrders,
      action.payload,
    )
    const { data: items, ...otherResp } = resp
    yield put(Actions.setPendingPickup({ items, ...otherResp }))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.setPendingPickupError(e))
  }
}

export function* fetchPickedUpOrders(action) {
  try {
    const resp = yield call(
      cantecFulfillmentApi.getPickedUpOrders,
      action.payload,
    )
    yield put(Actions.setPickedUp(resp, action.extraParams.isLoadingMore))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.setPickedUpError(e))
  }
}

export function* fetchLinkWarehouses(action) {
  try {
    const resp = yield call(
      cantecFulfillmentApi.getLinkedWarehouses,
      action.payload,
    )
    yield put(Actions.setLinkWarehouses(resp))
  } catch (e) {
    yield put(Actions.setLinkWarehousError(e))
  }
}

export function* fetchOnlineDrivers(action) {
  try {
    const resp = yield call(
      cantecFulfillmentApi.getOnlineDrivers,
      action.payload,
    )
    yield put(Actions.setOnlineDrivers(resp))
  } catch (e) {
    yield put(Actions.setOnlineDriverError(e))
  }
}

export function* fetchCountOrders(action) {
  try {
    const resp = yield call(cantecFulfillmentApi.countOrders, action.payload)
    yield put(Actions.setCountOrders(resp))
  } catch (e) {
    yield put(Actions.setCountOrderError(e))
  }
}

export function* fetchIncompletedOrders(action) {
  try {
    const resp = yield call(calendarApi.countOrders, action.payload)
    yield put(Actions.setIncompletedOrders(resp))
  } catch (e) {
    yield put(Actions.setIncompletedOrderError(e))
  }
}

export function* watchCantecFulfillment() {
  yield all([
    takeLatest(
      CANTEC_FULFILLMENT_ACTION.FETCH_PENDING_PICKING_LIST,
      fetchPendingPickingOrders,
    ),
    takeLatest(
      CANTEC_FULFILLMENT_ACTION.FETCH_PENDING_PICKUP_LIST,
      fetchPendingPickupOrders,
    ),
    takeLatest(
      CANTEC_FULFILLMENT_ACTION.FETCH_PICKED_UP_LIST,
      fetchPickedUpOrders,
    ),
    takeLatest(
      CANTEC_FULFILLMENT_ACTION.FETCH_LINK_WAREHOUSES,
      fetchLinkWarehouses,
    ),
    takeLatest(
      CANTEC_FULFILLMENT_ACTION.FETCH_ONLINE_DRIVERS,
      fetchOnlineDrivers,
    ),
    takeLatest(CANTEC_FULFILLMENT_ACTION.FETCH_COUNT_ORDERS, fetchCountOrders),
    takeLatest(
      CANTEC_FULFILLMENT_ACTION.FETCH_INCOMPLETED_ORDERS,
      fetchIncompletedOrders,
    ),
  ])
}

export default function* cantecFulfillment() {
  yield fork(watchCantecFulfillment)
}
