export const FILTER_TYPE = {
  ORDER: 'order',
  CUSTOMER: 'customer',
  PRODUCT_PERFORMANCE: 'product_performance',
  POS_ORDER: 'pos_order',
  COVA_CUSTOMER: 'cova_customer',
  GREENLINE_CUSTOMER: 'greenline_customer',
  SHOPIFY_CUSTOMER: 'shopify_customer',
}

export const QUICK_EDIT_FROM = {
  PERFORMANCE: 'performance',
  PRODUCTS: 'products',
}

export const FILTER_VALUE = {
  SIMPLE_FILTER: 'simple_filter',
  ADVANCED_FILTER: 'advanced_filter',
}

export const FILTER_NAME = {
  [FILTER_VALUE.SIMPLE_FILTER]: 'Search',
  [FILTER_VALUE.ADVANCED_FILTER]: 'Filter',
}

export const ACCEPT_TYPES = [
  'txt',
  'csv',
  'css',
  'isc',
  'jpg',
  'jpe',
  'jpeg',
  'gif',
  'png',
  'bmp',
  'psd',
  'tif',
  'tiff',
  'indd',
  'ai',
  'eps',
  'svg',
  'doc',
  'docx',
  'rtf',
  'odt',
  'ott',
  'pdf',
  'pub',
  'pages',
  'mobi',
  'epub',
  'zip',
  'vcf',
  'rar',
  'x-rar',
  'mp4',
  'quicktime',
  'mov',
  'mp3',
  'avi',
]

export const IMAGES_FILE_TYPES = [
  'jpg',
  'jpe',
  'jpeg',
  'gif',
  'png',
  'bmp',
  'psd',
  'tif',
  'tiff',
  'indd',
  'ai',
  'eps',
]

export const VANCOUVER_TZ = 'America/Vancouver'

export const DEFAULT_FILTER = ['limit', 'page', 'sort', 'sort_by']
