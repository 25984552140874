/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import NavContext from './context'
import CustomDialog from '~/components/common/Dialog/CustomDialog'
import versionApis from '~/services/apis/version'
import pubsub from '~/utils/pushNotification/pubsub'
import * as SessionStorageManager from '~/utils/sessionStorage/sessionStorageManager'
import { Notification } from '~/utils/sessionStorage/sessionStorageKey'
import Version from '~/assets/version.json'
import { REACT_APP_MODE } from '~/constants/environment'

const defaultMsg = {
  description:
    'If you leave this page, unsaved changes will be lost.\nAre you sure you want to continue?',
  icon: 'warn',
  title: 'Warning',
  cancelText: 'Cancel',
  okText: 'Yes, Leave',
}

const NavProvider = ({ children }) => {
  const [isBlocking, setB] = React.useState(false)
  const confirmedNavigation = React.useRef(false)
  const exceptionHandler = React.useRef(null)
  const nextNavigateStateHandler = React.useRef(null)
  const onBeforeNavigateHandler = React.useRef(null)
  const [isShowConfirm, setShowConfirmDialog] = React.useState(false)
  const [confirmParams, setConfirmParams] = React.useState()
  const [specialDialogParams, setSpecialDialogParams] = React.useState()
  const storeHandler = React.useRef({})
  const beforeUnloadEvent = React.useRef(null)

  const setBlockNavigation = (x) => {
    setB(x)
  }

  const registerChangeStoreHandler = useCallback((func) => {
    if (!func) {
      storeHandler.current.onChangeStoreHandler = null
      return
    }
    if (typeof func !== 'function') {
      throw new Error('Expected the param to be a function.')
    }
    storeHandler.current.onChangeStoreHandler = func

    // unregister customer action change store
    return () => {
      storeHandler.current.onChangeStoreHandler = null
    }
  }, [])

  const showModal = useCallback(
    (params) => {
      setSpecialDialogParams(params)
      setShowConfirmDialog(true)
    },
    [setShowConfirmDialog],
  )

  const setException = useCallback((e) => {
    if (!e) {
      exceptionHandler.current = null
      return
    }
    if (typeof e !== 'function') {
      throw new Error('Expected the param to be a function.')
    }
    exceptionHandler.current = e
  }, [])

  const setBeforeNavigateHandler = useCallback((func) => {
    if (!func) {
      onBeforeNavigateHandler.current = null
      return
    }
    if (typeof func !== 'function') {
      throw new Error('Expected the param to be a function.')
    }
    onBeforeNavigateHandler.current = func
  }, [])

  const dialogParams = React.useMemo(
    () => ({
      ...defaultMsg,
      ...confirmParams,
      ...specialDialogParams,
    }),
    [confirmParams, specialDialogParams],
  )

  const confirmDialogHandler = React.useCallback(() => {
    setShowConfirmDialog(false)
    confirmedNavigation.current = true
    if (nextNavigateStateHandler.current) {
      setBlockNavigation(false)
      nextNavigateStateHandler.current(window.history)
    }
    if (dialogParams && dialogParams.onOk) {
      dialogParams.onOk()
      // TODO: recheck when reset confirmedNavigation.current
      confirmedNavigation.current = false
      exceptionHandler.current = null
      onBeforeNavigateHandler.current = null
    }
    setSpecialDialogParams(null)
  }, [setShowConfirmDialog, setBlockNavigation, dialogParams])

  const cancelDialogHandler = React.useCallback(() => {
    setShowConfirmDialog(false)
    setSpecialDialogParams(null)
  }, [setShowConfirmDialog])

  const checkCurrentVersion = React.useCallback(() => {
    if (REACT_APP_MODE === 'development') return
    versionApis
      .getHash()
      .then((data) => {
        const currentVersion = Version.hash
        if (data.hash !== currentVersion) {
          SessionStorageManager.storeSessionWithKey(
            Notification.APP_UPDATED,
            'true',
          )
          setBlockNavigation(false)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
        // eslint-disable-next-line
      })
      .catch((e) => {})
  }, [setBlockNavigation])

  React.useEffect(() => {
    window.addEventListener('focus', () => {
      checkCurrentVersion()
    })
    const timer = setInterval(
      () => {
        checkCurrentVersion()
      },
      60 * 60 * 1000,
    )
    return () => {
      clearInterval(timer)
    }
  }, [])

  React.useEffect(() => {
    if (
      SessionStorageManager.getSessionWithKey(Notification.APP_UPDATED) ===
      'true'
    ) {
      SessionStorageManager.storeSessionWithKey(
        Notification.APP_UPDATED,
        'false',
      )
      setTimeout(() => {
        pubsub.emit('message', {
          type: 'app_updated',
        })
      }, [2000])
    }
  }, [])

  React.useEffect(() => {
    if (!isBlocking) {
      if (beforeUnloadEvent.current) {
        window.removeEventListener('beforeunload', beforeUnloadEvent.current, {
          passive: true,
        })
      }
      return () => {}
    }

    beforeUnloadEvent.current = (e) => {
      const msg = dialogParams?.description
      e.returnValue = msg // eslint-disable-line
      return msg
    }

    window.addEventListener('beforeunload', beforeUnloadEvent.current, {
      passive: true,
    })

    return () => {
      if (beforeUnloadEvent.current) {
        window.removeEventListener('beforeunload', beforeUnloadEvent.current, {
          passive: true,
        })
      }
    }
  }, [isBlocking])

  const values = React.useMemo(
    () => ({
      isBlocking,
      setBlockNavigation,
      setException,
      setConfirmParams,
      setBeforeNavigateHandler,
      showModal,
      storeHandler: storeHandler.current,
      registerChangeStoreHandler,
      isShowConfirm,
    }),
    [
      isBlocking,
      setBlockNavigation,
      setException,
      setConfirmParams,
      setBeforeNavigateHandler,
      showModal,
      storeHandler,
    ],
  )

  return (
    <>
      <NavContext.Provider value={values}>{children}</NavContext.Provider>
      <CustomDialog
        open={isShowConfirm}
        closeOnClick
        {...dialogParams}
        onClose={cancelDialogHandler}
        onOk={confirmDialogHandler}
      />
    </>
  )
}

NavProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NavProvider
