import { takeLatest, put, fork, call } from 'redux-saga/effects'
import paymentApi from '~/services/apis/payment'
import { paymentAction } from '~/redux/actions/payment'
import { showDialog } from '~/components/common/Dialog'
import { dialogParams } from '~/components/common/Dialog/CustomDialog'

export function* getPayment(action) {
  try {
    const response = yield call(paymentApi.getPaymentMethods, action.payload)
    yield put({
      type: paymentAction.GET_PAYMENT_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: paymentAction.GET_PAYMENT_ERR,
      payload: error,
    })
  }
}

export function* updatePayment(action) {
  try {
    const response = yield call(paymentApi.updatePaymentStatus, action.payload)
    yield put({
      type: paymentAction.UPDATE_PAYMENT_SUCCESS,
      payload: response,
    })
    showDialog(dialogParams.changesSaved)
  } catch (error) {
    yield put({
      type: paymentAction.UPDATE_PAYMENT_ERR,
      payload: error,
    })
    showDialog(dialogParams.error(error))
  }
}

export function* reorderPayment(action) {
  try {
    const response = yield call(
      paymentApi.reorderPaymentMethods,
      action.payload,
    )
    yield put({
      type: paymentAction.REORDER_PAYMENT_SUCCESS,
      payload: response,
    })
    showDialog(dialogParams.changesSaved)
  } catch (error) {
    yield put({
      type: paymentAction.REORDER_PAYMENT_ERR,
      payload: error,
    })
    showDialog(dialogParams.error(error))
  }
}

export function* syncWoocPayment(action) {
  try {
    const response = yield call(
      paymentApi.syncPaymentMethodsWithWoo,
      action.payload,
    )
    yield put({
      type: paymentAction.SYNC_WCM_PAYMENT_SUCCESS,
      payload: response,
    })
    showDialog(dialogParams.syncWithWooSuccess)
  } catch (error) {
    yield put({
      type: paymentAction.SYNC_WCM_PAYMENT_ERR,
      payload: error,
    })
    showDialog(dialogParams.error(error))
  }
}

export function* watchGetListPayment() {
  yield takeLatest(paymentAction.GET_PAYMENT, getPayment)
}

export function* watchUpdatePayment() {
  yield takeLatest(paymentAction.UPDATE_PAYMENT, updatePayment)
}

export function* watchReorderPayment() {
  yield takeLatest(paymentAction.REORDER_PAYMENT, reorderPayment)
}

export function* watchSyncWoocPayment() {
  yield takeLatest(paymentAction.SYNC_WCM_PAYMENT, syncWoocPayment)
}

export default function* reward() {
  yield fork(watchGetListPayment)
  yield fork(watchUpdatePayment)
  yield fork(watchReorderPayment)
  yield fork(watchSyncWoocPayment)
}
