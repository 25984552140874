import { fork, call, put, all, takeLatest } from 'redux-saga/effects'
import analyticApi from '~/services/apis/analytics'
import { ANALYTIC_ACTION } from '~/redux/actions/analytic'

export function* fetchAllCustomReports() {
  try {
    const resp = yield call(analyticApi.getAllCustomReport)
    yield put({
      type: ANALYTIC_ACTION.FETCH_CUSTOM_ALL_SUCCESS,
      payload: resp,
    })
  } catch (e) {
    yield put({
      type: ANALYTIC_ACTION.FETCH_CUSTOM_ALL_ERROR,
      payload: e,
    })
  }
}

export function* fetchDefaultReport(actions) {
  try {
    const resp = yield call(analyticApi.getDefaultReport, actions.payload)
    yield put({
      type: ANALYTIC_ACTION.FETCH_DEFAULT_REPORT_SUCCESS,
      payload: resp,
    })
  } catch (e) {
    yield put({
      type: ANALYTIC_ACTION.FETCH_DEFAULT_REPORT_ERROR,
      payload: e,
    })
  }
}

export function* createCustomReport({ payload, callbacks }) {
  try {
    const res = yield call(analyticApi.createCustomReport, payload)
    yield put({
      type: ANALYTIC_ACTION.CREATE_CUSTOM_REPORT_SUCCESS,
    })
    yield callbacks.onSuccess(res._id)
  } catch (e) {
    yield put({
      type: ANALYTIC_ACTION.CREATE_CUSTOM_REPORT_ERROR,
    })
    yield callbacks.onError(e.description[0])
  }
}

export function* updateCustomReport({ payload, callbacks }) {
  try {
    yield call(analyticApi.update, payload)
    yield put({
      type: ANALYTIC_ACTION.EDIT_CUSTOM_REPORT_SUCCESS,
    })
    yield callbacks.onSuccess(payload._id)
  } catch (e) {
    yield put({
      type: ANALYTIC_ACTION.EDIT_CUSTOM_REPORT_ERROR,
    })
    yield callbacks.onError(e.description[0])
  }
}

export function* deleteCustomReport({ payload, callbacks }) {
  try {
    yield call(analyticApi.remove, payload)
    yield put({
      type: ANALYTIC_ACTION.DELETE_CUSTOM_REPORT_SUCCESS,
    })
    yield callbacks.onSuccess()
  } catch (e) {
    yield put({
      type: ANALYTIC_ACTION.DELETE_CUSTOM_REPORT_ERROR,
    })
    yield callbacks.onError(e.description[0])
  }
}

export function* watchAnalytic() {
  yield all([
    takeLatest(ANALYTIC_ACTION.FETCH_CUSTOM_ALL, fetchAllCustomReports),
    takeLatest(ANALYTIC_ACTION.FETCH_DEFAULT_REPORT, fetchDefaultReport),
    takeLatest(ANALYTIC_ACTION.CREATE_CUSTOM_REPORT, createCustomReport),
    takeLatest(ANALYTIC_ACTION.EDIT_CUSTOM_REPORT, updateCustomReport),
    takeLatest(ANALYTIC_ACTION.DELETE_CUSTOM_REPORT, deleteCustomReport),
  ])
}

export default function* analytic() {
  yield fork(watchAnalytic)
}
