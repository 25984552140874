import audioNewOrder from '~/assets/audio/notify.aac'
import audioPendingOrder from '~/assets/audio/notify-repeat.aac'

export const playNewOrderSound = () => {
  const audioElement = new Audio(audioNewOrder)
  audioElement.addEventListener('canplaythrough', () => {
    audioElement.play().catch(() => {
      // follow https://developer.chrome.com/blog/autoplay/
    })
  })
}

export const playRepeatSound = () => {
  const audioElement = new Audio(audioPendingOrder)
  audioElement.addEventListener('canplaythrough', () => {
    audioElement.play().catch(() => {
      // follow https://developer.chrome.com/blog/autoplay/
    })
  })
}
