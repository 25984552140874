import { useSelector, useDispatch } from 'react-redux'
import { useMemo, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import _get from 'lodash/get'
import {
  getPendingPickingList,
  getPendingPickingListLoading,
  getPendingPickingListError,
  getPendingPickupList,
  getPendingPickupListLoading,
  getPendingPickupListError,
  getPickedUpList,
  getPickedUpListLoading,
  getPickedUpListError,
  getLinkWarehouseList,
  getLinkWarehouseLoading,
  getLinkWarehouseError,
  getOnlineDriverList,
  getOnlineDriverLoading,
  getOnlineDriverError,
  getCantecStatistic,
  getIncompletedOrders,
} from '../selectors/cantecFulfillment'
import cantecFulfillmentActions from '../actions/cantecFulfillment'
import {
  mapPendingPickingCantecOrders,
  mapPendingPickupCantecOrders,
  mapPickedUpCantecOrders,
} from '~/utils/fulfillment'

const sortedFulfillmentList = (list = []) => {
  // Handling time is faster than .sort((a, b) => (Date.parse(b.section_date || 0)) - (Date.parse(a.section_date || 0)))
  const sortedData = list
    .map((item) => ({
      ...item,
      timestamp: Date.parse(item?.section_date || 0),
    }))
    .sort((a, b) => b?.timestamp - a?.timestamp)
    .map(({ timestamp, ...rest }) => rest)

  return sortedData
}

export const usePendingPickingList = () => {
  const pendingPickingListData = useSelector(getPendingPickingList)
  const loading = useSelector(getPendingPickingListLoading)
  const error = useSelector(getPendingPickingListError)
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(cantecFulfillmentActions, dispatch),
    [dispatch],
  )

  return useMemo(
    () => ({
      actions,
      pendingPickingListData: {
        ...pendingPickingListData,
        items: sortedFulfillmentList(
          mapPendingPickingCantecOrders(pendingPickingListData.items),
        ),
      },
      loading,
      error,
    }),
    [actions, pendingPickingListData, loading, error],
  )
}

export const usePendingPickupList = () => {
  const pendingPickupListData = useSelector(getPendingPickupList)
  const loading = useSelector(getPendingPickupListLoading)
  const error = useSelector(getPendingPickupListError)
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(cantecFulfillmentActions, dispatch),
    [dispatch],
  )

  return useMemo(
    () => ({
      actions,
      pendingPickupListData: {
        ...pendingPickupListData,
        items: sortedFulfillmentList(
          mapPendingPickupCantecOrders(pendingPickupListData.items),
        ),
      },
      loading,
      error,
    }),
    [actions, pendingPickupListData, loading, error],
  )
}

export const usePickedUpList = () => {
  const pickedUpListData = useSelector(getPickedUpList)
  const loading = useSelector(getPickedUpListLoading)
  const error = useSelector(getPickedUpListError)
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(cantecFulfillmentActions, dispatch),
    [dispatch],
  )

  return useMemo(
    () => ({
      actions,
      pickedUpListData: {
        ...pickedUpListData,
        items: mapPickedUpCantecOrders(pickedUpListData.items),
      },
      loading,
      error,
    }),
    [actions, pickedUpListData, loading, error],
  )
}

export const useLinkWarehouseList = () => {
  const linkWarehouses = useSelector(getLinkWarehouseList)
  const loading = useSelector(getLinkWarehouseLoading)
  const error = useSelector(getLinkWarehouseError)
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(cantecFulfillmentActions, dispatch),
    [dispatch],
  )

  return useMemo(
    () => ({
      actions,
      linkWarehouses,
      loading,
      error,
    }),
    [actions, linkWarehouses, loading, error],
  )
}

export const useOnlineDriverList = () => {
  const onlineDrivers = useSelector(getOnlineDriverList)
  const loading = useSelector(getOnlineDriverLoading)
  const error = useSelector(getOnlineDriverError)
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(cantecFulfillmentActions, dispatch),
    [dispatch],
  )

  return useMemo(
    () => ({
      actions,
      onlineDrivers,
      loading,
      error,
    }),
    [actions, onlineDrivers, loading, error],
  )
}

export const useCantecStatistic = () => {
  const cantecStatistic = useSelector(getCantecStatistic)
  return useMemo(
    () => ({
      cantecStatistic,
    }),
    [cantecStatistic],
  )
}

export const useIncompletedOrdersOfSpecificStore = (storeId) => {
  const incompletedOrders = useSelector(getIncompletedOrders)
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(cantecFulfillmentActions, dispatch),
    [dispatch],
  )
  const incompletedOrdersOfSpecificStore = incompletedOrders.find(
    (item) => item.store_id === storeId,
  )
  return useMemo(
    () => ({
      actions,
      incompletedOrdersOfSpecificStore,
    }),
    [incompletedOrders],
  )
}

export const useCountIncompletedOrdersOfSpecificStore = (storeId) => {
  const incompletedOrders = useSelector(getIncompletedOrders)
  const incompletedOrdersOfSpecificStore = incompletedOrders.find(
    (item) => item.store_id === storeId,
  )
  const countCantecDelivery =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.cantec_delivery', [])
      .length +
    _get(incompletedOrdersOfSpecificStore, 'processing.cantec_delivery', [])
      .length
  const countCantecLocalPickup =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.local_pickup', []).length +
    _get(incompletedOrdersOfSpecificStore, 'processing.local_pickup', []).length
  const countCurbsidePickup =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.curbside_pickup', [])
      .length +
    _get(incompletedOrdersOfSpecificStore, 'processing.curbside_pickup', [])
      .length

  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(cantecFulfillmentActions, dispatch),
    [dispatch],
  )
  return useMemo(
    () => ({
      actions,
      countCantecDelivery,
      countCantecLocalPickup,
      countCurbsidePickup,
    }),
    [countCantecDelivery, countCantecLocalPickup, countCurbsidePickup],
  )
}

export const useHasUnpickedOrders = (storeId) => {
  const incompletedOrders = useSelector(getIncompletedOrders)
  const incompletedOrdersOfSpecificStore = incompletedOrders.find(
    (item) => item.store_id === storeId,
  )
  const countCantecDelivery =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.cantec_delivery', [])
      .length +
    _get(incompletedOrdersOfSpecificStore, 'processing.cantec_delivery', [])
      .length
  const countCanfleetDelivery =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.canfleet_delivery', [])
      .length +
    _get(incompletedOrdersOfSpecificStore, 'processing.canfleet_delivery', [])
      .length
  const countCantecLocalPickup =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.local_pickup', []).length +
    _get(incompletedOrdersOfSpecificStore, 'processing.local_pickup', []).length
  const countCurbsidePickup =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.curbside_pickup', [])
      .length +
    _get(incompletedOrdersOfSpecificStore, 'processing.curbside_pickup', [])
      .length
  const countOnfleetDelivery = _get(
    incompletedOrdersOfSpecificStore,
    'processing.onfleet',
    [],
  ).length
  const countDriveThroughPickup =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.drive_through_pickup', [])
      .length +
    _get(
      incompletedOrdersOfSpecificStore,
      'processing.drive_through_pickup',
      [],
    ).length
  const countMailOrders =
    _get(incompletedOrdersOfSpecificStore, 'on-hold.mail_orders', []).length +
    _get(incompletedOrdersOfSpecificStore, 'processing.mail_orders', []).length

  return useMemo(
    () => ({
      hasUnpickedOrders:
        Number(countCantecDelivery) +
          Number(countCanfleetDelivery) +
          Number(countCantecLocalPickup) +
          Number(countCurbsidePickup) +
          Number(countOnfleetDelivery) +
          Number(countDriveThroughPickup) +
          Number(countMailOrders) >
        0,
      hasUnpickedPickupAndDeliveryOrders:
        Number(countCanfleetDelivery) +
          Number(countCurbsidePickup) +
          Number(countDriveThroughPickup) >
        0,
      hasUnpickedMailOrders: Number(countMailOrders) > 0,
      hasUnpickedOnfleetDeliveryOrders: Number(countOnfleetDelivery) > 0,
    }),
    [
      countCanfleetDelivery,
      countCantecLocalPickup,
      countCurbsidePickup,
      countOnfleetDelivery,
      storeId,
    ],
  )
}

export const useFetchingIncompletedOrders = (storeId) => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(cantecFulfillmentActions, dispatch),
    [dispatch],
  )
  useEffect(() => {
    const fetchIncompletedOrders = () =>
      actions.fetchInCompletedOrders({
        store_id_list: [storeId],
      })

    let intervalId
    if (storeId) {
      fetchIncompletedOrders()
      intervalId = setInterval(() => {
        fetchIncompletedOrders()
      }, 60000 * 5)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [storeId])
}
