import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  SkuTags: 'inventories/tags',
}

export const getList = (params) =>
  httpClient.post(url.SkuTags, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })

const removeOne = (params) =>
  httpClient.post(url.SkuTags, {
    action: ACTIONS.DELETE,
    parameters: params,
  })

const deleteMany = (params) =>
  httpClient.post(url.SkuTags, {
    action: ACTIONS.DELETE_MANY,
    parameters: params,
  })

export const findOne = (params) =>
  httpClient.post(url.SkuTags, {
    action: ACTIONS.READ,
    parameters: params,
  })

export const updateOne = (params) =>
  httpClient.post(url.SkuTags, {
    action: ACTIONS.UPDATE,
    parameters: params,
  })

export const create = (params) =>
  httpClient.post(url.SkuTags, {
    action: ACTIONS.CREATE,
    parameters: params,
  })

const skuTagsApi = {
  getList,
  removeOne,
  findOne,
  updateOne,
  deleteMany,
  create,
}

export default skuTagsApi
