const PUBLIC_KEY =
  'BGRBIxN9X2xpLsMxK5wOYkszQCjMSNTyGZl4jytiN6LadIwJOFTUr2V1_nbbjToBG41Y-XUb3us8YXwJf9Jys64'
const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding)
    .replace(/\-/g, '+') // eslint-disable-line
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    // eslint-disable-line
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
const applicationServerKey = urlBase64ToUint8Array(PUBLIC_KEY)

/**
 * Using the registered service worker creates a push notification subscription
 */
const createSubscription = () =>
  navigator.serviceWorker.ready
    .then((sw) =>
      sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      }),
    )
    .then((sw) => sw)

/**
 * Returns the subscription if present or nothing
 */
const getSubscription = () =>
  navigator.serviceWorker.ready.then((sw) => sw.pushManager.getSubscription())

/**
 * Asks user consent to receive push notifications
 * and returns the response of the user,
 * one of granted, default, denied
 */
const askPermission = () => Notification.requestPermission()

export { createSubscription, getSubscription, askPermission }
