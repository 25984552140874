import { takeLatest, all, fork, call, put } from 'redux-saga/effects'
import Actions, { ONFLEET_ACTION } from '~/redux/actions/onfleet'
import onfleetApi from '~/services/apis/onfleet'
import { showDialog } from '~/components/common/Dialog'
import { dialogParams } from '~/components/common/Dialog/CustomDialog'

const mapOnfleetProcessing = (item) => ({
  ...(item?.cova_meta_data ? { cova_meta_data: item.cova_meta_data } : {}),
  ...(item?.greenline_mapping
    ? { greenline_mapping: item.greenline_mapping }
    : {}),
  id: item.id,
  customerName: item.customer_name,
  customerId: item.customer_id,
  doc: item.doc,
  status: item.status,
  storeId: item.store_id,
  total: item.total_value,
  trackingURL: item.onfleetDeliveryTrackingURL,
  section_date: item.date_created_gmt,
  onfleetAssigned: item.onfleetAssigned,
  receiptNumber: item?.cova_meta_data?.ReceiptNumber,
  twoPointDeliveryTaskId: item?.twoPointDeliveryTaskID,
})

export function* fetchOnfleetStoreList(action) {
  try {
    const resp = yield call(onfleetApi.getOnfleetStore, action.payload)
    yield put(Actions.setOnfleetStoreListData(resp))
  } catch (e) {
    yield put(Actions.setOnfleetStoreListError(e))
  }
}

export function* getOnfleetStoreDetail(action) {
  try {
    const resp = yield call(onfleetApi.getDetailStore, action.payload)
    yield put(Actions.getOnfleetStoreDetailSuccess(resp))
  } catch (error) {
    yield put(Actions.getOnfleetStoreDetailFail(error))
  }
}

export function* updateOnfleetStore(action) {
  try {
    const resp = yield call(onfleetApi.updateOnfleetStore, action.payload)
    yield put(Actions.updateOnfleetStoreOk(resp))
    showDialog(dialogParams.changesSaved)
  } catch (e) {
    showDialog(dialogParams.error(e))
    yield put(Actions.updateOnfleetStoreFail(e))
  }
}

export function* fetchOnfleetProcessing(action) {
  try {
    const resp = yield call(onfleetApi.getOnfleetFulfillment, action.payload)
    const respMapping = resp.reverse().map(mapOnfleetProcessing)
    yield put(Actions.setOnfleetProcessingData(respMapping))
  } catch (e) {
    yield put(Actions.setOnfleetProcessingError(e))
  }
}

export function* refetchOnfleetProcessing(action) {
  try {
    const resp = yield call(onfleetApi.getOnfleetFulfillment, action.payload)
    const respMapping = resp.reverse().map(mapOnfleetProcessing)
    yield put(Actions.setOnfleetProcessingData(respMapping))
  } catch (e) {
    yield put(Actions.setOnfleetProcessingError(e))
  }
}

export function* watchCredential() {
  yield all([
    takeLatest(ONFLEET_ACTION.FETCH_LIST, fetchOnfleetStoreList),
    takeLatest(ONFLEET_ACTION.GET_ONFLEET_STORE_DETAIL, getOnfleetStoreDetail),
    takeLatest(ONFLEET_ACTION.UPDATE_ONFLEET_STORE, updateOnfleetStore),
    takeLatest(ONFLEET_ACTION.FETCH_ONFLEET_PROCESSING, fetchOnfleetProcessing),
    takeLatest(
      ONFLEET_ACTION.REFETCH_ONFLEET_PROCESSING,
      refetchOnfleetProcessing,
    ),
  ])
}

export default function* onfleet() {
  yield fork(watchCredential)
}
