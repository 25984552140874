import { EXPORT_TYPE } from '~/constants/notification'

export function handleExportType(type) {
  if (Object.values(EXPORT_TYPE).includes(type)) return true

  return false
}

export function makeExportCSVNotify(type, export_id) {
  const msg = {
    cmd: 'pushNotification',
    payload: {
      type,
      data: {
        message: 'File Ready',
        title: 'CSV has finished generating.',
        desc: 'Click here to download',
        exportId: export_id,
      },
    },
  }
  return {
    msg,
    title: 'CSV has finished generating.',
    options: {},
  }
}

export function makeOnboardNotify(data) {
  const { type, store_id, store_name, status } = data
  const title =
    status === 'start_importing'
      ? 'Store Start Importing'
      : 'Store Importing Complete'
  const message =
    status === 'start_importing'
      ? `${store_name} is importing`
      : `${store_name} is now ready`
  const msg = {
    cmd: 'pushNotification',
    payload: {
      type,
      data: {
        status,
        message,
        title,
        storeId: store_id,
        ...(status === 'finish_importing'
          ? { path: `/s/${store_id}/home` }
          : {}),
      },
    },
  }

  return {
    msg,
    title,
    options: {
      body: message,
      icon: 'icons/logo_color_simple.png',
      badge: 'icons/logo_color_simple.png',
    },
  }
}

export function makePrivateProductNotify(data) {
  const { type, store_name, store_id } = data
  const msg = {
    cmd: 'pushNotification',
    payload: {
      type,
      data: {
        message: 'Onboarding',
        title: 'Products are set to be private',
        desc: store_name,
        path: `/s/${store_id}/products?status=private`,
      },
    },
  }
  return {
    msg,
    title: 'Onboarding',
    options: {},
  }
}
