import { lazyRetry } from '~/utils/lazyRetry'
import { ADMIN_ROUTES, INVENTORY_ROUTES } from '~/constants/router'

import {
  onlyWooInventoryRoles,
  onlyWooInventoryRolesEditableAll,
  onlyWooInventoryRolesEditableTransferAdjust,
} from '~/modules/auth/conditions'

const Warehouses = lazyRetry(() => import('~/pages/Inventory/Warehouses'))
const CovaWarehouses = lazyRetry(
  () => import('~/pages/Inventory/CovaWarehouses'),
)
const Transfers = lazyRetry(() => import('~/pages/Inventory/Transfers'))
const Adjustments = lazyRetry(() => import('~/pages/Inventory/Adjustments'))

const InventoryRouters = [
  {
    path: `${ADMIN_ROUTES.STORE}${INVENTORY_ROUTES.WAREHOUSES}/*`,
    covaComponent: CovaWarehouses,
    component: Warehouses,
    layout: 'admin',
    exact: true,
    roles: onlyWooInventoryRoles,
    editRoles: onlyWooInventoryRolesEditableAll,
    routeName: 'All Inventories',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${INVENTORY_ROUTES.TRANSFER}/*`,
    component: Transfers,
    layout: 'admin',
    exact: true,
    roles: onlyWooInventoryRoles,
    editRoles: onlyWooInventoryRolesEditableTransferAdjust,
    routeName: 'Transfers',
  },
  {
    path: `${ADMIN_ROUTES.STORE}${INVENTORY_ROUTES.ADJUSTMENT}/*`,
    component: Adjustments,
    layout: 'admin',
    exact: true,
    roles: onlyWooInventoryRoles,
    editRoles: onlyWooInventoryRolesEditableTransferAdjust,
    routeName: 'Adjustments',
  },
]

export default InventoryRouters
