export const convertCategoryData = (list) =>
  list.map(({ category_name }) => category_name)

export const convertLocationListName = (list) =>
  list.map(({ code, name, states }) => ({
    label: name,
    value: code,
    states: states.map(({ name: stateName, code: stateCode }) => ({
      label: stateName,
      value: stateCode,
    })),
  }))
