import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = {
  SYSTEM_STATUS: '/stores/woocommerce/system/status/',
}

const get = (parameters) =>
  httpClient.post(url.SYSTEM_STATUS, {
    action: ACTIONS.READ_DIRECT_PAGINATE,
    ...parameters,
  })

const pluginsApi = {
  get,
}

export default pluginsApi
