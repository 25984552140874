const d = {
  icon: 'warn',
  title: 'Warning',
  okText: 'OK',
  closeOnClick: true,
}
const getErrorMessage = (error) => {
  if (typeof error === 'string') {
    try {
      const parsedErr = JSON.parse(error)
      if (typeof parsedErr === 'string') return error
      if (parsedErr.message) return parsedErr.message
    } catch {
      return error
    }
  }
  if (typeof error === 'object') {
    const key = Object.keys(error)[0]
    return error[key] || 'Something went wrong!'
  }
  if (error?.message) return error.message
  return 'Something went wrong!'
}

// try to search messages below to avoid adding duplicated ones
const dialogParams = {
  error: (e) => ({
    ...d,
    title: 'Error',
    description: getErrorMessage(e),
  }),
  deleteSingleItem: {
    ...d,
    description:
      'Once deleted, item cannot be recovered.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  deleteMultipleItems: {
    ...d,
    description:
      'Once deleted, items cannot be recovered,\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  singleItemDeleted: {
    icon: 'trash',
    title: 'Deleted',
    okText: 'OK',
    closeOnClick: true,
  },
  SkuDeleted: {
    icon: 'trash',
    title: 'Success',
    okText: 'OK',
    description: 'This product has been deleted.',
  },
  multipleItemsDeleted: {
    icon: 'trash',
    title: 'Deleted',
    okText: 'OK',
    description: 'Item(s) successfully deleted!',
    closeOnClick: true,
  },
  productNoVariationCreateError: {
    ...d,
    title: 'No Variations Created!',
    description:
      'Variable product will be saved as simple product if no variations are created.\nAre you sure you want to continue?',
    okText: 'Yes, Continue',
    cancelText: 'Cancel',
  },
  productVariableMissSku: {
    ...d,
    title: 'Error',
    description: 'SKU can not empty.',
    okText: 'OK',
    closeOnClick: true,
  },
  productStopTrackingQuantity: {
    ...d,
    title: 'Stop tracking quantity of inventory?',
    description:
      'If you disable this setting, sales of this product will no longer affect the quantity of the linked SKU (inventory) product.',
    okText: 'Yes, Stop',
    cancelText: 'Cancel',
  },

  productPublished: {
    icon: 'success',
    title: 'Product Published',
    description: 'This product can now be seen on the website.',
    okText: 'OK',
  },
  productSaved: {
    icon: 'success',
    title: 'Product Saved',
    description: 'Changes to this product has been saved!',
    okText: 'OK',
  },
  createProductSaved: {
    icon: 'success',
    title: 'Product Saved',
    description: 'This product has been created successfully.',
    okText: 'OK',
  },
  attributeSaved: {
    icon: 'success',
    title: 'Attribute Saved',
    description: 'This attribute can be used for products now.',
    okText: 'OK',
  },
  attributeChanged: {
    icon: 'success',
    title: 'Attribute Saved',
    description: 'This attribute can be used for products now.',
    okText: 'OK',
  },
  deleteProduct: {
    ...d,
    title: 'Move Product to Trash',
    description:
      'If you move this product to trash,\nyou can still recover it in the "Trash" view.',
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  productDeleted: {
    icon: 'trash',
    title: 'Product moved to Trash',
    description: 'This product has now been moved to the trash bin.',
    okText: 'OK',
    closeOnClick: true,
  },
  productUpdated: {
    icon: 'success',
    title: 'Product Updated',
    description: 'Changes to this product have been updated!',
    okText: 'OK',
    closeOnClick: true,
  },
  restoreSuccessfully: {
    icon: 'success',
    title: 'Restore Successfully',
    description: 'Product has been restored successfully.',
    okText: 'OK',
    closeOnClick: true,
  },
  unableToRestore: {
    icon: 'warn',
    title: 'Unable To Restore Product',
    description:
      'This product can’t be restored. Because, it has been permanently deleted in WooCommerce.',
    okText: 'OK',
    closeOnClick: true,
  },
  warningChangeSkuName: {
    icon: 'warn',
    title: 'Warning',
    description:
      'You have changed the product SKU, all changes will be pushed to linked sales products.',
    okText: 'Continue',
    closeOnClick: true,
    cancelText: 'Cancel',
  },
  skuWarningDelete: {
    icon: 'warn',
    title: 'Warning',
    description:
      'Once deleted, this product cannot be recovered. Are you sure you want to continue?',
    closeOnClick: true,
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  skuUpdated: {
    icon: 'success',
    title: 'SKU Updated',
    description: 'Changes to this SKU have been updated!',
    okText: 'OK',
    closeOnClick: true,
  },
  skuTagSaved: {
    icon: 'success',
    title: 'SKU Saved',
    description: 'This SKU tags is ready to use.',
    okText: 'OK',
    closeOnClick: true,
  },
  skuTagUpdated: {
    icon: 'success',
    title: 'SKU Updated',
    description: 'Changes to this SKU tag have been updated.',
    okText: 'OK',
    closeOnClick: true,
  },
  skuTagExists: {
    icon: 'warn',
    title: 'Error',
    description: 'Tag already exists.',
    okText: 'OK',
    closeOnClick: true,
  },
  skuUpdatFail: {
    icon: 'warn',
    title: 'SKU Update fail',
    description: 'Changes to this sku have been fail!',
    okText: 'OK',
    closeOnClick: true,
  },
  onfleetStoreUpdated: {
    icon: 'success',
    title: 'Store Updated',
    description: 'Changes to this Onfleet Store have been updated.',
    okText: 'OK',
    closeOnClick: true,
  },
  changesSaved: {
    icon: 'success',
    title: 'Changes Saved',
    okText: 'OK',
    closeOnClick: true,
  },
  storeSaved: {
    icon: 'success',
    title: 'Store Saved',
    description: 'Changes to this store has been saved!',
    okText: 'OK',
    closeOnClick: true,
  },
  storeImporting: {
    icon: 'importing',
    title: 'Store data Importing',
    description:
      'Breadstack is now importing store data. This process will take a while.\nBut don’t worry, we will send you an email once data import is done',
    okText: 'OK',
    closeOnClick: true,
  },
  deleteFile: {
    icon: 'warn',
    title: 'Warning',
    description: [
      'Once deleted, the file(s) cannot be recovered.',
      'Are you sure you want to continue?',
    ],
    closeOnClick: true,
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  couponSaved: {
    icon: 'success',
    title: 'Coupon Saved',
    description: 'Changes to this coupon has been saved!',
    okText: 'OK',
    closeOnClick: true,
  },
  couponUpdated: {
    icon: 'success',
    title: 'Coupon Updated',
    description: 'Changes to this coupon have been updated!',
    okText: 'OK',
  },
  couponDeleted: {
    icon: 'success',
    title: 'Coupon Deleted',
    description: 'This coupon has been deleted!',
    okText: 'Ok',
    closeOnClick: true,
  },
  deleteCoupon: {
    icon: 'warn',
    title: 'Warning',
    description: [
      'Once deleted, files cannot be recovered.',
      'Are you sure you want to continue?',
    ],
    closeOnClick: true,
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  deleteCouponFail: {
    icon: 'warn',
    title: 'Error',
    description: 'Oops, Something Went Wrong',
    okText: 'OK',
    closeOnClick: true,
  },
  tagSaved: {
    icon: 'success',
    title: 'Tag Saved',
    description: 'Changes to this tag has been saved!',
    okText: 'OK',
  },
  tagUpdated: {
    icon: 'success',
    title: 'Tag Updated',
    description: 'Changes to this tag have been updated!',
    okText: 'OK',
  },

  categorySaved: {
    icon: 'success',
    title: 'Category Saved',
    description: 'You can now add products to this category.',
    okText: 'OK',
  },
  categoryUpdated: {
    icon: 'success',
    title: 'Category Updated',
    description: 'Changes to this category have been updated!',
    okText: 'OK',
  },

  resetPasswordCustomer: {
    icon: 'mail',
    title: 'Email Sent',
    description: ['Email with the new password has been sent to the customer'],
    closeOnClick: true,
    okText: 'OK',
  },
  customerNotFound: {
    icon: 'warn',
    title: 'Warning',
    description: ['Failed to read customer by id'],
    closeOnClick: true,
    okText: 'OK',
  },
  deleteOrder: {
    icon: 'red_trash',
    title: 'Move Order To Trash',
    description:
      'Once you move this order to trash,\nYou can recover it from the Trash list.',
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  bulkDeleteOrder: {
    icon: 'warn',
    title: 'Move Orders to Trash',
    description:
      'Once you move this order to trash,\nYou can recover it from the Trash list.',
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  changeStatusOrder: {
    icon: 'warn',
    okText: 'Yes, Change',
    cancelText: 'Cancel',
  },
  markAsProcessing: {
    icon: 'blue_success',
    title: 'Mark As Processing',
    description: 'Are you sure you want to mark this order as processing?',
    okText: 'Yes',
    cancelText: 'Cancel',
  },
  markAsComplete: {
    icon: 'success',
    title: 'Mark As Complete',
    description: 'Are you sure you want to mark this order as complete?',
    okText: 'Yes',
    cancelText: 'Cancel',
  },

  orderDeleted: {
    icon: 'trash',
    title: 'Order moved to Trash',
    description: 'This order has now been moved to the trash bin.',
    okText: 'OK',
  },
  editTrashOrder: {
    icon: 'warn',
    title: 'Warning',
    description:
      "You can't edit this item because it is in the Trash.\nPlease restore it and try again.",
    okText: 'OK',
    closeOnClick: true,
  },
  bulkDeleteProduct: {
    icon: 'warn',
    title: 'Move Products to Trash',
    description:
      'If you move these products to trash,\nyou can still recover them in the "Trash" view.',
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  warningSaveWarehouse: {
    ...d,
    description:
      'No selection has been made in “Fulfillment Options”. This means that the current warehouse will be used for storage purposes only.',
    cancelText: 'Cancel',
    okText: 'Yes, Save',
  },
  warningDeleteWarehouse: {
    ...d,
    description:
      'Once deleted, warehouse data cannot be recovered.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  warningSaveDeliveryHours: {
    ...d,
    description:
      'Updating time slot length will reset your delivery schedule configurations.\n Are you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Continue',
  },
  warningUnableToDeleteWarehouse: {
    ...d,
    title: 'Unable to delete warehouse',
    description:
      'We are unable to delete if you have any of the item below in the warehouse.',
  },
  warehouseDeleted: {
    ...d,
    icon: 'trash',
    title: 'Deleted',
    description: '',
  },
  warehouseCreated: {
    ...d,
    icon: 'success',
    title: 'Warehouse Created!',
    description: 'This warehouse is now up for use.',
  },
  warehouseUpdated: {
    ...d,
    icon: 'success',
    title: 'Warehouse Updated',
    description: 'Changes to this warehouse have been updated!',
  },
  warningDeleteUser: {
    ...d,
    description:
      'Once deleted, user will lose access to the store(s).\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  userDeleted: {
    ...d,
    icon: 'success',
    title: 'User Deleted',
    description: 'User has been successfully removed.',
  },
  invitationSent: {
    ...d,
    icon: 'success',
    title: 'Invitation Email Sent',
    description:
      'You will be able to work with them once they accept the invitation.',
  },
  refundOrder: {
    icon: 'warn',
    title: 'Confirm Refund',
    description:
      'Are you sure you want to process this refund?\nThis action cannot be undone.',
    okText: 'Yes',
    cancelText: 'Cancel',
  },
  commentSaved: {
    icon: 'success',
    title: 'Comment Updated',
    description: 'Changes to this comment have been updated.',
    okText: 'OK',
  },
  deleteComment: {
    ...d,
    title: 'Move Comment to Trash',
    description:
      'If you move this comment to trash,\nyou can still recover it in the "Trash" view.',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  commentDeleted: {
    icon: 'warn',
    title: 'Comment moved to Trash',
    description: 'This comment has now been moved to the trash bin.',
    okText: 'OK',
  },
  moveBulkCommentsToTrash: {
    icon: 'warn',
    title: 'Move To Trash',
    description:
      'If you move these comments to trash,\nyou can still recover it in the "Trash" view.',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  storeDeleted: {
    ...d,
    icon: 'success',
    title: 'Store Deleted',
    description: 'Store has been successfully removed.',
  },
  forceUnlockOrder: {
    ...d,
    icon: 'warn',
    title: 'Force Unlock Order',
    description:
      'Please make sure the other order picking is order.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Unlock',
  },
  someoneAlreadyPicking: {
    ...d,
    icon: 'warn',
    title: 'Someone is already picking this order',
    description:
      'You can select this order when is available again\nor pickup another order to continue your process.',
  },
  taxClassCreated: {
    ...d,
    icon: 'success',
    title: 'Tax Class Created!',
    description: 'This tax class is now up for use.',
  },
  warningDeleteTaxClass: {
    ...d,
    description:
      'Once deleted, tax class info cannot be recovered.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  taxClassDeleted: {
    ...d,
    icon: 'success',
    title: 'Tax Class Deleted',
    description: 'Tax Class has been successfully removed.',
  },
  warningSyncWithWoo: {
    ...d,
    description:
      'Once synced, there may be new data added to your settings.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Sync',
  },
  syncWithWooSuccess: {
    ...d,
    icon: 'success',
    title: 'Data Updated',
    description: 'Your data has been synced with WooCommerce.',
  },
  warningDeleteTaxRate: {
    ...d,
    description:
      'Once deleted, tax rate info cannot be recovered.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  taxRateDeleted: {
    ...d,
    icon: 'success',
    title: 'Rate Deleted',
    description: 'Rate has been successfully removed.',
  },
  taxRateCreated: {
    ...d,
    icon: 'success',
    title: 'New Rate Created!',
    description: 'This rate is now up for use.',
  },
  shippingZone: {
    ...d,
    icon: 'success',
    title: 'Zone Created!',
    description: 'The Shipping zone has been created.',
  },
  shippingZoneDeleted: {
    ...d,
    icon: 'success',
    title: 'Shipping Zone Deleted',
    description: 'Shipping Zone has been successfully removed.',
  },
  shippingClassDeleted: {
    ...d,
    icon: 'success',
    title: 'Shipping Class Deleted',
    description: 'Shipping Class has been successfully removed.',
  },
  shippingClassCreated: {
    ...d,
    icon: 'success',
    title: 'New Shipping Class Created!',
    description: 'This Shipping Class is now up for use.',
  },
  syncShipping: {
    ...d,
    icon: 'warn',
    title: 'Warning',
    description:
      'Once synced, there may be new data added to your settings.\nAre you sure you want to continue?',
    okText: 'Yes, Sync',
    cancelText: 'Cancel',
  },
  syncedShipping: {
    ...d,
    icon: 'success',
    title: 'Data Updated',
    description: 'Your data has been synced with WooCommerce.',
  },
  inventoryTransCreated: {
    ...d,
    icon: 'success',
    title: 'Inventory Transfer Created',
    description: 'The stock amount has been deducted from your inventory.',
  },
  failedToCreatedTransDuetoQuantity: {
    ...d,
    icon: 'warn',
    title: 'Insufficient Available Quantity',
    description:
      'Current available quantity is below the number you entered. Please enter a new quantity.',
  },
  failedToSaveFilter: {
    icon: 'warn',
    title: 'Fail To Save Search',
    description:
      'You had reached the maximum number of saved search.\nTo add new filter, please delete unwanted search.',
    okText: 'Ok',
  },
  inventorySaved: {
    icon: 'success',
    title: 'New Inventory Product Saved',
    description: 'This inventory can now be linked to store sales products.',
    okText: 'Ok',
  },
  inventoryCreated: {
    icon: 'success',
    title: 'New Inventory Product (SKU) Saved',
    description: 'The new SKU is now associated with the product.',
    okText: 'Ok',
  },
  inventoryQuitSKUCreation: {
    ...d,
    title: 'Quit SKU Creation?',
    description:
      'You will lose your progress on the SKU creation, and will have to re-check the quantity tracking.',
    okText: 'Yes, Quit',
    cancelText: 'Cancel',
  },
  inventoryDeleteProduct: {
    ...d,
    title: 'Warning',
    description:
      'Once deleted, this product cannot be recovered. Are you sure you want to continue?',
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  warningDeleteProductIsSelling: {
    ...d,
    title: 'Warning',
    description:
      'You can not delete a inventory product which is selling in store',
  },
  warningCreateNewSKU: {
    icon: 'warn',
    title: 'Warning',
    description:
      'You will create a new SKU production this organization.\nAre you sure you want to continue?',
    okText: 'Yes, Continue',
    cancelText: 'Cancel',
  },
  warningSkuExisted: {
    icon: 'warn',
    title: 'Warning',
    description: 'SKU already exists. Please change to another one.',
    okText: 'OK',
  },
  warningSkuFieldBlank: {
    icon: 'warn',
    title: 'Warning',
    description:
      'If you leave the "SKU" field blank, system will auto-generate one for you.\nAre you sure you want to continue?',
    okText: 'Yes, Continue',
    cancelText: 'Cancel',
  },
  warningEditInventoryProduct: {
    icon: 'warn',
    title: 'Warning',
    description:
      'You have changed the product SKU, all changes will be\npushed to linked sales products.',
    okText: 'OK',
  },
  editInventoryChangeSaved: {
    icon: 'success',
    title: 'Changes Saved',
    description: 'All changes to this inventory has been updated.',
    okText: 'OK',
  },
  forceUnlockTransfer: {
    icon: 'warn',
    title: 'Force Unlock Transfer',
    description:
      'Please make sure the other agent is no longer checking this transfer.\nAre you sure you want to continue?',
    okText: 'Yes, Unlock',
    cancelText: 'Cancel',
  },
  transferLocked: {
    icon: 'warn',
    title: 'Someone Is Already Checking This Order',
    description:
      'You can select this order when it’s available again\nor check another transfer to continue your process.',
    okText: 'OK',
  },
  checkInSaved: {
    icon: 'success',
    title: 'Inventory Transfer Saved',
    description: 'You can still open and edit this transfer until complete.',
    okText: 'OK',
  },
  checkInSuccess: {
    icon: 'success',
    title: 'Inventory Transfer Complete',
    description: 'You can no longer edit this transfer, after it is complete.',
    okText: 'OK',
  },
  pluginNotInstalled: {
    ...d,
    title: 'Plugin Not Installed',
    description:
      'To enable Cantec Delivery, you need to install a WordPress plugin.\nClick “OK” below to see instruction, click “Cancel” to close this pop-up.',
    cancelText: 'Cancel',
  },
  orderRecalculateWarning: {
    icon: 'warn',
    title: 'Warning',
    description:
      'Recalculate total? This will calculate taxes base on customers country (or the store based country) and update total.',
    closeOnClick: true,
    okText: 'OK',
    cancelText: 'Cancel',
  },
  removeTransferOut: {
    icon: 'warn',
    title: 'Warning',
    description:
      'Once delete, transfer date cannot be recovered. Are you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  transferOutRemoved: {
    icon: 'success',
    title: 'Transfer has been successfully deleted',
    description: 'You can still view or delete other transfers.',
    okText: 'OK',
  },
  requiredNameTerm: {
    icon: 'warn',
    title: 'Warning',
    description: 'Please enter a Term Name.',
    okText: 'OK',
  },
  warningLeave: {
    description:
      'If you leave this page, unsaved changes will be lost.\nAre you sure you want to continue?',
    icon: 'warn',
    title: 'Warning',
    cancelText: 'Cancel',
    okText: 'Yes, Leave',
  },
  skuProductSaved: {
    icon: 'success',
    title: 'SKU Product Saved',
    description: 'This SKU product can now be assigned to warehouses.',
    okText: 'OK',
  },
  deleteSku: {
    icon: 'warn',
    title: 'Warning',
    description:
      'Once deleted, this product cannot be recovered.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  warehouseProductDeleted: {
    icon: 'trash',
    title: 'Success',
    description: 'This product has been deleted.',
    okText: 'OK',
    closeOnClick: true,
  },
  updateProfile: {
    icon: 'success',
    title: 'Update Successfully',
    description: 'Your profile has been successfully updated.',
    okText: 'OK',
    closeOnClick: true,
  },
  updateLocationImage: {
    icon: 'success',
    title: 'Update Successfully',
    description: 'Your image has been successfully updated.',
    okText: 'OK',
    closeOnClick: true,
  },
  alertStoreDeleted: {
    icon: 'warn',
    title: 'Warning',
    description: 'This store may be deleted. Switch to the default store?',
    okText: 'OK',
    closeOnClick: true,
  },
  exportCsv: {
    icon: 'loader',
    title: 'Generating CSV',
    description:
      'You will receive a notification and an email once the CSV is done generating.',
    okText: 'OK',
    closeOnClick: true,
  },
  createPromotionSuccess: {
    icon: 'success',
    title: 'Promotion Saved',
    description: 'This promotion has been created successfully.',
    okText: 'OK',
  },
  updatePromotionSettingSuccess: {
    icon: 'success',
    title: 'Update Successfully',
    description: 'Your promotion setting has been successfully updated.',
    okText: 'OK',
    closeOnClick: true,
  },
  endPromotion: {
    ...d,
    title: 'End Promotion',
    description:
      'Are you sure you want to end this promotion?\nThis can’t be undone.',
    cancelText: 'Cancel',
    okText: 'Yes',
  },
  endPromotionSuccess: {
    icon: 'success',
    title: 'End Successfully',
    description: 'Your promotion has been ended.',
    okText: 'OK',
    closeOnClick: true,
  },
  updateCovaCategoriesMapping: {
    icon: 'warn',
    title: 'Are You Sure To Continue?',
    description:
      'Some COVA types are not matched to Breadstack menu categories.\nContinuing will put the products under these categories into Uncategorized.',
    okText: 'Continue',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  unSuccessCategoriesMapping: {
    icon: 'warn',
    title: 'Some categories can not be updated!',
    okText: 'OK',
  },
  categoriesMappingAfterSaving: {
    icon: 'success',
    title: 'Products are syncing!',
    description:
      'Syncing products may take a few minutes. Please wait until the syncing process is finished.',
    okText: 'OK',
  },
  addPromotionNote: {
    icon: 'success',
    title: 'Note added successfully!',
    description: 'Change log has been updated.',
    okText: 'OK',
  },
  deletePromotionWarning: {
    icon: 'warn',
    title: 'Warning',
    description:
      'If you delete this promotion, all the details will be lost.\nAre you sure you want to continue?',
    okText: 'Yes, Remove',
    cancelText: 'Cancel',
  },
  deleteZone: {
    icon: 'warn',
    title: 'Warning',
    description:
      'Once deleted, this zone cannot be recovered.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  warningImportZoneFromOtherWarehouse: {
    icon: 'warn',
    title: 'Are You Sure To Continue?',
    description:
      'Importing Zones will overwrite all the existing zones in this warehouse.',
    okText: 'Yes',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  addCustomerNote: {
    icon: 'success',
    title: 'Note added successfully!',
    description: 'Timeline has been updated.',
    okText: 'OK',
  },
  deleteCustomerNote: {
    icon: 'warn',
    title: 'Warning',
    description:
      'Once deleted, this note cannot be recovered.\nAre you sure you want to continue?',
    okText: 'Yes, Delete',
    cancelText: 'Cancel',
  },
  createTrackingNumberSuccess: {
    icon: 'success',
    title: 'Tracking Number Saved',
    description: 'This tracking number has been added successfully',
    okText: 'OK',
  },
  createShippingSuccess: {
    icon: 'success',
    title: 'Update Successfully',
    description: 'Shipping method has been successfully updated',
    okText: 'OK',
  },
  warningDeleteTrackingNumber: {
    ...d,
    description:
      'Once deleted, tracking number cannot be recovered.\nAre you sure you want to continue?',
    cancelText: 'Cancel',
    okText: 'Yes, Delete',
  },
  trackingNumberDeleted: {
    ...d,
    icon: 'success',
    title: 'Tracking Number Deleted',
    description: 'Tracking Number been successfully deleted.',
  },
  updateOrderSuccess: {
    icon: 'success',
    title: 'Update Successfully',
    description: 'Order has been successfully updated.',
    okText: 'OK',
    closeOnClick: true,
  },
  warningCancelOrder: {
    icon: 'warn_red',
    title: 'Are you sure to cancel the order?',
    description:
      'Are you certain you want to proceed? Once the order is cancelled, it cannot be processed any further.',
    okText: 'Yes',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  warningCancelRefundOrder: {
    icon: 'warn_red',
    title: 'Are you sure to cancel and refund the order?',
    description:
      'Cancelling will generate a refund back to the customer for the entire order. Are you sure want to continue?',
    okText: 'Yes',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  warningOnholdOrder: {
    icon: 'warn',
    title: 'Are you sure to put the order on hold?',
    description: 'Are you sure you want to place this order on hold?',
    okText: 'Yes',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  warningCompleteGreenlineOrder: {
    icon: 'warn',
    title: 'You cannot update unpaid order in Breadstack.',
    description:
      'You may still be able to update it in Greenline. If you do so, the order will automatically update in Breadstack.',
    okText: 'OK',
    closeOnClick: true,
  },
  warningCancelGreenlineOrder: {
    icon: 'warn',
    title:
      'You cannot cancel this order in Breadstack because it has already been paid.',
    description:
      'You may still be able to cancel it in Greenline. If you do so, the order status will automatically update to "Cancelled" in Breadstack.',
    okText: 'OK',
    closeOnClick: true,
  },
  warningChangeStatusGreenlineOrder: {
    icon: 'warn',
    title: 'You cannot change order status in Breadstack.',
    description:
      'You may still be able to change it in Greenline. If you do so, the order status will automatically update in Breadstack.',
    okText: 'OK',
    closeOnClick: true,
  },
  warningProcessingGreenlineOrder: {
    icon: 'warn',
    title: 'You cannot update unpaid order in Breadstack.',
    description:
      'You may still be able to update it in Greenline. If you do so, the order will automatically update in Breadstack.',
    okText: 'OK',
    closeOnClick: true,
  },
  warningOnholdGreenlineOrder: {
    icon: 'warn',
    title: 'You cannot update order status in Breadstack.',
    description:
      'You may still be able to update it in Greenline. If you do so, the order will automatically update in Breadstack.',
    okText: 'OK',
    closeOnClick: true,
  },
  emailSent: {
    icon: 'success',
    title: 'Email Sent',
    description: 'Email has been sent to recipients.',
    okText: 'OK',
    closeOnClick: true,
  },
  updateGreenlineCategoriesMapping: {
    icon: 'warn',
    title: 'Are You Sure To Continue?',
    description:
      'Some Greenline types are not matched to Breadstack menu categories.\nContinuing will put the products under these categories into Uncategorized.',
    okText: 'Continue',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  disableConnection: {
    icon: 'warn',
    title: 'Are you sure you want to continue?',
    okText: 'Continue',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  deleteConnection: {
    icon: 'warn',
    title: 'Delete this integration?',
    okText: 'Continue',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  donePicking: {
    icon: 'success',
    title: 'Success',
    description:
      'Delivery task was created successfully.\nPlease refresh this page.',
    okText: 'OK',
    cancelText: '',
  },
  warningChangeDeliveryType: {
    icon: 'warn_red',
    title: 'Are you sure to change the delivery area type?',
    description:
      'If you change the delivery area type, all you existing zones will be disabled.',
    okText: 'Yes',
    cancelText: 'Cancel',
    closeOnClick: true,
  },
  warningRetrieveProduct: {
    icon: 'warn',
    title: 'Warning',
    description:
      'Retrieving product data from POS will erase all modifications made to the product information on Breadstack. Are you sure to continue?',
    closeOnClick: true,
    okText: 'Yes, continue',
    cancelText: 'Cancel',
  },
  warningProductEdit: {
    icon: 'warn',
    title: 'Warning',
    description:
      'Sorry you cannot update the product details\n and you need to change those in your POS system.',
    okText: 'OK',
  },
}

export default dialogParams
