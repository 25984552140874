import { useMutation } from '@tanstack/react-query'
import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from '~/services/apis/common'

const url = {
  NOTIFICATION_SETTINGS: 'stores/woocommerce/notification-settings/',
}

const getNotificationSettings = (params) =>
  httpClient.json.post(url.NOTIFICATION_SETTINGS, {
    action: ACTIONS.READ,
    parameters: {
      store_ids: params,
    },
  })

export const useNotificationSettings = ({
  onSuccess,
  onError,
}: { onSuccess?: (res: any) => void; onError?: (err: any) => void }) =>
  useMutation({
    mutationFn: getNotificationSettings,
    onSuccess,
    onError,
  })
