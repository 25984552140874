import { vh } from '~/themes/common'

const style = (theme) => ({
  body: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    flex: 1,
    overflow: 'hidden',
  },
  content: {
    position: 'relative',
    width: 'calc(100vw - 80px)',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',

    '&:after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: 30,
      flex: '0 0 30px',
      // backgroundColor: '#f8f9ff',
      backgroundColor: '#FBFBFC',
    },

    '@media screen and (min-width: 1280px)': {
      '&:after': {
        display: 'none',
      },
    },

    [vh.down('md')]: {
      overflowY: 'visible',
    },

    'body.mobile-tablet-device[style*="overflow: hidden"] &, body.mobile-tablet-device[style*="overflow:hidden"] &':
      {
        overflow: 'hidden',
      },
  },
  childrenWrap: {
    overflow: 'auto',
    scrollbarGutter: 'stable',
    '&::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: '#b4bfd9',
    },
  },

  showBtnUnifined: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.background.pageBg,

    padding: theme.spacing(1, 2),
    '@media (min-width: 1025px)': {
      display: 'none',
    },
    '&> img': {
      cursor: 'pointer',
      height: 24,
    },
  },
})

export default style
