import httpClient from '~/modules/core/httpClient'
import { ACTIONS } from './common'

const url = Object.freeze({
  PRODUCT_REVIEW: '/stores/woocommerce/products/reviews/',
})

const buildParams = (data) => ({
  item_data: data,
})

const findOne = (id, product_id) =>
  httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.READ,
    parameters: { id, product_id },
  })
const getReviewsByProductId = async (
  product_id,
  page = 1,
  limit = 100,
  filters = {},
) =>
  httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.PAGINATE,
    parameters: {
      limit,
      page,
      filters: { product_id, ...filters },
    },
  })
const getList = (params) =>
  httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.PAGINATE,
    parameters: params,
  })
const update = (data) =>
  httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.UPDATE,
    parameters: buildParams(data),
  })
const create = (data) =>
  httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.CREATE,
    parameters: buildParams(data),
  })
const remove = (id) =>
  httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.DELETE,
    parameters: { id },
  })
const sync = (product_id) =>
  httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.SYNC,
    parameters: { product_id },
  })
const syncAll = () =>
  httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.SYNC,
    parameters: {},
  })
const bulkUpdate = (ids = [], status) => {
  const params = {
    ids,
    status,
  }
  return httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.BULK_UPDATE,
    parameters: params,
  })
}

const bulkDelete = (ids = []) => {
  const params = {
    ids,
  }
  return httpClient.post(url.PRODUCT_REVIEW, {
    action: ACTIONS.BULK_DELETE,
    parameters: params,
  })
}

export default {
  findOne,
  getList,
  update,
  create,
  remove,
  getReviewsByProductId,
  sync,
  syncAll,
  bulkUpdate,
  bulkDelete,
}
