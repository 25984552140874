import {
  NOTIFICATION_ORDER_TYPES,
  NOTIFICATION_SHIPPING_METHODS,
} from '~/constants/settings'
import get from 'lodash/get'

const DOCUMENT_NAME = 'Breadstack'

const mapOrders = (orders, methodId) => [
  ...get(orders, `on-hold.${methodId}`, []).map((item) => ({
    ...item,
    methodId,
  })),
  ...get(orders, `processing.${methodId}`, []).map((item) => ({
    ...item,
    methodId,
  })),
]

export const groupOrders = (orders, type) => {
  if (type === NOTIFICATION_ORDER_TYPES.MAIL_ORDER) {
    return mapOrders(orders, 'mail_orders')
  } else if (type === NOTIFICATION_ORDER_TYPES.LOCAL_DELIVERY) {
    return [
      ...mapOrders(orders, NOTIFICATION_SHIPPING_METHODS.CANTEC_DELIVERY),
      ...mapOrders(orders, NOTIFICATION_SHIPPING_METHODS.CANFLEET_DELIVERY),
      ...mapOrders(orders, NOTIFICATION_SHIPPING_METHODS.ONFLEET),
    ]
  } else if (type === NOTIFICATION_ORDER_TYPES.PICKUP) {
    return [
      ...mapOrders(orders, NOTIFICATION_SHIPPING_METHODS.LOCAL_PICKUP),
      ...mapOrders(orders, NOTIFICATION_SHIPPING_METHODS.CURBSIDE_PICKUP),
      ...mapOrders(orders, NOTIFICATION_SHIPPING_METHODS.DRIVE_THROUGH_PICKUP),
    ]
  } else {
    return []
  }
}

export const isEnabled = (settings, storeId, type) =>
  get(
    get(settings, `${storeId}.settings`, []).find((s) => s.type === type),
    'enabled',
    false,
  )

export const isAll = (settings, storeId, type) => {
  const arr = get(
    get(settings, `${storeId}.settings`, []).find((s) => s.type === type),
    'warehouses',
    [],
  )

  if (arr.find((item) => item.all === false)) {
    return false
  }
  return true
}

export const increaseNewNotiDocumentName = () => {
  const title = document.title
  const lastCount = title.split(' ')[0]
  if (lastCount === DOCUMENT_NAME) {
    document.title = `(1) ${DOCUMENT_NAME}`
    return
  }
  if (lastCount.match(/(\d+)/)) {
    const counted = lastCount.match(/(\d+)/)
    const newCount = parseInt(counted[0]) + 1
    document.title = `(${newCount}) ${DOCUMENT_NAME}`
  }
}

export const reduceNewNotiDocumentName = () => {
  const title = document.title
  const lastCount = title.split(' ')[0]
  if (lastCount === DOCUMENT_NAME) {
    return
  }
  if (lastCount.match(/(\d+)/)) {
    const counted = lastCount.match(/(\d+)/)
    const newCount = parseInt(counted[0]) - 1
    if (newCount <= 0) {
      document.title = DOCUMENT_NAME
    } else {
      document.title = `(${newCount}) ${DOCUMENT_NAME}`
    }
  }
}
