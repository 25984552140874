export const mediaAction = Object.freeze({
  GET_LIST_FOLDER_REQUEST: 'mediaLibrary.GET_LIST_FOLDER_REQUEST',
  GET_LIST_FOLDER_SUCCESS: 'mediaLibrary.GET_LIST_FOLDER_SUCCESS',
  GET_LIST_FOLDER_FAILURE: 'mediaLibrary.GET_LIST_FOLDER_FAILURE',

  GET_LIST_FILE_REQUEST: 'mediaLibrary.GET_LIST_FILE_REQUEST',
  GET_LIST_FILE_SUCCESS: 'mediaLibrary.GET_LIST_FILE_SUCCESS',
  GET_LIST_FILE_FAILURE: 'mediaLibrary.GET_LIST_FILE_FAILURE',

  MOVE_FILE_TO_FOLDER_REQUEST: 'mediaLibrary.MOVE_FILE_TO_FOLDER_REQUEST',
  MOVE_FILE_TO_FOLDER_SUCCESS: 'mediaLibrary.MOVE_FILE_TO_FOLDER_SUCCESS',
  MOVE_FILE_TO_FOLDER_FAILURE: 'mediaLibrary.MOVE_FILE_TO_FOLDER_FAILURE',

  DELETE_FILE_REQUEST: 'mediaLibrary.DELETE_FILE_REQUEST',
  DELETE_FILE_SUCCESS: 'mediaLibrary.DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE: 'mediaLibrary.DELETE_FILE_FAILURE',

  ADD_NEW_FOLDER_REQUEST: 'mediaLibrary.ADD_NEW_FOLDER_REQUEST',
  ADD_NEW_FOLDER_SUCCESS: 'mediaLibrary.ADD_NEW_FOLDER_SUCCESS',
  ADD_NEW_FOLDER_FAILURE: 'mediaLibrary.ADD_NEW_FOLDER_FAILURE',

  EDIT_FOLDER_REQUEST: 'mediaLibrary.EDIT_FOLDER_REQUEST',
  EDIT_FOLDER_SUCCESS: 'mediaLibrary.EDIT_FOLDER_SUCCESS',
  EDIT_FOLDER_FAILURE: 'mediaLibrary.EDIT_FOLDER_FAILURE',

  DELETE_FOLDER_REQUEST: 'mediaLibrary.DELETE_FOLDER_REQUEST',
  DELETE_FOLDER_SUCCESS: 'mediaLibrary.DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER_FAILURE: 'mediaLibrary.DELETE_FOLDER_FAILURE',

  SET_LIST_FILE_FOLDER_DATA: 'mediaLibrary.SET_LIST_FILE_FOLDER_DATA',
})

const getListFolder = (onSuccess = () => {}) => ({
  type: mediaAction.GET_LIST_FOLDER_REQUEST,
  payload: {},
  callbacks: {
    onSuccess,
  },
})

const getListFile = (params, onSuccess = () => {}) => ({
  type: mediaAction.GET_LIST_FILE_REQUEST,
  payload: params,
  callbacks: {
    onSuccess,
  },
})

const deleteFile = ({ fileId }, onSuccess = () => {}) => ({
  type: mediaAction.DELETE_FILE_REQUEST,
  payload: {
    fileId,
  },
  callbacks: {
    onSuccess,
  },
})

const addNewFolder = (
  { storeId, folderName, parentId },
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: mediaAction.ADD_NEW_FOLDER_REQUEST,
  payload: {
    storeId,
    folderName,
    parentId,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const editFolder = (
  { folderId, folderName },
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: mediaAction.EDIT_FOLDER_REQUEST,
  payload: {
    folderId,
    folderName,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const deleteFolder = ({ folderId }, onSuccess = () => {}) => ({
  type: mediaAction.DELETE_FOLDER_REQUEST,
  payload: {
    folderId,
  },
  callbacks: {
    onSuccess,
  },
})

const setListFileFolderData = (payload) => ({
  type: mediaAction.SET_LIST_FILE_FOLDER_DATA,
  payload,
})

const moveFileToFolder = (
  { parentId, mediaIdList },
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: mediaAction.MOVE_FILE_TO_FOLDER_REQUEST,
  payload: {
    parentId,
    mediaIdList,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

export default {
  getListFolder,
  getListFile,
  setListFileFolderData,
  deleteFile,
  addNewFolder,
  deleteFolder,
  editFolder,
  moveFileToFolder,
}
