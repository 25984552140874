/* eslint-disable no-param-reassign */
import produce from 'immer'

import { COUPON_ACTION } from '../actions/coupon'
import {
  DEFAULT_COUPON_LIST_DATA,
  DEFAULT_COUPON_DETAIL,
} from '../default/coupon'

const initialState = {
  data: DEFAULT_COUPON_LIST_DATA,
  loading: false,
  detail: DEFAULT_COUPON_DETAIL,
  loadingDetail: false,
  error: '',
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case COUPON_ACTION.FETCH_COUPON_LIST:
        draft.loading = true
        draft.error = ''
        break
      case COUPON_ACTION.SET_LIST_DATA:
        draft.data = payload
        draft.loading = false
        draft.error = ''
        break
      case COUPON_ACTION.SET_LIST_ERROR:
        draft.loading = false
        draft.error = payload
        break

      case COUPON_ACTION.FETCH_DETAIL:
        // draft.detail = DEFAULT_COUPON_DETAIL
        draft.loadingDetail = true
        draft.error = ''
        break
      case COUPON_ACTION.SET_DETAIL_DATA_ERROR:
        draft.loadingDetail = false
        draft.error = payload
        break
      case COUPON_ACTION.SET_DETAIL_DATA:
        draft.loadingDetail = false
        draft.error = ''
        draft.detail = payload
        break
      default:
        break
    }
  })

export default reducer
