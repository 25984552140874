import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { notificationCenterSelector } from '../selectors/notificationCenter'
import notificationCenterActions from '../actions/notificationCenter'

export const useNotification = () => {
  const notifies = useSelector(notificationCenterSelector)
  const dispatch = useDispatch()
  const actionNotifi = useMemo(
    () => bindActionCreators(notificationCenterActions, dispatch),
    [dispatch],
  )

  return useMemo(
    () => ({
      actionNotifi,
      notifies,
    }),
    [actionNotifi, notifies],
  )
}
