import produce from 'immer'
import { whAdjustmentActions } from '../actions/warehouseAdjustments'

const initialState = {
  list: {
    loading: false,
    items: [],
    total_items: 0,
    total_pages: 0,
    error: '',
  },
  detail: {
    loading: false,
    items: [],
    total_items: 0,
    total_pages: 0,
    error: '',
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload = {} } = action

    if (type === whAdjustmentActions.GET_LIST_WH_ADJUSTMENTS) {
      draft.list.loading = true
    }

    if (type === whAdjustmentActions.GET_LIST_WH_ADJUSTMENTS_OK) {
      draft.list.loading = false
      draft.list.items = payload.items
      draft.list.total_items = payload.total_items
      draft.list.total_pages = payload.total_pages
    }

    if (type === whAdjustmentActions.GET_LIST_WH_ADJUSTMENTS_ERR) {
      draft.list.loading = false
      draft.list.error = 'Error occurred'
    }
  })

export default reducer
