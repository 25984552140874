import moment from 'moment'
import {
  PRODUCT_TYPE_LIST,
  PRODUCT_TYPE,
  COMPOSITE_SHIPPINGS,
} from '~/constants/product'
import {
  isNullOrUndefined,
  diffObj,
  isNullOrUndefinedOrEmptyString,
} from './utility'
import { convertGmtToLocalTime, convertLocalToGmtTime } from '~/utils/datetime'
import { transformTypeToTitle } from '~/utils/common'
import { tags as TAGS } from '~/components/common/Tag/tags'
import { stripHtmlTags } from '~/utils/order'
import { COMPOSITE_SCENARIO } from '~/redux/default/product'
import { COLORS } from '~/themes/common'

export const STOCKS = {
  instock: {
    label: 'In Stock',
    color: COLORS.accent.viridianGreen1,
  },
  outofstock: {
    label: 'Out of Stock',
    color: COLORS.accent.rubyRed1,
  },
  onbackorder: {
    label: 'On Backorder',
    color: COLORS.accent.apricotOrange1,
  },
}

const mapBasicCommonKey = ({
  name,
  supplier_name,
  supplier_quantity,
  shipping_class,
  dimensions,
  categories,
  date_on_sale_from_gmt,
  date_on_sale_to_gmt,
  visibility,
  published,
  featured,
  upsell_ids,
  cross_sell_ids,
  type,
  ...rest
}) => {
  let typeName
  const filterTypes = PRODUCT_TYPE_LIST.filter((item) => item.value === type)
  if (filterTypes.length > 0) {
    typeName = filterTypes[0].label
  }

  const reproduceDimensions = dimensions
    ? {
        height: dimensions.height || '',
        length: dimensions.length || '',
        width: dimensions.width || '',
      }
    : {
        height: '',
        length: '',
        width: '',
      }

  const origin = {
    name,
    supplier_name,
    supplier_quantity,
    shipping_class,
    dimensions: reproduceDimensions,
    categories,
    date_on_sale_from_gmt,
    date_on_sale_to_gmt,
    visibility,
    published,
    featured,
    upsell_ids,
    cross_sell_ids,
    type,
    ...rest,
  }

  return {
    ...rest,
    name,
    supplier_name,
    supplier_quantity,
    shipping_class,
    dimensions: reproduceDimensions,
    ...reproduceDimensions,
    categories: categories
      ? categories.map((item) => ({
          id: item.id,
          title: item.name,
          slug: item.slug,
        }))
      : [],
    date_on_sale_from_gmt,
    date_on_sale_to_gmt,
    date_on_sale_from: date_on_sale_from_gmt
      ? convertGmtToLocalTime(date_on_sale_from_gmt, 'MM/DD/YYYY')
      : null,
    date_on_sale_to: date_on_sale_to_gmt
      ? convertGmtToLocalTime(date_on_sale_to_gmt, 'MM/DD/YYYY')
      : null,
    visibility: visibility || 'public',
    published: published || 'immediately',
    featured: isNullOrUndefined(featured) ? false : featured,
    upsell_ids: upsell_ids || [],
    cross_sell_ids: cross_sell_ids || [],
    type,
    typeName,
    originProduct: origin,
  }
}

export const mapProductList = (products) =>
  !products || !products.length
    ? []
    : products.map((product) => mapBasicCommonKey(product))

export const mapProductFilter = (params) => {
  const { limit, page, sort, search_parameter, ...filters } = params
  Object.keys(filters).forEach(
    (key) => filters[key] == null && delete filters[key],
  )

  if (!filters.type) {
    filters.type = { $ne: 'variation' }
  }

  const updatingParams = {
    limit: limit || 100,
    page: page || 1,
    search_parameter: search_parameter || '',
    sort: sort || null,
    filters,
  }
  if (!updatingParams.sort) {
    delete updatingParams.sort
  }
  return updatingParams
}

export const variationBuilder = (attrs, linkedInventory = true) => ({
  attributes: attrs,
  main_image: null,
  images: [],
  status: 'publish',
  type: 'variation',
  downloadable: false,
  virtual: false,
  manage_stock: false,
  stock_quantity: 0,
  backorders: 'no',
  stock_status: 'instock',
  sku: '',
  regular_price: '',
  sale_price: '',
  date_on_sale_from: null,
  date_on_sale_to: null,
  date_on_sale_from_gmt: null,
  date_on_sale_to_gmt: null,
  weight: '',
  length: '',
  width: '',
  height: '',
  shipping_class: 'same_as_parent',
  tax_class: 'same_as_parent',
  tax_status: 'taxable',
  description: '',
  createdTimeStamp: moment.unix(),
  barcode: '',
  deleting: false,
  linked_inventory: linkedInventory,
})

// return properties in tag constance
export const mapDataToTag = (tagStatus) => {
  switch (tagStatus) {
    case 'publish':
      return 'published'
    default:
      return tagStatus
  }
}

// return properties in stocks.js
export const mapDataToStock = (stockStatus) => {
  switch (stockStatus) {
    case 'instock':
      return 'instock'
    case 'outofstock':
      return 'outofstock'
    case 'onbackorder':
      return 'onbackorder'
    default:
      return stockStatus
  }
}

// return text to show in table
export const mapDataToProductType = (productType) => {
  switch (productType) {
    case 'simple':
      return 'Simple Product'
    case 'variable':
      return 'Variable Product'
    case 'composite':
      return 'Composite Product'
    default:
      return transformTypeToTitle(productType)
  }
}

// JSON data
export const mapProductToExport = (products) =>
  products.map(
    ({
      id,
      name,
      attributes,
      status,
      sku,
      inventory_quantity,
      stock_status,
      price,
      regular_price,
      min_price,
      max_price,
      type,
      categories,
      tags,
      featured,
      cost_of_good,
      variations,
      date_created_gmt,
    }) => ({
      id,
      name:
        type === 'variation' && attributes
          ? attributes.map((attr) => attr.option).join('/')
          : name,
      status: TAGS[mapDataToTag(status)].label,
      sku,
      inventory_quantity,
      stock_status: STOCKS[stock_status]
        ? STOCKS[stock_status].label
        : transformTypeToTitle(stock_status),
      price:
        min_price && max_price
          ? `$${min_price} - $${max_price}`
          : regular_price && price && regular_price !== price
            ? `[$${regular_price}] | $${price}`
            : price && `$${price}`,
      product_type: mapDataToProductType(type),
      categories: categories?.map((c) => c.name).join(', '),
      tags: tags?.map((t) => t.name).join(', '),
      featured: featured && '*',
      cost_of_good: cost_of_good || 0,
      variations: variations?.join(', '),
      date_created_gmt: convertGmtToLocalTime(
        date_created_gmt,
        'YYYY/MM/DD HH:mm',
      ),
    }),
  )

export const mapHeaderCSVProduct = (showColumns) => {
  const csvHeader = ['Id']
  if (showColumns.name) csvHeader.push('Name')
  if (showColumns.status) csvHeader.push('Status')
  if (showColumns.sku) csvHeader.push('Sku')
  if (showColumns.inventory_quantity) csvHeader.push('Inv. Qty')
  if (showColumns.stock_status) csvHeader.push('Stock Status')
  if (showColumns.price) csvHeader.push('Price')
  if (showColumns.product_type) csvHeader.push('Product Type')
  if (showColumns.categories) csvHeader.push('Categories')
  if (showColumns.tags) csvHeader.push('Tags')
  if (showColumns.featured) csvHeader.push('Featured')
  csvHeader.push('COGs')
  csvHeader.push('Variation')
  if (showColumns.date_created_gmt) csvHeader.push('Create Date')
  return csvHeader
}

// Array data to export
export const mapExportData = (items, showColumns) =>
  items.map(
    ({
      id,
      name,
      status,
      sku,
      inventory_quantity,
      stock_status,
      price,
      product_type,
      categories,
      tags,
      featured,
      cost_of_good,
      variations,
      date_created_gmt,
    }) => {
      const contentCSV = [id]
      if (showColumns.name) contentCSV.push(name)
      if (showColumns.status) contentCSV.push(status)
      if (showColumns.sku) contentCSV.push(sku)
      if (showColumns.inventory_quantity) contentCSV.push(inventory_quantity)
      if (showColumns.stock_status) contentCSV.push(stock_status)
      if (showColumns.price) contentCSV.push(price)
      if (showColumns.product_type) contentCSV.push(product_type)
      if (showColumns.categories) contentCSV.push(categories)
      if (showColumns.tags) contentCSV.push(tags)
      if (showColumns.featured) contentCSV.push(featured)
      contentCSV.push(cost_of_good)
      contentCSV.push(variations)
      if (showColumns.date_created_gmt) contentCSV.push(date_created_gmt)
      return contentCSV
    },
  )

export const mappingProductToLineItem = ({ id, ...product }) => ({
  ...product,
  image: product.images && product.images.length ? product.images[0].src : null,
  product_id: id,
  id: product.lineItemId
    ? product.lineItemId
    : `new_item_${id}_${moment().format('YYYYMMDDHHmmss')}`,
})

export const mappingLineItemToProduct = ({ product_id, ...lineItem }) => ({
  ...lineItem,
  id: product_id,
  images: [
    {
      src: lineItem.image,
    },
  ],
  lineItemId: lineItem.id,
})
export const forwardProductToForm = (
  { images, firstLoadAttributesData, firstLoadVariationContainers, ...rest },
  hasSupportedCompositeVersion = false,
) => ({
  ...rest,
  images: images
    ? images.map(
        ({
          id,
          name,
          src,
          size,
          content_type,
          alt,
          date_created,
          date_modified,
          date_created_gmt,
          date_modified_gmt,
        }) => ({
          id,
          name,
          src,
          size,
          content_type,
          alternative_text: alt,
          date_created,
          date_created_gmt: convertGmtToLocalTime(
            date_created_gmt,
            'YYYY-MM-DDTHH:mm:ss',
          ),
          date_modified,
          date_modified_gmt: convertGmtToLocalTime(
            date_modified_gmt,
            'YYYY-MM-DDTHH:mm:ss',
          ),
        }),
      )
    : [],
  tax_class:
    rest.tax_class === '' && rest.tax_status === 'taxable'
      ? 'standard'
      : rest.tax_class,
  variationContainers: firstLoadVariationContainers?.length
    ? firstLoadVariationContainers
    : firstLoadAttributesData || [],
  oldVariationContainer: firstLoadVariationContainers?.length
    ? firstLoadVariationContainers
    : [],
  ...(firstLoadVariationContainers?.length ? { isVariationLoaded: true } : {}),
  sku: rest.type === 'variable' ? '' : rest?.sku,
  sku_variable: rest.type === 'variable' ? rest?.sku : '',
  sku_status: rest.sku_status,
  isScheduled: false,
  isScheduleChange: false,
  published_date: convertGmtToLocalTime(rest.date_created_gmt, 'MM/DD/YYYY'),
  published_time: convertGmtToLocalTime(rest.date_created_gmt, 'hh:mm A'),
  date_on_sale_from: rest.date_on_sale_from_gmt
    ? convertGmtToLocalTime(rest.date_on_sale_from_gmt, 'YYYY-MM-DDTHH:mm:ss')
    : '',
  date_on_sale_to: rest.date_on_sale_to_gmt
    ? convertGmtToLocalTime(rest.date_on_sale_to_gmt, 'YYYY-MM-DDTHH:mm:ss')
    : '',
  ...(rest.type === PRODUCT_TYPE.COMPOSITE
    ? {
        ...(rest.virtual && !rest.shipping_required && !rest.shipping_taxable
          ? {
              shipping_type: COMPOSITE_SHIPPINGS.UNASSIGNED,
            }
          : {
              shipping_type: COMPOSITE_SHIPPINGS.ASSEMBLED,
            }),
      }
    : {}),
  ...(rest.composite_components && rest.composite_components.length > 0
    ? {
        composite_components: rest.composite_components.map((c) => ({
          ...c,
          ...(c.thumbnail_src
            ? {
                thumbnail: {
                  id: c.thumbnail_id,
                  src: c.thumbnail_src,
                },
              }
            : {}),
          description: stripHtmlTags(c.description),
        })),
      }
    : {}),
  ...(rest.composite_scenarios && rest.composite_scenarios.length > 0
    ? {
        composite_scenarios: rest.composite_scenarios.map((s) => ({
          ...s,
          // this new field is used to support version under 8.0.0
          configuration_map: (s.configuration || []).reduce((conf, c) => {
            conf[c.component_id] = c // eslint-disable-line no-param-reassign
            return conf
          }, {}),
          actions: [
            {
              ...COMPOSITE_SCENARIO.actions[0],
              ...(s.actions || []).find((a) => a.action_id === 'compat_group'),
            },
            {
              ...COMPOSITE_SCENARIO.actions[1],
              ...(s.actions || []).find(
                (a) => a.action_id === 'conditional_components',
              ),
            },
            {
              ...COMPOSITE_SCENARIO.actions[2],
              ...(s.actions || []).find(
                (a) => a.action_id === 'conditional_options',
              ),
            },
          ],
        })),
      }
    : {}),
  hasSupportedCompositeVersion,
})

export const revertProductToForm = (
  {
    type,
    sku,
    sku_variable,
    isScheduled,
    published_date,
    published_time,
    isScheduleChange,
    shipping_type,
    composite_components,
    composite_scenarios,
    hasSupportedCompositeVersion,
    ...values
  },
  isEditing,
) => {
  // Handle published time
  const dateCreated = (() => {
    if ((isScheduled || isEditing) && published_date && published_time) {
      const publishDate = moment(
        `${published_date} ${published_time}`,
        'MM/DD/YYYY hh:mm A',
      ).format('YYYY-MM-DDTHH:mm:00')
      if (!isEditing || isScheduleChange) {
        return { date_created_gmt: convertLocalToGmtTime(publishDate) }
      }
    }
    return {}
  })()

  return {
    ...values,
    type,
    ...dateCreated,
    // FOR SIMPLE
    ...(type === PRODUCT_TYPE.SIMPLE ? { sku } : {}),
    date_on_sale_from_gmt: values.date_on_sale_from
      ? convertLocalToGmtTime(values.date_on_sale_from, 'YYYY-MM-DDTHH:mm:ss')
      : '',
    date_on_sale_to_gmt: values.date_on_sale_to
      ? convertLocalToGmtTime(values.date_on_sale_to, 'YYYY-MM-DDTHH:mm:ss')
      : '',
    // FOR VARIABLE
    ...(type === PRODUCT_TYPE.VARIABLE
      ? {
          sku: sku_variable || '',
        }
      : {}),
    // FOR COMPOSITE
    ...(type === PRODUCT_TYPE.COMPOSITE
      ? {
          // Components
          ...(composite_components && composite_components.length > 0
            ? {
                composite_components: composite_components.map(
                  ({
                    thumbnail,
                    thumbnail_id,
                    thumbnail_src,
                    id,
                    open,
                    default_option_id,
                    shipped_individually,
                    discount,
                    ...c
                  }) => ({
                    ...c,
                    ...(id && (id || '').startsWith('component_')
                      ? {}
                      : { id }),
                    tempId: id,
                    ...(thumbnail?.id && thumbnail?.id !== ''
                      ? { thumbnail_id: thumbnail.id }
                      : {}),
                    ...(thumbnail?.src ? { thumbnail_src: thumbnail.src } : {}),
                    quantity_min:
                      c.quantity_min || c.quantity_min === 0
                        ? c.quantity_min === 0
                          ? 0
                          : c.quantity_min === 1
                            ? 1
                            : c.quantity_min >= 2
                              ? c.quantity_min
                              : 1
                        : 1,
                    quantity_max:
                      c.quantity_max || c.quantity_max === 0
                        ? c.quantity_max === 0 || c.quantity_max === 1
                          ? 1
                          : c.quantity_max >= 2
                            ? c.quantity_max
                            : ''
                        : '',
                    default_option_id: default_option_id
                      ? parseInt(default_option_id, 10)
                      : 0,
                    ...(shipping_type === COMPOSITE_SHIPPINGS.ASSEMBLED
                      ? { shipped_individually }
                      : {}),
                    ...(!isNullOrUndefinedOrEmptyString(discount)
                      ? { discount: String(discount) }
                      : {}),
                  }),
                ),
              }
            : {}),
          // Scenarios
          ...(hasSupportedCompositeVersion &&
          composite_scenarios &&
          composite_scenarios.length > 0
            ? {
                composite_scenarios: composite_scenarios.map(
                  ({ configuration_map, id, open, ...s }) => ({
                    ...s,
                    ...(id && (id || '').startsWith('scenario_') ? {} : { id }),
                    tempId: id,
                  }),
                ),
              }
            : {}),
          // Shipping
          ...(shipping_type === COMPOSITE_SHIPPINGS.UNASSIGNED
            ? {
                virtual: true,
                shipping_required: false,
                shipping_taxable: false,
              }
            : {}),
          ...(shipping_type === COMPOSITE_SHIPPINGS.ASSEMBLED
            ? {
                virtual: false,
                shipping_required: true,
                shipping_taxable: true,
              }
            : {}),
        }
      : {}),
  }
}

export const shortProductData = (
  { id: productId, type, sku, ...values },
  origin,
) => {
  const variableSpecificFields = [
    'variations',
    'oldVariationContainer',
    'variationContainers',
  ]
  const compositeSpecificFields = [
    'shipping_type',
    'composite_add_to_cart_form_location',
    'composite_components',
    'composite_editable_in_cart',
    'composite_layout',
    'composite_scenarios',
    'composite_shop_price_calc',
    'composite_sold_individually_context',
  ]

  const changedData = diffObj(
    values,
    origin,
    (key, val) => {
      switch (key) {
        case 'name':
          return (val || '').trim()
        case 'regular_price':
          return val || val === 0 ? val.toString() : ''
        case 'sale_price':
          return val || val === 0 ? val.toString() : ''
        case 'cross_sell_ids':
          return (val || []).map((item) =>
            typeof item === 'number' ? item : item.id,
          )
        case 'upsell_ids':
          return (val || []).map((item) =>
            typeof item === 'number' ? item : item.id,
          )
        case 'weight':
          return val ? val.toString() : ''
        case 'dimensions':
          return val
            ? {
                width: val.width ? val.width.toString() : '',
                height: val.height ? val.height.toString() : '',
                length: val.length ? val.length.toString() : '',
              }
            : null
        case 'tags':
          return (val || []).map((item) => ({
            id: item.id,
            name: item.title,
            slug: item.slug,
          }))
        case 'categories':
          return (val || []).map((item) => ({
            id: item.id,
            name: item.title,
            slug: item.slug,
          }))
        case 'tax_class':
          return val === '' ? 'standard' : val
        default:
          return val
      }
    },
    [...(type === PRODUCT_TYPE.VARIABLE ? variableSpecificFields : [])],
    [
      ...(type === PRODUCT_TYPE.SIMPLE
        ? [...variableSpecificFields, ...compositeSpecificFields]
        : []),
      ...(type === PRODUCT_TYPE.VARIABLE ? compositeSpecificFields : []),
      ...(type === PRODUCT_TYPE.COMPOSITE ? variableSpecificFields : []),
    ],
  )

  return {
    // DEFAULT PROPERTY
    ...(productId ? { id: productId } : {}),
    sku,
    type,
    ...changedData,
  }
}

export const isValidQtyCompositeProduct = (product) => {
  let isValid = true
  if (product.composite_components) {
    for (let i = 0; i < product.composite_components.length; i += 1) {
      const component = product.composite_components[i]
      if (
        !component.optional &&
        ((Number.isInteger(component.quantity_max) &&
          component.selectedProduct?.quantity > component.quantity_max) ||
          (Number.isInteger(component.quantity_min) &&
            component.selectedProduct?.quantity < component.quantity_min))
      ) {
        isValid = false
        break
      }
    }
  }
  return isValid
}

export const displayAddress = (...args) =>
  args.filter((item) => !!item).join(', ')
