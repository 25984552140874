/* eslint-disable no-underscore-dangle */
import moment from 'moment'
import { convertGmtToLocalTime } from '~/utils/datetime'
import { MEDIA_FOLDER_STATIC } from '~/constants/media'

interface IFile {
  content_type?: string
  name?: string
  file_type?: string
  _created?: string
  date_created_gmt?: string
  size?: number
  src?: string
  public_url?: string
  _id?: string
  id?: string
  id_select?: string
}

interface IFolder {
  _id: string
  parent_id?: string
  count: number
  name: string
  children: IFolder[]
}

export const isFileImage = (file: IFile) =>
  file.content_type ? file.content_type.split('/')[0] === 'image' : true

export const filterListFile = (files: IFile[], selectType = 'all') =>
  files.filter((file) => file.name !== 'temp_files' && selectType === 'all')
export const convertPaths = (paths: any[]) =>
  paths ? paths.map((e) => e.name).filter((e) => e !== '') : []
export const getGalleryFileInfo = (filePreview: IFile) => {
  const type = filePreview.file_type
  const name = filePreview.name ? filePreview.name : ''
  const updateDate =
    filePreview._created && filePreview._created
      ? moment(filePreview._created).format('MM/DD/YYYY')
      : ''
  const updateTime =
    filePreview._created && filePreview._created
      ? moment(filePreview._created).format('h:mm a')
      : ''

  const createdDate =
    filePreview.date_created_gmt && filePreview.date_created_gmt
      ? convertGmtToLocalTime(filePreview.date_created_gmt, 'MM/DD/YYYY')
      : ''
  const createdTime =
    filePreview.date_created_gmt && filePreview.date_created_gmt
      ? convertGmtToLocalTime(filePreview.date_created_gmt, 'h:mm a')
      : ''
  const fileSize = filePreview.size
    ? `${parseFloat((filePreview.size / 1024).toFixed(2))} KB`
    : 'No data'
  return {
    id: filePreview._id || filePreview.id,
    id_select: filePreview.id_select,
    name,
    fileType: type || 'No data',
    uploadedOn: updateDate ? `${updateDate} at ${updateTime}` : 'No data',
    fileSize,
    src: filePreview.src || filePreview.public_url,
    dateCreated: createdDate ? `${createdDate} at ${createdTime}` : 'No data',
  }
}

export const handleTransformListFolder = (folders: IFolder[]) => {
  const newFolders = []
  const handleCheckNewList = (
    list: IFolder[],
    level: number,
    paths: string[] = [],
    openIds: string[] = [],
  ) => {
    list.forEach((element) => {
      newFolders.push({
        id: element._id,
        parent_id: element.parent_id || 0,
        count: element.count,
        level,
        name: element.name,
        droppable: element.children.length > 0,
        paths: paths.concat([element.name]),
        openIds: openIds.concat([element._id]),
      })
      if (element.children) {
        handleCheckNewList(
          element.children,
          level + 1,
          paths.concat([element.name]),
          openIds.concat([element._id]),
        )
      }
    })
  }
  handleCheckNewList(folders, 1)
  return newFolders
}

export const getFolderId = (id: string) => {
  if (id === MEDIA_FOLDER_STATIC.ALL_FILE) {
    return undefined
  }
  if (id === MEDIA_FOLDER_STATIC.UNSORTED) {
    return ''
  }
  return id
}

export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
    10,
  )
  return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`
}

export const substringFileName = (str: string) => {
  if (str.length > 15) {
    return `${str.substring(0, 15)}...`
  }
  return str
}
