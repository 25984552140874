import { useQuery } from '@tanstack/react-query'
import httpClient from '~/modules/core/httpClient'
import { sortObjAlphabetically } from '../utility'
import { get as getItem } from '~/utils/storage/memoryStorage'

const fetchWhs = (store_id) =>
  httpClient.json.get('organizations/stores/warehouses', {
    params: {
      ...(getItem('ORGANIZATION_ID')
        ? { organization_id: getItem('ORGANIZATION_ID') }
        : {}),
      store_id,
    },
  })

export const useWHLocations = (storeId) =>
  useQuery({
    queryKey: ['useOrgWarehouses', storeId],
    queryFn: () => fetchWhs(storeId),
    initialData: [],
    select: (data) =>
      (data || [])
        .sort((a, b) => sortObjAlphabetically(a, b, 'name'))
        .map((v) => ({
          company_id:
            v.cova_metadata?.CompanyId || v.greenline_mapping?.company_id,
          id: v.cova_metadata?.LocationId || v.greenline_mapping?.location_id,
          name: v.name,
        })),
    enabled: !!storeId,
  })

export const useWHLocationOptions = (storeId, opts = { defaultAllValue: '' }) =>
  useQuery({
    queryKey: ['useOrgWarehouses', storeId],
    queryFn: () => fetchWhs(storeId),
    initialData: [],
    select: (data) =>
      [
        {
          value: opts.defaultAllValue,
          label: 'All Locations',
          name: 'All Locations',
        },
      ].concat(
        (data || [])
          .sort((a, b) => sortObjAlphabetically(a, b, 'name'))
          .map((item) => ({
            value:
              item.cova_metadata?.LocationId ||
              item.greenline_mapping?.location_id,
            label: item.name,
          })),
      ),
  })
