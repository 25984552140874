import httpClient from '~/modules/core/httpClient'

const url = {
  ORGANIZATIONS: '/organizations',
  STORES: '/stores',
  FULFILLMENT: '/stores/woocommerce/orders/',
}

const ACTIONS = {
  READ_ONFLEET_KEYS_STORES: 'read_of_keys_in_stores',
  READ_OF_KEYS: 'read_of_keys',
  UPDATE_OF_KEYS: 'update_of_keys',
  READ_ONFLEET_ONFLEET_PROCESSING: 'onfleet_processing',
}

const getOnfleetStore = () =>
  httpClient.post(url.ORGANIZATIONS, {
    action: ACTIONS.READ_ONFLEET_KEYS_STORES,
    parameters: {},
  })

const getDetailStore = (store_id) =>
  httpClient.post(url.STORES, ({ organization_id }) => ({
    organization_id,
    store_id,
    action: ACTIONS.READ_OF_KEYS,
    parameters: {},
  }))

const updateOnfleetStore = (params) =>
  httpClient.post(url.STORES, {
    action: ACTIONS.UPDATE_OF_KEYS,
    store_id: params.storeId,
    parameters: params,
  })

const getOnfleetFulfillment = (parameters) =>
  httpClient.post(url.FULFILLMENT, ({ organization_id, store_id }) => ({
    organization_id,
    store_id,
    action: ACTIONS.READ_ONFLEET_ONFLEET_PROCESSING,
    parameters,
  }))

const onfleetApi = {
  getOnfleetStore,
  getDetailStore,
  updateOnfleetStore,
  getOnfleetFulfillment,
}

export default onfleetApi
