export const customerAction = {
  GET_CUSTOMER_DATA_REQUEST: 'customer.GET_CUSTOMER_DATA_REQUEST',
  GET_CUSTOMER_DATA_SUCCESS: 'customer.GET_CUSTOMER_DATA_SUCCESS',
  GET_CUSTOMER_DATA_FAILURE: 'customer.GET_CUSTOMER_DATA_FAILURE',

  RESET_PASSWORD_REQUEST: 'customer.RESET_PASSWORD_REQUEST',
  REMOVE_CUSTOMER_REQUEST: 'customer.REMOVE_CUSTOMER_REQUEST',

  UPDATE_CUSTOMER_STATUS: 'customer.UPDATE_CUSTOMER_STATUS',
  UPDATE_CUSTOMER_STATUS_SUCCESS: 'customer.UPDATE_CUSTOMER_STATUS_SUCCESS',

  UPDATE_CUSTOMER_INFO: 'customer.UPDATE_CUSTOMER_INFO',
  UPDATE_CUSTOMER_INFO_SUCCESS: 'customer.UPDATE_CUSTOMER_INFO_SUCCESS',

  GET_CUSTOMER_LIST_DATA_REQUEST: 'customer.GET_CUSTOMER_LIST_DATA_REQUEST',
  GET_CUSTOMER_LIST_DATA_REQUEST_SUCCESS:
    'customer.GET_CUSTOMER_LIST_DATA_REQUEST_SUCCESS',
  GET_CUSTOMER_LIST_DATA_REQUEST_FAILURE:
    'customer.GET_CUSTOMER_LIST_DATA_REQUEST_FAILURE',

  APPROVE_CUSTOMER: 'customer.APPROVE_CUSTOMER',
  APPROVE_CUSTOMER_SUCCESS: 'customer.APPROVE_CUSTOMER_SUCCESS',
  APPROVE_CUSTOMER_FAILURE: 'customer.APPROVE_CUSTOMER_FAILURE',

  DENY_CUSTOMER: 'customer.DENY_CUSTOMER',
  DENY_CUSTOMER_SUCCESS: 'customer.DENY_CUSTOMER_SUCCESS',
  DENY_CUSTOMER_FAILURE: 'customer.DENY_CUSTOMER_FAILURE',

  GET_COVA_CUSTOMER_DATA_REQUEST: 'customer.GET_COVA_CUSTOMER_DATA_REQUEST',
  GET_COVA_CUSTOMER_DATA_SUCCESS: 'customer.GET_COVA_CUSTOMER_DATA_SUCCESS',
  GET_COVA_CUSTOMER_DATA_FAILED: 'customer.GET_COVA_CUSTOMER_DATA_FAILED',

  GET_COVA_CUSTOMER_LIST_DATA_REQUEST:
    'customer.GET_COVA_CUSTOMER_LIST_DATA_REQUEST',
  GET_COVA_CUSTOMER_LIST_DATA_REQUEST_SUCCESS:
    'customer.GET_COVA_CUSTOMER_LIST_DATA_REQUEST_SUCCESS',
  GET_COVA_CUSTOMER_LIST_DATA_REQUEST_FAILURE:
    'customer.GET_COVA_CUSTOMER_LIST_DATA_REQUEST_FAILURE',

  FETCH_CUSTOMER_NOTES: 'customer.FETCH_CUSTOMER_NOTES',
  SET_CUSTOMER_NOTES: 'customer.SET_CUSTOMER_NOTES',
  SET_CUSTOMER_NOTES_ERROR: 'customer.SET_CUSTOMER_NOTES_ERROR',
}

const getCustomerData = (
  customerId,
  { onSuccess = () => {}, onError = () => {} },
  timeZone,
) => ({
  type: customerAction.GET_CUSTOMER_DATA_REQUEST,
  payload: {
    customerId,
    timeZone,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const setCustomerData = (payload) => ({
  type: customerAction.GET_CUSTOMER_DATA_SUCCESS,
  payload,
})

const resetPassword = (params, onSuccess = () => {}, onError = () => {}) => ({
  type: customerAction.RESET_PASSWORD_REQUEST,
  payload: {
    params,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const removeCustomer = (
  customerId,
  onSuccess = () => {},
  onError = () => {},
) => ({
  type: customerAction.REMOVE_CUSTOMER_REQUEST,
  payload: {
    customerId,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const updateCustomerStatus = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: customerAction.UPDATE_CUSTOMER_STATUS,
  payload: { params },
  callbacks: {
    onSuccess,
    onError,
  },
})

const updateCustomerInfo = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: customerAction.UPDATE_CUSTOMER_INFO,
  payload: { params },
  callbacks: {
    onSuccess,
    onError,
  },
})

const getListCustomer = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: customerAction.GET_CUSTOMER_LIST_DATA_REQUEST,
  payload: { params },
  callbacks: {
    onSuccess,
    onError,
  },
})

const approveCustomer = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: customerAction.APPROVE_CUSTOMER,
  payload: { params },
  callbacks: {
    onSuccess,
    onError,
  },
})

const denyCustomer = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: customerAction.DENY_CUSTOMER,
  payload: { params },
  callbacks: {
    onSuccess,
    onError,
  },
})

const getCovaCustomerData = (
  customerId,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: customerAction.GET_COVA_CUSTOMER_DATA_REQUEST,
  payload: {
    customerId,
  },
  callbacks: {
    onSuccess,
    onError,
  },
})

const getCovaListCustomer = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: customerAction.GET_COVA_CUSTOMER_LIST_DATA_REQUEST,
  payload: { params },
  callbacks: {
    onSuccess,
    onError,
  },
})

const setCovaCustomerData = (payload) => ({
  type: customerAction.GET_COVA_CUSTOMER_DATA_SUCCESS,
  payload,
})
const fetchCustomerNotes = (
  customerId,
  callbacks = { onSuccess: () => {}, onError: () => {} },
) => ({
  type: customerAction.FETCH_CUSTOMER_NOTES,
  payload: customerId,
  callbacks,
})

const setCustomerNotes = (notes) => ({
  type: customerAction.SET_CUSTOMER_NOTES,
  payload: notes,
})

const setCustomerNotesError = (error) => ({
  type: customerAction.SET_CUSTOMER_NOTES_ERROR,
  payload: error,
})

export default {
  getCustomerData,
  setCustomerData,
  resetPassword,
  removeCustomer,
  updateCustomerStatus,
  updateCustomerInfo,
  getListCustomer,
  approveCustomer,
  denyCustomer,

  getCovaCustomerData,
  getCovaListCustomer,
  setCovaCustomerData,
  fetchCustomerNotes,
  setCustomerNotes,
  setCustomerNotesError,
}
