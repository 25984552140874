import { takeLatest, put, fork, call } from 'redux-saga/effects'
import notificationCenterApi from '~/services/apis/notificationCenter'
import { notificationCenterAction } from '~/redux/actions/notificationCenter'

export function* getNotificationCenter(action) {
  try {
    const response = yield call(
      notificationCenterApi.getNotificationCenter,
      action.payload,
    )
    yield put({
      type: notificationCenterAction.GET_NOTIFICATION_CENTER_SUCCESS,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: notificationCenterAction.GET_NOTIFICATION_CENTER_ERR,
      payload: error,
    })
  }
}

export function* watchGetNotificationCenter() {
  yield takeLatest(
    notificationCenterAction.GET_NOTIFICATION_CENTER,
    getNotificationCenter,
  )
}

export default function* reward() {
  yield fork(watchGetNotificationCenter)
}
