import { TypographyOptions } from '@mui/material/styles/createTypography'
import { COLORS } from '~/themes/common'

const typography = {
  fontFamily: 'Rubik',
  h1: {
    fontSize: 32,
    fontWeight: 500,
  },
  h2: {
    fontSize: 26,
    fontWeight: 500,
    lineHeight: '38px',
  },
  h6: {
    fontSize: 18,
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: 14,
  },
  subtitle2: {
    fontSize: 14,
    color: COLORS.gray.textGray2,
    fontWeight: 400,
  },
} as TypographyOptions

export default typography
