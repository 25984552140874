import { takeLatest, put, fork, call } from 'redux-saga/effects'
import settingsApi from '~/services/apis/settings'
import { showDialog } from '~/components/common/Dialog'
import { dialogParams } from '~/components/common/Dialog/CustomDialog'

import {
  SETTING_ACTION,
  getTaxClassesSuccess,
  getTaxClassesFailed,
  createTaxClassSuccess,
  createTaxClassFailed,
  removeTaxClassSuccess,
  removeTaxClassFailed,
} from '../actions/settings'

export function* getNotificationSettings(action) {
  try {
    const response = yield call(
      settingsApi.getNotificationSettings,
      action.payload.params,
    )
    yield put({
      type: SETTING_ACTION.GET_NOTIFICATION_SETTING_OK,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: SETTING_ACTION.GET_NOTIFICATION_SETTING_ERR,
      payload: error,
    })
  }
}

export function* updateNotificationSettings(action) {
  try {
    const response = yield call(
      settingsApi.updateNotificationSettings,
      action.payload.params,
    )
    yield put({
      type: SETTING_ACTION.UPDATE_NOTIFICATION_SETTING_OK,
      payload: response,
    })
    action.callbacks.onSuccess(response)
  } catch (error) {
    yield put({
      type: SETTING_ACTION.UPDATE_NOTIFICATION_SETTING_ERR,
      payload: error,
    })
    action.callbacks.onError(onError)
  }
}

export function* getTaxClasses({ payload }) {
  try {
    const response = yield call(settingsApi.getTaxClasses, payload)
    yield put(getTaxClassesSuccess(response))
  } catch (e) {
    yield put(getTaxClassesFailed(e))
  }
}

export function* createTaxClass({ payload }) {
  try {
    const { params, callback } = payload
    const response = yield call(settingsApi.createTaxClass, params)
    callback(response.slug)
    showDialog(dialogParams.taxClassCreated)
    yield put(createTaxClassSuccess(response))
  } catch (e) {
    showDialog(dialogParams.error(e))
    yield put(createTaxClassFailed(e))
  }
}

export function* removeTaxClass({ payload }) {
  try {
    const { params, callback } = payload
    const response = yield call(settingsApi.removeTaxClass, params)
    callback()
    yield put(removeTaxClassSuccess(response))
    showDialog(dialogParams.taxClassDeleted)
  } catch (e) {
    showDialog(dialogParams.error(e))
    yield put(removeTaxClassFailed(e))
  }
}

export function* watchGetNotificationSettings() {
  yield takeLatest(
    SETTING_ACTION.GET_NOTIFICATION_SETTING,
    getNotificationSettings,
  )
}

export function* watchUpdateNotificationSettings() {
  yield takeLatest(
    SETTING_ACTION.UPDATE_NOTIFICATION_SETTING,
    updateNotificationSettings,
  )
}

export function* watchGetTaxClasses() {
  yield takeLatest(SETTING_ACTION.GET_TAX_CLASSES, getTaxClasses)
}

export function* watchCreateTaxClass() {
  yield takeLatest(SETTING_ACTION.CREATE_TAX_CLASS, createTaxClass)
}

export function* watchRemoveTaxClass() {
  yield takeLatest(SETTING_ACTION.REMOVE_TAX_CLASS, removeTaxClass)
}

export default function* setting() {
  yield fork(watchGetNotificationSettings)
  yield fork(watchUpdateNotificationSettings)
  yield fork(watchGetTaxClasses)
  yield fork(watchCreateTaxClass)
  yield fork(watchRemoveTaxClass)
}
