import produce from 'immer'

import { SKU_TAGS_ACTIONS } from '../actions/skuTags'
import {
  DEFAULT_SKU_TAGS_LIST_DATA,
  DEFAULT_SKU_TAGS_DETAIL,
} from '../default/sku'

const initialState = {
  data: DEFAULT_SKU_TAGS_LIST_DATA,
  loading: false,
  error: null,

  detail: DEFAULT_SKU_TAGS_DETAIL,
  loadingDetail: false,
  errorDetail: null,
  loadingForm: false,
  errorForm: null,
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    switch (type) {
      case SKU_TAGS_ACTIONS.GET_SKU_TAGS_LIST:
        draft.loading = true
        draft.error = null
        break
      case SKU_TAGS_ACTIONS.GET_SKU_TAGS_LIST_OK:
        draft.data = payload
        draft.loading = false
        break
      case SKU_TAGS_ACTIONS.GET_SKU_TAGS_LIST_ERR:
        draft.loading = false
        draft.error = payload
        break

      case SKU_TAGS_ACTIONS.GET_SKU_TAGS_DETAIL:
        draft.loadingDetail = true
        draft.errorDetail = null
        break
      case SKU_TAGS_ACTIONS.GET_SKU_TAGS_DETAIL_OK:
        draft.detail = payload
        draft.loadingDetail = false
        break
      case SKU_TAGS_ACTIONS.GET_SKU_TAGS_DETAIL_ERR:
        draft.loadingDetail = false
        draft.errorDetail = payload
        break
      case SKU_TAGS_ACTIONS.CLEAR_SKU_TAGS_DETAIL:
        draft.loadingForm = false
        draft.detail = payload
        break

      case SKU_TAGS_ACTIONS.UPDATE_SKU_TAGS:
        draft.loadingForm = false
        break
      case SKU_TAGS_ACTIONS.UPDATE_SKU_TAGS_OK:
        draft.loadingForm = false
        draft.errorDetail = payload
        break
      case SKU_TAGS_ACTIONS.UPDATE_SKU_TAGS_FAIL:
        draft.loadingForm = false
        draft.detail = payload
        break

      case SKU_TAGS_ACTIONS.CREATE_SKU_TAGS:
        draft.loadingForm = true
        break
      case SKU_TAGS_ACTIONS.CREATE_SKU_TAGS_OK:
        draft.loadingForm = false
        draft.detail = payload
        break
      case SKU_TAGS_ACTIONS.CREATE_SKU_TAGS_FAIL:
        draft.loadingForm = false
        draft.errorDetail = payload
        break

      default:
        break
    }
  })

export default reducer
