import { takeLatest, all, put, fork, call } from 'redux-saga/effects'
import rewardRequest from '~/services/apis/reward'
import { rewardAction } from '~/redux/actions/reward'
import { convertList, convertListLog } from '~/utils/reward'

export function* getListReward(action) {
  try {
    const response = yield call(rewardRequest.getList, action.payload)
    yield put({
      type: rewardAction.GET_LIST_REWARD_OK,
      payload: convertList(response),
    })
  } catch (error) {
    yield put({
      type: rewardAction.GET_LIST_REWARD_ERR,
      payload: error,
    })
  }
}

export function* getListPointLog(action) {
  try {
    const response = yield call(rewardRequest.getListPointLog, action.payload)
    yield put({
      type: rewardAction.GET_LIST_POINT_LOG_OK,
      payload: convertListLog(response),
    })
  } catch (error) {
    yield put({
      type: rewardAction.GET_LIST_POINT_LOG_ERR,
      payload: error,
    })
  }
}

export function* updateOneCustomerPoint(action) {
  try {
    const response = yield call(rewardRequest.updateOne, action.payload)
    yield put({
      type: rewardAction.UPDATE_CUSTOMER_POINT_OK,
      payload: response,
    })
    action.callbacks.onSuccess()
  } catch (error) {
    yield put({
      type: rewardAction.UPDATE_CUSTOMER_POINT_ERR,
      payload: error,
    })
  }
}

export function* getPointLogCustomer(action) {
  try {
    const response = yield call(
      rewardRequest.getListPointLogCustomer,
      action.payload,
    )
    yield put({
      type: rewardAction.GET_LIST_POINT_LOG_CUSTOMER_OK,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: rewardAction.GET_LIST_POINT_LOG_CUSTOMER_ERR,
      payload: error,
    })
  }
}

export function* getPointLogDetail(action) {
  try {
    const response = yield call(rewardRequest.getDetail, action.payload)
    yield put({
      type: rewardAction.GET_POINT_LOG_DETAIL_OK,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: rewardAction.GET_POINT_LOG_DETAIL_ERR,
      payload: error,
    })
  }
}

export function* watchGetListReward() {
  yield all([
    takeLatest(rewardAction.GET_LIST_REWARD, getListReward),
    takeLatest(rewardAction.GET_LIST_POINT_LOG, getListPointLog),
    takeLatest(rewardAction.UPDATE_CUSTOMER_POINT, updateOneCustomerPoint),
    takeLatest(rewardAction.GET_LIST_POINT_LOG_CUSTOMER, getPointLogCustomer),
    takeLatest(rewardAction.GET_POINT_LOG_DETAIL, getPointLogDetail),
  ])
}

export default function* reward() {
  yield fork(watchGetListReward)
}
