import httpClient from '~/modules/core/httpClient'
import { ACTIONS as COMMON_ACTIONS } from './common'

const url = {
  SCHEDULED_REPORTS: '/scheduled_reports',
  REPORTS: '/report',
}

const ACTIONS = {
  ...COMMON_ACTIONS,
  LIST_ALL_TIMEZONES: 'list_all_timezones',
  LIST_ALL: 'list_all',
  LIST_ALL_GENERATED_REPORTS: 'list_all_generated_reports',
  READ_GENERATED_REPORT: 'read_generated_report',
}

const getTimeZones = () =>
  httpClient.post(url.SCHEDULED_REPORTS, {
    action: ACTIONS.LIST_ALL_TIMEZONES,
    parameters: {},
  })

const createCustomReport = (report) =>
  httpClient.post(url.SCHEDULED_REPORTS, {
    action: ACTIONS.CREATE,
    parameters: report,
  })

const get = (id, organization_id, store_id) =>
  httpClient.post(url.SCHEDULED_REPORTS, (params) => ({
    ...params,
    action: ACTIONS.READ,
    parameters: { _id: id },
    ...(organization_id ? { organization_id } : {}),
    ...(store_id ? { store_id } : {}),
  }))

const update = (report) =>
  httpClient.post(url.SCHEDULED_REPORTS, {
    action: ACTIONS.UPDATE,
    parameters: report,
  })

const remove = (id) =>
  httpClient.post(url.SCHEDULED_REPORTS, {
    action: ACTIONS.DELETE,
    parameters: { _id: id },
  })

const getAllCustomReport = () =>
  httpClient.post(url.SCHEDULED_REPORTS, {
    action: ACTIONS.LIST_ALL,
    parameters: {},
  })

const getGeneratedReports = (id) =>
  httpClient.post(url.SCHEDULED_REPORTS, {
    action: ACTIONS.LIST_ALL_GENERATED_REPORTS,
    parameters: { _id: id },
  })

const getGeneratedReport = (id, generatedReportId, organization_id, store_id) =>
  httpClient.post(url.SCHEDULED_REPORTS, (params) => ({
    ...params,
    action: ACTIONS.READ_GENERATED_REPORT,
    parameters: {
      _id: id,
      generated_report_id: generatedReportId,
    },
    ...(organization_id ? { organization_id } : {}),
    ...(store_id ? { store_id } : {}),
  }))

const getDefaultReport = (parameters) =>
  httpClient.post(url.REPORTS, {
    action: ACTIONS.READ,
    parameters,
  })

export default {
  getTimeZones,
  createCustomReport,
  get,
  update,
  remove,
  getAllCustomReport,
  getGeneratedReports,
  getGeneratedReport,
  getDefaultReport,
}
