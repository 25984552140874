import { takeLatest, all, fork, call, put } from 'redux-saga/effects'
import orderApi from '~/services/apis/order'
import noteApi from '~/services/apis/note'

import Actions, { ORDER_ACTION } from '~/redux/actions/order'

export function* fetchOrderList(action) {
  try {
    const resp = yield call(orderApi.getList, action.payload)
    yield put(Actions.setOrderListData(resp))
    action.callbacks.onSuccess(resp)
  } catch (e) {
    yield put(Actions.setOrderListError(e))
  }
}

export function* fetchOrderDetail(action) {
  try {
    const resp = yield call(orderApi.findOne, action.payload)
    yield put(Actions.setOrderDetail(resp))
  } catch (e) {
    yield put(Actions.setOrderDetailError(e))
    action.callbacks?.onError(e)
  }
}

export function* fetchOrderNotes(action) {
  try {
    const resp = yield call(noteApi.findOne, action.payload)
    yield put(Actions.setOrderNotes(resp.items))
  } catch (e) {
    yield put(Actions.setOrderNotesError(e))
  }
}

export function* fetchOrderPreview(action) {
  try {
    const resp = yield call(orderApi.preview, action.payload)
    yield put(Actions.setOrderPreview(resp))
  } catch (e) {
    yield put(Actions.setOrderPreviewError(e))
  }
}

export function* fetchCovaOrderList(action) {
  try {
    const resp = yield call(orderApi.getCovaList, action.payload)
    yield put(Actions.setCovaOrderListData(resp))
  } catch (e) {
    yield put(Actions.setOrderListError(e))
  }
}

export function* fetchPosOrderList(action) {
  try {
    const resp = yield call(orderApi.getPOSList, action.payload)
    yield put(Actions.setPOSOrderList(resp))
  } catch (e) {
    yield put(Actions.setPOSListError(e))
  }
}

export function* watchOrder() {
  yield all([
    takeLatest(ORDER_ACTION.FETCH_ORDER_LIST, fetchOrderList),
    takeLatest(ORDER_ACTION.FETCH_DETAIL, fetchOrderDetail),
    takeLatest(ORDER_ACTION.FETCH_ORDER_NOTES, fetchOrderNotes),
    takeLatest(ORDER_ACTION.FETCH_PREVIEW, fetchOrderPreview),
    takeLatest(ORDER_ACTION.FETCH_COVA_ORDER_LIST, fetchCovaOrderList),
    takeLatest(ORDER_ACTION.FETCH_POS_ORDER_LIST, fetchPosOrderList),
  ])
}

export default function* order() {
  yield fork(watchOrder)
}
