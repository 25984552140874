import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'
import { handleExportType } from '~/utils/notifyUtils'
import pubsub from '~/utils/pushNotification/pubsub'

const ExportFileNotification = () => {
  const { enqueueSnackbar } = useSnackbar()
  const showNotification = useCallback((evt) => {
    if (handleExportType(evt.type)) {
      enqueueSnackbar('', {
        variant: 'exportFile',
        autoHideDuration: 15000,
        data: {
          type: evt.type,
          id: evt.type,
          data: evt.data,
          shownTime: moment().format('hh:mm A'),
        },
      })
    }
  }, [])

  useEffect(() => {
    pubsub.on('message', showNotification)
    return () => pubsub.off('message', showNotification)
  }, [showNotification])

  return null
}

export default ExportFileNotification
