/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { INTERCEPTOR_ERROR_CODES } from '~/constants/common'
import { BASE_URL } from '~/constants/environment'
import fbAuth from '~/modules/firebase'
import { get as getItem } from '~/utils/storage/memoryStorage'
import HttpConstants from '~/modules/core/httpClient/constants'

// Request interceptors
export const basePath =
  (path = BASE_URL) =>
  (cfg) => {
    cfg.baseURL = path
    return cfg
  }

export const addCredentials = () => (cfg) => {
  cfg.withCredentials = true
  return cfg
}

export const timeout =
  (timeout = 90 * 1000) =>
  (cfg) => {
    cfg.timeout = timeout
    return cfg
  }

export const addHeader =
  (...headers) =>
  async (cfg) => {
    try {
      const customHeaders = await Promise.all(headers.map((h) => h(cfg)))
      cfg.headers = customHeaders.reduce(
        (headers, header) => ({
          ...headers,
          ...header,
        }),
        cfg.headers,
      )

      return cfg
    } catch (e) {
      return cfg
    }
  }

export const orgAndStoreHeader = () => ({
  ...(getItem('STORE_ID') ? { 'bs-store-id': getItem('STORE_ID') } : {}),
  ...(getItem('ORGANIZATION_ID')
    ? { 'bs-org-id': getItem('ORGANIZATION_ID') }
    : {}),
})

export const jwtHeader = async () => {
  const token = await fbAuth.getToken()

  return { Authorization: `Bearer ${token}` }
}

export const defaultRequestHandlerError = () => {}

// Response interceptors
export const defaultResponseHandler = (res) => {
  const { data, message, status } = res.data
  if (status === 'success') {
    if (
      typeof data === 'object' ||
      typeof data === 'number' ||
      typeof data === 'string' ||
      data === undefined
    ) {
      return data
    }
    throw data.message || ''
  }
  throw message
}

export const defaultResponseErrorHandler = (error) => {
  // if invalid token
  if (
    error.response &&
    error.response.data &&
    error.response.status === 401 &&
    error.response.data.code === 1000
  ) {
    // deleteStores()
  }
  if (
    error.response &&
    error.response.data &&
    error.response.data.message === 'User does not exist.'
  ) {
    throw error.response.data.data
  }

  if (
    error.response &&
    error.response.data &&
    Object.values(INTERCEPTOR_ERROR_CODES).includes(error.response.data.code)
  ) {
    throw error.response.data
  }

  if (error.response && error.response.data && error.response.data.message) {
    throw error.response.data.message
  }

  if (error.response && error.response.data && !error.response.data.message) {
    throw error.response.data
  }

  throw error.message
}

export const addCsWsIdHeader = () => ({
  ...(getItem(HttpConstants.WORKSPACE_ID)
    ? { workspace: getItem(HttpConstants.WORKSPACE_ID) }
    : {}),
})

export const handleDefaultChatsoResponse = (res) => res.data

export const handleDefaultSSOResponse = (res) => res.data
