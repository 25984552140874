import memoize from 'lodash/memoize'

export const getAuthStatus = (state) => state.auth.status
export const getAuthError = (state) => state.auth.error

const parseAuthState = memoize((auth) => ({
  ...auth,
  ...(auth.data
    ? {
        data: {
          ...auth.data,
          ...(auth.plugins ? { plugins: auth.plugins } : {}),
        },
      }
    : {}),
}))

export const getAuthState = (state) => parseAuthState(state.auth)
