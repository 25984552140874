import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link as BtnLink, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import get from 'lodash/get'
import Avatar from '@mui/material/Avatar'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import clsx from 'clsx'
import { useParams } from 'react-router-dom'
import { withStyles } from '~/themes/useStyles'
import { useSettings } from '~/redux/hooks/settings'
import {
  getCSVLink,
  handleExportType,
  exportTypePrefix,
} from '~/utils/helpers/csvDownload'
import cantecLogo from '~/assets/icons/cantec.svg'
import pickupLogo from '~/assets/icons/pickup.svg'
import onfleetLogo from '~/assets/icons/onfleet_noti.png'
import curbsideLogo from '~/assets/icons/curbside-noti.svg'
import twoPointLogo from '~/assets/icons/logo/logo_twoPoint.svg'
import storeStartImporting from '~/assets/icons/start_importing.svg'
import storeFinishImporting from '~/assets/icons/finish_importing.svg'
import alertTriangle from '~/assets/icons/ic_alert_triangle.svg'
import driveThroughLogo from '~/assets/icons/ic_shipping_drive_thru.svg'
import style from './style'
import { NOTIFICATION_SHIPPING_METHODS } from '~/constants/settings'
import { useGetStatusIntegrations } from '~/pages/Settings/hooks/useIntegrations'

const icons = {
  cantec_delivery: cantecLogo,
  local_pickup: pickupLogo,
  bsof_local_shipping: onfleetLogo,
  start_importing: storeStartImporting,
  finish_importing: storeFinishImporting,
  notify_private_product: alertTriangle,
  curbside_pickup: curbsideLogo,
  breadstack_canfleet_local_shipping: cantecLogo,
  two_point: twoPointLogo,
  drive_through_pickup: driveThroughLogo,
}

const NotificationItem = ({ classes, noti, onClick, onClose }) => {
  const {
    notificationSettings: { data: notificationSettings },
  } = useSettings()
  const { storeId: sId } = useParams()
  const { data: dataStatusIntegrations } = useGetStatusIntegrations(sId)

  const {
    type,
    data: { status, message, title, desc, orderId, storeId, isGroup, exportId },
    shownTime,
  } = noti

  const [loading, setLoading] = useState(false)

  const closeId = useMemo(() => {
    switch (true) {
      case type === 'app_updated':
        return 'app_updated'
      case type === 'notify_private_product':
        return 'notify_private_product'
      case type === 'import_store':
        return `${status}${storeId}`
      case handleExportType(type):
        return exportId
      default:
        return orderId
    }
  }, [type, status, exportId, orderId, storeId])

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let duration = 0
    if (handleExportType(type)) {
      duration = 0.25
    } else {
      duration = get(
        get(notificationSettings, `${[storeId]}.settings`, []).find(
          (s) =>
            s.type ===
            (type === NOTIFICATION_SHIPPING_METHODS.CANTEC_DELIVERY
              ? NOTIFICATION_SHIPPING_METHODS.CANTEC
              : type === NOTIFICATION_SHIPPING_METHODS.BSOF_LOCAL_SHIPPING
                ? NOTIFICATION_SHIPPING_METHODS.ONFLEET
                : type),
        ),
        'notification_duration',
        1,
      )
    }

    const timeoutId = setTimeout(() => onClose(closeId), duration * 60000)
    return () => {
      onClose(closeId)
      clearTimeout(timeoutId)
    }
  }, [onClose, notificationSettings, orderId, storeId, type, exportId])

  const handleDownload = useCallback(() => {
    setLoading(true)
    getCSVLink(exportId)
      .then((res) => {
        if (res?.signed_url) {
          window.open(res.signed_url, '_blank')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [exportId])

  return (
    <div
      role="presentation"
      onClick={handleExportType(type) ? () => {} : onClick}
      className={classes.notification}
    >
      {type !== 'import_store' &&
      type !== NOTIFICATION_SHIPPING_METHODS.BSOF_LOCAL_SHIPPING ? (
        <div className={classes.meta}>
          <span
            className={
              isGroup ? `${classes.message} highlight` : classes.message
            }
          >
            {message}
          </span>
          <span className={classes.time}>{shownTime}</span>
        </div>
      ) : (
        ''
      )}
      <div
        className={clsx(classes.content, {
          [classes.notificationOnfleetContent]:
            type === NOTIFICATION_SHIPPING_METHODS.BSOF_LOCAL_SHIPPING,
        })}
      >
        {type !== 'app_updated' ? (
          <div className={classes.icon}>
            {handleExportType(type) ? (
              <Avatar className={classes.primary}>
                <InsertDriveFileIcon fontSize="small" />
              </Avatar>
            ) : dataStatusIntegrations?.twoPoint?.connected &&
              type === 'bsof_local_shipping' ? (
              <img src={icons.two_point} alt="" />
            ) : (
              <img
                src={icons[type === 'import_store' ? status : type]}
                alt=""
              />
            )}
          </div>
        ) : (
          ''
        )}
        {type === 'import_store' ? (
          <div className={classes.importStore}>
            <div className={classes.descRow}>
              <h5 className={classes.title}>
                {exportTypePrefix[type] || ''} {title}
              </h5>
              <div>
                <button
                  type="button"
                  className={classes.closeButton}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClose(closeId)
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            <div>
              <div className={classes.descRow}>
                <div>{message}</div>
                <div className={classes.shownTime}>{shownTime}</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.info}>
              {type === NOTIFICATION_SHIPPING_METHODS.BSOF_LOCAL_SHIPPING ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <h5
                    className={clsx(classes.title, {
                      [classes.appUpdatedLabel]: type === 'app_updated',
                    })}
                  >
                    {dataStatusIntegrations?.twoPoint?.connected &&
                    message === 'New Onfleet Order'
                      ? 'New 2Point Order'
                      : message}
                    {' - '}
                    {orderId}
                  </h5>
                  <button
                    type="button"
                    className={classes.closeButton}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClose(closeId)
                    }}
                  >
                    <CloseIcon />
                  </button>
                </Box>
              ) : (
                <h5
                  className={clsx(classes.title, {
                    [classes.appUpdatedLabel]: type === 'app_updated',
                  })}
                >
                  {exportTypePrefix[type] || ''} {title}
                </h5>
              )}

              {handleExportType(type) ? (
                <BtnLink
                  component="button"
                  variant="body2"
                  onClick={handleDownload}
                  disabled={loading}
                >
                  {desc}
                </BtnLink>
              ) : type === NOTIFICATION_SHIPPING_METHODS.BSOF_LOCAL_SHIPPING ? (
                <Box display="flex" justifyContent="space-between">
                  <p className={classes.desc}>{`${title} - ${desc}`}</p>
                  <p className={classes.showTime}>{shownTime}</p>
                </Box>
              ) : (
                <p className={classes.desc}>{desc}</p>
              )}
            </div>
            {type !== NOTIFICATION_SHIPPING_METHODS.BSOF_LOCAL_SHIPPING ? (
              <div className={classes.closeButtonContainer}>
                <button
                  type="button"
                  className={classes.closeButton}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClose(closeId)
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
  )
}

NotificationItem.defaultProps = {
  noti: {},
  onClick: () => {},
  onClose: () => {},
}

NotificationItem.propTypes = {
  classes: PropTypes.shape().isRequired,
  noti: PropTypes.shape(),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
}

export default withStyles(style)(NotificationItem)
