import { takeLatest, all, fork, call, put } from 'redux-saga/effects'
import couponApi from '~/services/apis/coupon'

import Actions, { COUPON_ACTION } from '~/redux/actions/coupon'

export function* fetchCouponList(action) {
  try {
    const resp = yield call(couponApi.getList, action.payload)
    yield put(Actions.setCouponListData(resp))
  } catch (e) {
    yield put(Actions.setCouponListError(e))
  }
}

export function* fetchCouponDetail(action) {
  try {
    const resp = yield call(couponApi.findOne, action.payload)
    yield put(Actions.setCouponDetail(resp))
  } catch (e) {
    yield put(Actions.setCouponDetailError(e))
    action.callbacks?.onError(e)
  }
}

export function* watchCoupon() {
  yield all([
    takeLatest(COUPON_ACTION.FETCH_COUPON_LIST, fetchCouponList),
    takeLatest(COUPON_ACTION.FETCH_DETAIL, fetchCouponDetail),
  ])
}

export default function* coupon() {
  yield fork(watchCoupon)
}
