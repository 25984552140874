export const warehouseTransferActions = {
  GET_TRANSFER_REQUEST: 'warehouseTransfer.GET_TRANSFER_REQUEST',
  GET_TRANSFER_OK: 'warehouseTransfer.GET_TRANSFER_OK',
  GET_TRANSFER_ERR: 'warehouseTransfer.GET_TRANSFER_ERR',

  UNLOCK_TRANSFER_ITEM: 'warehouseTransferIn.UNLOCK_TRANSFER_ITEM',
  UNLOCK_TRANSFER_ITEM_SUCCESS:
    'warehouseTransferIn.UNLOCK_TRANSFER_ITEM_SUCCESS',

  DELETE_TRANSFER_ITEM: 'warehouseTransfer.DELETE_TRANSFER_ITEM',
  DELETE_TRANSFER_ITEM_SUCCESS:
    'warehouseTransfer.DELETE_TRANSFER_ITEM_SUCCESS',
  DELETE_TRANSFER_ITEM_ERROR: 'warehouseTransfer.DELETE_TRANSFER_ITEM_ERROR',

  GET_ALL_WAREHOUSES: 'warehouseTransfer.GET_ALL_WAREHOUSES',
  GET_ALL_WAREHOUSES_SUCCESS: 'warehouseTransfer.GET_ALL_WAREHOUSES_SUCCESS',
  GET_ALL_WAREHOUSES_ERROR: 'warehouseTransfer.GET_ALL_WAREHOUSES_ERROR',
}

const getWarehouseTransfer = (
  params,
  { onSuccess = () => {}, onError = () => {} },
) => ({
  type: warehouseTransferActions.GET_TRANSFER_REQUEST,
  payload: params,
  callbacks: {
    onSuccess,
    onError,
  },
})

export const unlockTransferItem = (payload) => ({
  type: warehouseTransferActions.UNLOCK_TRANSFER_ITEM,
  payload,
})

export const unlockTransferItemFinish = (payload) => ({
  type: warehouseTransferActions.UNLOCK_TRANSFER_ITEM_SUCCESS,
  payload,
})

export const removeTransferItem = (payload) => ({
  type: warehouseTransferActions.DELETE_TRANSFER_ITEM,
  payload,
})

export const removeTransferItemSuccess = (payload) => ({
  type: warehouseTransferActions.DELETE_TRANSFER_ITEM_SUCCESS,
  payload,
})

export const removeTransferItemError = (payload) => ({
  type: warehouseTransferActions.DELETE_TRANSFER_ITEM_ERROR,
  payload,
})

export const getAllWarehouses = (payload) => ({
  type: warehouseTransferActions.GET_ALL_WAREHOUSES,
  payload,
})

export default {
  getWarehouseTransfer,
  unlockTransferItem,
  unlockTransferItemFinish,
  removeTransferItem,
  removeTransferItemSuccess,
  removeTransferItemError,
  getAllWarehouses,
}
