import React from 'react'
import { Dialog, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '~/themes/useStyles'
import Button from '~/components/common/Button'

import dialogParams from './dialogParams'
import styles from './style'

const CustomDialog = ({
  icon,
  title,
  description,
  cancelText,
  okText,
  classes,
  children,
  open,
  onClose,
  onCancel,
  onOk,
  closeOnClick,
  cancelButtonProps,
  titleStyle,
  descriptionStyle,
  okButtonStyle,
  cancelButtonStyle,
  buttonTextStyle,
  ...props
}) => {
  const iconImage = {
    warn: '/icons/warning.svg',
    warn_red: '/icons/warning-red.svg',
    success: '/icons/success.svg',
    blue_success: '/icons/blue_success.svg',
    update: '',
    trash: '/icons/trash.svg',
    red_trash: '/icons/Group-11960.svg',
    mail: '/icons/mail.svg',
    importing: '/icons/importing.svg',
    default: '',
    file: '/icons/file-break.svg',
    redWarn: '/icons/red-warning.svg',
    loader: '/icons/feather-loader.svg',
  }

  const cancel = () => {
    if (closeOnClick) {
      onCancel()
      onClose()
    } else {
      onCancel()
    }
  }

  const ok = () => {
    if (closeOnClick) {
      onOk()
      onClose()
    } else {
      onOk()
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={(_, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          cancel()
        }
      }}
      onKeyPress={(e) => !cancelText && e.key === 'Enter' && ok()}
      {...props}
    >
      {typeof children === 'function' ? (
        children({ onClose })
      ) : (
        <div className={classes.container}>
          <div className={classes.dialogHeader}>
            <img
              src={icon ? iconImage[icon] : iconImage.default}
              alt={`${icon} icon`}
            />
            <Typography
              className={classes.title}
              style={titleStyle}
              variant="h6"
            >
              {title}
            </Typography>
          </div>

          {description && (
            <div className={clsx(classes.dialogContent)}>
              {typeof description === 'string' ? (
                <p
                  className={classes.description}
                  style={descriptionStyle}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(description),
                  }}
                />
              ) : (
                Array.isArray(description) && (
                  <>
                    {description.map((d, i) =>
                      typeof d === 'string' ? (
                        <p
                          className={classes.description}
                          key={i}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(d),
                          }}
                        />
                      ) : (
                        <p className={classes.description} key={i}>
                          {d}
                        </p>
                      ),
                    )}
                  </>
                )
              )}
            </div>
          )}

          {children && <div className={classes.dialogContent}>{children}</div>}

          <div className={classes.dialogFooter}>
            {cancelText && (
              <Button
                className={classes.actionBtn}
                onClick={cancel}
                rounded
                color="third"
                style={cancelButtonStyle}
                {...cancelButtonProps}
              >
                <span style={buttonTextStyle}>{cancelText}</span>
              </Button>
            )}
            <Button
              className={`${classes.actionBtn} ${classes.okButton}`}
              onClick={ok}
              style={okButtonStyle}
              rounded
            >
              <span style={buttonTextStyle}>{okText}</span>
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  )
}

CustomDialog.defaultProps = {
  onCancel: () => {},
  onOk: () => {},
  onClose: () => {},
  closeOnClick: true,
  children: '',
  description: '',
  cancelText: null,
  title: '',
  okText: '',
  icon: '',
  cancelButtonProps: {},
  titleStyle: {},
  descriptionStyle: {},
  cancelButtonStyle: {},
  okButtonStyle: {},
  buttonTextStyle: {},
}

CustomDialog.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  classes: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
  ]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  closeOnClick: PropTypes.bool,
  cancelButtonProps: PropTypes.shape(),
  titleStyle: PropTypes.shape(),
  descriptionStyle: PropTypes.shape(),
  cancelButtonStyle: PropTypes.shape(),
  okButtonStyle: PropTypes.shape(),
  buttonTextStyle: PropTypes.shape(),
}

export { dialogParams }
export default withStyles(styles)(CustomDialog)
