let storagePlace = {}

export function set(key: string, value: any) {
  storagePlace = {
    ...storagePlace,
    [key]: value,
  }
}

export function get(key: string) {
  return storagePlace && storagePlace[key]
}

export function clear(key: string) {
  if (storagePlace && storagePlace[key]) {
    delete storagePlace[key]
  }
}

export function clearAll() {
  storagePlace = {}
}

export default {
  set,
  get,
  clear,
  clearAll,
}
