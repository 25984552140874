import { takeLatest, all, fork, call, put } from 'redux-saga/effects'
import attributeApi from '~/services/apis/attribute'

import Actions, { ATTRIBUTE_ACTION } from '~/redux/actions/attribute'

export function* fetchAttributeList(action) {
  try {
    const resp = yield call(attributeApi.getList, action.payload)
    yield put(Actions.setAttributeListData(resp))
  } catch (e) {
    yield put(Actions.setAttributeListError(e))
  }
}

export function* fetchAttributeDetail(action) {
  try {
    const resp = yield call(attributeApi.findOne, action.payload)
    yield put(Actions.setAttributeDetail(resp))
  } catch (e) {
    yield put(Actions.setAttributeDetailError(e))
  }
}

export function* watchAttribute() {
  yield all([
    takeLatest(ATTRIBUTE_ACTION.FETCH_LIST, fetchAttributeList),
    takeLatest(ATTRIBUTE_ACTION.FETCH_DETAIL, fetchAttributeDetail),
  ])
}

export default function* tag() {
  yield fork(watchAttribute)
}
